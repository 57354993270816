import UiPanel from "./ui-panel";
import FlipMove from "react-flip-move";

class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            canILog: false,
            loading: false
        }
    }

    componentDidMount() {
        if (this.refs.email.value && this.refs.pwd.value) {
            this.inputValidator();
        }
    }

    userLogin(creds) {
        this.setState({ loading: true });
        $.ajax({
            type: "POST",
            url: `${this.props.apiRoot}/security/login`,
            data: JSON.stringify(creds),
            contentType: "application/json",
            success: res => {
                if (this.props.onSuccess && res.token) {
                    this.props.onSuccess(res.token);
                }
            },
            error: err => {
                window.logger.error(err.responseJSON)
                this.refs.code.value = "";
                this.setState({ loading: false })
            }
        })
    }

    inputValidator() {
        //Lower case only
        this.refs.email.value = this.refs.email.value.toLowerCase();
        let user = this.refs.email.value;
        let pwd = this.refs.pwd.value;

        if (user.length > 0 && user.length < 250 && pwd.length > 0 && pwd.length < 250) {
            this.setState({
                canILog: true
            })
        } else {
            this.setState({
                canILog: false
            })
        }
    }
    render() {
        return (
                <div className="form-wrapper">
                    <h1 className="main-heading">TAD SYSTEM</h1>
                    <a className="contact-link" href="mailto:help@tad-system.co.uk">HELP@TAD-SYSTEM.CO.UK</a>
                    <UiPanel
                        icon="user"
                        name="Login"
                    >
                        <div className="login-form">
                            <form
                                onSubmit={e => {
                                    e.preventDefault();
                                }}
                                className={this.state.loading ? "faded" : ""}
                            >
                                <input
                                    className="custom-border-color"
                                    ref="email"
                                    placeholder="email"
                                    type="text"
                                    onChange={this.inputValidator.bind(this)}
                                    defaultValue=""
                                />
                                <input
                                    className="custom-border-color"
                                    ref="pwd"
                                    placeholder="Password"
                                    type="password"
                                    onChange={this.inputValidator.bind(this)}
                                    defaultValue=""
                                />
                                <button
                                    className={!this.state.canILog ? "disabled custom-bg-feature1" : "custom-bg-feature1"}
                                    onClick={() => {
                                        if (this.state.canILog) {
                                            if (/^[^\@\s\\\/]+\@[^\@\s\\\/]+\.[^\@\s\\\/]+$/.test(this.refs.email.value)) {
                                                this.userLogin({
                                                    email: this.refs.email.value,
                                                    pwd: this.refs.pwd.value
                                                })
                                            } else {
                                                logger.error("Bad email address.")
                                            }
                                        }
                                    }}
                                >Login
                            </button>
                                <hr />
                                <input
                                    className="custom-border-color qr-code-input"
                                    type="password"
                                    ref="code"
                                    placeholder="QR code login."
                                    onInput={e => {
                                        // Pull data out of field, and hide field value.
                                        let data = e.target.value.split(" ");
                                        e.target.value = "********************";
                                        // Check the format of credentials is correct.
                                        if (
                                            data.length !== 2 ||
                                            !/^[^\@]+\@[^\@]+$/.test(data[0]) ||
                                            !/^[\w\d]{20}$/.test(data[1])
                                        ) {
                                            logger.error("Not a valid login code.")
                                            return e.target.value = "";
                                        }
                                        // Make the login request.
                                        this.userLogin({
                                            email: data[0],
                                            code: data[1]
                                        });
                                    }}
                                />
                            </form>
                            <FlipMove>
                                {this.state.loading
                                    ?
                                    <div className="overlay">
                                        <img src="/static/media/loading.gif" alt="loading..." />
                                    </div>
                                    :
                                    null
                                }
                            </FlipMove>
                        </div>
                    </UiPanel>
                </div>
        )
    }
}

export default LoginForm;