import Field from "./field";
import DataField from "./dataField";
import SelectField from "./selectField";

class ReportSearchField extends Field {
    constructor(props) {
        super(props);
        this.settings = {
            type: "reportSearch"
        };
        this.searchTypes = [
            {
                val: "id",
                display: "Id"    
            },
            {
                val: "customer",
                display: "Customer"
            },
            {
                val: "status",
                display: "Status"
            },
            {
                val: "type",
                display: "Type"
            }
        ];
        this.searchDefaults = [];
        this.queryDefaults = null;
    }

    renderField() {
        return (
            <div className="field-wrapper">
                <SelectField
                    varname="searchBy"
                    name="Search By"
                    options={this.searchTypes}
                    change={(varname, val, err) => {
                        this.setState({searchBy: val})
                    }}
                >
                    {this.state.searchBy}
                </SelectField>
                <DataField
                    dataName="id"
                    searchBy={this.state.searchBy}
                    route="reports"
                    queryDefaults={this.queryDefaults}
                    defaults={this.searchDefaults}
                    change={(varname, val, err) => {
                        this.change(val);
                    }}
                    optionRenderer={report => (
                        <div className="option-inner">
                            <div>
                                {report.title}
                            </div>
                            <div>
                                {report.type}
                            </div>
                        </div>
                    )
                    }
                >
                    {this.props.children}
                </DataField>
            </div>
        )
    }

}

export default ReportSearchField;