import View from "../view";
import WorkOrderCreator from "./workOrderCreator";
import UiPanel from "../../assets/ui-panel";
import { Aux, renderDate } from "../../helpers";


class WorkOrderView extends View {
	constructor(props) {
		super(props);

		this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);

		this.state = {
			workOrder: null
		}
	}

	componentDidMount() {
		let workOrderId = this.props.router.match.params.workOrderId;

		if (workOrderId && workOrderId !== "create") {
			//Get a single workOrder matching the workOrder.id
			this.getWorkOrder({ id: workOrderId });
		}
	}

	componentDidUpdate(prevProps) {
		let prevWorkOrderId = prevProps.router.match.params.workOrderId;
		let workOrderId = this.props.router.match.params.workOrderId;

		if (prevWorkOrderId !== workOrderId) {
			if (workOrderId && workOrderId !== "create") {
				//Get a single workOrder matching the workOrderId
				this.getWorkOrder({ id: workOrderId });
			}
		}
	}

	createQueryString(obj) {
		return "?" + Object.keys(obj).map(name => {
			if (!obj[name]) return "";
			return `${name}=${obj[name]}`;
		}).join("&");
	}

	getWorkOrder(query, cb) {
		$.get(`${this.props.apiRoot}/workorders/${this.createQueryString(query)}`).done(workOrder => {
			if (cb) {
				cb(workOrder)
			} else {
				this.setState({ workOrder });
			}
		}).catch(err => {
			window.logger.error(err)
		})
	}

	createWorkOrder(workOrder, cb) {
		// console.log("WORKORDER", workOrder);
		$.post(`${this.props.apiRoot}/workorders`, JSON.stringify(workOrder)).done(response => {
			//Redirecting to Deliveries View
			logger.success(response.msg);
			this.setState({ workOrder: response.workOrder });
			// Run callback if passed
			if (cb) cb()
			// this.props.router.history.push("/schedule");
		}).catch(err => {
			logger.error(err)
		})
	}

	updateWorkOrder(changes, id, cb) {
		// console.log("UPDATING,", changes);
		$.ajax({
			type: "PUT",
			url: `${this.props.apiRoot}/workorders/${id}`,
			data: JSON.stringify(changes)
		}).done((response) => {
			logger.success(response.msg)
			this.setState({ workOrder: response.workOrder });
			// Run callback if passed
			if (cb) cb()
		}).catch(err => {
			logger.error(err)
		})
	}

	renderView() {
		// console.log("props", this.props)
		return (
			<Aux>
				<div className="row heading-row">
					<div className="col-12">
						<h1 className="client-view custom-c-dark">{`${this.client} Work Orders`}</h1>
						<h5 className="date custom-c-mid">{renderDate()}</h5>
					</div>
				</div>
				<div className="row view workOrdersView">
					<div className="col-12 panel-wrapper">
						<UiPanel
							name={this.state.workOrder ? `Editing WorkOrder - ${this.state.workOrder.id}` : "WorkOrder Creator"}
							icon="document"
						>
							<WorkOrderCreator
								router={this.props.router}
								apiRoot={this.props.apiRoot}
								workOrder={this.state.workOrder}
								user={this.props.user}
								onSubmit={(workOrder, cb) => {
									this.createWorkOrder(workOrder, cb)
								}}
								onUpdate={(workOrder, id, cb) => {
									this.updateWorkOrder(workOrder, id, cb)
								}}
							/>
						</UiPanel>
					</div>
				</div>
			</Aux>
		)
	}
}

export default WorkOrderView;