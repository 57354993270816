import Stage from "./stage";

class Custom extends Stage {

    renderStage() {
        return (
            <div className="customStage">
                {this.props.config.render.bind(this)(this)}
            </div>
        )
    }
}

export default Custom;