/**
 * Slightly different item styling. 
 */

import UiList from "./ui-list";
import UiPanelItemWo from "./ui-panel-item-wo";

class UiCalendarList extends UiList {
    constructor(props) {
        super(props)
        this.state = {
            currentPos: 0,
            showGroup: null
        }
    }

    itemData(item) {
        switch (item.type) {
            case "delivery":
                return {
                    icon: "delivery",
                    link: "deliveries",
                    color: "custom-bg-feature4"
                }
            case "workOrder":
                return {
                    icon: "document",
                    link: "workorders",
                    color: item.data.customer.split("-").length > 2 ? "custom-bg-feature1" : "custom-bg-feature5"
                }
            case "collection":
                return {
                    icon: "collection",
                    link: "collections",
                    color: "custom-bg-feature6"
                }
            case "courier-collection":
                return {
                    icon: "collection",
                    link: "courier-collections",
                    color: "custom-bg-feature8"
                }
            case "deliveryGroup":
                return {
                    icon: "delivery",
                    color: "custom-bg-feature7"
                }
			case "removal":
				return {
					icon: "home",
					link: "removals",
					color: "custom-bg-feature9"
				}
            default:
                console.error("Bad item type: ", item);
                break;
        }
    }

    renderListItems() {
        let items = this.props.listItems.slice(this.state.currentPos, 3 + this.state.currentPos);
        // console.log("items", this.props.listItems);
        return items.map(item => {
            let icon = this.itemData(item).icon;
            let bgColor = this.itemData(item).color;
            let link = this.itemData(item).link;
            // let icon = item.type === "delivery" ? "delivery" : "document";
            let subtextLength = item.subtext ? item.subtext.split(",").length : 0;
            if (item.type === "deliveryGroup") {
                return (
                    <div
                        className="statusItem custom-bg-light list-hover"
                        style={{
                            overflow: "initial"
                        }}
                        key={item.title}
                        onClick={() => {
                            this.setState({ showGroup: item.title })
                        }}
                    >
                        <div
                            className={"icon-wrap " + bgColor}
                            style={{
                                borderRadius: "2px 0px 0px 2px"
                            }}
                        >
                            <div className={`icon-white ${icon}`}></div>
                        </div>
                        <div className="text custom-c-dark">{item.title}</div>
                        <div className={"delivery-list custom-bg-dark shadow-1" + (this.state.showGroup === item.title ? " show" : "") + (this.props.row >=3 ? " bottomRow" : " topRow")}>
                            <div 
                                className="icon close-icon"
                                onClick={e => {
                                    e.stopPropagation();
                                    this.setState({ showGroup: null })
                                }} 
                            />
                            {item.events.map(event => {
                                return (
                                    <a
                                        className="statusItem custom-bg-light list-hover"
                                        target="_blank"
                                        key={event.ref}
                                        href={`/deliveries/${event.ref}`}
                                        style={{
                                            paddingRight: "10px"
                                        }}
                                    >
                                        <div
                                            className={"icon-wrap custom-bg-feature4"}
                                        >
                                            <div className={`icon-white delivery`}></div>
                                        </div>
                                        <div className="text custom-c-dark">{event.title}</div>
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                )
            } else if (item.type === "workOrder") {
                return (
                    <UiPanelItemWo
                        row={this.props.row}
                        key={item.ref}
                        item={item}
                        icon={icon}
                        bgColor={bgColor}
                        link={link}
                        subtextLength={subtextLength} 
                    />
                )
            } else {
                return (
                    <a
                        href={`/${link}/${item.ref}`}
                        target="_blank"
                        key={item.ref}
                        className={"statusItem custom-bg-light list-hover" + ((item.data && item.data.status) === "cancelled" ? " cancelled" : "")}
                    >
                        <div
                            className={"icon-wrap " + bgColor}
                        >
                            <div className={`icon-white ${icon}`}></div>
                        </div>
                        <div className="text custom-c-dark">{item.title}</div>
                        {subtextLength
                            ?
                            <div className="subtext custom-c-dark custom-border-color">
                                {subtextLength > 1 ? `${subtextLength}x` : item.subtext}
                            </div>
                            :
                            null
                        }
                    </a>
                )
            }
        })
    }

}

export default UiCalendarList
