import Stage from "./stage";
import FlipMove from "react-flip-move";

class ListStage extends Stage {
    constructor(props) {
        super(props);
        this.state.error = ""
    }

    // componentDidMount() {
    //     super.componentDidMount();
    //     this.refs.input.focus();
    // }

    renderStage() {
        let items = (typeof this.props.config.items === "function" ?
            this.props.config.items() : this.props.config.items) || [];
        // If sorting function passed , sort items
        if (this.props.config.sort) {
            items = this.props.config.sort(items);
            // console.log(items)
        }
        return (
            <div className="listStage">
                {items.length
                    ?
                    <FlipMove>
                        {items.map((item, index) => {
                            return <div
                                onClick={e => {
                                    if (this.props.config.disable && this.props.config.disable(item))
                                        return true;
                                    this.stageComplete(item);
                                }}
                                key={index}
                                className="option custom-bg-light list-hover custom-c-dark"
                            >
                                <div className="border-color custom-bg-feature1"></div>
                                <div className={`icon ${this.props.config.icon}-icon`}></div>
                                {this.props.config.renderItem(item, index, this)}
                            </div>
                        })}
                    </FlipMove>
                    :
                    <div className="no-items">
                        No items to process
                    </div>
                }
            </div>
        )
    }

}

export default ListStage;