import DataField from "./dataField";
// import StaggeredSearchField from "./staggeredSearchField";
// import FlipMove from "react-flip-move";

class StockDataField extends DataField {
    getData(value) {
        // Generate object of query options, and add in defaults.
        let options = Object.assign({},
            this.props.queryDefaults || {},
            this.props.customer ? { customer: this.props.customer } : {},
            this.props.searchBy ? {
                [this.props.searchBy]: value
            } : {}
        );
        // console.log("QD: ", this.props.queryDefaults);

        // console.log("Options: ", options);
        $.get(`${window.apiRoot}/${this.props.route}${this.createQueryString(options)}`)
            .done(data => {
                // console.log("data:", data);
                this.setState({
                    data: data.results
                        .concat(this.props.defaults || [])
                        .filter(item => {
                            return this.props.filter ? this.props.filter(item) : true;
                        }),
                    open: true
                });
            }).catch(err => {
                logger.error(err);
            });
    }
}

export default StockDataField;
