import FlipMove from "react-flip-move";
import { renderDate, rid } from "../../helpers";
import TextareaField from "../../fields/textareaField";
import SelectField from "../../fields/selectField";
import VehicleSizeClassField from "../../fields/vehicleSizeClassField";
import VehicleSearchField from "../../fields/vehicleSearchField";
import VehicleListField from "../../fields/vehicleListField";
import FileField from "../../fields/fileField";
import TimeField from "../../fields/timeField";
import DateField from "../../fields/dateField";
import DataField from "../../fields/dataField";
import Context from "../../context";
import { Prompt } from "react-router-dom";
import RemovalStockList from "./RemovalStockList";
import React from "react";

class RemovalEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			removal: this.props.removal || {
				dateIncoming: (dString => {
					if (!dString) return Date.now();
					let d = new Date(dString.split("#")[1]);
					if (d.toString() === "Invalid Date") {
						return Date.now();
					} else {
						return d.getTime();
					}
				})(window.location.hash),
				customer: null,
				status: "pending",
				notes: null,
				timeOutgoing: "all day",
				stock: [],
				images: []
			},
			stock: [],
			changes: {},
			showImages: null,
			editingCustomer: false,
			customerData: null,
			showSignature: false
		}
	}

	displayOnly() {
		return this.props.user.role === 3 || (this.props.user.role === 4 && this.props.removal && this.props.removal.status !== "pending")
	}

	componentDidMount() {
		// Get full stock items
		if (this.props.removal.stock.length) {
			this.getStock()
		}
		// And customer
		if (this.props.removal.customer) {
			this.getCustomer(this.props.removal.customer)
		}
		window.onbeforeunload = () => {
			if (Object.keys(this.state.changes).length) {
				return "You have unsaved changes. Are you sure?";
			} else {
				return null;
			}
		}
	}

	componentWillUnmount() {
		window.onbeforeunload = null;
	}

	componentDidUpdate(pp, ps) {
		const hasChanged = this.hasRemovalChanged(pp.removal, this.props.removal);
		if (hasChanged) {
			this.setState({
				removal: this.props.removal || {
					dateIncoming: null,
					customer: null,
					status: "pending",
					notes: null,
					timeOutgoing: "all day",
					stock: [],
					images: []
				}
			})
			this.getStock();
			if (this.props.removal) {
				this.getCustomer(this.props.removal.customer)
			}
		}
	}

	getStock() {
		// Creating an array of stock id's
		let stock = this.state.removal.stock.map(s => s.id).filter(Boolean);
		// Only make request if removal has stock
		if (stock.length) {
			$.post(`${this.props.apiRoot}/stock/multi`, JSON.stringify(stock)).done(response => {
				this.setState({ stock: response.results })
			}).catch(err => {
				logger.error(err)
			})
		}
	}

	getCustomer(name) {
		$.get(`${this.props.apiRoot}/customers/${name}`).then(response => {
			this.setState({
				customerData: response.customer
			});
		}).catch(err => {
			logger.error(err);
		})
	}

	hasRemovalChanged(oldRemoval, newRemoval) {
		if (!oldRemoval && newRemoval) return true;
		if (!newRemoval && oldRemoval) return true;
		if (!oldRemoval && !newRemoval) return false;
		if (oldRemoval.stock.length !== newRemoval.stock.length) return true;

		let hasChanged = false;
		Object.keys(newRemoval).forEach(key => {
			if (newRemoval[key] !== oldRemoval[key]) hasChanged = true;
		})
		return hasChanged;
	}

	renderSelectCustomerField() {
		return this.state.editingCustomer
			?
			<React.Fragment>
				<DataField
					varname="customer"
					defaults={[
						{
							name: "internal",
							displayName: "Internal User"
						}
					]}
					optionRenderer={customer => (
						<div className="option-inner">
							<b>{customer.displayName}</b> <br />({customer.name})
						</div>
					)}
					label="Customer"
					route="customers"
					dataName="name"
					searchBy="term"
					change={(varname, value, error) => {
						let customer = value ? value.name : null;
						if (!customer) return;
						let removal = Object.assign({}, this.state.removal, { customer });
						let changes = Object.assign({}, this.state.changes, { customer });
						this.setState({
							removal,
							changes,
							editingCustomer: false
						});
					}}
				>
					{this.state.removal.customer || ""}
				</DataField>
				<br />
			</React.Fragment>
			:
			<div className="show-customer">
				<label className="custom-c-dark">Customer</label>
				<div className="name custom-c-feature2">
					{this.state.removal.customer || "Internal"}
					{this.props.user.role < 3
						?
						<button
							className="btn"
							style={{
								marginLeft: "20px"
							}}
							onClick={e => {
								if (window.confirm("Are you sure you want to edit the customer on this removal? This can cause unexpected issues if you do not know what you're doing.")) {
									this.setState({
										editingCustomer: true
									});
								}
							}}
						>
							Edit
						</button>
						:
						null
					}
				</div>
			</div>
	}

	renderStatusButton() {
		if (this.displayOnly() || this.state.removal.status === "unloaded") {
			// Not for editing
			return (
				<div className="display-only custom-c-dark">
					<label>Removal Status</label>
					<p>{this.state.removal.status}</p>
				</div>
			)
		} else {
			let status = this.state.removal.status;
			let optionsArray = [
				{
					val: "cancelled",
					display: "Cancelled"
				},
				{
					val: "pending",
					display: "Pending"
				},
				{
					val: "confirmed",
					display: "Confirmed"
				},
				{
					val: "collected",
					display: "Collected"
				},
				{
					val: "in progress",
					display: "In progress"
				},
				{
					val: "delivered",
					display: "Delivered"
				}
			];
			return (
				<div style={{ paddingBottom: "20px" }}>
					<SelectField
						label="Removal status"
						varname="status"
						options={optionsArray}
						change={(varname, value, error) => {
							if (value !== status) {
								if ((value === "collected") || (value === "unloaded") && this.props.user.role > 1) {
									logger.error(`You cannot manually change a removal status to ${value}.`)
								} else {
									if (window.confirm("Are you sure you want to manually change the status of this collection? This can cause unexpected issues if you do not know what you're doing.")) {
										let removal = Object.assign({}, this.state.removal);
										removal.status = value;
										let changes = Object.assign({}, this.state.changes);
										changes.status = value;
										this.setState({ changes, removal });
									}
								}
							}
						}}
					>
						{status}
					</SelectField>
				</div>
			)
		}
	}

	groupSizeClass(sizeString) {
		//If nothing passed
		if (!sizeString) return null;
		let sizeArr = sizeString.split(",");
		//If only one
		if (sizeArr.length <= 1) return (
			<p>{sizeString}</p>
		);
		//Else
		let sizeObj = {}
		sizeArr.forEach(item => {
			if (sizeObj[item]) {
				sizeObj[item]++
			} else {
				sizeObj[item] = 1
			}
		})
		return Object.keys(sizeObj).map(size => {
			return (
				<div className="size" key={size}>
					<span>{`${size}: `}</span>
					<span>{`x${sizeObj[size]}`}</span>
				</div>
			)
		})
	}

	renderAddress() {
		if (!this.state.customerData || !this.state.customerData.defaultAddress) return null;
		return <div className="all-addresses custom-c-dark">
			<label style={{ paddingLeft: 0 }}>Customer Address</label>
			<div className="address-group custom-c-light" style={{ paddingLeft: 0 }}>
				<div className="address">
					{this.state.customerData.defaultAddress}
				</div>
			</div>
		</div>
	}

	render() {
		const allStockIds = this.state.stock.map(s => s.id);
		const displayOnly = this.displayOnly();

		return (
			<div className="removal-editor">
				<Prompt
					when={Object.keys(this.state.changes).length > 0}
					message="You have unsaved changes, are you sure?"
				/>
				{this.renderSelectCustomerField()}
				{this.renderStatusButton()}
				{this.props.removal && this.props.removal.signature
					?
					<div
						className="show-risk-assessment button custom-bg-feature1"
						onClick={() => {
							this.setState({
								showSignature: !this.state.showSignature
							})
						}}
					>
						{this.state.showSignature ? "Hide signature" : "Show signature"}
					</div>
					:
					null
				}
				{(
					!displayOnly &&
					this.props.removal.id &&
					["collected", "delivered", "in progress"].includes(this.state.removal.status)
				) && <a
					style={{ textDecoration: "none" }}
					className="show-risk-assessment button custom-bg-feature1"
					href={`/removals/inventory/${this.props.removal.id}`}
					target="_blank"
				>
						Show inventory
					</a>
				}
				{this.state.showSignature
					?
					<React.Fragment>
						{this.props.removal.signedBy
							?
							<div className="display-only custom-c-dark">
								<br />
								<label>Signed by</label>
								<p>{this.props.removal.signedBy}</p>
							</div>
							:
							null
						}
						<div className="signature custom-bg-light custom-border-3">
							<img src={this.props.removal.signature} alt="signature" />
						</div>
					</React.Fragment>
					:
					null
				}
				<div className="date-time-notes">
					{!displayOnly
						?
						<DateField
							varname="dateIncoming"
							label="Date incoming"
							change={(varname, value, error) => {
								const removal = Object.assign({}, this.state.removal, {
									dateIncoming: value
								});
								const changes = Object.assign({}, this.state.changes, {
									dateIncoming: value
								});
								this.setState({ removal, changes });
							}}
						>
							{this.state.removal.dateIncoming}
						</DateField>
						:
						<div className="display-only split first custom-c-dark">
							<label>Date incoming</label>
							<p>{renderDate(this.state.removal.dateIncoming)}</p>
						</div>
					}
					{!displayOnly
						?
						<TimeField
							varname="time"
							label="Time"
							from={900}
							to={1800}
							change={(varname, value, error) => {
								const removal = Object.assign({}, this.state.removal, {
									timeIncoming: value
								});
								const changes = Object.assign({}, this.state.changes, {
									timeIncoming: value
								});
								this.setState({ removal, changes });
							}}
						>
							{this.state.removal.timeIncoming}
						</TimeField>
						:
						<div className="display-only split custom-c-dark">
							<label>Time</label>
							<p>{this.state.collection.timeIncoming}</p>
						</div>
					}
					{!displayOnly
						?
						<TextareaField
							varname="notes"
							label="Removal notes"
							change={(varname, value, error) => {
								const removal = Object.assign({}, this.state.removal, {
									notes: value
								});
								const changes = Object.assign({}, this.state.changes, {
									notes: value
								});
								this.setState({ removal, changes })
							}}
						>
							{this.state.removal.notes || ""}
						</TextareaField>
						:
						<div className="display-only custom-c-dark">
							<label>Removal notes</label>
							<p>{this.state.removal.notes || "none"}</p>
						</div>
					}
					{this.props.user.role <= 2
						?
						<div className="sizeClass">
							<VehicleSizeClassField
								style={{
									marginBottom: "15px"
								}}
								label="Size class"
								varname="sizeClass"
								change={(varname, value, error) => {
									const removal = Object.assign({}, this.state.removal, {
										sizeClass: value
									});
									const changes = Object.assign({}, this.state.changes, {
										sizeClass: value
									});
									this.setState({ changes, removal })
								}}
							>
								{this.state.removal.sizeClass}
							</VehicleSizeClassField>
						</div>
						:
						<div className="display-only custom-c-dark">
							<label>Size class</label>
							{this.groupSizeClass(this.state.removal.sizeClass)}
						</div>
					}
					{!displayOnly && this.props.user.role < 4
						?
						<VehicleSearchField
							label="Primary Vehicle."
							varname="vehicle"
							defaultSearch="locator"
							change={(varname, vehicleObject, error) => {
								const removal = Object.assign({}, this.state.removal, {
									vehicle: vehicleObject ? vehicleObject.locator : ""
								});
								const changes = Object.assign({}, this.state.changes, {
									vehicle: vehicleObject ? vehicleObject.locator : ""
								});
								this.setState({ removal, changes });
							}}
						>
							{this.state.removal.vehicle}
						</VehicleSearchField>
						:
						<div className="display-only custom-c-dark">
							<label>Vehicle</label>
							<p>{this.state.removal.vehicle || "Vehicle not assigned."}</p>
						</div>
					}
					{!displayOnly && this.state.removal.vehicle && this.props.user.role < 4
						?
						<VehicleListField
							label="Additional vehicles"
							varname="vehicles"
							exclude={[this.state.removal.vehicle]}
							change={(varname, vehicles, error) => {
								const removal = Object.assign({}, this.state.removal, { vehicles });
								const changes = Object.assign({}, this.state.changes, { vehicles });
								this.setState({ removal, changes });
							}}
						>
							{this.state.removal.vehicles || []}
						</VehicleListField>
						:
						(this.state.removal.vehicles || []).length
							?
							<div className="display-only custom-c-dark">
								<label>Vehicles</label>
								<p>{(this.state.removal.vehicles || []).join(", ")}</p>
							</div>
							:
							null
					}
				</div>
				{this.renderAddress()}
				{this.state.removal.images && this.state.removal.images.length
					?
					<FileField
						label="Images"
						readOnly={displayOnly ? true : false}
						varname="images"
						change={(varname, value, error) => {
							const images = value.map(v => v.url);
							const removal = Object.assign({}, this.state.removal, { images });
							const changes = Object.assign({}, this.state.changes, { images });
							this.setState({ removal, changes });
						}}
					>
						{this.state.removal.images
							.map(f => {
								return { name: f, url: f, type: "image" }
							})
						}
					</FileField>
					:
					null
				}
				{["collected", "delivered", "in progress"].includes(this.state.removal.status)
					?
					<div className="removal-stock">
						<p className="custom-c-light">Stock Items</p>
						<RemovalStockList
							customer={this.state.removal.customer}
							user={this.props.user}
							displayOnly={displayOnly}
							controls={(() => {
								if (this.state.removal.status === "unloaded") {
									return ["edit", "delete", "print", "image"]
								};
								return ["image"];
							})()}
							allCreatorFields={false}
							items={this.state.removal.stock
								.map(s => {
									const match = this.state.stock.find(item => s.id === item.id) || {};
									return { ...match, ...s }
								})
								.filter(s => (s.code || s.productBarcode))
							}
							onNew={item => {
								item.id = item.id || rid();
								const stock = [...this.state.removal.stock, item];
								this.setState({
									removal: Object.assign({}, this.state.removal, {
										stock
									}),
									changes: Object.assign({}, this.state.changes, {
										stock
									})
								})
							}}
							onUpdate={(changes, itemId) => {
								const stock = this.state.removal.stock.map(s => {
									if (s.id === itemId) {
										return Object.assign({}, s, changes);
									}
									return s;
								});
								this.setState({
									removal: Object.assign({}, this.state.removal, {
										stock
									}),
									changes: Object.assign({}, this.state.changes, {
										stock
									})
								})
							}}
							onDelete={id => {
								const stock = this.state.removal.stock.filter(s => {
									return s.id !== id;
								});
								this.setState({
									removal: Object.assign({}, this.state.removal, {
										stock
									}),
									changes: Object.assign({}, this.state.changes, {
										stock
									})
								});
							}}
						/>
					</div>
					:
					null
				}
				<FlipMove>
					{Object.keys(this.state.changes).length
						?
						<div className="save-changes">
							{"Changes were made, make sure you save them!"}
						</div>
						:
						null
					}
				</FlipMove>
				{!displayOnly
					?
					<div
						className={"update-removal-button button custom-bg-feature1" + (Object.keys(this.state.changes).length ? "" : " disabled")}
						onClick={() => {
							// Update only when if changes were made
							if (Object.keys(this.state.changes).length) {
								// Set blank fields to null.
								let changes = Object.assign({}, this.state.changes);
								Object.keys(changes).forEach(key => {
									if (changes[key] === "") changes[key] = null;
								});
								// Save them.
								this.props.onUpdate(changes, this.state.removal.id, () => {
									this.setState({ changes: {} });
								});
							}
						}}
					>
						Save Changes
					</div>
					:
					null
				}
			</div>
		)
	}
}

RemovalEditor.contextType = Context;

export default RemovalEditor;

