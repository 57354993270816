export default function Spinner({ size = 25, color = "#ffffff", style = {} }) {
	return (
        <div style={{
            height: "100%",
            display: "flex",
            justifyContent: "center"
        }}>
            <span
                className="lds-dual-ring"
                style={{
                    ...style,
                    width: size,
                    height: size
                }}
            >
                <span className="inner" style={{
                    width: size * 0.8,
                    height: size * 0.8,
                    borderWidth: size * 0.15,
                    borderColor: `${color} transparent ${color} transparent`
                }} />
            </span>
        </div>
	)
}