import Stage from "./stage";
// import FlipMove from "react-flip-move";

class Confirm extends Stage {

    renderStage() {
        return (
            <div className="confirmStage">
                <div className="icon-wrap">
                    {this.config().icon ? <div className="icon stock-icon" /> : null}
                </div>
                <div className="message">
                    {this.config().message ? this.config().message() : null}
                </div>
                <button
                    className="confirm button custom-bg-feature1"
                    onClick={() => {
                        this.stageComplete();
                    }}
                >
                    Confirm
                </button>
            </div>
        )
    }
}

Confirm.defaultProps = {
    title: "Confirm.",
}

export default Confirm;