const skuProductTemplate = {
	name: "SKU Products",
	columns: [
		"productBarcode",
		"description",
		"description",
		"description",
		"ignore",
		"ignore",
		"ignore",
		"productCode",
		"quantity"
	],
	columnLabels: [
		"product_barcode",
		"description_1",
		"description_2",
		"customer_name",
		"",
		"",
		"",
		"SKU",
		"quantity"
	],
	retainQuantities: true
}

export default skuProductTemplate;