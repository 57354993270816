import View from "./view";
import TextField from "../fields/textField";
import SelectField from "../fields/selectField";
import PasswordField from "../fields/passwordField";
import { Aux, renderDate } from "../helpers";
import Qrcode from "../assets/qrcode";

/**
 *  PROPS:
 * 
 *      user: OBJECT with user data
 *      change: FUNCTION(newSettings) handler for changing user settings object
 */

class SettingsView extends View {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            changes: {},
            code: null
        }

        this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);

        this.colorThemes = [
            {
                val: "light",
                display: "Light"
            },
            {
                val: "dark",
                display: "Dark"
            }
        ]
    }

    renderView() {
        // console.log("STATE: ", this.state);
        // console.log("PROPS: ", this.props);
        return (
            <Aux>
                <div className="row heading-row">
                    <div className="col-12">
                        <h1 className="client-view custom-c-dark">{`${this.client} Settings`}</h1>
                        <h5 className="date custom-c-mid">{renderDate()}</h5>
                    </div>
                </div>
                <div className="row view settingsView">
                    <div className="col-12">
                        <div className="field-combiner">
                            <TextField
                                varname="name"
                                label="Display Name"
                                change={(varname, value, err) => {
                                    let user = Object.assign({}, this.state.user);
                                    user.name = value;

                                    let changes = Object.assign({}, this.state.changes);
                                    changes.name = value;
                                    this.setState({ user, changes });
                                }}
                            >
                                {this.state.user.name}
                            </TextField>
                            <SelectField
                                varname="theme"
                                name="Theme"
                                label="Select theme"
                                options={this.colorThemes}
                                change={(varname, value, err) => {
                                    let user = Object.assign({}, this.state.user);
                                    user.data.theme = value;
                                    let changes = Object.assign({}, this.state.changes);
                                    if (!changes.data) changes.data = {};
                                    changes.data.theme = value;
                                    this.setState({ user, changes });
                                }}
                            >
                                {this.state.user.data.theme}
                            </SelectField>
                        </div>
                        <PasswordField
                            varname="pwd"
                            change={(varname, value, err) => {
                                let changes = Object.assign({}, this.state.changes);
                                changes.pwd = value;
                                this.setState({ changes })
                            }}
                        >
                            {""}
                        </PasswordField>
                        <TextField
                            varname="email"
                            label="Email"
                            change={(varname, value, err) => {
                                let user = Object.assign({}, this.state.user);
                                user.email = value;

                                let changes = Object.assign({}, this.state.changes);
                                changes.email = value;
                                this.setState({ user, changes });
                            }}
                        >
                            {this.state.user.email}
                        </TextField>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="custom-c-dark">
                            <label className="access-code-label">Generate a new access code:</label>
                            <br />
                            <div
                                className="button access-token-button custom-bg-feature1"
                                onClick={e => {
                                    if (window.confirm("Are you sure? This will invalidate any existing token."))
                                        $.get(`${this.props.apiRoot}/security/me/code`).done(res => {
                                            this.setState({
                                                code: res.code
                                            })
                                        }).catch(err => {
                                            console.error("Error getting code: ", err);
                                            logger.error("Error creating access code: ", err.msg);
                                        });
                                }}
                            >Generate</div>
                            <br /><br />
                            {this.state.code ? <p>Be sure to print this, you will only see it once.</p> : null}
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        {this.state.code ?
                            <Qrcode label={this.props.user.email}>
                                {`${this.props.user.email} ${this.state.code}`}
                            </Qrcode>
                            :
                            null
                        }
                    </div>
                    <div className="col-12">
                        <hr />
                        <div
                            className="button save-button custom-bg-feature1"
                            onClick={() => {
                                // console.log("CHANGES:", this.state.changes);
                                if (Object.keys(this.state.changes).length) {
                                    //If changes were made
                                    if (this.state.changes.email && !/^[^\@]+\@[^\@]+$/.test(this.state.changes.email)) {
                                        //If email field has incorrect value
                                        logger.error("Invalid value for the email field")
                                    } else {
                                        $.ajax({
                                            type: "PUT",
                                            url: `${this.props.apiRoot}/users/${this.props.user.id}`,
                                            data: JSON.stringify(this.state.changes),
                                            contentType: "application/json"
                                        }).done(response => {
                                            this.props.change(response.user)
                                            logger.success(response.msg);
                                        }).catch(err => {
                                            logger.error(err)
                                        })
                                    }
                                }
                            }}
                        >Save changes
                    </div>
                    </div>
                </div>
            </Aux>
        )
    }
}

export default SettingsView;
