const stockTemplate = {
    name: "Stock",
    columns: [
        "supplier",
        "description",
        "description",
        "description",
        "description",
        "productCode",
        "ignore"
    ],
    columnLabels: [
        "Supplier",
        "Customer",
        "Customer reference",
        "Sales Order",
        "Description",
        "Product code",
        "quantity"
    ]
}

export default stockTemplate;