import React from "react";
import Context from "../../../context";

class Process extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: 0,
        }
        this.stages = []
    }
    stageData(stage = 0) {
        if (this.stages[stage] && this.state[stage])
            return this.state[stage];
        return {};
    }
    clearStageData(stages, cb) {
        if (stages.constructor !== Array) stages = [stages];
        let newState = {}
        stages.forEach(stage => {
            newState[stage] = null;
        });
        this.setState(newState, cb);
    }
    setStageData(stage, data, cb) {
        this.setState({
            [stage]: data
        }, cb);
    }
    changeStage(stage) {
        if (stage > (this.stages.length - 1)) {
            return this.props.exit();
        }

        if (stage < 0) {
            stage = 0;
        }

        this.setState({ stage });
    }
    nextStage(steps = 1) {
        this.changeStage(this.state.stage + steps);
    }
    previousStage(steps = 1) {
        this.changeStage(this.state.stage - steps);
    }
    render() {
        return <div className="col-12 process">
            {/* Heading bar displays title, and allows exiting of process */}
            <div className="heading custom-bg-light custom-c-dark">
                <button
                    className="go-back-button custom-border-color"
                    onClick={() => {
                        this.props.exit();
                    }}
                >
                    <div className="icon arrow-icon" />
                </button>
                <div className="title">{this.props.title}</div>
            </div>

            {/* The actual stage component to get rendered */}
            <div className="stage-wrapper">
                {(Stage => {
                    let stage = this.stages[this.state.stage];
                    return Stage ? <Stage
                        stageData={this.stageData.bind(this)}
                        config={stage.config}
                        key={this.state.stage}
                        apiRoot={this.props.apiRoot}
                        onChangeStage={newStage => {
                            this.changeStage(newStage);
                        }}
                        onExit={() => {
                            this.props.exit();
                        }}
                        onComplete={data => {
                            if (data) {
                                this.setState({
                                    [this.state.stage]: data
                                }, () => {
                                    this.nextStage();
                                });
                            } else {
                                this.nextStage();
                            }
                        }}
                    /> : null
                })(this.stages[this.state.stage] ? this.stages[this.state.stage].component : null)}
            </div>

            {/* Dots to show progress along process */}
            <div className="progress-dots">
                {this.stages.map((stage, index) => {
                    return <div
                        key={index}
                        className={index === this.state.stage ? "dot custom-bg-feature1 current" : "dot custom-bg-light"}
                    />
                })}
            </div>
        </div>
    }
}

Process.contextType = Context;

export default Process;