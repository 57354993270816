import jsbarcode from "jsbarcode";

/**
 * Generates a printable barcode.
 */
class Barcode extends React.Component {
    constructor(props) {
        super(props);
		this.iframe = React.createRef();
		this.target = React.createRef();
		this.preview = React.createRef();
		this.bcTarget = React.createRef();
    }
    componentDidMount() {
        this.createBarcode(this.props.children);
    }
    componentDidUpdate(prevProps) {
        if (this.props.children !== prevProps.children)
            this.createBarcode(this.props.children);
    }
    createBarcode(code) {
        jsbarcode(this.bcTarget.current, code, { margin: 0 });
        jsbarcode(this.preview.current, code, { margin: 0 });
    }
    print() {
        this.iframe.current.contentWindow.document.querySelector("body").innerHTML = "";
        let content = this.target.current.cloneNode(true);
        this.iframe.current.contentWindow.document.querySelector("body").appendChild(content);
        this.iframe.current.contentWindow.print();
    }
    render() {
        return (
            <div className="barcode-generator custom-border-3">
                {/* The visible barcode */}
                <svg ref={this.preview}></svg>
                {/* The container used for print */}
                <div style={{ display: "none" }}>
                    <div ref={this.target} style={{ width: "100%" }}>
                        <style>{`
                            svg { 
                                display: block;
                                margin: auto;
                                max-width: 100% !important;
                                max-height: ${this.props.moreInfo ? "70px" : "100%"};
                                overflow: initial !important;
                            }
                        `}</style>
                        <svg ref={this.bcTarget}></svg>
                        {this.props.moreInfo ?
                            <React.Fragment>
                                <hr/>
                                <div
                                    className="moreInfo"
                                    style={{
                                        // whiteSpace: "pre-wrap",
                                        fontSize: "10px",
                                        fontFamily: "monospace"
                                    }}
                                >
                                    {this.props.moreInfo}
                                </div>
                            </React.Fragment>
                            : null}
                    </div>
                </div>
                {/* Print button */}
                <div className="controls">
                    <button
                        onClick={this.print.bind(this)}
                        className="print custom-bg-dark custom-c-dark"
                    >Print</button>
                </div>
                {/* Hidden iframe that gets print triggered on it. */}
                <iframe
                    style={{ display: "none" }}
                    ref={this.iframe}
                ></iframe>
            </div>
        )
    }
}

export default Barcode;