import Field from "./field";
import StaggeredSearchField from "./staggeredSearchField";
import FlipMove from "react-flip-move";

class DataField extends Field {
    constructor(props) {
        super(props);
        this.state.data = this.props.defaults || [];
        this.state.open = false;
        this.state.value = this.props.children ? this.props.children : "";
        this.settings = {
            type: "data"
        }
    }

    createQueryString(obj) {
        let pairs = [];
        Object.keys(obj).forEach(name => {
            if (obj[name]) pairs.push(`${name}=${obj[name]}`);
        })
        return pairs.length ? ("?" + pairs.join("&")) : "";
    }

    getData(value) {
        // Generate object of query options, and add in defaults.
        let options = Object.assign({},
            this.props.queryDefaults || {},
            this.props.searchBy ? {
                [this.props.searchBy]: value
            } : {}
        );
        // console.log("QD: ", this.props.queryDefaults);

        // console.log("Options: ", options);
        $.get(`${window.apiRoot}/${this.props.route}${this.createQueryString(options)}`)
            .done(data => {
                // console.log("data:", data);
                this.setState({
                    data: data.results
                        .concat(this.props.defaults || [])
                        .filter(item => {
                            return this.props.filter ? this.props.filter(item) : true;
                        }),
                    open: true
                });
            }).catch(err => {
                logger.error(err);
            });
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.children && prevProps.children !== this.props.children)
            this.setState({ value: this.props.children || "" });
    }

    renderField() {
        return (
            <div className="field-wrapper">
                <StaggeredSearchField
                    delay={250}
                    change={(varname, value, err) => {
                        if (value) {
                            this.getData(value);
                        } else {
                            this.change(null)
                        }
                        this.setState({ value })
                    }}
                >
                    {this.state.value}
                </StaggeredSearchField>
                <div
                    className="dropdown-btn custom-bg-feature1"
                    onClick={() => {
                        this.setState({ open: !this.state.open })
                    }}
                >
                    <div className="matches">{this.state.data.length}</div>
                    <div className={this.state.open ? "icon-white chevron open" : "icon-white chevron"}></div>
                </div>
                {this.state.data.length ? <div className={"options custom-bg-dark" + (this.state.open ? " open custom-border-3" : "")}>
                    {this.props.multiAdd
                        ?
                        <div className="option custom-bg-light custom-border-color">
                            <div className="border-left custom-bg-feature1"></div>
                            <div
                                className="multiAdd"
                                onClick={() => {
                                    this.props.multiAdd(this.state.data)
                                }}
                            >
                                ADD ALL
                            </div>
                        </div>
                        :
                        null
                    }
                    <FlipMove>
                        {this.state.data
                            .sort((a, b) => {
                                let aLocator = (a[this.props.dataName] || "").toUpperCase();
                                let bLocator = (b[this.props.dataName] || "").toUpperCase();
                                if (aLocator < bLocator) {
                                    return -1;
                                }
                                if (aLocator > bLocator) {
                                    return 1;
                                }
                                return 0;

                            })
                            .map((d, index) => (
                                <div
                                    key={d[this.props.dataName] + "" + index}
                                    className="option custom-bg-light custom-border-color"
                                    onClick={e => {
                                        this.setState({
                                            open: false,
                                            value: d[this.props.dataName] || "Item has no " + this.props.dataName
                                        });
                                        this.change(d);
                                    }}
                                >
                                    <div className="border-left custom-bg-feature1"></div>
                                    {this.props.optionRenderer ? this.props.optionRenderer(d) : d[this.props.dataName]}
                                </div>
                            ))}
                    </FlipMove>
                </div> : null}
            </div>
        )
    }
}

export default DataField;