import BarcodeSet from "./barcodeSet";

class BarcodeSetIcon extends BarcodeSet {
    renderPrintButton() {
        return (
            <div 
                className="control icon print-icon"
                onClick={this.triggerPrint.bind(this)}
            ></div>    
        )
    }    
}

export default BarcodeSetIcon;