import Stage from "./stage";
import FileField from "../../../fields/fileField";
import TextareaField from "../../../fields/textareaField";

class RemedialData extends Stage {
    constructor(props) {
        super(props)
        this.state.images = [];
        this.state.description = props.config.stockItem().description;
    }

    renderStage() {
        return (
            <div>
                <FileField
                    label="Images"
                    varname="files"
                    apiRoot={this.props.apiRoot}
                    change={(varname, value, error) => {
                        // console.log(value);
                        this.setState({ images: value })
                    }}
                >
                    {this.state.images}
                </FileField>
                <TextareaField
                    varname="description"
                    label="Description"
                    change={(vn, val, err) => {
                        this.setState({ description: val })
                    }}
                >
                    {this.state.description}
                </TextareaField>
                {/* {If description and files added} */}
                {this.state.images.length
                    ?
                    <button
                        className="nextStageBtn custom-bg-feature1"
                        onClick={() => {
                            this.stageComplete({
                                description: this.state.description,
                                images: (this.props.config.stockItem().images || [])
                                    .concat(this.state.images.map(i => i.url))
                            })
                        }}
                    >
                        Submit
                    </button>
                    :
                    null
                }
            </div>
        )
    }
}

RemedialData.defaultProps = {
    title: "Remedial Data.",
}

export default RemedialData;