import View from "../view";
import CollectionCreator from "./collectionCreator";
import UiPanel from "../../assets/ui-panel";
import { Aux, renderDate } from "../../helpers";

class CollectionView extends View {
    constructor(props) {
        super(props);

        this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);

        this.state = {
            collection: null
        }
    }
    
    componentDidMount() {
        let collectionId = this.props.router.match.params.collectionId;
        if (collectionId && collectionId !== "create") {
            // Get a single collection matching the collectionId
            this.getCollection({ id: collectionId })
        }
    }

    componentDidUpdate(prevProps) {
        let prevCollectionId = prevProps.router.match.params.collectionId;
        let collectionId = this.props.router.match.params.collectionId;

        if (prevCollectionId !== collectionId) {
            if (collectionId && collectionId !== "create") {
                this.getCollection({ id: collectionId })
            }
        }
    }

    createQueryString(obj) {
        return "?" + Object.keys(obj).map(name => {
            if (!obj[name]) return "";
            return `${name}=${obj[name]}`;
        }).join("&");
    }

    getCollection(query, cb) {
        $.get(`${this.props.apiRoot}/collections/${this.createQueryString(query)}`)
            .done(collection => {
                if (cb) {
                    cb(collection)
                } else {
                    this.setState({ collection });    
                }
            })
            .catch(err => {
                logger.error(err)
            })
    }

    createCollection(collection) {
        $.post(`${this.props.apiRoot}/collections`, JSON.stringify(collection))
            .done(res => {
                logger.success(res.msg);
                this.props.router.history.push("/schedule");
            })
            .catch(err => {
                logger.error(err);
            });
    }

    updateCollection(changes, id) {
        $.ajax({
            type: "PUT",
            url: `${this.props.apiRoot}/collections/${id}`,
            data: JSON.stringify(changes)
        }).done(res => {
            logger.success(res.msg);
            this.setState({ collection: res.collection });
        }).catch(err => {
            logger.error(err);
        });
    }

    renderView() {
        return (
            <Aux>
                <div className="row heading-row">
                    <div className="col-12">
                        <h1 className="client-view custom-c-dark">{`${this.client} Collections`}</h1>
                        <h5 className="data custom-c-mid">{renderDate()}</h5>
                    </div>
                </div>
                <div className="row view collectionsView">
                    <div className="col-12 panel-wrapper">
                        <UiPanel
                            name={this.state.collection ? `Editing Collection - ${this.state.collection.id}` : "Collection Creator"}
                            icon="collection"
                        >
                            <CollectionCreator
                                isCourier={false}
                                router={this.props.router} 
                                apiRoot={this.props.apiRoot}
                                collection={this.state.collection}
                                user={this.props.user}
                                onSubmit={collection => {
                                    this.createCollection(collection)
                                }}
                                onUpdate={(collection, id) => {
                                    this.updateCollection(collection, id)
                                }}
                            />
                        </UiPanel>
                    </div>
                </div>
            </Aux>
        )
    }

}

export default CollectionView