import ItemCreator from "../../assets/ui-super-list/itemCreator";
import TextField from "../../fields/textField";
import NumberField from "../../fields/numberField";
import FlipMove from "react-flip-move";
import DataField from "../../fields/dataField";
import PasswordField from "../../fields/passwordField";

class UserCreator extends ItemCreator {

    canSubmit() {
        if (this.props.item) {
            //Updating existing user
            if (this.props.item.role !== this.state.item.role || this.state.item.pwd) {
                return true
            }
        } else {
            //Creating a new user
            if (this.state.item.name && this.state.item.role && this.state.item.customer && this.state.item.email && this.state.item.pwd && this.state.item.pwd.length > 3) {
                return true
            }
        }
        //Return false by default
        return false
    }


    renderCreator() {
        // console.log("USER CREATOR PROPS: ", this.props);
        // console.log("USER CREATOR STATE: ", this.state);
        return (
            <div className="creator">
                {!this.props.item
                    ?
                    //If creating a new user
                    <div className="field-combiner">
                        <TextField
                            varname="name"
                            label="Full Name"
                            change={(varname, value, err) => {
                                let item = Object.assign({}, this.state.item);
                                item.name = value;
                                this.setState({ item })
                            }}
                        >
                            {this.state.item.name || ""}
                        </TextField>
                        <TextField
                            varname="email"
                            label="Email"
                            change={this.itemChanged.bind(this)}
                        >
                            {this.state.item.email || ""}
                        </TextField>
                    </div>
                    :
                    //Editing existing one
                    <div className="field-combiner">
                        <div className="field placeholder">
                            <label className="custom-c-dark">Name</label>
                            <div className="field-value custom-c-feature2">
                                {this.props.item.name}
                            </div>
                        </div>
                        <div className="field placeholder">
                            <label className="custom-c-dark">Email</label>
                            <div className="field-value custom-c-feature2">
                                {this.props.item.email}
                            </div>
                        </div>
                    </div>
                }
                {!this.props.item
                    ?
                    //If creating a new user
                    <FlipMove className="field-combiner">
                        <DataField
                            varname="customer"
                            defaults={[
                                {
                                    name: "internal",
                                    displayName: "Internal User"
                                }
                            ]}
                            optionRenderer={customer => (
                                <div className="option-inner">
                                    <b>{customer.displayName}</b> <br />({customer.name})
                                </div>
                            )}
                            label="Customer"
                            route="customers"
                            dataName="name"
                            searchBy="term"
                            change={(varname, value, error) => {
                                let item = Object.assign({}, this.state.item);
                                item.role = 1;
                                if (value && (value.name !== this.props.user.customer)) {
                                    item.role = 4
                                }
                                item.customer = value ? value.name : "";
                                this.setState({ item });
                            }}
                        >
                            {this.state.item.customer || ""}
                        </DataField>
                        {this.state.item.customer
                            ?
                            <NumberField
                                varname="role"
                                label="Role"
                                min={(() => {
                                    if (this.state.item.customer === this.props.user.customer) {
                                        //If its a client
                                        return 1
                                    } else {
                                        //If customer
                                        return 4
                                    }
                                })()}
                                max={(() => {
                                    if (this.state.item.customer === this.props.user.customer) {
                                        //If its a client
                                        return 3
                                    } else {
                                        //If customer
                                        return 4
                                    }
                                })()}
                                change={this.itemChanged.bind(this)}
                            >
                                {this.state.item.role || ""}
                            </NumberField>
                            :
                            null
                        }
                    </FlipMove>
                    :
                    //Editing existing one
                    <div className="field-combiner">
                        <div className="field placeholder">
                            <label className="custom-c-dark">Customer</label>
                            <div className="field-value custom-c-feature2">
                                {this.props.item.customer || "Internal"}
                            </div>
                        </div>
                        <NumberField
                            varname="role"
                            label="Role"
                            min={(() => {
                                if (this.props.item.customer === "internal") {
                                    //If its a client
                                    return 1
                                } else {
                                    //If customer
                                    return 4
                                }
                            })()}
                            max={(() => {
                                if (this.props.item.customer === "internal") {
                                    //If its a client
                                    return 3
                                } else {
                                    //If customer
                                    return 4
                                }
                            })()}
                            change={this.itemChanged.bind(this)}
                        >
                            {this.state.item.role || this.props.item.role}
                        </NumberField>
                    </div>
                }
                <PasswordField
                    varname="pwd"
                    change={this.itemChanged.bind(this)}
                >
                    {""}
                </PasswordField>
            </div>
        )
    }
}

export default UserCreator