import Field from "./field";
import FlipMove from "react-flip-move";

class CheckboxField extends Field {
    constructor(props) {
        super(props);
        this.settings = {
            type: "checkbox"
        }
    }

    renderField() {
        return (
            <div 
                className={this.props.children ? "box on" : "box"}
                onClick={() => {
                    this.change(!this.props.children);     
                }}
            >
                <img
                    className={this.props.children ? "tick show" : "tick"} 
                    src="/static/media/tick-white.svg" 
                />
            </div>
        )
    }

    render() {
        let settings = this.settings;
        return <div className={"field custom-c-dark noselect "+settings.type+"Field"} >
            {this.props.label 
            ? 
                <label
                    onClick={() => {
                        this.change(!this.props.children);    
                    }}
                >
                    {this.props.label}
                </label> 
            :
                null
            }
            <div className="inner">
                {this.renderField()}
            </div>
            <FlipMove>
                {this.state.error ? <div key="err" className="error">
                    {this.state.error}
                </div>:null}
            </FlipMove>
        </div>
    }
}

export default CheckboxField;