import Process from "./index";
// Stages. 
import MultiStockSelect from "../stages/multiStockSelect";
import ListStage from "../stages/listStage";
import SelectLocation from "../stages/selectLocation";
import Confirm from "../stages/confirm";
import Action from "../stages/action";
import Success from "../stages/success";
import RemedialData from "../stages/remedialData";

class MoveStock extends Process {
	constructor(props) {
		super(props);
		this.state.stage = 0;
		this.stages = [
			{
				// 0 - Selecting stock item for movement
				component: MultiStockSelect
			},
			{
				// 1 - Checking hom many matches
				component: Action,
				config: {
					action: ({ changeStage }) => {
						if (this.stageData(0).length > 1) {
							changeStage(2)
						} else {
							changeStage(3)
						}
					}
				}
			},
			{
				// 2 - Selecting item from the list of matches
				component: ListStage,
				config: {
					icon: "stock",
					title: "Select Stock Item",
					items: () => this.stageData(0),
					renderItem: item => {
						return (
							<div className="content">
								<div><b>Customer:</b> {item.customer}</div>
                                {item.productCode && <div><b>Product code:</b> {item.productCode}</div>}
                                {item.code && <div><b>Code:</b> {item.code}</div>}
                                {item.productBarcode && <div><b>Product Barcode:</b> {item.productBarcode}</div>}
								<div><b>Location:</b> {item.location}</div>
								<div><b>Quantity:</b> {item.quantity}</div>
							</div>
						);
					}
				},
			},
			{
				// 3 - Standardise data from previous stages into one object
				component: Action,
				config: {
					action: ({ finish }) => {
						if (this.stageData(0).length > 1) {
							finish(this.stageData(2))
						} else {
							finish(this.stageData(0)[0])
						}
					}
				}
			},
			{
				// 4 - Select location
				component: SelectLocation,
				config: {
					defaultValue: () => (window.cookies.lastMoveLocation || null)
				},
			},
			{
				// 5 - Checking if selected location is remedial
				component: Action,
				config: {
					action: ({ changeStage }) => {
						// If the location is remedial..
						if (/^rem/i.test(this.stageData(4).locator)) {
							changeStage(6);
						} else {
							changeStage(7);
						}
					}
				}
			},
			{
				// 6 - Selected location is remedial, add image(s) and description to stock item
				component: RemedialData,
				config: {
					stockItem: () => this.stageData(3)
				}
			},
			{
				// 7 - Confirm all changes
				component: Confirm,
				config: {
					icon: "stock",
					message: () => <div className="summary custom-c-light">
						<p>You are about to move a stock item <span className="custom-c-dark">{this.stageData(3).code}</span> from <span className="custom-c-dark">{this.stageData(3).location}</span> to <span className="custom-c-dark">{this.stageData(4).locator}</span></p>
					</div>
				}
			},
			{
				// 8 - API call to update stock item
				component: Action,
				config: {
					action: ({ finish }) => {
						let changes = { location: this.stageData(4).locator };
						// If remedial
						if (this.stageData(6)) {
							changes.images = this.stageData(6).images;
							changes.description = this.stageData(6).description;
						}
						// Changes stock item call
						this.updateStock(this.stageData(3).id, changes, () => {
							window.setCookie("lastMoveLocation", this.stageData(4).locator);
							finish();
						});
					}
				}
			},
			{
				// 9 - Showing success message
				component: Success
			}
		]
	}

	updateStock(id, changes, cb) {
		$.ajax({
			type: "PUT",
			url: `${this.context.apiRoot}/stock/${id}?moving=true`,
			contentType: "application/json",
			data: JSON.stringify(changes)
		}).done(response => {
			if (cb) cb(response)
		}).catch(err => {
			logger.error(err);
		});
	}
}

export default MoveStock;