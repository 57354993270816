import SelectField from "./selectField";
import Field from "./field";

class DateField extends Field {
    constructor(props) {
        super(props);
        if (this.props.children) {
            let startingDate = new Date(this.props.children);
            this.state.day = startingDate.getDate();
            this.state.month = startingDate.getMonth();
            this.state.year = startingDate.getFullYear();
        } else {
            this.state.day = null;
            this.state.month = null;
            this.state.year = null;
        }
        this.settings = {
            type: "date"
        }
        this.monthsArray = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ]
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.children === this.props.children) || this.props.children === null) return;
        let startingDate = new Date(this.props.children);
        this.setState({
            day: startingDate.getDate(),
            month: startingDate.getMonth(),
            year: startingDate.getFullYear()
        })
    }

    isValid() {
        if (this.state.year && this.state.month !== null && this.state.day) {
            return true;
        }
        return false;
    }

    serialize() {
        return new Date(this.state.year, this.state.month, this.state.day).getTime();
    }

    
    renderField() {
        // console.log("VALUE: ", this.props.children);
        return (
            <div className="field-wrapper">
                <SelectField
                    varname="year"
                    name="Year"
                    options={(() => {
                        let yearArr = [];
                        let yearNow = Date.now();
                        yearNow = new Date(yearNow).getFullYear();
                        for (var i = yearNow-100; i <= yearNow + 100; i++) {
                            yearArr.push({ val: i, display: i })
                        }
                        return yearArr;
                    })()}
                    change={(varname, value, err) => {
                        let day = this.state.day || null;
                        if (day && this.state.month) {
                            let max = new Date(value, this.state.month, 0).getDate();
                            if (day > max) day = max;
                        }
                        this.setState({
                            year: value,
                            day
                        }, () => {
                            if (this.isValid()) this.change(this.serialize());
                        })
                    }}
                >
                    {this.state.year}
                </SelectField>
                <SelectField
                    varname="month"
                    name="Month"
                    options={this.monthsArray.map((month, index) => ({
                        val: index, display: month
                    }))}
                    change={(varname, value, err) => {
                        let day = this.state.day || null;
                        if (day && this.state.year) {
                            let max = new Date(this.state.year, value, 0).getDate();
                            if (day > max) day = max;
                        }
                        this.setState({
                            month: value,
                            day
                        }, () => {
                            if (this.isValid()) this.change(this.serialize());
                        })
                    }}
                >
                    {this.state.month}
                </SelectField>
                <SelectField
                    varname="day"
                    name="Day"
                    options={(() => {
                        let days = []
                        if (!this.state.month && !this.state.year) return [{
                            display: "--",
                            val: ""
                        }]
                        let nrOfDays = new Date(this.state.year, this.state.month+1, 0).getDate();
                        for (var i = 1; i <= nrOfDays; i++) {
                            days.push({ val: i, display: i });
                        }
                        return days;
                    })()}
                    change={(varname, value, err) => {
                        this.setState({
                            day: value
                        }, () => {
                            if (this.isValid()) this.change(this.serialize());
                        })
                    }}
                >
                    {this.state.day}
                </SelectField>
            </div>
        )
    }

}

export default DateField;