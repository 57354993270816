import FlipMove from "react-flip-move";

class Field extends React.Component {

    /**
     * props:
     * 
     *  label (String - optional) "Some field",
     * 
     *  varname (String) name of the property the field chanegs.
     * 
     *  children (ANY) 
     *       - current value of the input.
     * 
     *  change (Function)
     *       - called back with (varname, value, error)
     */

    constructor(props) {
        super(props);
        this.state = {
            error: null
        }
        this.settings = { type: "NO-TYPE" }
    }
    renderField() {
        return "No field type passed!!!!!!"
    }
    change(value) {
        let error = null;
        if (this.props.validator) {
            error = this.props.validator(value);
            this.setState({ error });
        }
        
        if (this.props.change)
            this.props.change(this.props.varname, value, error);
    }

    render() {
        let settings = this.settings;
        return <div className={"field custom-c-dark noselect "+settings.type+"Field"} >
            {this.props.label 
            ? 
                <label>
                    {this.props.label}
                </label> 
            :
                null
            }
            <div className="inner">
                {this.renderField()}
            </div>
            <FlipMove>
                {this.state.error ? <div key="err" className="error">
                    {this.state.error}
                </div>:null}
            </FlipMove>
        </div>
    }
}

export default Field;