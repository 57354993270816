const kitchenTemplate = {
    name: "Kitchen",
    columns: [
        "description",
        "group",
        "description",
        "ignore",
        "code",
        "productCode",
        "description",
        "description",
        "description",
        "description",
        "ignore",
        "description"
    ],
    columnLabels: [
        "Transport number",
        "Order number",
        "Order position",
        "Status",
        "Production Order",
        "Product code",
        "Colli number",
        "Colli of",
        "Commission text",
        "Customer number",
        "Relation to first/original order number",
        "Net value"
    ]
}

export default kitchenTemplate;