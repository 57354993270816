import SuperList from "../../assets/ui-super-list/";
import StockCreator from "./stockCreator";
import StockSplitter from "./stockSplitter";
import FileField from "../../fields/fileField";
import BarcodeSetIcon from "../../assets/barcodeSetIcon";

/**
 *  PROPS:
 * 
 *      renderControls: BOOLEAN
 *      allCreatorFields: BOOLEAN
 */

class StockList extends SuperList {

    renderRow(item) {
        if (this.state.showingFiles === item)
            return this.renderStockFiles();

        if (this.state.editing === item)
            return this.renderItemCreator(item);

        if (this.state.splitting === item)
            return this.renderStockSplitter(item);

        // Render columns
        let columns = this.props.columns;
        if (this.props.mobileColumns && this.state.mobileSize) {
            //If mobileColumns are passed and the screnn in mobileSize
            columns = this.props.mobileColumns;
        }
        let width = (100 / (columns.length + 1)) + "%";
        let sections = columns.map(column => {
            let val = column.renderer ? column.renderer(item) : "-";
            return <div
                style={{ width }}
                className="section"
                key={column.name}
                onMouseEnter={e => {
                    if (this.state.showInfo) this.setState({
                        hovering: {
                            item: item[this.props.uniqueField],
                            col: column.name,
                            val
                        }
                    })
                }}
            >
                {column.renderer ?
                    column.renderer(item)
                    :
                    "-"
                }
            </div>
        })
        // Add hover preview if matched.
        if (
            this.state.showInfo &&
            this.state.hovering.item === item[this.props.uniqueField]
        ) sections.push(
            <div className="hoverPreview shadow-1" key="hover">
                {this.state.hovering.val}
            </div>
        )
        // Add on controls.
		sections.push(<div key="section-controls" className="section section-controls" style={{ width }}>
			{this.renderControls(item)}
		</div>)

        return sections;
    }

    renderStockFiles() {
        return (
            <div className="item-creator custom-border-color">
                <div
                    className="icon close-icon"
                    onClick={() => {
                        this.setState({ showingFiles: null })
                    }}
                >
                </div>
                <div className="creator">
                    <FileField
                        readOnly={true}
                    >
                        {this.state.showingFiles.images.map(f => ({
                            name: f, url: f, type: "image"
                        }))}
                    </FileField>
                </div>
            </div>
        )
    }

    renderControls(item) {
        if (!this.props.controls.length) return null;
        return this.props.controls
            .map(control => {
                if (
					!this.props.displayOnly &&
                    this.props.user.role < 4 &&
                    control === "split" &&
                    item.quantity > 1
                ) {
                    return {
                        icon: this.props.user.data.theme === "dark" ? "split-stock-white.svg" : "split-stock.svg",
                        label: "split",
                        callback: () => {
                            this.setState({
                                splitting: item,
                                addingItem: false,
                                editing: null,
                                showingFiles: null
                            })
                        }
                    }
                } else if (control === "edit") {
                    return {
                        icon: this.props.user.data.theme === "dark" ? "pen-white.svg " : "pen.svg",
                        label: "edit",
                        callback: () => {
                            this.setState({
                                editing: item,
                                splitting: null,
                                addingItem: false,
                                showingFiles: null
                            })
                        },
                    }
                } else if (!this.props.displayOnly && this.props.onDelete && control === "delete") {
                    return {
                        icon: this.props.user.data.theme === "dark" ? "delete-bright.svg" : "delete.svg",
                        label: "delete",
                        callback: (item) => {
                            if (window.confirm("Are you sure you want to delete this stock item?")) {
                                this.props.onDelete(item[this.props.uniqueField])
                            }
                        }
                    }
                } else if (!this.props.displayOnly && control === "image" && item.images && item.images.length) {
                    return {
                        icon: this.props.user.data.theme === "dark" ? "document-white.svg" : "document.svg",
                        label: "files",
                        callback: item => {
                            this.setState({
                                editing: null,
                                splitting: null,
                                addingItem: null,
                                showingFiles: item
                            })
                        }
                    }
                } else if (!this.props.displayOnly && control === "print") {
                    return {
                        type: "print",
                        item: item
                    }
                } else {
                    return null;
                }
            })
            // Filter out nulls.
            .filter(c => c)
            // Map and render.
            .map(control => {
                // console.log("Control: ", control);
                if (control.type === "print") {
                    return (
                        <div
                            className="control"
                            key="Print barcode"
                            title="Print barcode"
                        >
                            <BarcodeSetIcon
                                items={[{
                                    code: control.item.code || control.item.productBarcode,
                                    moreInfo: <div>
                                        <div>
                                            CUST: {this.props.customer} | PC: {control.item.productCode || "NO CODE"}
                                        </div>
                                        <hr />
                                        <div>{control.item.description || "NO DESC"}</div>
                                    </div>
                                }]}

                            />
                        </div>
                    )
                } else {
                    return <div
                        title={control.label}
                        key={control.label}
                        className="control"
                        style={{ backgroundImage: `url("/static/media/${control.icon}")` }}
                        onClick={e => { control.callback(item) }}
                    />
                }
            })
    }

    renderStockSplitter() {
        return (
            <StockSplitter
                uniqueField={this.props.uniqueField}
                item={this.state.splitting}
                closeCreator={() => {
                    this.setState({ splitting: null })
                }}
                itemUpdated={(item, id) => {
                    if (this.props.onSplit) {
                        this.props.onSplit(item, id);
                        this.setState({ splitting: null })
                    }
                }}
            />
        )
    }

    renderItemCreator() {
        return (
            <StockCreator
                user={this.props.user}
                apiRoot={this.props.apiRoot}
                router={this.props.router}
                allCreatorFields={this.props.allCreatorFields}
                uniqueField={this.props.uniqueField}
                item={this.state.editing}
                itemCreated={item => {
                    if (this.props.onNew) {
                        this.props.onNew(item)
                        this.setState({ addingItem: false })
                    }
                }}
                itemUpdated={(item, id) => {
                    if (this.props.onUpdate) {
                        this.props.onUpdate(item, id);
                        this.setState({ editing: null })
                    }
                }}
                itemUnlocked={id => {
                    if (this.props.onUnlock) {
                        this.props.onUnlock(id);
                        this.setState({  })
                    }
                }}
                closeCreator={() => {
                    if (this.state.editing) {
                        //if editing an item
                        this.setState({ editing: null })
                    } else {
                        //or creating new item
                        this.setState({ addingItem: false })
                    }
                }}
            />
        )
    }
}

function renderDate(ms) {
    function twoDigit(n) {
        let nstr = String(n);
        return nstr.length !== 2 ? "0" + nstr : nstr;
    }
    if (!ms) return "";
    let d = new Date(ms);
    if (d.toString() === "Invalid Date") return "";
    // return `${String(d.getFullYear()).substring(2)}/${d.getMonth()+1}/${d.getDate()}`
    return `${twoDigit(d.getDate())}/${twoDigit(d.getMonth() + 1)}/${String(d.getFullYear()).substring(2)}`
}

StockList.defaultProps = {
    icon: "stock",
    uniqueField: "id",
    columns: [
        {
            name: "Location",
            renderer: item => item.location,
            sorter: item => item.location
        },
        {
            name: "In/Out",
            renderer: item => {
                let output;
                if (item.out) {
                    output = renderDate(item.out)
                } else if (item.in) {
                    output = <b>{renderDate(item.in)}</b>
                } else {
                    output = "-"
                }
                return output;
            },
            sorter: item => {
                let output;
                if (item.out) {
                    output = renderDate(item.out)
                } else if (item.in) {
                    output = <b>{renderDate(item.in)}</b>
                } else {
                    output = "-"
                }
                return output;
            }
        },
        {
            name: "Cus",
            renderer: item => {
                let customer = item.customer.split("-");
                if (customer.length > 2) {
                    return customer.slice(2).join("-")
                } else {
                    return customer[1]
                }
            },
            sorter: item => item.customer || ""
        },
        {
            name: "Product Code",
            renderer: item => item.productCode,
            sorter: item => item.productCode
        },
        {
            name: "Group",
            renderer: item => item.group || "",
            sorter: item => item.group || ""
        },
        {
            name: "POS",
            renderer: item => {
                let result = /order position:\s*([\d]+)\s*/gi.exec(item.description);
                return result ? result[1] : "-"
            }
        }
    ],
    mobileColumns: [
        {
            name: "Description",
            renderer: item => item.description,
        },
        {
            name: "Location",
            renderer: item => item.location,
            sorter: item => item.location
        },
    ]
}


export default StockList;