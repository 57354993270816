import View from "../view";
import { Route } from "react-router-dom"
import Calendar from "../../assets/calendar";
import MiniCalendar from "../../assets/mini-calendar";
import FlipMove from "react-flip-move";
import { renderDate } from "../../helpers";
import moment from "moment";
import ScheduleList from "./scheduleList";
import SelectField from "../../fields/selectField";
import TextField from "../../fields/textField";
import CheckboxField from "../../fields/checkboxField";

/**
 *  PROPS
 * 
 *      role: NUMBER
 *      user: OBJECT
 *      apiRoot: STRING
 *      customers: ARRAY of customer objects
 *        
 */

window.moment = moment;

class ScheduleView extends View {
    constructor(props) {
        super(props);
        this.state = {
            selectedDay: null,
            showWeekend: false,
            viewingDate: this.props.currentDate || Date.now(),
            calendarMode: $(window).width() >= 1200 ? "big" : "small",
            events: [],
            customerFilters: [],
			trackingFilter: "",
            showCancelled: false,
            showDeliveries: true,
            showWorkOrders: true,
            showCollections: true,
			showRemovals: true,
            showCourierCollections: true
        }
        this.monthStrings = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ]

        this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);

        this.resizeHandler = () => {
            let calendarMode;
            if ($(window).width() >= 1200) {
                calendarMode = "big";
            } else {
                calendarMode = "small";
            }
            if (calendarMode !== this.state.calendarMode)
                this.setState({ calendarMode });
        }
    }

    componentDidMount() {
        this.getEvents(this.getFromToValues());
        $(window).resize(this.resizeHandler);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.viewingDate !== this.state.viewingDate) {
            //If the viewing date has changes, reget the events
            this.getEvents(this.getFromToValues());
        }
    }

    componentWillUnmount() {
        $(window).unbind("resize", this.resizeHandler)
    }

    getFromToValues() {
        //Gets the ms vale for the first and last day of the month and returns it as an object
        const date = new Date(this.state.viewingDate);
        const year = date.getFullYear();
        const month = date.getMonth();
        const dayCount = new Date(year, month + 1, 0).getDate();
        const firstDay = new Date(year, month, 1, 0, 0).getTime();
        const lastDay = new Date(year, month, dayCount, 23, 59).getTime();
        return {
            from: firstDay,
            to: lastDay
        }
    }

    createQueryString(obj) {
        return "?" + Object.keys(obj).map(name => {
            if (!obj[name]) return "";
            return `${name}=${obj[name]}`;
        }).join("&");
    }

    // getUniqueCustomers(events) {
    //     let customers = [];
    //     events.forEach(e => {
    //         let customer = e.data.customer.split("-");
    //         customer = `${customer[0]}-${customer[1]}`;
    //         if (!customers.includes(customer)) {
    //             customers.push(customer)
    //         }
    //     })
    //     return customers.map(cus => {
    //         return { val: cus, display: cus.replace(this.props.user.client + "-", "") }
    //     })
    // }

	getUniqueCustomers(events) {
        let customers = [];
        events.forEach(e => {
            if (e.data.customer && !customers.includes(e.data.customer)) {
                customers.push(e.data.customer)
            }
        })
        return customers.sort().map(cus => {
            return { val: cus, display: cus.replace(this.props.user.client + "-", "") }
        })
    }

    filterEvents(events) {
        return events
            .filter(event => {
                // Check for cancelled events.
                if (this.state.showCancelled) return true;
                return (
                    event.data &&
                    event.data.status !== "cancelled"
                );
            }).filter(event => {
                // Customer filter.
                if (!this.state.customerFilters.length) return true;
				let result = false;
				for (const filter of this.state.customerFilters) {
					if (event.data && event.data.customer.includes(filter)) {
						result = true;
						break;
					}
				}
				return result;
            }).filter(event => {
                // Deliveries filter
                if (this.state.showDeliveries) return true;
                return event.type !== "delivery"
            }).filter(event => {
                // WorkOrders filter
                if (this.state.showWorkOrders) return true;
                return event.type !== "workOrder"
            }).filter(event => {
                // Dont show deliveries for role 4 users
                if (this.props.user.role !== 4) return true;
                return event.type !== "delivery"
            }).filter(event => {
                // Collections filter
                if (this.state.showCollections) return true;
                return event.type !== "collection"
            }).filter(event => {
                // Courier collection filter
                if (this.state.showCourierCollections) return true;
                return event.type !== "courier-collection"
            }).filter(event => {
				// Removals filter
				if (this.state.showRemovals) return true;
				return event.type !== "removal"
			}).filter(event => {
				if (!this.state.trackingFilter) return true;
				return (
					event.data &&
					event.data.trackingNumber &&
					event.data.trackingNumber.includes(this.state.trackingFilter)
				)
			})
    }

    getEvents(query, cb) {
        $.get(`${this.props.apiRoot}/misc/schedule/${this.createQueryString(query)}`).done(events => {
            if (cb) {
                cb(events);
            } else {
                this.setState({ events })
            }
        }).catch(err => {
            window.logger.error(err)
        })
    }

    changeMonth(val) {
        const date = moment(this.state.viewingDate);
        date.add(val, "month");
        this.setState({
            viewingDate: date.valueOf()
        })
    }

    calendarEvent(event, val) {
        if (event === "showWeekend") {
            this.setState({ showWeekend: val })
        } else if (event === "month") {
            this.changeMonth(val)
        } else if (event === "select-day") {
            this.setState({ selectedDay: val })
        } else if (event === "create-delivery") {
            this.props.router.history.push(`/deliveries/create${
                this.state.selectedDay ? "#" + this.formatDate(this.state.selectedDay.date) : ""
                }`);
        } else if (event === "create-workOrder") {
            this.props.router.history.push(`/workorders/create${
                this.state.selectedDay ? "#" + this.formatDate(this.state.selectedDay.date) : ""
                }`);
        } else if (event === "create-collection") {
            this.props.router.history.push(`/collections/create${
                this.state.selectedDay ? "#" + this.formatDate(this.state.selectedDay.date) : ""
                }`)
        } else if (event === "create-courier-collection") {
            this.props.router.history.push(`/courier-collections/create${
                this.state.selectedDay ? "#" + this.formatDate(this.state.selectedDay.date) : ""
                }`)
        } else if (event === "create-removal") {
			this.props.router.history.push(`/removals/create${
				this.state.selectedDay ? "#" + this.formatDate(this.state.selectedDay.date) : ""
			}`)
		} else if (event === "delivery") {
            this.props.router.history.push(`/deliveries/${val}`);
        } else if (event === "workOrder") {
            this.props.router.history.push(`/workorders/${val}`);
        }
    }

    formatDate(msDate) {
        let d = new Date(msDate);
        return `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`;
    }

    getEventsForDay(day, events) {
        let formattedDay = this.formatDate(day);
        return events.filter(event => formattedDay === this.formatDate(event.date));
    }

    renderView() {
        // console.log("STATE:", this.state);
        // console.log("PROPS:", this.props);
        // console.log("CONTEXT: ", this.context);
        return (
            <React.Fragment>
                <div className="row heading-row">
                    <div className="col-12">
                        <h1 className="client-view custom-c-dark">{`${this.client} Schedule`}</h1>
                        <h5 className="date custom-c-mid">{renderDate()}</h5>
                    </div>
                </div>
                <div className="row view scheduleView">
                    <Route
                        exact
                        path="/schedule"
                        render={router => (
                            <React.Fragment>
                                <div className="col-12 calendar-ui-component panel-wrapper">
                                    <div className="custom-c-light scheduleFilters">
                                        <div className="customerFilter">
                                            <SelectField
												withFilter={true}
                                                varname="cusFilter"
                                                label="Filter by customer"
                                                name="Select Customer"
                                                options={this.getUniqueCustomers(this.state.events)
                                                    .filter(uCus => !this.state.customerFilters.includes(uCus.val))
                                                }
                                                change={(vn, val, err) => {
                                                    let customerFilters = [...this.state.customerFilters];
                                                    if (!customerFilters.includes(val)) {
                                                        customerFilters.push(val);
                                                        this.setState({ customerFilters })
                                                    }
                                                }}
                                            >
                                            </SelectField>
                                            <FlipMove className="customerList">
                                                {this.state.customerFilters.map(cus => {
                                                    return (
                                                        <div className="filterField" key={cus}>
                                                            <span>
                                                                {cus.replace(this.props.user.client + "-", "")}
                                                            </span>
                                                            <span className="remove" onClick={e => {
                                                                this.setState({
                                                                    customerFilters: this.state.customerFilters
                                                                        .filter(cFilter => cFilter !== cus)
                                                                })
                                                            }}>x</span>
                                                        </div>
                                                    )
                                                })}
                                            </FlipMove>
                                        </div>
										<div className="trackingFilter">
											<TextField
												label="Filter by tracking number"
												placeholder="tracking number..."
												style={{
													backgroundColor: "#e4e4e4"
												}}
												varname="trackingFilter"
												change={(varname, value, err) => {
													this.setState({ trackingFilter: value })
												}}
											>
												{this.state.trackingFilter}
											</TextField>
										</div>
                                        <div className="scheduleFilter">
                                            <p className="custom-c-dark">Filter by type</p>
                                            <div className="filter-wrapper" style={{ border: "3px solid #4ebbbf" }}>
                                                <CheckboxField
                                                    label="Show Deliveries"
                                                    change={(vn, val, err) => {
                                                        this.setState({ showDeliveries: val })
                                                    }}
                                                >
                                                    {this.state.showDeliveries}
                                                </CheckboxField>
                                            </div>
                                            <div className="filter-wrapper" style={{ border: "3px solid #1e43a9" }}>
                                                <CheckboxField
                                                    label="Show Workorders"
                                                    change={(vn, val, err) => {
                                                        this.setState({ showWorkOrders: val })
                                                    }}
                                                >
                                                    {this.state.showWorkOrders}
                                                </CheckboxField>
                                            </div>
                                            <div className="filter-wrapper" style={{ border: "3px solid #88d45a" }}>
                                                <CheckboxField
                                                    label="Show Collections"
                                                    change={(vn, val, err) => {
                                                        this.setState({ showCollections: val })
                                                    }}
                                                >
                                                    {this.state.showCollections}
                                                </CheckboxField>
                                            </div>
                                            <div className="filter-wrapper" style={{ border: "3px solid #a99e3f" }}>
                                                <CheckboxField
                                                    label="Show Courier Collections"
                                                    change={(vn, val, er) => {
                                                        this.setState({ showCourierCollections: val })
                                                    }}
                                                >
                                                    {this.state.showCourierCollections}
                                                </CheckboxField>
                                            </div>
											<div className="filter-wrapper" style={{ border: "3px solid #d17a7a" }}>
												<CheckboxField
													label="Show Removals"
													change={(vn, val, er) => {
														this.setState({ showRemovals: val })
													}}
												>
													{this.state.showRemovals}
												</CheckboxField>
											</div>
                                            <div className="filter-wrapper" style={{ border: "3px solid #ad4d4d" }}>
                                                <CheckboxField
                                                    label="Show cancelled"
                                                    change={(vn, val, er) => {
                                                        this.setState({ showCancelled: val })
                                                    }}
                                                >
                                                    {this.state.showCancelled}
                                                </CheckboxField>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.calendarMode === "big" ?
                                        <Calendar
                                            apiRoot
                                            events={this.filterEvents(this.state.events)}
                                            eventHandler={(event, val) => {
                                                if (event === "select-day" && val && this.props.user.role !== 4) {
                                                    let speed = $(".calendar-ui-component").height() / 2.5;
                                                    $("#app").animate({
                                                        scrollTop: $(".calendar-ui-component").height() + 100
                                                    }, speed);
                                                }
                                                this.calendarEvent(event, val);
                                            }}
                                            currentDate={this.props.currentDate}
                                            viewingDate={this.state.viewingDate}
                                            selectedDay={this.state.selectedDay}
                                            monthsArray={this.monthStrings}
                                            daysArray={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
                                            showWeekend={this.state.showWeekend}
                                            classes="calendar"
                                        />
                                        :
                                        <MiniCalendar
                                            events={this.filterEvents(this.state.events)}
                                            eventHandler={(event, val) => {
                                                if (event === "select-day" && val && this.props.user.role !== 4) {
                                                    let speed = $(".calendar-ui-component").height() / 2.5;
                                                    $("#app").animate({
                                                        scrollTop: $(".calendar-ui-component").height() + 50
                                                    }, speed);
                                                }
                                                this.calendarEvent(event, val);
                                            }}
                                            currentDate={this.props.currentDate}
                                            viewingDate={this.state.viewingDate}
                                            selectedDay={this.state.selectedDay}
                                            monthsArray={this.monthStrings}
                                            daysArray={["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]}
                                            showWeekend={this.state.showWeekend}
                                            classes="mini-calendar"
                                        />}
                                </div>
                                <FlipMove className="col-12 full-list-col" >
                                    {this.state.selectedDay
                                        ?
                                        <div className="controls">
                                            <span
                                                onClick={e => {
                                                    let speed = $(".calendar-ui-component").height() / 2.5;
                                                    $("#app").animate({
                                                        scrollTop: 100
                                                    }, speed);
                                                }}
                                            >
                                                <div className="custom-c-dark">Back to top</div>
                                                <div className="icon chevron-icon"></div>
                                            </span>
                                        </div>
                                        :
                                        null
                                    }
                                    {this.state.selectedDay ?
                                        <ScheduleList
                                            user={this.props.user}
                                            eventHandler={(event, val) => {
                                                this.calendarEvent(event, val);
                                            }}
                                            events={this.getEventsForDay(
                                                this.state.selectedDay.date,
                                                this.filterEvents(this.state.events)
                                            )}
                                        />
                                        : null}
                                </FlipMove>
                            </React.Fragment>
                        )}
                    >
                    </Route>
                </div>
            </React.Fragment>
        )
    }
}

export default ScheduleView