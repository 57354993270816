/**
 *  PROPS:
 *
 *      events: (Array of Objects)
 */

class ScheduleList extends React.Component {
    constructor(props) {
        super(props)
    }

    getLinkIcon(type) {
        switch (type) {
            case "delivery":
                return {
                    icon: "delivery",
                    link: "deliveries"
                }
            case "workOrder":
                return {
                    icon: "document",
                    link: "workorders"
                }
            case "collection":
                return {
                    icon: "collection",
                    link: "collections"
                }
            case "courier-collection":
                return {
                    icon: "collection",
                    link: "courier-collections"
                }
			case "removals":
				return {
					icon: "home",
					link: "removals"
				}
        }
    }

    sortEvents(events) {
        // Creating empty arrays holding different event types
        let deliveries = [];
        let workOrders = [];
        let collections = [];
        let courierCollections = [];
        // Putting every event into correct array.
        events.forEach(item => {
            if (item.type === "delivery") {
                deliveries.push(item);
            } else if (item.type === "workOrder") {
                workOrders.push(item)
            } else if (item.type === "collection") {
                collections.push(item)
            } else if (item.type === "courier-collection") {
                courierCollections.push(item)
            } else {
                console.error("Unsupported event type")
            }
        })
        // Returning all arrays
        return {
            deliveries,
            workOrders,
            collections,
            courierCollections
        };

    }

    renderEvents(events) {
        return events
            .sort((a, b) => {
                let aVal = a.data.customer;
                let bVal = b.data.customer;
                if (aVal < bVal) {
                    return -1;
                } else if (bVal < aVal) {
                    return 1;
                } else {
                    return 0;
                }
            })
            .map(event => {
                let icon = this.getLinkIcon(event.type).icon;
                let link = this.getLinkIcon(event.type).link;
                return (
                    <div className="col-12 col-lg-6 col-xl-4" key={event.ref}>
                        <a
                            href={`/${link}/${event.ref}`}
                            className="item custom-bg-light list-hover custom-c-dark"
                            target="_blank"
                        >
                            <div className="border-color custom-bg-feature1"></div>
                            <div className={`icon ${icon}-icon`}></div>
                            <div className="item-name">{`${event.title}`}</div>
                            <div className="item-id">{`${event.ref}`}</div>
                            <div className="date">{this.formatDate(event.date)}</div>
                            <hr />
                            <div className="moreInfo">
                                {event.moreInfo || "No further information."}
                            </div>
                            <hr />
                            <div className="status">
                                Status: <b>{event.data.status}</b>
                            </div>
                        </a>
                    </div>
                )
            })
    }

    formatDate(ms) {
        const date = new Date(ms);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }

    render() {
        let eventItems = this.sortEvents(this.props.events);
        return (
            <div className="full-list">
                <div className="row create-buttons" style={{ marginBottom: "20px" }}>
                    {this.props.user.role !== 3
                        ?
                        <div className="col-12">
                            <div
                                className="button custom-bg-light"
                                onClick={() => {
                                    this.props.eventHandler("create-delivery", "create");
                                }}
                            >
                                <div className="icon add-icon"></div>
                                <span className="custom-c-dark">Add delivery</span>
                            </div>
                        </div>
                        :
                        null
                    }
                    {this.props.user.role <= 2
                        ?
                        <div className="col-12">
                            <div
                                className="button custom-bg-light"
                                onClick={() => {
                                    if (this.props.user.role <= 2) {
                                        this.props.eventHandler("create-workOrder", "create");
                                    }
                                }}
                            >
                                <div className="icon add-icon"></div>
                                <span className="custom-c-dark">Add work order</span>
                            </div>
                        </div>
                        :
                        null
                    }
                    {this.props.user.role <= 2
                        ?
                        <div className="col-12">
                            <div
                                className="button custom-bg-light"
                                onClick={() => {
                                    if (this.props.user.role <= 2) {
                                        this.props.eventHandler("create-collection", "create");
                                    }
                                }}
                            >
                                <div className="icon add-icon"></div>
                                <span className="custom-c-dark">Add collection</span>
                            </div>
                        </div>
                        :
                        null
                    }
                    {this.props.user.role <= 2
                        ?
                        <div className="col-12">
                            <div
                                className="button custom-bg-light"
                                onClick={() => {
                                    if (this.props.user.role <= 2)
                                        this.props.eventHandler("create-courier-collection", "create")
                                }}
                            >
                                <div className="icon add-icon"></div>
                                <span className="custom-c-dark">Add courier collection</span>
                            </div>
                        </div>
                        :
                        null
                    }
					{this.props.user.role <= 2
						?
						<div className="col-12">
							<div 
								className="button custom-bg-light"
								onClick={() => {
									if (this.props.user.role <= 2)
										this.props.eventHandler("create-removal", "create")
								}}
							>
								<div className="icon add-icon" />
								<span className="custom-c-dark">Add removal collection</span>	
							</div>
						</div>
						:
						null
					}
                </div>
                {eventItems.deliveries.length
                    ?
                    <div className="row list-items" style={{ marginBottom: "15px" }}>
                        <div className="col-12">
                            <h2 className="custom-c-feature2">Deliveries</h2>
                        </div>
                        {this.renderEvents(eventItems.deliveries)}
                    </div>
                    :
                    null
                }
                {eventItems.workOrders.length
                    ?
                    <div className="row list-items" style={{ marginBottom: "15px" }}>
                        <div className="col-12">
                            <h2 className="custom-c-feature2">Work Orders</h2>
                        </div>
                        {this.renderEvents(eventItems.workOrders)}
                    </div>
                    :
                    null
                }
                {eventItems.collections.length
                    ?
                    <div className="row list-items" style={{ marginBottom: "15px" }}>
                        <div className="col-12">
                            <h2 className="custom-c-feature2">Collections</h2>
                        </div>
                        {this.renderEvents(eventItems.collections)}
                    </div>
                    :
                    null
                }
                {eventItems.courierCollections.length
                    ?
                    <div className="row list-items">
                        <div className="col-12">
                            <h2 className="custom-c-feature2">Courier Collections</h2>
                        </div>
                        {this.renderEvents(eventItems.courierCollections)}
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

export default ScheduleList;