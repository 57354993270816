import ItemCreator from "../../assets/ui-super-list/itemCreator";
import NumberField from "../../fields/numberField";

class StockSplitter extends ItemCreator {
    constructor(props) {
        super(props)
        this.state.item.quantity = 1;
    }

    canSubmit() {
        return true
    }

    renderCreator() {
        return (
            <div className="creator">
                <NumberField
                    varname="quantity"
                    label="Split stock"
                    change={this.itemChanged.bind(this)}
                    min={1}
                    max={this.props.item.quantity - 1}
                >
                    {this.state.item.quantity}
                </NumberField>
            </div>
        )
    }

}

export default StockSplitter;