import SelectorStage from "./selectorStage";

class SelectLocationToAdd extends SelectorStage {
    constructor(props) {
        super(props)
    }

    getItem(locator) {
        return new Promise((resolve, reject) => {
            $.get(`${this.context.apiRoot}/locations?locator=${locator}`).then(response => {
                if (response.total) return reject("Location already exists");
                resolve(locator)
            }).catch(err => {
                reject(err.responseJSON.msg);    
            })    
        })
    }
    
}


SelectLocationToAdd.defaultProps = {
    title: "Select location.",
    icon: "location"
}

export default SelectLocationToAdd