import FlipMove from 'react-flip-move';

/**
 *  STATE:
 * 
 *      messages: Array of Objects in the form of {msg: "string"m type: "string"} 
 */

class Logger extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            minimized: false
        }
        window.logger = this
    }

    success(message) {
        if (typeof message === "string") {
            message = {
                msg: message,
            }
        }
        if (message.responseJSON) {
            message = {
                msg: message.responseJSON.msg || message.responseJSON.message
            };
        }
        message.type = "success";
        message.id = Math.random() + "";
        this.setState({
            messages: [message, ...this.state.messages]
        }); 
    }

    error(message) {
        if (typeof message === "string") {
            message = {
                msg: message,
            }
        }
        if (message.responseJSON) {
            message = {
                msg: message.responseJSON.msg || message.responseJSON.message
            };
        }
        message.type = "error";
        message.id = Math.random() + "";
        this.setState({
            messages: [message, ...this.state.messages]
        }); 
    }



    renderMessages() {
        return (
            <FlipMove>
                {
                    this.state.messages.map(message => {
                        return (
                            <div
                                className={`message ${message.type}`}
                                key={message.id}
                            >
                                <div
                                    className="close-msg"
                                    onClick={event => {
                                        this.setState({
                                            messages: this.state.messages.filter(msg => msg.id !== message.id)
                                        })
                                    }}
                                >
                                </div>
                                {message.msg || message.message}
                            </div>
                        )
                    })
                }
            </FlipMove>
        )
    }

    renderMinimizedMessages() {
        let eCount = 0;
        let sCount = 0;
        this.state.messages.forEach(msg => {
            if (msg.type === "error") {
                eCount++
            } else if (msg.type === "success") {
                sCount++
            }
        })
        return (
            <div key="count" className="msg-count">
                {sCount > 0
                    ?
                    <div className="success-count">{sCount}</div>
                    :
                    null
                }
                {eCount > 0
                    ?
                    <div className="error-count">{eCount}</div>
                    :
                    null
                }
            </div>
        )    
    }

    render() {
        return (
            <div className="logger">
                <div>
                    {!this.state.minimized
                        ?
                        this.renderMessages()
                        :
                        this.renderMinimizedMessages()
                    }
                </div>
                <FlipMove>
                    {this.state.messages.length
                        ?
                        <div className="buttons-wrapper">
                            <div
                                key="close-all" 
                                className="close-all custom-c-dark"
                                onClick={() => {
                                    this.setState({ 
                                        messages: [],
                                        minimized: false 
                                    })    
                                }}
                            >
                                CLOSE ALL
                            </div>
                            <div 
                                className={this.state.minimized ? "icon arrow-icon minimize-btn minimized" : "icon arrow-icon minimize-btn"}
                                onClick={() => {
                                    this.setState({ minimized: !this.state.minimized })
                                }}
                            >
                            </div>
                        </div>
                        :
                        null
                    }
                </FlipMove>
            </div>
        )
    }
}

export default Logger;