import FlipMove from "react-flip-move";

/**
 *  STATE:
 * 
 *      currentPos: NUMBER
 * 
 *  PROPS:
 * 
 *      listItems:  ARRAY of objects {icon, status, text, ref}
 *      itemSelectedHandler: FUNCTION.
 */

class UiList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPos: 0
        }
    }

    componentDidUpdate(prevProps) {
        //Resetting position to 0 if received different listItems
        if (prevProps.listItems.length !== this.props.listItems.length) {
            this.setState({ currentPos: 0 })
        }
    }

    changeCurrentPos(direction) {
        if (direction === "up" && this.state.currentPos > 0) {
            this.setState({
                currentPos: this.state.currentPos - 1
            })
        } else if (direction === "down" && this.state.currentPos < this.props.listItems.length ) {
            this.setState({
                currentPos: this.state.currentPos + 1
            })
        }
    }

    renderListItems() {
        let items = this.props.listItems.slice(this.state.currentPos, 3 + this.state.currentPos)
        return items
            .map(item => (
                <div 
                    key={item.ref} 
                    className="listItem custom-bg-light list-hover custom-border-color"
                    onClick={() => {
                        this.props.itemSelectedHandler(item);
                    }}
                >
                    <div className={item.status === "warning" ? "border-color custom-bg-feature3" : "border-color custom-bg-feature1"}></div>
                    <div className={item.status === "warning" ? `icon error-icon` : `icon ${item.type}-icon`}></div>
                    <div className="text custom-c-dark">{item.title}</div>
                </div>
            ))
    }

    render() {
        return (
            <div className="content-list">
                <div 
                    className={this.state.currentPos > 0 ? "arrow up-arrow" : "arrow up-arrow hidden"}
                    onClick={() => {
                        this.changeCurrentPos("up")
                    }}
                >
                </div>
                <FlipMove duration={200}>
                    {this.renderListItems()}
                </FlipMove>
                <div 
                    className={this.state.currentPos < this.props.listItems.length - 3 ? "arrow down-arrow" : "arrow down-arrow hidden"}
                    onClick={() => {
                        this.changeCurrentPos("down")
                    }}
                >
                </div>
            </div>
        )
    }

}

export default UiList;