import React from "react";
import SuperList from "../../assets/ui-super-list";
import View from "../view";
import CustomerField from "../../fields/customerField";
import LocationSearchField from "../../fields/locationSearchField";
import FlipMove from "react-flip-move";
import StaggeredSearchField from "../../fields/staggeredSearchField";
import SelectField from "../../fields/selectField";
import CheckboxField from "../../fields/checkboxField";
import csvCreator from "../../assets/csvCreator";

class ProductReporter extends View {
    constructor(props) {
        super(props);
        this.state = {
            productList: [],
            page: 1,
            pageCount: 1,
            filters: {},
            searchFilters: {
                show: "in",
                groups: "hide",
                subcustomer: "false"
            },
            productFilters: {},
            productPage: 1,
            productPageCount: 1,
            product: null,
            productLocations: []
        }
        this.pageSize = 15;
    }

    componentDidMount() {
        this.getProducts();
    }

    filterProductList(list) {
        let productCodeFilter = (this.state.filters.productCode || "").toLowerCase();
        return list
            .filter(item => {
                let passed = true;
                if (
                    productCodeFilter &&
                    !(item.productCode || "").toLowerCase().includes(productCodeFilter)
                ) passed = false;
                return passed;
            })
            .slice((this.state.page - 1) * this.pageSize, ((this.state.page - 1) * this.pageSize) + this.pageSize);
    }

    productListForExport(list) {
        let productCodeFilter = (this.state.filters.productCode || "").toLowerCase();
        return list
            .filter(item => {
                let passed = true;
                if (
                    productCodeFilter &&
                    !(item.productCode || "").toLowerCase().includes(productCodeFilter)
                ) passed = false;
                return passed;
            })
    }

    filterLocationList(list) {
        return list
            .filter(item => {
                let passed = true;
                if (
                    this.state.productFilters.location &&
                    item.locator !== this.state.productFilters.location
                ) passed = false;
                return passed;
            })
            .slice((this.state.productPage - 1) * this.pageSize, ((this.state.productPage - 1) * this.pageSize) + this.pageSize);
    }

    filterChange(values, type = "filters") {
        this.setState({
            [type]: Object.assign({}, this.state[type], values)
        }, () => {
            if (type === "searchFilters") {
                this.getProducts();
                if (this.state.product)
                    this.getProductLocations(this.state.product);
            }
        });
    }

    getProductLocations(productCode) {
        this.getProducts({ productCode }, productLocations => {
            this.setState({
                productLocations,
                productPage: 1,
                productPageCount: Math.floor(productLocations.length / this.pageSize)
            });
        })
    }

    getProducts(filters = {}, cb) {
        filters = Object.assign(
            {},
            this.state.searchFilters,
            filters
        );

        $.get(`${this.props.apiRoot}/stock/product-report${this.createQueryString(filters)}`).done(response => {
            if (cb) {
                cb(response.results);
            } else if (!filters.exportMode) {
                this.setState({
                    productList: response.results,
                    pageCount: Math.ceil(response.total / this.pageSize),
                    page: 1
                });
            }
        }).catch(err => {
            logger.error(err);
        });
    }

    render() {

        return <div className="row view stockView splitPanelView">
            <div className="col-12 col-xl-3 ui-panel-split ui-panel-split-left custom-bg-light custom-c-light shadow-1 filters" style={{
                minHeight: "850px"
            }}>
                <FlipMove
                    enterAnimation="fade"
                    leaveAnimation="fade"
                    className="filters"
                    key="productReportFilters"
                >
                    {/* Filter by in or out */}
                    <SelectField
                        label="Show"
                        varname="show"
                        name="Show"
                        options={[
                            {
                                val: "all",
                                display: "All"
                            },
                            {
                                val: "in",
                                display: "In"
                            },
                            {
                                val: "out",
                                display: "Out"
                            },
                        ]}
                        change={(vn, show) => {
                            this.filterChange({ show }, "searchFilters");
                        }}
                    >
                        {this.state.searchFilters.show}
                    </SelectField>
                    {/* Filtering by groups */}
                    <SelectField
                        label="Items with group"
                        varname="groups"
                        name="Groups"
                        options={[
                            {
                                val: "all",
                                display: "Show all items"
                            },
                            {
                                val: "show",
                                display: "Show only in groups"
                            },
                            {
                                val: "hide",
                                display: "Show only individual"
                            }
                        ]}
                        change={(vn, groups) => {
                            this.filterChange({ groups }, "searchFilters");
                        }}
                    >
                        {this.state.searchFilters.groups}
                    </SelectField>
                    {/* Filtering by customer */}
                    <CustomerField
                        key="customer"
                        defaults={this.props.user.role >= 4
                            ?
                            []
                            :
                            [
                                {
                                    name: "internal",
                                    displayName: "Internal"
                                }
                            ]
                        }
                        change={(vn, customer) => {
                            this.filterChange({
                                customer: customer ? customer.name : null
                            }, "searchFilters")
                        }}
                    >
                        {this.state.searchFilters.customer}
                    </CustomerField>
                    {/* Toggle visibility of sub customers stock */}
                    <CheckboxField
                        label="Show subcustomer stock"
                        change={(vn, val, err) => {
                            this.filterChange({ subcustomer: val ? "true" : "false" }, "searchFilters")
                        }}
                    >
                        {this.state.searchFilters.subcustomer === "true"}
                    </CheckboxField>
                    {/* Product code */}
                    {!this.state.product ? <StaggeredSearchField
                        label="Product code"
                        varname="productCode"
                        delay={250}
                        autoFillItem={"stock"}
                        autoFillField={"productCode"}
                        change={(vn, productCode) => {
                            this.filterChange({ productCode });
                        }}
                    >
                        {this.state.filters.productCode}
                    </StaggeredSearchField> : null}
                    {!this.state.product ? <StaggeredSearchField
                        label="Supplier"
                        varname="supplier"
                        delay={250}
                        change={(vn, supplier) => {
                            this.filterChange({ supplier }, "searchFilters")
                        }}
                    >
                        {this.state.searchFilters.supplier}
                    </StaggeredSearchField> : null}
                    {!this.state.product ? <button
                        disabled={!Boolean(this.state.searchFilters.customer) && this.props.user.role !== 4}
                        className="manualRefresh btn custom-bg-feature1"
                        onClick={e => {
                            csvCreator.create(this.productListForExport(this.state.productList)).then(csv => {
                                csvCreator.download(csv);
                            });
                        }}
                    >
                        Export data
                    </button> : null}
                    {/* Location */}
                    {this.state.product ? <LocationSearchField
                        key="location"
                        label="Location"
                        varname="location"
                        change={(vn, location) => {
                            this.filterChange({
                                location: location ? location.locator : null
                            }, "productFilters");
                        }}
                    >
                        {this.state.productFilters.location}
                    </LocationSearchField> : null}
                    {}
                    {/* For returning to the product code list */}
                    {this.state.product ? <hr key="divider1" /> : null}
                    {this.state.product ? <button
                        key="refresh"
                        className="manualRefresh btn custom-bg-feature1"
                        onClick={e => {
                            this.setState({
                                product: null,
                                productLocations: []
                            });
                        }}
                    >Back to product list.</button> : null}
                </FlipMove>
            </div>
            {
                this.state.product
                    ?
                    <div className="col-12 col-xl-9 stock ui-panel-split ui-panel-split-right custom-bg-dark custom-c-light shadow-1">
                        <div className="icon stock-icon title-icon"></div>
                        <h2 className="panel-name">{this.state.product}</h2>
                        <SuperList
                            user={this.props.user}
                            apiRoot={this.props.apiRoot}
                            controls={[]}
                            numberOfPages={this.state.productPageCount}
                            page={this.state.productPage}
                            items={this.filterLocationList(this.state.productLocations)}
                            onPageChange={productPage => {
                                this.setState({ productPage });
                            }}
                            icon="location"
                            uniqueField="locator"
                            columns={[
                                {
                                    name: "Location",
                                    renderer: item => item.locator,
                                    sorter: item => item.locator
                                },
                                {
                                    name: "Item Count",
                                    renderer: item => item.count,
                                    sorter: item => item.count
                                }
                            ]}
                        />
                    </div>
                    :
                    <div className="col-12 col-xl-9 stock ui-panel-split ui-panel-split-right custom-bg-dark custom-c-light shadow-1">
                        <div className="icon stock-icon title-icon"></div>
                        <h2 className="panel-name">Product Report</h2>
                        <SuperList
                            user={this.props.user}
                            apiRoot={this.props.apiRoot}
                            controls={[]}
                            numberOfPages={this.state.pageCount}
                            page={this.state.page}
                            items={this.filterProductList(this.state.productList)}
                            onPageChange={page => {
                                this.setState({ page });
                            }}
                            onSelect={item => {
                                this.setState({
                                    product: item.productCode,
                                    productLocations: []
                                }, () => {
                                    this.getProductLocations(item.productCode);
                                });
                            }}
                            icon="stock"
                            uniqueField="productCode"
                            columns={[
                                {
                                    name: "Product Code",
                                    renderer: item => item.productCode,
                                    sorter: item => item.productCode
                                },
                                {
                                    name: "Item Count",
                                    renderer: item => item.count,
                                    sorter: item => item.count
                                }
                            ]}
                        />
                    </div>
            }
        </div >
    }
}

export default ProductReporter;