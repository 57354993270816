/**
 * Status panel is similar to a normal panel, bu also
 * accepts the prop 'status' which will change its
 * feature color.
 */
const UiStatusPanel = (props) => (
    <div className={"panel status-panel custom-bg-dark custom-c-light shadow-1 "+ (props.classes ? props.classes : "")}>
        <div className={props.warning ? "top-border custom-bg-feature3" : "top-border custom-bg-feature1"}></div>
        <div className="heading">
            {props.title}
        </div>
        {props.children}
    </div>
)

export default UiStatusPanel;