class Pod extends React.Component {
    componentDidMount() {
        let wrapper = window.document.createElement("div");
        if (this.props.user.role === 4) {
            let image = window.document.createElement('img');
            image.src = "/static/media/POD_header_small.png";
            image.style.width = "100%";
            image.style.margin = "-10px 0px 0px 0px";
            wrapper.append(image.cloneNode(true));
        }
        wrapper.append(this.refs.content.cloneNode(true));
        let newWindow = window.open('', '', 'width=100, height=100'),
            document = newWindow.document.open(),
            pageContent = wrapper.innerHTML;
        document.write(pageContent);
        // Deciding which css file to attach based on the user role
        if (this.props.user.role === 4) {
            // Its a customer
            document.querySelector("head").innerHTML = '<link rel="stylesheet" href="/static/css/PODCus.css">';
        } else {
            // Internal staff
            document.querySelector("head").innerHTML = '<link rel="stylesheet" href="/static/css/POD.css">';
        }
        document.close();
        newWindow.moveTo(0, 0);
        newWindow.resizeTo(screen.width, screen.height);
        setTimeout(() => {
            newWindow.print();
            newWindow.close();
            if (this.props.onExit)
                this.props.onExit();
        }, 1000);
    }

    formatDate(ms) {
        //dd/mm/yyyy
        let date = new Date(ms);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }

    render() {
        let collection = this.props.collection;
        let uniqueGroups = [];
        let groups = [];
        let stock = [];
        let stockWithGroups = [];

        // Splitting name into first and last name
        let names = [];
        if (collection.signedBy) names = collection.signedBy.split(" ");

        // Splitting stock into two arrays, with and without groups
        this.props.stock.forEach(s => {
            if (s.group) {
                stockWithGroups.push(s)
            } else {
                stock.push(s)
            }
        });

        // Getting unique groups
        stockWithGroups.forEach(s => {
            let position = uniqueGroups.indexOf(s.group);
            // If group not already added
            if (position < 0) {
                groups.push({
                    group: s.group,
                    description: s.description,
                    total: 1
                })
                uniqueGroups.push(s.group)
            } else {
                groups[position].total++
            }
        })

        return <div style={{ display: "none" }} className="pod">
            {/* Holder for actual POD markup. */}
            <div
                ref="content"
                className="content"
                style={{
                    width: "100%",
                    fontFamily: "sans-serif",
                    fontSize: "12px",
                    color: "#1a1a1a",
                }}
            >
                <h1>Proof of collection</h1>
                <p
                    style={{
                        marginBottom: "0px"
                    }}
                >
                    {`ID: ${collection.id}`}
                </p>
                <p
                    style={{
                        marginBottom: "0px"
                    }}
                >
                    {`Customer: ${collection.customer}`}
                </p>
                <p
                    style={{
                        marginBottom: "0px"
                    }}
                >
                    {`Collection date: ${this.formatDate(collection.dateOutgoing)}`}
                </p>
                {collection.notes
                    ?
                    <p
                        style={{
                            marginBottom: "0px"
                        }}
                    >
                        {`Notes: ${collection.notes}`}
                    </p>
                    :
                    null
                }
                <hr
                    style={{ marginBottom: "20px" }}
                />
                {/* Stock table */}
                {stock.length
                    ?
                    <table
                        style={{
                            width: "100%",
                            border: "1px solid #1a1a1a",
                            borderCollapse: "collapse",
                            textAlign: "left",
                            marginBottom: "10px"
                        }}
                    >
                        <tbody>
                            <tr>
                                <th
                                    style={{
                                        border: "1px solid #1a1a1a",
                                        padding: "5px",
                                        width: "25%"
                                    }}
                                >
                                    Product Code
                                </th>
                                <th
                                    style={{
                                        border: "1px solid #1a1a1a",
                                        padding: "5px",
                                        width: "50%"
                                    }}
                                >
                                    Description
                                </th>
                            </tr>
                            {stock.map((s, index) => {
                                return (
                                    <tr key={s.code + "stock" + index} >
                                        <td
                                            style={{
                                                border: "1px solid #1a1a1a",
                                                padding: "5px"
                                            }}
                                        >
                                            {s.productCode}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #1a1a1a",
                                                padding: "5px"
                                            }}
                                        >
                                            {s.description}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    :
                    null
                }
                {groups.length
                    ?
                    <table
                        style={{
                            width: "100%",
                            border: "1px solid #1a1a1a",
                            borderCollapse: "collapse",
                            textAlign: "left"
                        }}
                    >
                        <tbody>
                            <tr>
                                <th
                                    style={{
                                        border: "1px solid #1a1a1a",
                                        padding: "5px",
                                        width: "25%"
                                    }}
                                >
                                    Group
                            </th>
                                <th
                                    style={{
                                        border: "1px solid #1a1a1a",
                                        padding: "5px",
                                        width: "25%"
                                    }}
                                >
                                    Total
                            </th>
                                <th
                                    style={{
                                        border: "1px solid #1a1a1a",
                                        padding: "5px",
                                        width: "50%"
                                    }}
                                >
                                    Description
                            </th>
                            </tr>
                            {groups.map((s, index) => {
                                return (
                                    <tr key={s.code + "group" + index} >
                                        <td
                                            style={{
                                                border: "1px solid #1a1a1a",
                                                padding: "5px"
                                            }}
                                        >
                                            {s.group}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #1a1a1a",
                                                padding: "5px"
                                            }}
                                        >
                                            {s.total}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #1a1a1a",
                                                padding: "5px"
                                            }}
                                        >
                                            {(() => {
                                                let result = /commission text:\s*([^\n]+)/gi.exec(s.description);
                                                if (result && result[1]) return result[1];
                                                return s.description
                                            })()}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    :
                    null
                }
                <div
                    className="signDate"
                    style={{
                        marginTop: "40px",
                        fontWeight: "bold",
                        fontSize: "18px"
                    }}
                >
                    <p className="name">Forename: {(names.length && names[0]) ? names[0] : ""}</p>
                    <p className="name">Surname: {(names.length && names[1]) ? names[1] : ""}</p>
                    <p className="signature">Signature: {
                        collection.signature
                            ?
                            <img src={collection.signature} style={{
                                display: "inline-block",
                                verticalAlign: "middle"
                            }} />

                            : null
                    }</p>
                    {!collection.signature
                        ?
                        <p className="date">Date:</p>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    }
}

export default Pod;