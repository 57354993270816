import FlipMove from "react-flip-move";
import Process from "./index";
// Stages
import Action from "../stages/action";
import Success from "../stages/success";
import Custom from "../stages/custom";
import SelectLocationToAdd from "../stages/selectLocationToAdd";
// Field Components
import SelectField from "../../../fields/selectField";
import TextareaField from "../../../fields/textareaField";

class AddLocation extends Process {
    constructor(props) {
        super(props);
        this.state.stage = 0;
        this.stages = [
            {
                // 0 - Creating a locator and checking if one already exists
                component: SelectLocationToAdd
            },
            {
                // 1 - adding type and description
                component: Custom,
                config: {
                    state: {
                        type: "",
                        description: ""
                    },
                    render: stage => {
                        return (
                            <div className="addLocationStage">
                                <div className={`icon location-icon largeIcon`} />
                                <div className="location-details">
                                    <div className="locator">
                                        <p className="name">Locator:</p>
                                        <p className="value">{this.stageData(0)}</p>
                                    </div>
                                    <SelectField
                                        label="Type"
                                        varname="type"
                                        name="Type"
                                        options={[
                                            {
                                                val: "warehouse",
                                                display: "Warehouse"
                                            },
                                            {
                                                val: "vehicle",
                                                display: "Vehicle"
                                            },
                                            {
                                                val: "holding",
                                                display: "Holding"
                                            },
                                            {
                                                val: "remedial",
                                                display: "Remedial"
                                            },
                                            {
                                                val: "other",
                                                display: "Other"
                                            }
                                        ]}
                                        change={(varname, value, error) => {
                                            stage.setState({ type: value })
                                        }}
                                    >
                                        {stage.state.type}
                                    </SelectField>
                                    <TextareaField
                                        label="Description"
                                        varname="description"
                                        change={(varname, value, error) => {
                                            stage.setState({ description: value })
                                        }}
                                    >
                                        {stage.state.description}
                                    </TextareaField>
                                </div>
                                <FlipMove>
                                    {stage.state.type
                                        ?
                                        <button
                                            key="confirmButton" 
                                            className="confirm custom-bg-feature1"
                                            onClick={() => {
                                                let data = {
                                                    type: stage.state.type,
                                                    locator: this.stageData(0)   
                                                };
                                                if (stage.state.description) 
                                                    data.description = stage.state.description;
                                                
                                                stage.stageComplete(data)
                                            }}
                                        >
                                            Confirm
                                        </button>
                                        :
                                        null
                                    }
                                </FlipMove>
                            </div>
                        )
                    }
                }
            },
            {
                // 2 - making the create location call
                component: Action,
                config: {
                    action: ({ finish, error, changeStage }) => {
                        $.ajax({
                            type: "POST",
                            url: `${this.context.apiRoot}/locations`,
                            data: JSON.stringify(this.stageData(1))
                        }).done(response => {
                            finish()
                        }).catch(err => {
                            console.error(err)
                            error("Couldn't create location");
                            setTimeout(() => {
                                changeStage(1)
                            },3000)
                        })    
                    }
                }
            },
            {
                // 3 - show success message and finish process
                component: Success,
                config: {
                    message: () => <div>Success! Location created.</div>
                }
            }
        ]
    }
}

export default AddLocation;