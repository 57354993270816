import ItemCreator from "../../assets/ui-super-list/itemCreator";
import StockSearchField from "../../fields/stockSearchField";
import NumberField from "../../fields/numberField";
import Context from "../../context";

class CollectionStockCreator extends ItemCreator {

    constructor(props) {
        super(props);
        this.state.adding = null;
        this.state.addingQuantity = 1;
    }

    canSubmit() {
        if (this.state.item && this.state.item.id) {
            return true
        }
        return false
    }

    renderCreator() {
        let customer = this.props.customer;
        // let customer = this.props.customer.split("-");
        // customer = `${customer[0]}-${customer[1]}`;
        return (
            <div className="creator" style={this.state.adding ? {
                paddingRight: 110
            } : null}>
                <StockSearchField
                    queryDefaults={{
                        show: "in",
                        count: 250
                    }}
                    filter={item => {
                        if (this.props.filter && !this.props.filter(item))
                            return false;

                        if (!item.customer.includes(this.props.customer))
                            return false;

                        if (item.lockedBy)
                            return false;

                        return true;
                    }}
                    customer={customer}
                    label="Stock"
                    defaultSearch="productCode"
                    varname="id"
                    change={(vn, stockItem) => {
                        // Cancel if null.
                        if (!stockItem) return this.setState({
                            adding: null,
                            addingQuantity: 1
                        });
                        if (stockItem.quantity > 1) {
                            // If it is a multi-quantity item, add to state for splitting.
                            this.setState({
                                adding: stockItem,
                                addingQuantity: stockItem.quantity
                            });
                        } else {
                            // Otherwise nullify state.
                            this.setState({
                                adding: null,
                                addingQuantity: 1
                            });
                        }
                        // Pass the change up to the parent.
                        this.itemChanged(vn, stockItem);
                    }}
                    submit={this.submit.bind(this)}
                >
                    {this.state.item.code || null}
                </StockSearchField>
                {this.state.adding ?
                    <div className="quantity-selector" style={{
                        width: 95,
                        position: "absolute",
                        bottom: 0,
                        right: 0
                    }}>
                        <NumberField
                            min={1}
                            max={this.state.adding.quantity}
                            change={(vn, addingQuantity) => {
                                this.setState({ addingQuantity });
                            }}
                        >
                            {this.state.addingQuantity}
                        </NumberField>
                    </div>
                    : null
                }
            </div>
        )
    }

    splitStock(stockItem, quantity, cb) {
        $.ajax({
            url: `${this.context.apiRoot}/stock/${stockItem.id}/split`,
            method: "PUT",
            data: JSON.stringify({ quantity }),
            contentType: "application/json"
        }).then(response => {
            cb(response.split[0]);
        }).catch(err => {
            console.error("Error splitting stock: ", err);
        });
    }

    render() {

        let canSubmit = this.canSubmit();

        return (
            <div className="item-creator custom-border-color">
                {this.props.closeCreator ? <div
                    className="icon close-icon"
                    onClick={() => {
                        this.props.closeCreator()
                    }}
                /> : null}
                {this.renderCreator()}
                {/* Button for simple stock adding */}
                {!this.state.adding || (
                    this.state.adding &&
                    this.state.addingQuantity === this.state.adding.quantity
                ) ?
                    <div
                        className={"button create-item-button custom-bg-feature2" + (canSubmit ? "" : " disabled")}
                        onClick={() => {
                            if (canSubmit) {
                                this.setState({
                                    adding: null,
                                    addingQuantity: 1
                                }, () => {
                                    this.submit(Object.assign({}, this.state.item));
                                });
                            }
                        }}
                    >
                        Add stock.
                    </div>
                    : null
                }
                {/* Button to split stock before adding */}
                {this.state.adding && this.state.addingQuantity !== this.state.adding.quantity
                    ?
                    <div
                        className="button create-item-button custom-bg-feature2"
                        onClick={() => {
                            // Make ajax call to split stock.
                            this.splitStock(this.state.adding, this.state.addingQuantity, newStock => {
                                // console.log("DID SPLIT: ", newStock);
                                this.setState({
                                    adding: null,
                                    addingQuantity: 1
                                }, () => {
                                    // MUST BE SENT IN THIS FORM WITH ID BECAUSE OF STUPID RULES PREVIOUSLY CREATED.
                                    newStock.splitRef = newStock.history[0].ref;
                                    this.submit({ id: newStock });
                                    this.itemChanged("id", newStock);
                                });
                            });
                        }}
                    >
                        Split stock stack and add.
                    </div>
                    : null
                }
            </div>
        )
    }

}

CollectionStockCreator.contextType = Context;

export default CollectionStockCreator;