const leichtKitchenTemplate = {
    name: "Leicht Kitchens",
    columns: [
        "description",
        "group",
        "description",
        "ignore",
        "code", // CODE: Ignore if colli no. isn't 0.
        "productCode",
        "colli", // COLLI NO.: If this isn't 0, use as code.
        "description",
        "description",
        "description",
        "ignore",
        "description"
    ],
    columnLabels: [
        "Transport number",
        "Order number",
        "Order position",
        "Status",
        "Production Order",
        "Product code",
        "Colli number",
        "Colli of",
        "Commission text",
        "Customer number",
        "Relation to first/original order number",
        "Net value"
    ],
    postProcess: rows => {
        let result = []
        rows.forEach(item => {
            // First ensure colli number is used as code if it exists.
            if (item.colli !== "0") {
                item.code = item.colli;
            }
            // And remove the colli.
            delete item.colli;
            // Now look for a match.
            for (let i = 0; i < result.length; i++) {
                // If one is found, increase quantity, and exit.
                if (result[i].code === item.code) {
                    result[i].quantity = String(Number(result[i].quantity) + 1);
                    return;
                }
            }
            // Otherwise, add it on.
            result.push(item);
        });
        // result.forEach(item => {
        //     console.log(item.code, item.quantity)
        // })
        return result;
    }
}

export default leichtKitchenTemplate;