import SuperList from "../../assets/ui-super-list";
import StockCreator from "../../views/stock-view/stockCreator";
import WorkOrderStockCreator from "./workOrderStockCreator";
import FileField from "../../fields/fileField";

class WorkOrderStockList extends SuperList {
    // componentDidUpdate() {
    //     console.log(this.props.items);
    // }
    constructor(props) {
        super(props);
        this.state.printing = null;
        this.state.previewing = null;
    }
    renderControls(item) {
        if (!this.props.renderControls) return null
        let controls = [
            {
                icon: this.props.user.data.theme === "dark" ? "pen-white.svg " : "pen.svg",
                label: "edit",
                callback: () => {
                    this.setState({
                        previewing: item,
                        splitting: null,
                        addingItem: false,
                        showingFiles: null
                    })
                },
            }
        ]

        if (!this.props.displayOnly) {
            controls.push({
                icon: "delete.svg",
                label: "delete",
                callback: this.props.onDelete ? (item) => {
                    if (window.confirm("Are you sure you want to remove this stock item?")) {
                        this.props.onDelete(item[this.props.uniqueField])
                    }
                } : null
            })
        }

        if (item.files && item.files.length) {
            controls.push({
                icon: "document.svg",
                label: "Show files",
                callback: () => {
                    this.setState({ showingFiles: item })
                }
            })
        }

        if (item.gps && !item.gps.includes("NO_DATA")) {
            controls.push({
                icon: "location.svg",
                label: "Show location",
                callback: () => {
                    this.setState({ showLocation: item })
                }
            })
        }

        let controlComponents = controls
            .reverse()
            .filter(c => c.callback ? true : false)
            .map(control => {
                return <div
                    title={control.label}
                    key={control.label}
                    className="control"
                    style={{ backgroundImage: `url("/static/media/${control.icon}")` }}
                    onClick={e => { control.callback(item) }}
                ></div>
            })

        // if (!this.props.displayOnly) controlComponents.push(
        //     <div
        //         title={"Print barcode"}
        //         key={"Print barcode"}
        //         className="control"
        //     >
        //         {this.state.printing === item ?
        //             <BarcodeSetIcon
        //                 printOnMount={true}
        //                 items={[{
        //                     code: item.code,
        //                     moreInfo: <div>
        //                         <div>
        //                             CUST: {this.props.customer} | PC: {item.productCode || "NO CODE"}
        //                         </div>
        //                         <hr />
        //                         <div>{item.description || "NO DESC"}</div>
        //                     </div>
        //                 }]}
        //             />
        //             :
        //             <div
        //                 className="control icon print-icon"
        //                 onClick={e => {
        //                     this.setState({ printing: item });
        //                 }}
        //             />
        //         }
        //     </div>
        // )

        return controlComponents;
    }

    renderRow(item) {
        if (this.state.showingFiles === item)
            return this.renderStockFiles();

        if (this.state.showLocation === item) {
            return this.renderLocation(item.gps)
        }

        if (this.state.previewing === item) {
            return this.renderItemPreviewer()
        }

        let columns = this.props.columns;
        if (this.props.mobileColumns && this.state.mobileSize) {
            //If mobileColumns are passed and the screen in mobileSize
            columns = this.props.mobileColumns;
        }
        let width = (100 / (columns.length + 1)) + "%";
        let sections = columns.map(column => {
            let val = column.renderer ? column.renderer(item) : "-";
            return <div
                style={{ width }}
                className="section"
                key={column.name}
                onMouseEnter={e => {
                    if (this.state.showInfo) this.setState({
                        hovering: {
                            item: item[this.props.uniqueField],
                            col: column.name,
                            val
                        }
                    })
                }}
            >
                {column.renderer ?
                    column.renderer(item)
                    :
                    "-"
                }
            </div>
        });

        if (
            this.state.showInfo &&
            this.state.hovering.item === item[this.props.uniqueField]
        ) sections.push(
            <div className="hoverPreview shadow-1" key="hover">
                {this.state.hovering.val}
            </div>
        );

        // Add on controls.

        sections.push(<div key="section-controls" className="section section-controls" style={{ width }}>
            {this.renderControls(item)}
        </div>)

        return sections;
    }

    renderStockFiles() {
        return (
            <div className="item-creator custom-border-color">
                <div
                    className="icon close-icon"
                    onClick={() => {
                        this.setState({ showingFiles: null })
                    }}
                >
                </div>
                <div className="creator">
                    <FileField
                        readOnly={true}
                    >
                        {this.state.showingFiles.files.map(f => ({
                            name: f, url: f, type: "image"
                        }))}
                    </FileField>
                </div>
            </div>
        )
    }

    renderLocation(coords) {
        return (
            <div className="item-creator custom-border-color">
                <div
                    className="icon close-icon"
                    onClick={() => {
                        this.setState({ showLocation: null })
                    }}
                >
                </div>
                <div className="creator">
                    <div className="iframe-wrapper">
                        <iframe
                            src={`https://www.google.com/maps/embed/v1/place?key=${window.GMAPS_KEY}&q=${coords.replace("|", ",")}`}
                            frameBorder="0"
                            allowFullScreen
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderItemPreviewer() {
        return (
            <div className="previewer">
                <StockCreator
                    hideInOut={true}
                    displayOnly={true}
                    user={this.props.user}
                    apiRoot={this.props.apiRoot}
                    router={this.props.router}
                    allCreatorFields={this.props.allCreatorFields}
                    uniqueField={this.props.uniqueField}
                    item={this.state.previewing}
                    closeCreator={() => {
                        this.setState({ previewing: null })
                    }}
                />
            </div>
        )
    }

    renderItemCreator() {
        return (
            <WorkOrderStockCreator
                user={this.props.user}
                filter={this.props.filter}
                customer={this.props.customer}
                uniqueField={this.props.uniqueField}
                item={this.state.editing}
                itemCreated={item => {
                    if (this.props.onNew) {
                        this.props.onNew(item.id)
                        this.setState({ addingItem: false })
                    }
                }}
                itemUpdated={(item, id) => {
                    if (this.props.onUpdate) {
                        this.props.onUpdate(item, id);
                        this.setState({ editing: null })
                    }
                }}
                closeCreator={() => {
                    if (this.state.editing) {
                        //if editing an item
                        this.setState({ editing: null })
                    } else {
                        //or creating new item
                        this.setState({ addingItem: false })
                    }
                }}
            />
        )
    }
}

WorkOrderStockList.defaultProps = {
    icon: "stock",
    uniqueField: "id",
    renderControls: true,
}

export default WorkOrderStockList;
