import FlipMove from "react-flip-move";
import SelectField from "../../fields/selectField";

class SuperList extends React.Component {

    /**
     * Takes props:
     * 
     * columns (Array) Maps out the structure of the data
     *      that is displayed, and the headers.
     * 
     * mobileColumns (Array) maps the data structure for devices below 992 (optional)
     * 
     * sorter (Function) optional sorting function for items.
     * 
     * items (Array) the actual array of data.
     * 
     * uniqueField (String) the name of the unique property on
     *       each item.
     * 
     * onNew (Function(item)) called when creating a new list item
     * 
     * onEdit (Function(item)) calledn when updating an existing list item.
     * 
     * icon: (String)
     * 
     * numberOfPages: (Number) optional prop which controls number of list items per page.
     * 
     *  displayOnly: (Boolean) if yes you wont be able to add or edit listItems  
     */

    constructor(props) {
        super(props);
        this.state = {
            sortBy: null,
            sortDirection: true, // true for ASC, false for DESC
            editing: null,
            addingItem: false,
            mobileSize: window.innerWidth < 992,
            splitting: null, //Only for stock
            showingFiles: null, 
            showLocation: null, //Only for workOrderStock
            showInfo: false,
            hovering: {
                item: null,
                col: null
            }
        }
        this.checkScreenSize = this._checkScreenSize.bind(this);
    }

    _checkScreenSize() {
        this.setState({ mobileSize: window.innerWidth < 992 })
    }

    componentDidMount() {
        window.addEventListener("resize", this.checkScreenSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkScreenSize);
    }

    sorterFactory() {
        let sortSelector = this.props.columns
            .filter(c => c.name === this.state.sortBy)[0].sorter;
        function sanitise(val) {
            if (typeof val === "string") {
                return val.toLowerCase();
            } else {
                return val;
            }
        }
        function compareVals(a, b) {
            // console.log(`Comparing: ${a} ~ ${b}`);
            if (a < b) {
                return -1;
            } else if (b < a) {
                return 1;
            } else {
                return 0;
            }
        }
        return (a, b) => {
            let result = compareVals(
                sanitise(sortSelector(a)),
                sanitise(sortSelector(b))
            );
            if (result === 0 && this.props.uniqueField) {
                return compareVals(
                    a[this.props.uniqueField],
                    b[this.props.uniqueField]
                );
            } else {
                return result;
            }
        }
    }

    renderHeaders() {
        let columns = this.props.columns;
        if (this.props.mobileColumns && this.state.mobileSize) {
            //If mboileColumns are passed and the screnn in mobileSize
            columns = this.props.mobileColumns;
        }
        let width = (100 / (columns.length + 1)) + "%";
        return columns.map(column => {
            return <div
                style={{ width }}
                className={column.sorter ? "section header-section sortable custom-c-light hover-class" : "section header-section"}
                key={column.name + "h"}
                onClick={() => {
                    if (!column.sorter) return null;
                    if (column.name === this.state.sortBy) {
                        this.setState({ sortDirection: !this.state.sortDirection })
                    } else {
                        this.setState({
                            sortBy: column.name,
                            sortDirection: true
                        });
                    }
                }}
            >
                <div className="name">{column.name}</div>
                {column.sorter
                    ?
                    <div className={this.state.sortDirection && column.name === this.state.sortBy ? "icon chevron-icon" : "icon chevron-icon rotate"}></div>
                    :
                    null
                }
                <div className={column.name === this.state.sortBy ? "bottom-border custom-bg-feature1" : "bottom-border custom-bg-light custom-border-color-2"}></div>
            </div>
        })
    }

    renderControls(item) {
        return (this.props.controls || [
            {
                icon: this.props.user.data.theme === "dark" ? "pen-white.svg " : "pen.svg",
                label: "edit",
                callback: this.props.onEdit
            },
            {
                icon: this.props.user.data.theme === "dark" ? "delete-bright.svg" : "delete.svg",
                label: "delete",
                callback: this.props.onDelete ? (item) => {
                    if (window.confirm("Are you sure you want to delete this item?")) {
                        this.props.onDelete(item[this.props.uniqueField])
                    }
                } : null
            }
        ])
            .filter(c => c.callback ? true : false)
            .map(control => {
                return <div
                    title={control.label}
                    key={control.label}
                    className="control"
                    style={{ backgroundImage: `url("/static/media/${control.icon}")` }}
                    onClick={e => { control.callback(item) }}
                ></div>
            })
    }

    renderRows(items) {
        //If sorting is enabled
        if (this.state.sortBy !== null) {
            items = items.sort(this.sorterFactory());
            if (!this.state.sortDirection)
                items = items.reverse();
        }
        return items
            .map(item => {
                let editing = (item === this.state.editing);
                let splitting = (item === this.state.splitting);
                let showingFiles = (item === this.state.showingFiles);
                let showingLocation = (item === this.state.showingLocation);

                return <div
                    className={"item custom-bg-light custom-border-color " + ((editing || splitting || showingFiles || showingLocation) ? " editing" : "") + (this.props.onSelect ? " selectable" : "")}
                    key={(this.props.uniqueField && item[this.props.uniqueField]) 
						? item[this.props.uniqueField] 
						: JSON.stringify(item)
					}
                    onClick={e => {
                        if (e.target.className && e.target.className.includes && e.target.className.includes("control")) return null;
                        if (this.props.onSelect)
                            this.props.onSelect(item);
                    }}
                >
                    {!editing && !splitting && !showingFiles && !showingLocation
                        ?
                        <div className={`icon ${this.props.icon}-icon item-icn`}></div>
                        :
                        null
                    }
                    <div className="border-color custom-bg-feature1"></div>
                    {this.renderRow(item)}
                </div>
            });
    }

    renderItemCreator(item) {
        return "Override this method with to return an itemCreator.";
    }

    renderRow(item) {
        if (this.state.editing === item)
            return this.renderItemCreator(item);

        // Render columns
        let columns = this.props.columns;
        if (this.props.mobileColumns && this.state.mobileSize) {
            //If mobileColumns are passed and the screen in mobileSize
            columns = this.props.mobileColumns;
        }
        let width = (100 / (columns.length + 1)) + "%";
        let sections = columns.map(column => {
            let val = column.renderer ? column.renderer(item) : "-";
            return <div
                style={{ width }}
                className="section"
                key={column.name + "r"}
                onMouseEnter={e => {
                    if (this.state.showInfo) this.setState({
                        hovering: {
                            item: item[this.props.uniqueField],
                            col: column.name,
                            val
                        }
                    })
                }}
            >
                {val}
            </div>
        })
        // Add hover preview if matched.
        if (
            this.state.showInfo &&
            this.state.hovering.item === item[this.props.uniqueField]
        ) sections.push(
            <div className="hoverPreview shadow-1" key="hover">
                {this.state.hovering.val}
            </div>
        )


        // Add on controls.
        if (!this.props.displayOnly) {
            sections.push(<div key="section-controls" className="section section-controls" style={{ width }}>
                {this.renderControls(item)}
            </div>)
        }
        return sections;
    }

    renderChangePageControls() {
        let optionsArray = [];
        for (var i = 1; i <= this.props.numberOfPages; i++) {
            optionsArray.push({ val: i, display: i })
        }

        return (
            <div className="change-page-controls">
                <div
                    className="icon chevron-icon prev"
                    onClick={() => {
                        let page = this.props.page - 1;
                        if (page >= 1) {
                            this.props.onPageChange(page)
                        }
                    }}
                >
                </div>
                <SelectField
                    varname="page"
                    options={optionsArray}
                    change={(varname, value, error) => {
                        this.props.onPageChange(value)
                    }}
                >
                    {this.props.page}
                </SelectField>
                <div
                    className="icon chevron-icon next"
                    onClick={() => {
                        let page = this.props.page + 1;
                        if (page <= this.props.numberOfPages) {
                            this.props.onPageChange(page)
                        }
                    }}
                >
                </div>
            </div>
        )
    }

    render() {
        let showInfoId = "superList-showInfo" + Math.random();
        return <div className="super-list" onMouseLeave={e => {
            this.setState({
                hovering: {}
            })
        }}>
            <div className="showInfo">
                <label htmlFor={showInfoId}>
                    {this.state.showInfo ? "Hide":"Show"} details
                </label>
                <input 
                    style={{display: "none"}}
                    type="checkbox" 
                    id={showInfoId}
                    checked={this.state.showInfo}
                    onChange={e => {
                        this.setState({ showInfo: !this.state.showInfo });
                    }}
                />
            </div>
            <div className="header">
                {this.renderHeaders()}
            </div>
            <FlipMove className="items">
                {this.renderRows(this.props.items || [])}
                {!this.props.displayOnly
                    ?
                    this.props.onNew ?
                        this.state.addingItem ?
                            (
                                <div key="creator">{this.renderItemCreator()}</div>
                            ) : (
                                <div
                                    key="addItem"
                                    className="addItem custom-bg-light custom-border-color"

                                >
                                    <div className="border-color"></div>
                                    <div
                                        className="wrapper custom-c-dark"
                                        onClick={e => {
                                            this.setState({
                                                addingItem: true,
                                                editing: null
                                            })
                                        }}
                                    >
                                        <div className="icon add-icon"></div>
                                        <div style={{ fontWeight: "bold" }}>Add Item</div>
                                    </div>
                                </div>
                            ) : null
                    :
                    null
                }
            </FlipMove>
            {this.props.numberOfPages > 1
                ?
                this.renderChangePageControls()
                :
                null
            }
        </div>
    }
}

export default SuperList;

