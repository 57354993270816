import Field from "./field";
import DataField from "./dataField";
import SelectField from "./selectField";

class LocationSearchField extends Field {
    constructor(props) {
        super(props);
        this.settings = {
            type: "locationSearch"
        }
        // Allowed types for searching.
        this.searchTypes = [
            {
                val: "partialLocator",
                display: "Locator"
            },
            {
                val: "description",
                display: "Description"
            },
            {
                val: "type",
                display: "Type"
            }
        ];
        // Default a search value.
        this.state.searchBy = this.props.defaultSearch || this.searchTypes[0].val;
        // Default results that appear in searching.
        this.searchDefaults = this.props.searchDefaults || [
            {
                locator: "incoming",
                type: "external",
                description: "Incoming Stock"
            },
            {
                locator: "delivered",
                type: "external",
                description: "Delivered Stock"
            },
            {
                locator: "collected",
                type: "external",
                description: "Collected Stock"
            },
            {
                locator: "unconfirmed",
                type: "internal",
                description: "Not in a location"
            }
        ];
        // No query defaults.
        this.queryDefaults = null;
    }

    renderField() {
        return (
            <div className="field-wrapper">
                <SelectField
                    varname="searchBy"
                    name="Search By"
                    options={this.searchTypes}
                    change={(varname, val, err) => {
                        this.setState({searchBy: val})
                    }}
                >
                    {this.state.searchBy}
                </SelectField>
                <DataField
                    dataName="locator"
                    searchBy={this.state.searchBy}
                    route="locations"
                    queryDefaults={this.queryDefaults}
                    defaults={this.searchDefaults}
                    change={(varname, val, err) => {
                        this.change(val);
                    }}
                    optionRenderer={location => (
                        <div className="option-inner">
                            <div>
                                {location.type} - {location.locator}
                            </div>
                            <div>
                                {location.description || "No description."}
                            </div>
                        </div>
                    )
                    }
                >
                    {this.props.children}
                </DataField>
            </div>
        )
    }

}

export default LocationSearchField;
