import Stage from "./stage";
// import FlipMove from "react-flip-move";

class SelectorStage extends Stage {
    constructor(props) {
        super(props);
        this.state.inputValue = "";
        this.state.error = "";
    }

    componentDidMount() {
        super.componentDidMount();
        this.refs.input.focus();
    }

    getItem() {
        console.log("Overwrite me !! - getItem");
        return new Promise((resolve, reject) => {
            resolve([]);
        });
    }

    _getCall(query) {
        this.getItem(query).then(result => {
            this.stageComplete(result);
        }).catch(err => {
            this.error(err);
        });
    }

    componentDidUpdate(pp, ps) {
        // Check how much the value has increased by.
        let changedCharCount = this.state.inputValue.length - ps.inputValue.length;
        // If more than 3, assume it's from a scanner.
        if (changedCharCount > 3) {
            // Do call straight away.
            this._getCall(this.state.inputValue);
        }
    }

    renderStage() {
        let { defaultValue, stockItem, quantity } = this.config()
        // let defaultValue = this.config().defaultValue;
        // let stockItem = this.config().stockItem;
        if (typeof defaultValue === "function") {
            defaultValue = defaultValue();
        }
        if (typeof stockItem === "function") {
            stockItem = stockItem().stock;
        }
        if (typeof quantity === "function") {
            quantity = quantity();
        }
        return (
            <div className="selectStage">
                <div className={`icon ${this.props.icon}-icon largeIcon`} />
                {/* Check type, render right icon class. */}
                {stockItem && <div className="stockItem">
                    {stockItem.code && <p>Code: <b>{stockItem.code}</b></p>}
                    {stockItem.productCode && <p>ProductCode: <b>{stockItem.productCode}</b></p>}
                    {stockItem.productBarcode && <p>ProductBarcode: <b>{stockItem.productBarcode}</b></p>}
                    <p>Quantity: <b>{quantity || stockItem.quantity}</b></p>
                </div>}
                <div className="selector">
                    <input
                        className="custom-border-3"
                        ref="input"
                        value={this.state.inputValue}
                        onChange={event => {
                            this.setState({ inputValue: event.target.value });
                        }}
                        // onKeyDown={event => {
                        //     if (event.key === "Enter") {
                        //         // If user pressed enter
                        //         if (this.state.inputValue)
                        //             this._getCall(this.state.inputValue);
                        //     }
                        // }}
                    />
                    <button
                        className="go-btn custom-bg-feature1"
                        onClick={() => {
                            if (this.state.inputValue)
                                this._getCall(this.state.inputValue);
                        }}
                    >
                        <div className="icon-white tick"></div>
                    </button>
                </div>
                {defaultValue
                    ?
                    <React.Fragment>
                        <div className="title">Or</div>
                        <button
                            className="prevLocationBtn custom-bg-feature1"
                            onClick={() => {
                                this._getCall(defaultValue);
                            }}
                        >
                            Use {defaultValue}
                        </button>
                    </React.Fragment>
                    :
                    null
                }
            </div>
        )
    }
}

export default SelectorStage;