import ItemCreator from "../../assets/ui-super-list/itemCreator";
import RestrictedTextField from "../../fields/restrictedTextField";
import TextField from "../../fields/textField";
import TextareaField from "../../fields/textareaField";
import SelectField from "../../fields/selectField";
import CheckboxField from "../../fields/checkboxField";
import csvCreator from "../../assets/csvCreator";
import Spinner from "../../assets/spinner";
import { createQueryString } from "../../helpers"
import moment from "moment";
import FileField from "../../fields/fileField";

class CustomerCreator extends ItemCreator {
    constructor(props) {
        super(props)
        this.state = {
            item: {},
            changes: {},
            collectionDate: moment().subtract(1, "month"),
            collectionLoading: false,
            collectionSubcustomer: true,
            workOrderDate: moment().subtract(1, "month"),
            workOrderLoading: false,
            workOrderSubcustomer: true
        };
        this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        this.collectionLoading = false;
        this.workOrderLoading = false;
    }

    canSubmit() {
        if (this.props.item) {
            //Updating existing customer
            let canSubmit = false
            Object.keys(this.state.item).forEach(key => {
                if (this.props.item[key] !== this.state.item[key]) {
                    //if change was made
                    canSubmit = true
                }
            })
            if (canSubmit) return true
        } else {
            //Creating a new customer
            if (this.state.item.name && this.state.item.displayName) {
                return true
            }
        }
        //Return false by default
        return false
    }

    getCollections(query) {
        // Prevent users form making multiple requests
        this.collectionLoading = true;
        this.setState({ collectionLoading: true });
        $.get(`${this.props.apiRoot}/collections/report${createQueryString(query)}`).then(res => {
            // Only create csv file if got any results
            if (res.results.length) {
                csvCreator.create(res.results).then(csv => {
                    csvCreator.download(csv);
                })
            } else {
                logger.error("No collections found for selected time period.")
            }
            // Unblock the function
            this.collectionLoading = false;
            this.setState({ collectionLoading: false });
        }).catch(err => {
            this.collectionLoading = false;
            this.setState({ collectionLoading: false });
            logger.error(err)
        })
    }

    getWorkOrders(query) {
        // Prevent users from making multiple requests
        this.workOrderLoading = true;
        this.setState({ workOrderLoading: true });
        $.get(`${this.props.apiRoot}/workorders/report${createQueryString(query)}`).then(res => {
            // Only create csv file if got any results
            if (res.results.length) {
                csvCreator.create(res.results).then(csv => {
                    csvCreator.download(csv)
                })
            } else {
                logger.error("No work orders found for selected time period.")
            }
            // Unblock the function
            this.workOrderLoading = false;
            this.setState({ workOrderLoading: false });
        }).catch(err => {
            this.workOrderLoading = false;
            this.setState({ workOrderLoading: false });
            logger.error(err);
        })
    }

    renderCreator() {
        // console.log("PROPS:", this.props);
        // console.log("STATE:", this.state);
        // console.log("ITEM:", this.state.item);
        let defaultNotes = (this.props.subCustomer && this.props.subCustomer.defaultNotes) ? this.props.subCustomer.defaultNotes : null;
        return (
            <div className="creator">
                <div className="field-combiner">
                    {!this.props.item
                        //If creating a new item 
                        ?
                        <RestrictedTextField
                            varname="name"
                            label="Name"
                            change={this.itemChanged.bind(this)}
                            regex={this.props.user.role <= 2 ? /^[a-z\d\_\-]*$/ : null}
                            prefix={(() => {
                                if (this.props.subCustomer && this.props.subCustomer.name) {
                                    return `${this.props.subCustomer.name}-`
                                } else if (this.props.user.role === 4) {
                                    return `${this.props.user.customer}-`
                                } else {
                                    return `${this.props.user.client}-`
                                }
                            })()}
                        >
                            {this.state.item.name || ""}
                        </RestrictedTextField>
                        :
                        //Editing an existing one
                        <div className="field placeholder">
                            <label className="custom-c-dark">Name</label>
                            <div className="field-value custom-c-feature2">{this.props.item.name}</div>
                        </div>
                    }
                    <TextField
                        varname="displayName"
                        label="Display Name"
                        change={this.itemChanged.bind(this)}
                    >
                        {this.state.item.displayName || ""}
                    </TextField>
                </div>
				<TextField
					varname="email"
					label="Customer Email"
					change={(vn, email, err) => {
						this.itemChanged("email", email || null);
					}}
				>
					{this.state.item.email || ""}
				</TextField>
                <div className="field-combiner">
                    <TextField
                        varname="color"
                        label="Feature Color"
                        change={this.itemChanged.bind(this)}
                    >
                        {this.state.item.color || ""}
                    </TextField>
                    <TextField
                        varname="url"
                        label="Customer Site"
                        change={this.itemChanged.bind(this)}
                    >
                        {this.state.item.url || ""}
                    </TextField>
                </div>
                <TextField
                    varname="logo"
                    label="Customer Logo"
                    change={this.itemChanged.bind(this)}
                >
                    {this.state.item.logo || ""}
                </TextField>
                <TextareaField
                    varname="defaultAddress"
                    label="Default Address"
                    change={this.itemChanged.bind(this)}
                >
                    {this.state.item.defaultAddress || ""}
                </TextareaField>
                <TextareaField
                    varname="defaultNotes"
                    label="Default Notes"
                    change={this.itemChanged.bind(this)}
                >
                    {this.state.item.defaultNotes || defaultNotes || ""}
                </TextareaField>
                <TextField
                    varname="shopifyKey"
                    label="Shopify Key"
                    change={this.itemChanged.bind(this)}
                >
                    {this.state.item.shopifyKey || ""}
                </TextField>
                <TextField
                    varname="shopifyUrl"
                    label="Shopify Url"
                    change={this.itemChanged.bind(this)}
                >
                    {this.state.item.shopifyUrl || ""}
                </TextField>
                <TextField
                    varname="shopifyLocationId"
                    label="Shopify Location ID"
                    change={this.itemChanged.bind(this)}
                >
                    {this.state.item.shopifyLocationId || ""}
                </TextField>
                <FileField 
                    label="Default Work Order Files"
                    multiple={true}
                    change={(varname, value, error) => {
                        let images = value.map(v => v.url);
                        let item = Object.assign({}, this.state.item, { defaultWorkOrderFiles: images });
                        let changes = Object.assign({}, this.state.changes, { defaultWorkOrderFiles: images });
                        this.setState({ item, changes });
                    }}
                >
                    {(this.state.item.defaultWorkOrderFiles || []).map(file => {
                        let type = file.split(".");
                        type = type[type.length - 1];
                        let name = file.split("/");
                        name = name[name.length - 1];
                        return { 
                            name, 
                            url: file, 
                            type: ["svg", "jpg", "jpeg", "png"].includes(type) ? "image" : "file" 
                        }
                    })}
                </FileField>
            </div>
        )
    }
    render() {
        return (
            <div className="item-creator custom-border-color">
                {this.props.closeCreator ? <div
                    className="icon close-icon"
                    onClick={() => {
                        this.props.closeCreator()
                    }}
                /> : null}
                {this.renderCreator()}
                <div
                    className={"button create-item-button custom-bg-feature2" + (this.canSubmit() ? "" : " disabled")}
                    onClick={() => {
                        if (this.canSubmit()) {
                            //Sending the whole item or just the changes(creating/editing)
                            let item = this.props.item ? this.state.changes : this.state.item;
                            this.submit(item)
                        }
                    }}
                >
                    Save
                </div>
                {this.props.item
                    ?
                    <div
                        className="button add-sub-customer custom-bg-feature2"
                        onClick={() => {
                            this.props.createSubCustomer()
                        }}
                    >
                        Add sub-customer
                    </div>
                    :
                    null
                }
                {this.props.item &&
                    <div className="collections-report">
                        <hr />
                        <div
                            className="button custom-bg-feature2"
                            onClick={() => {
                                if (!this.collectionLoading) {
                                    let from = this.state.collectionDate.startOf('month').valueOf();
                                    let to = this.state.collectionDate.endOf('month').valueOf();
                                    let subcustomer = this.state.collectionSubcustomer;
                                    this.getCollections({ from, to, subcustomer, customer: this.props.item.name });
                                }
                            }}
                        >
                            {this.state.collectionLoading ? <Spinner size={24} /> : "Collections report"}
                        </div>
                        <div className="date-selector">
                            <SelectField
                                varname="month"
                                name="Month"
                                options={this.months.map((month, index) => ({ val: index, display: month }))}
                                change={(vn, val, err) => {
                                    let collectionDate = this.state.collectionDate.month(val);
                                    this.setState({ collectionDate });
                                }}
                            >
                                {this.state.collectionDate.month()}
                            </SelectField>
                            <SelectField
                                varname="year"
                                name="Year"
                                options={(() => {
                                    let years = [];
                                    let current = this.state.collectionDate.year();
                                    for (let i = current - 10; i <= current + 10; i++) {
                                        years.push(i)
                                    }
                                    return years.map(y => ({ val: y, display: y }))
                                })()}
                                change={(vn, val, err) => {
                                    let collectionDate = this.state.collectionDate.year(val);
                                    this.setState({ collectionDate });
                                }}
                            >
                                {this.state.collectionDate.year()}
                            </SelectField>
                        </div>
                        <CheckboxField
                            label="Include sub customers"
                            change={(vn, val, err) => this.setState({ collectionSubcustomer: val })}
                        >
                            {this.state.collectionSubcustomer}
                        </CheckboxField>
                    </div>
                }
                {this.props.item &&
                    <div className="workOrders-report">
                        <hr />
                        <div
                            className="button custom-bg-feature2"
                            onClick={() => {
                                if (!this.workOrderLoading) {
                                    let from = this.state.workOrderDate.startOf('month').valueOf();
                                    let to = this.state.workOrderDate.endOf('month').valueOf();
                                    let subcustomer = this.state.workOrderSubcustomer;
                                    this.getWorkOrders({ from, to, subcustomer, customer: this.props.item.name });
                                }
                            }}
                        >
                            {this.state.workOrderLoading ? <Spinner size={24} /> : "Work orders report"}
                        </div>
                        <div className="date-selector">
                            <SelectField
                                varname="month"
                                name="Month"
                                options={this.months.map((month, index) => ({ val: index, display: month }))}
                                change={(vn, val, err) => {
                                    let workOrderDate = this.state.workOrderDate.month(val);
                                    this.setState({ workOrderDate });
                                }}
                            >
                                {this.state.workOrderDate.month()}
                            </SelectField>
                            <SelectField
                                varname="year"
                                name="Year"
                                options={(() => {
                                    let years = [];
                                    let current = this.state.workOrderDate.year();
                                    for (let i = current - 10; i <= current + 10; i++) {
                                        years.push(i)
                                    }
                                    return years.map(y => ({ val: y, display: y }))
                                })()}
                                change={(vn, val, err) => {
                                    let workOrderDate = this.state.workOrderDate.year(val);
                                    this.setState({ workOrderDate });
                                }}
                            >
                                {this.state.workOrderDate.year()}
                            </SelectField>
                        </div>
                        <CheckboxField
                            label="Include sub customers"
                            change={(vn, val, err) => this.setState({ workOrderSubcustomer: val })}
                        >
                            {this.state.workOrderSubcustomer}
                        </CheckboxField>
                    </div>
                }
            </div>
        )
    }
}

export default CustomerCreator;