import React, { useState, useContext } from "react";
import axios from "axios";
import Context from "../../context";
import Spinner from "../../assets/spinner";
import { errorResponse } from "../../../../sls/utils";
import { renderDate } from "../../helpers";

export default function RemovalCreator({ dateIncoming }) {

	const [removal, setRemoval] = useState(null);
	const [emailRequired, setEmailRequired] = useState(true);

	return (
		<div className="from-file-creator">
			{removal
				?
				<Creator
					goBack={() => setRemoval(null)}
					dateIncoming={dateIncoming}
					removal={removal}
					emailRequired={emailRequired}
				/>
				:
				<FileUploader
					onSuccess={data => {
						setRemoval(data.removal);
						setEmailRequired(data.emailRequired);
					}}
				/>
			}
		</div>
	)

}

function FileUploader({ onSuccess }) {

	const [file, setFile] = useState(null);
	const [submitting, setSubmitting] = useState(false);

	const { token, apiRoot } = useContext(Context);

	function readFile(file) {
		// Promise for reading the file
		let promise = new Promise((resolve, reject) => {
			if (file.size >= 40000000) {
				logger.error("Files are limited to 40MB.");
				return resolve(null);
			}
			// Construct some serialized file data.
			let ext;
			let fileData = {
				name: file.name,
				url: null,
				type: file.type,
				extension: (ext = /(\.[^\.]+)$/.exec(file.name)) ? ext[1] : ""
			};
			// Construct a file reader to get a buffer for tye file.
			let reader = new FileReader();
			// Callback for when the file is read.
			reader.onload = readEvent => {
				fileData.buffer = readEvent.target.result;
				resolve(fileData);
			};
			// Start reading the file.
			reader.readAsArrayBuffer(file);
		});
		// Call promise to upload the file.
		promise.then(file => uploadFile(file));
	};

	function uploadFile(file) {
		$.post({
			url: `${apiRoot}/misc/file`,
			data: JSON.stringify({ extension: file.extension })
		}).done(response => {
			let url = response.url;
			if (!file.buffer) {
				logger.error("Cannot upload an empty file.");
			}
			let xhr = new XMLHttpRequest();
			xhr.onreadystatechange = function () {
				if (this.readyState === 4) {
					if (this.status === 200) {
						// console.log("FILENAME: ", response.fileName);
						setFile({ ...file, fileName: response.fileName });
					} else {
						// Handle failed upload.
						logger.error("Couldn't upload file " + file.name);
						setFile(null);
					}
				}
			};
			xhr.open("PUT", url);
			xhr.send(file.buffer);
		}).catch(err => {
			console.error("Error getting S3 signed url: ", err);
			logger.error("Error uploading pdf. Please try again.");
		})
	}

	return (
		<>
			<div>
				<div className="field">
					<label className="custom-c-dark">Create from PDF</label>
				</div>
				<label className="button custom-bg-feature1 file-btn">
					{Boolean(file) ? "Change file" : "Select file"}
					<input
						key={file ? "file" : "noFile"}
						type="file"
						accept=".pdf"
						onChange={e => {
							e.preventDefault();
							readFile(e.target.files[0]);
						}}
					/>
				</label>
				{Boolean(file) && <div className="selected-file">
					<div className="file-name">{file.name}</div>
					<div
						className="red-cross"
						onClick={() => {
							setFile(null);
						}}
					/>
				</div>}
			</div>
			<br />
			<div
				className={"button custom-bg-feature1" + ((!file || submitting) ? " disabled" : "")}
				style={{ width: "100%", textAlign: "center" }}
				onClick={async () => {
					// Prevent from sending bad requests.
					if (!file || !file.fileName || submitting) return;
					// Block request spamming
					if (!submitting) setSubmitting(true);
					// We need to read the content of the file, and return it into base 64 for sending.
					// Send file
					axios.post(`${apiRoot}/removals/from-file`, { fileName: file.fileName }, {
						headers: {
							Authorization: token
						}
					}).then(res => res.data).then(data => {
						// Saving data in state
						onSuccess(data);
					}).catch(err => {
						console.error("Error parsing file: ", err);
						logger.error("Error parsing file. Please try again");
						setSubmitting(false);
					})
				}}
			>
				{submitting ? <Spinner size={24} /> : "Submit file"}
			</div>
		</>
	)
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function Creator({ removal = null, emailRequired = true, goBack = () => null, dateIncoming = Date.now() }) {

	const { token, apiRoot } = useContext(Context);
	const [email, setEmail] = useState("");
	const [creating, setCreating] = useState(false);

	const isDisabled = creating || (emailRequired && !EMAIL_REGEX.test(email))

	return (
		<>
			<div className="removals-row">
				<div className="removal-item">
					<div>Customer: <b>{`${removal.customerName}_${removal.customerId}`}</b></div>
					<div>Date: <b>{renderDate(removal.date || dateIncoming)}</b></div>
					<div>Address: <b>{removal.address}</b></div>
				</div>
				{emailRequired && <div className="field custom-c-dark noselect" style={{
					marginBottom: "20px"
				}}>
					<label>Customers email</label>
					<div className="inner">
						<input
							className="custom-border-3"
							placeholder="email" 
							type="text" 
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
					</div>
				</div>}
			</div>
			<div className="button-row">
				<button
					className={"button custom-bg-feature1 create-btn" + (isDisabled ? " disabled" : "")}
					style={{ marginRight: "10px" }}
					disabled={creating}
					onClick={async () => {
						// Prevent from making a bad request
						if (isDisabled) return;
						// Block request spamming.
						setCreating(true);
						// Create request body
						const body = { removal };
						if (email && emailRequired) body.email = email;
						// Send removal
						try {
							await axios.post(`${apiRoot}/removals/from-file/create`, body, {
								headers: { Authorization: token }
							});
							// Success
							logger.success("Removal collection created.");
							goBack();
						} catch (error) {
							// Unblock
							setCreating(false);
							// Show errors
							console.error(error);
							logger.error(errorResponse(error));
						}
					}}
				>
					{creating ? <Spinner size={24} /> : "Create removal"}
				</button>
				<button
					className="button custom-bg-feature1"
					onClick={goBack}
				>
					Select different file
				</button>
			</div>
		</>
	)
}

