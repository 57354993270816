import SuperList from "../../assets/ui-super-list";
import ReportCreator from "./reportCreator";

class ReportsList extends SuperList {

    renderItemCreator() {
        return (
            <ReportCreator
                item={this.state.editing}
                user={this.props.user} 
                itemCreated={(item) => {
                    this.props.onNew(item, () => {
                        this.setState({ addingItem: false })
                    })
                }}
                closeCreator={() => {
                    this.setState({ addingItem: false })
                }}
            />
        )
    }
}

export default ReportsList