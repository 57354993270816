import FileField from "../../fields/fileField";
import TextareaField from "../../fields/textareaField";
import SelectField from "../../fields/selectField";

/**
 *  STATE
 *      
 *      text: "STRING"
 *      files: ARRAY
 *      status: "STRING"
 * 
 *  PROPS
 *
 *      reportId: "STRING"
 *      
 */

class MessageCreator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            files: [],
            status: null
        }
    }

    change(name, value, error) {
        this.setState({
            [name]: value
        }, () => {
            console.log("STATE:", this.state);
        })
    }

    canSubmit() {
        if (this.state.text || this.state.status || this.state.files.length) {
            return true
        } else {
            return false
        }
    }

    submit() {
        // callback when save is pressed.
        this.props.onSubmit({
            content: {
                text: this.state.text || null,
                files: this.state.files.length ? this.state.files : [],
                status: this.state.status || null
            }
        })
    }

    render() {
        return (
            <div className="message-creator">
                <TextareaField
                    varname="text"
                    placeholder="Add text"
                    change={this.change.bind(this)}
                    validator={value => {
                        if (value.length <= 250) {
                            return null;
                        } else {
                            return "Message to long, up to 250 characters."
                        }
                    }}
                >
                    {this.state.text}
                </TextareaField>
                <SelectField
                    varname="status"
                    name="Change Report Status"
                    options={[
                        {
                            val: "urgent",
                            display: "Urgent"
                        },
                        {
                            val: "resolved",
                            display: "Resolved"
                        },
                        {
                            val: "unresolved",
                            display: "Unresolved"
                        }
                    ]}
                    change={this.change.bind(this)}
                >
                    {this.state.status}
                </SelectField>
                <FileField
                    readOnly={false}
                    label="Files"
                    varname="files"
                    apiRoot={this.props.apiRoot}
                    change={this.change.bind(this)}
                >
                    {this.state.files}
                </FileField>
                <div
                    className="button submit custom-bg-feature1"
                    onClick={() => {
                        if (this.canSubmit()) {
                            this.submit()
                        }
                    }}
                >
                    Submit Message
                </div>
            </div>
        )
    }
}

export default MessageCreator;