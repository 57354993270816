import Calendar from "./calendar";

/**
 *  PROPS
 * 
 *      currentDate: MS integer
 *      classes: STRING (css class)
 *      daysArray: ARRAY of day name strings
 *      monthsArray: ARRAY of month name STRINGS
 *      eventHandler: FUNCTION(event, val)
 *      viewingDate: MS integer
 *      selectedDay: NULL or OBJECT {}
 *      showWeekend: BOOLEAN
 *      events: ARRAY OF EVENTS
 *      
 */

class MiniCalendar extends Calendar {

    renderDay(day) {
        let classes = ["custom-border-color"];
        if (this.checkIfToday(day.date)) {
            classes.push("today")
        }
        if (this.checkIfSelected(day.date)) {
            classes.push("current")
        }
        return (
            <div
                className={day.currentMonth ? "day" : "day faded"} key={day.date}
            >
                <UiMiniCalendarDayPanel
                    classes={classes.join(" ")}
                    title={(() => {
                        const date = new Date(day.date);
                        const dayNumber = date.getDate();
                        return dayNumber
                    })()}
                    warning={this.checkIfWarning(day.events)}
                    onSelect={() => {
                        this.props.eventHandler("select-day", day);
                    }}
                >
                    <div className="content-list">
                        {day.events.length}
                    </div>
                </UiMiniCalendarDayPanel>
            </div>
        )
    }
}

const UiMiniCalendarDayPanel = (props) => (
    <div className={"panel status-panel item-selectable-1 custom-bg-dark custom-c-light " + (props.classes ? props.classes : "")}>
        <div 
            className={props.warning ? "heading custom-bg-feature3" : "heading custom-bg-feature1"}
            onClick={e => {
                props.onSelect();
            }}
        >
            {props.title}
        </div>
        {props.children}
    </div>
)

export default MiniCalendar;