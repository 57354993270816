import Process from "./index";
import ScanInput from "../../../fields/scanInput";
// Stages.
import ListStage from "../stages/listStage";
import SelectLocation from "../stages/selectLocation";
import Action from "../stages/action";
import Success from "../stages/success";
//Other components
import CheckboxField from "../../../fields/checkboxField";

class PickWorkOrder extends Process {
    constructor(props) {
        super(props);
        this.state.stage = 0;
        this.state.showUnscannedOnly = false
        this.stages = [
            {
                // 0 - Load workOrders.
                component: Action,
                config: {
                    action: ({ finish, error, exit }) => {
                        this.getItems((err, items) => {
                            if (!err) {
                                finish(items);
                            } else {
                                console.error("Couldn't get work orders: ", err);
                                error("Error getting work orders");
                                setTimeout(() => {
                                    exit();
                                }, 3000);
                            }
                        });
                    }
                }
            },
            {
                // 1 - Showing list of work orders
                component: ListStage,
                config: {
                    icon: "document",
                    title: "Select Work Order",
                    items: () => this.stageData(0),
                    renderItem: item => {
                        return (
                            <div className="content">
                                <p className="customer">{item.customer}</p>
                                <p className="status">status: {item.status}</p>
                                <div className="date-time">
                                    <p className="date">{this.msToDate(item.dateOutgoing)}</p>
                                    <p className="time">{`At ${item.timeOutgoing}`}</p>
                                </div>
                                <p className="stock">{`stock quantity: ${item.stock.length}`}</p>
                                {item.vehicle
                                    ?
                                    <p className="vehicle">{`Vehicle: ${[item.vehicle].concat(item.vehicles || []).join(", ")}`}</p>
                                    :
                                    null
                                }
                                {item.notes
                                    ?
                                    <div className="notes-wrapper">
                                        <hr />
                                        <p className="notes">{item.notes}</p>
                                    </div>
                                    :
                                    null
                                }
                                <hr />
                                <p className="id">{item.id}</p>
                            </div>
                        )
                    }
                }
            },
            {
                // 2 - Getting full stock items object for selected work order
                component: Action,
                config: {
                    action: ({ finish, error, exit }) => {
                        this.getFullStock(this.stageData(1).stock, (err, items) => {
                            if (!err) {
                                finish(items);
                            } else {
                                console.error("Couldn't get full stock data:", err);
                                error("Error getting work orders stock");
                                setTimeout(() => {
                                    exit();
                                }, 3000);
                            }
                        })
                    }
                }
            },
            {
                // 3 - Showing list of stock for selected work order
                component: ListStage,
                config: {
                    icon: "stock",
                    title: "Scan Stock Item",
                    sort: items => {
                        return items.sort((a,b) => {
                            let aLocation = a.location;
                            let bLocation = b.location;
                            if (aLocation < bLocation) return -1;
                            if (aLocation > bLocation) return 1;
                            return 0;
                        })
                    },
                    state: {
                        inputValue: ""
                    },
                    before: stage => {
                        // Getting total numbers for stock and picked stock
                        let total = this.stageData(1).stock.length;
                        let scanned = this.stageData(1).stock.filter(s => s.status === "picked").length;
                        // Render scanned stock toggle, total numbers of stock scanned and stock selector
                        return (
                            <div>
                                <div className="totalItems">
                                    {`[${scanned}/${total}]`}
                                </div>
                                <div className="toggle">
                                    <CheckboxField
                                        label="Show unscanned only"
                                        change={(vn, val, err) => {
                                            this.setState({ showUnscannedOnly: val })
                                        }}
                                    >
                                        {this.state.showUnscannedOnly}
                                    </CheckboxField>
                                </div>
                                <ScanInput
                                    focusOnMount={true}
                                    onScan={code => {
                                        let match = this.stageData(2).filter(s => s.code === code)[0];
                                        if (match) {
                                            if (match.status && match.status === "picked") {
                                                stage.error("Item already picked.")
                                            } else {
                                                stage.stageComplete(match)
                                            }
                                        } else {
                                            stage.error("No match found for that bar code.")
                                        }
                                    }}
                                />
                            </div>
                        )
                    },
                    items: () => {
                        if (this.state.showUnscannedOnly) {
                            return this.stageData(2).filter(s => (!s.status || s.status !== "picked"))
                        }
                        return this.stageData(2)
                    },
                    disable: item => true,
                    renderItem: item => {
                        return (
                            <div className="content">
                                <p className="code"><span>Product code:</span>{item.productCode}</p>
                                <p className="code"><span>Barcode:</span><br />{item.code}</p>
                                <p className="group"><span>Group:</span>{item.group}</p>
                                <p className="location"><span>Loc:</span>{item.location}</p>
                                <p className="destination"><span>Dest:</span>{item.destination}</p>
                                <hr />
                                <p className="description"><span>desc:</span>{item.description}</p>
                                {item.status && item.status === "picked"
                                    ?
                                    <div className="green-tick"></div>
                                    :
                                    null
                                }
                            </div>
                        )
                    }
                }
            },
            {
                // 4 - Select location
                component: SelectLocation,
                config: {
                    defaultValue: () => (window.cookies.lastPickLocation || null)
                }
            },
            {
                // 5 - Action, make request and check if all done.
                component: Action,
                config: {
                    action: ({ finish, error, changeStage }) => {
                        // Make call to update work order stock
                        $.ajax({
                            type: "PUT",
                            url: `${this.context.apiRoot}/workorders/${this.stageData(1).id}/pick/${this.stageData(3).id}`,
                            data: JSON.stringify({ location: this.stageData(4).locator })
                        }).then(response => {
                            // Check if work order was completed.
                            let finished = !Boolean(
                                this.stageData(2)
                                    // Get list excluding current item.
                                    .filter(s => s.code !== this.stageData(3).code)
                                    // Get items not picked
                                    .filter(s => s.status !== "picked")
                                    // If there are items not picked, we aren't finished.
                                    .length
                            );
                            // Immediately leave, because we're done.
                            if (finished) return changeStage(7);

                            // Now, update the the selected delivery object
                            this.setStageData(1, response.workOrder, () => {
                                // Setting the value of the cookie for last used location
                                window.setCookie("lastPickLocation", this.stageData(4).locator);

                                // Now check for completion of current group.
                                let groupFinished = !Boolean(this.stageData(2).filter(s =>
                                    s.code !== this.stageData(3).code &&
                                    s.group === this.stageData(3).group &&
                                    s.status !== "picked"
                                ).length);

                                if (groupFinished) {
                                    // If group done, go to the group completion message. 
                                    changeStage(6);
                                } else {
                                    // Otherwise, clear data and go back to the stock list. 
                                    this.clearStageData([3], () => {
                                        changeStage(2);
                                    });
                                }
                            });
                        }).catch(err => {
                            console.error("Couldn't pick the stock item", err);
                            error("Error picking work order item.");
                            setTimeout(() => {
                                changeStage(1);
                            }, 3000)
                        })
                    }
                }
            },
            {
                // 6 - Informing a user that a group was completed
                component: Success,
                config: {
                    action: () => {
                        this.clearStageData([3], () => {
                            this.changeStage(2);
                        });
                    },
                    message: () => <div>
                        <span>Group <b>{this.stageData(3).group}</b> completed.</span>
                        <br/>
                        <span>Click to scan remaining items.</span>
                    </div>
                }
            },
            {
                // 7 - Work order picked message
                component: Success,
                config: {
                    message: () => <div>Success! Work order completely picked.</div>
                }
            }
        ]
    }

    msToDate(ms) {
        const date = new Date(ms);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    getFullStock(stock, cb) {
        // Takes an array of stock from previously selected work order
        let ids = stock.map(s => s.id)
        $.post(`${this.context.apiRoot}/stock/multi`, JSON.stringify(ids)).done(response => {
            // Combining work order stock with corresponding full stock object
            let items = stock.map(s => {
                let match = response.results.filter(res => res.id === s.id)[0]
                if (!match) return s;
                return Object.assign({}, match, s);
            })
            if (cb) cb(null, items);
        }).catch(err => {
            if (cb) cb(err)
        })
    }

    getItems(cb) {
        //Creating to and from dates
        let date = new Date().getTime();
        let from = date - (86400000 * 5);
        let to = date + (86400000 * 5);
        //Getting workOrders
        $.get(`${this.context.apiRoot}/workorders?from=${from}&to=${to}`).done(response => {
            // Only saving confirmed workorders
            let items = response.results.filter(result => ["confirmed"].includes(result.status));
            if (cb) cb(null, items);
        }).catch(err => {
            if (cb) cb(err)
        })
    }
}

export default PickWorkOrder;