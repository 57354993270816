import View from "../view";
// import Process from "./process";
import { renderDate } from "../../helpers";
// Processes
import MoveStock  from "./processes/moveStockWithMulti";
import ReturnStock from "./processes/returnStock";
import SplitStock from "./processes/splitStock";
import UnloadDelivery from "./processes/UnloadDelivery";
import PickWorkOrder from "./processes/pickWorkOrder";
import LoadWorkOrder from "./processes/loadWorkOrder";
import UnloadWorkOrder from "./processes/unloadWorkOrder";
// import AddSignature from "./processes/addSignature";
import AddLocation from "./processes/addLocation";
import DamageReport from "./processes/damageReport";
import PickCollection from "./processes/pickCollection";
import LoadCollection from "./processes/loadCollection";
import CourierCollection from "./processes/courierCollection";
import MergeStock from "./processes/mergeStock";

class ProcessesView extends View {
    constructor(props) {
        super(props);
        this.state = {
            process: null
        }
        this.processes = [
            {
                title: "Return Stock",
                icon: "stock",
                roles: [1, 2, 3],
                component: ReturnStock
            },
            {
                title: "Move Stock",
                icon: "stock",
                roles: [1, 2, 3],
                component: MoveStock
            },
            {
                title: "Split Stock",
                icon: "split-stock",
                roles: [1, 2, 3],
                component: SplitStock
            },
            {
                title: "Merge Stock",
                icon: "stock",
                roles: [1, 2, 3],
                component: MergeStock
            },
            {
                title: "Unload Delivery",
                icon: "delivery",
                roles: [1, 2, 3, 4],
                component: UnloadDelivery
            },
            {
                title: "Pick Collection",
                icon: "collection",
                roles: [1, 2, 3, 4],
                component: PickCollection
            },
            {
                title: "Load Collection",
                icon: "collection",
                roles: [1,2,3,4],
                component: LoadCollection
            },
            {
                title: "Prepare Courier Collection",
                icon: "collection",
                roles: [1,2,3,4],
                component: CourierCollection
            },
            {
                title: "Pick Work Order",
                icon: "document",
                roles: [1,2,3,4],
                component: PickWorkOrder
            },
            {
                title: "Load Work Order",
                icon: "document",
                roles: [1,2,3,4],
                component: LoadWorkOrder
            },
            {
                title: "Unload Work Order",
                icon: "document",
                roles: [1,2,3,4],
                component: UnloadWorkOrder
            },
            // {
            //     title: "Add Signature",
            //     icon: "document",
            //     roles: [1,2,3,4],
            //     component: AddSignature
            // },
            {
                title: "Add Location",
                icon: "location",
                roles: [1,2,3,4],
                component: AddLocation
            },
            {
                title: "Damage Report",
                icon: "report",
                roles: [1,2,3,4],
                component: DamageReport
            }
        ]
        this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);
    }

    renderView() {
        return (
            <div className="row view processesView">
                {this.state.process
                    ?
                    // If there is a process, render out the component passed
                    (Process => (
                        <Process
                            title={this.state.process.title}
                            apiRoot={this.props.apiRoot}
                            exit={e => {
                                this.setState({ process: null });
                            }}
                        />
                    ))(this.state.process.component)
                    :
                    // Otherwise, a welcome page with a way to select the process.
                    <div className="col-12 panel-wrapper" key="options">

                        {/* Heading */}
                        <div className="heading-row">
                            <h1 className="client-view custom-c-dark">{`${this.client} Processes`}</h1>
                            <h5 className="date custom-c-mid">{renderDate()}</h5>
                        </div>

                        {/* Render out the list of processes to choose from */}
                        <div className="processes">
                            {this.processes
                                .filter(p => p.roles.includes(this.props.user.role))
                                .map(process => (
                                    <div
                                        key={process.title}
                                        className="option custom-bg-light list-hover custom-c-dark"
                                        onClick={() => {
                                            this.setState({ process });
                                        }}
                                    >
                                        <div className="border-color custom-bg-feature1" />
                                        <div className={`icon ${process.icon}-icon`} />
                                        <div className="option-title">{process.title}</div>
                                    </div>
                                ))}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ProcessesView;