import View from "../view";
import DeliveryCreator from "./deliveryCreator";
import GroupDeliveryCreator from "./groupDeliveryCreator";
import UiPanel from "../../assets/ui-panel";
import { Aux, rid, renderDate } from "../../helpers";

class DeliveryView extends View {
    constructor(props) {
        super(props);

        this.client = this.props.user.client.charAt(0).toUpperCase() +
            this.props.user.client.slice(1);

        this.state = {
            delivery: null,
            viewMode: "single"
        }
    }

    componentDidMount() {
        let deliveryId = this.props.router.match.params.deliveryId;

        if (deliveryId && deliveryId !== "create") {
            //Get a single delivery matching the deliveryId
            this.getDeliveries({ id: deliveryId });
        }
    }

    componentDidUpdate(prevProps) {
        let prevDeliveryId = prevProps.router.match.params.deliveryId;
        let deliveryId = this.props.router.match.params.deliveryId;

        if (prevDeliveryId !== deliveryId) {
            if (deliveryId && deliveryId !== "create") {
                //Get a single delivery matching the deliveryId
                this.getDeliveries({ id: deliveryId })
            }
        }
    }

    createQueryString(obj) {
        return "?" + Object.keys(obj).map(name => {
            if (!obj[name]) return "";
            return `${name}=${obj[name]}`;
        }).join("&");
    }

    getDeliveries(query, cb) {
        $.get(`${this.props.apiRoot}/deliveries/${this.createQueryString(query)}`).done(delivery => {
            if (cb) {
                cb(delivery);
            } else {
                delivery.stock = delivery.stock.map(s => {
                    s.id = s.id || rid()
                    return s
                })
                this.setState({ delivery });
            }
        }).catch(err => {
            logger.error(err)
        });
    }

    createDelivery(delivery) {
        // console.log("DELIVERY:", delivery);
        $.post(`${this.props.apiRoot}/deliveries`, JSON.stringify(delivery)).done((response) => {
            //Redirecting to Deliveries View
            logger.success(response.msg)
            this.setState({ delivery: response.delivery });
            // this.props.router.history.push("/schedule");
        }).catch((err) => {
            logger.error(err)
        })
    }

    updateDelivery(changes, id) {
        $.ajax({
            type: "PUT",
            url: `${this.props.apiRoot}/deliveries/${id}`,
            data: JSON.stringify(changes)
        }).done((response) => {
            logger.success(response.msg)
            this.setState({ delivery: response.delivery });
        }).catch((err) => {
            logger.error(err)
        })
    }

    singleDeliveryCreator() {
        return (
            <UiPanel
                name={this.state.delivery ? `Editing Delivery - ${this.state.delivery.id}` : "Delivery Creator"}
                icon="delivery"
            >
                <DeliveryCreator
                    apiRoot={this.props.apiRoot}
                    delivery={this.state.delivery}
                    user={this.props.user}
                    onSubmit={(delivery) => {
                        //Removing id from thse stock befor sending it
                        if (delivery.stock && delivery.stock.length) {
                            delivery.stock = delivery.stock.map(s => {
                                delete s.id;
                                return s
                            })
                        }
                        this.createDelivery(delivery)
                    }}
                    onUpdate={(delivery, id) => {
                        //Removing id from thse stock befor sending it
                        if (delivery.stock && delivery.stock.length) {
                            delivery.stock = delivery.stock.map(s => {
                                delete s.id;
                                return s
                            })
                        }
                        this.updateDelivery(delivery, id)
                    }}
                />
            </UiPanel>
        )
    }

    groupDeliveryCreator() {
        return (
            <UiPanel
                name="Group Delivery Creator"
                icon="delivery"
            >
                <GroupDeliveryCreator 
                    user={this.props.user}
                    apiRoot={this.props.apiRoot}
                    onSubmit={delivery => {
                        this.createDelivery(delivery);
                    }}   
                />
            </UiPanel>
        )
    }

    renderView() {
        let deliveryId = this.props.router.match.params.deliveryId;
        return (
            <Aux>
                <div className="row heading-row">
                    <div className="col-12">
                        <h1 className="client-view custom-c-dark">{`${this.client} Deliveries`}</h1>
                        <h5 className="date custom-c-mid">{renderDate()}</h5>
                    </div>
                </div>
                {deliveryId === "create"
                    ?
                    <div className="row modeControls">
                        <span>Select mode:</span>
                        <br />
                        {[
                            ["Create single delivery", "single"],
                            ["Create group with CSV", "group"]
                        ].filter(i => i).map(btnPair => {
                            return (
                                <button
                                    key={btnPair[1]}
                                    className={"btn" + (this.state.viewMode === btnPair[1] ? " custom-bg-feature1" : "")}
                                    onClick={e => {
                                        this.setState({ viewMode: btnPair[1] })
                                    }}
                                >
                                    {btnPair[0]}
                                </button>
                            )
                        })}
                    </div>
                    :
                    null
                }
                <div className="row view deliveriesView">
                    <div className="col-12 panel-wrapper">
                        {/* Single delivery creator */}
                        {this.state.viewMode === "single" ? this.singleDeliveryCreator() : null}
                        {/* Group delivery creator */}
                        {this.state.viewMode === "group" ? this.groupDeliveryCreator() : null}
                    </div>
                </div>
            </Aux>
        )
    }
}

export default DeliveryView;