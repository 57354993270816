// import FlipMove from "react-flip-move";
import moment from "moment";
import { rid } from "../../helpers";
import StockCSVField from "../../fields/csvField/stockCsvField";
import TextField from "../../fields/textField";
import CustomerField from "../../fields/customerField";
import Context from "../../context";
import leichtDeliveryGroupKitchensTemplate from "../../fields/csvField/templates/leichtDeliveryGroupKitchensTemplate";
import deliveryGroupStockTemplate from "../../fields/csvField/templates/deliveryGroupStockTemplate";
import deliveryGroupStockWithDescTemplate from "../../fields/csvField/templates/deliveryGroupStockWithDescTemplate";
import lieferscheinGroupTemplate from "../../fields/csvField/templates/lieferscheinGroupTemplate";
import hackerDeliveryGroupTemplate from "../../fields/csvField/templates/hackerDeliveryGroupTemplate";
import BarcodeSet from "../../assets/barcodeSet";

class GroupDeliveryCreator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: {
                withCus: [],
                withoutCus: []
            },
            deliveries: [],
            deliveryGroup: ""
        }
        this.date = null;
    }

    componentDidMount() {
        // Getting a ms date value from window.location
        if (!window.location.hash) {
            this.date = Date.now();
        } else {
            let d = new Date(window.location.hash.split("#")[1]);
            if (d.toString() === "Invalid Date") {
                this.date = Date.now();
            } else {
                this.date = d.getTime();
            }
        }
    }

    createDelivery(delivery) {
        // console.log("DELIVERY:", delivery);
        $.post(`${this.props.apiRoot}/deliveries`, JSON.stringify(delivery)).done((response) => {
            // logging success message
            logger.success(response.msg)
            // Updating state with newly created delivery
            this.setState({
                deliveries: [...this.state.deliveries].map(d => {
                    if (d.customer === response.delivery.customer) return response.delivery
                    return d
                })
            })
        }).catch((err) => {
            logger.error(err)
        })
    }

    // Splits items into two arrays, with and without customer.
    sortData(stock) {
        // Creating empty arrays for stock with and without customer
        let items = {
            withCus: [],
            withoutCus: []
        }
        // Sorting stock items
        stock.forEach(i => {
            if (i.customer) {
                items.withCus.push(i);
            } else {
                items.withoutCus.push(i);
            }
        })
        // Getting deliveries for each unique customer
        let deliveries = this.createDeliveriesArray(items.withCus);
        // Storing results in state
        this.setState({ items, deliveries });
    }

    // Creates a list of deliveries based on 
    createDeliveriesArray(results) {
        // Creating empty array which will store a delivery for each unique customer
        let deliveries = [];
        // Array which will store unique customers
        let uniqueCustomers = [];
        results.forEach(stock => {
            let position = uniqueCustomers.indexOf(stock.customer);
            // Check if delivery for this customer was already created
            if (position < 0) {
                // Not created, construct a new delivery object
                let delivery = {
                    deliveryGroup: `${this.state.deliveryGroup}-${this.date}`,
                    customer: stock.customer,
                    dateIncoming: this.date,
                    notes: null,
                    stock: [stock],
                    timeIncoming: "all day"
                }
                // push new delivery and unique customer
                deliveries.push(delivery);
                uniqueCustomers.push(stock.customer)
            } else {
                // Already exists, just add stock
                deliveries[position].stock.push(stock)
            }
        })
        // Returning deliveries
        return deliveries;
    }

    allStock(deliveries) {
        let results = [];
        deliveries.forEach(delivery => {
            // console.log("stock: ", stock);
            let stock = delivery.stock.map(s => ({...s, customer: delivery.customer}));
            results.push(...stock);
        });
        return results;
    }

    renderResults(items, deliveries) {
        // Rendering items withoutCus
        if (items.withoutCus.length) {
            return (
                <div
                    className="csv-results"
                >
                    {[...new Set(
                        items.withoutCus
                            .map((item, index) => {
                                // Trying to get commission text from description, otherwise get the item code
                                let commissionText = /customer_:\s*([^\n]+)/gi.exec(item.description);
                                // Returning values with types for more accurate checks in the SelectCustomerCard Component
                                return (commissionText && commissionText[1])
                                    // Stringify results to return only unique values 
                                    // ? JSON.stringify({ val: commissionText[1], type: "commission" })
                                    // : JSON.stringify({ val: item.code, type: "code" });
                                    ? `${commissionText[1]}<||>commission`
                                    : `${item.code}<||>code`
                            })
                    )].map(string => {
                        // Parsing JSOn string before passing it down.
                        let split = string.split("<||>");
                        return (
                            <SelectCustomerCard
                                key={split[0]}
                                text={split[0]}
                                type={split[1]}
                                onConfirm={(cus, text, type) => {
                                    // Creating copies of both items arrays
                                    let withCus = [...items.withCus];
                                    let withoutCus = [];
                                    // Looking for matching items
                                    items.withoutCus.forEach(itm => {
                                        // Looking for a match, type is used to avoid matching code with description.
                                        if ((type === "commission" && itm.description.includes(text)) || (type === "code" && itm.code === text)) {
                                            // Setting a customer value and moving it to the array of items with customers
                                            withCus.push(Object.assign({}, itm, { customer: cus }))
                                        } else {
                                            // item does not match so no customer set
                                            withoutCus.push(itm)
                                        }
                                    });
                                    // Creating updated deliveries array
                                    let deliveries = this.createDeliveriesArray(withCus);
                                    // Storing updated values in state
                                    this.setState({ items: { withCus, withoutCus }, deliveries });
                                }}
                            />
                        )
                    })}
                    <hr />
                    <TextField
                        label="Delivery Group"
                        change={(vn, val, er) => {
                            this.setState({ deliveryGroup: val })
                        }}
                    >
                        {this.state.deliveryGroup}
                    </TextField>
                    <br />
                    {this.state.deliveryGroup
                        ?
                        <StockCSVField
                            templates={[leichtDeliveryGroupKitchensTemplate, deliveryGroupStockTemplate]}
                            // key={Date.now()}
                            customer={this.context.user.customer}
                            preProcess={s => {
                                // console.log("Preprocessing: ", s)
                                let stock = Object.assign({
                                    code: s.code || rid(),
                                    quantity: "1",
                                    customer: s.customer
                                }, s)
                                let rows = stock.code.split("\n");
                                // If only one value, return.
                                if (rows.length < 2) return stock;
                                // console.log(rows);
                                // Otherwise, change vals.
                                stock.code = rows.map(r => {
                                    return (r.split(":")[1] || "")
                                        .trim();
                                }).join("_");
                                return stock;
                            }}
                            onConfirm={(data, cb) => {
                                // console.log(data);
                                // Resetting state data before submitting new csv file
                                this.setState({
                                    items: {
                                        withCus: [],
                                        withoutCus: []
                                    },
                                    deliveries: []
                                });
                                this.sortData(data)
                                if (cb) cb(true);
                            }}
                        />
                        :
                        null
                    }
                </div>
            )
        }
        // All items have customer, render list of deliveries
        return (
            <div className="csv-results">
                {deliveries.map(delivery => {
                    return (
                        <div key={delivery.customer} className="delivery">
                            <div>{`Delivery Group: ${delivery.deliveryGroup}`}</div>
                            <div>{`Customer: ${delivery.customer}`}</div>
                            <div>{`${delivery.stock.length} stock items`}</div>
                            <div>{`Date incoming: ${moment(delivery.dateIncoming).format("DD MM YYYY")}`}</div>
                            {delivery.id
                                ?
                                <>
                                    <a
                                        href={`/deliveries/${delivery.id}`}
                                        target="_blank"
                                        style={{
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            marginTop: "8px",
                                            textDecoration: "none"
                                        }}
                                        className="viewDel button custom-bg-feature1"
                                    >
                                        View
                                    </a>
                                    <div
                                        className="barcodeSet"
                                        style={{
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            marginTop: "8px",
                                            marginLeft: "10px"
                                        }}
                                    >
                                        <BarcodeSet
                                            items={delivery.stock
                                                .filter(s => s.code)
                                                .map(s => {
                                                    return {
                                                        code: s.code,
                                                        moreInfo: <div>
                                                            <div>
                                                                CUST: {delivery.customer} | PC: {s.productCode || "NO CODE"}
                                                            </div>
                                                            <hr />
                                                            <div>{s.description || "NO DESC"}</div>
                                                        </div>
                                                    }
                                                })
                                            }
                                        />
                                    </div>
                                </>
                                :
                                null
                            }
                        </div>
                    )
                })}
                <br />
                {!this.state.deliveries.filter(d => d.id).length
                    ?
                    <div
                        className="btn btn custom-bg-feature1"
                        style={{
                            display: "block"
                        }}
                        onClick={() => {
                            deliveries.forEach(delivery => {
                                this.createDelivery(Object.assign({}, delivery, {
                                    stock: [...delivery.stock].map(d => {
                                        return {
                                            code: d.code,
                                            description: d.description,
                                            group: d.group,
                                            quantity: d.quantity,
                                            productCode: d.productCode,
                                            supplier: d.supplier || null
                                        }
                                    })
                                }));
                            })
                        }}
                    >
                        Confirm &amp; Create
                    </div>
                    :
                    <div className="barcodeSet">
                        {deliveries.filter(d => !d.id).length
                            ?
                            <div className="custom-c-light">Loading...</div>
                            :
                            <BarcodeSet
                                label="Print all barcodes."
                                items={this.allStock(deliveries)
                                    .filter(s => s.code)
                                    .map(s => {
                                        return {
                                            code: s.code,
                                            moreInfo: <div>
                                                <div>
                                                    CUST: {s.customer} | PC: {s.productCode || "NO CODE"}
                                                </div>
                                                <hr />
                                                <div>{s.description || "NO DESC"}</div>
                                            </div>
                                        }
                                    })
                                }
                            />

                        }
                    </div>
                }
            </div>
        )
    }

    render() {
        // console.log("state:", this.state);
        return (
            <div className="delivery-creator">
                {this.state.items.withoutCus.length || this.state.items.withCus.length
                    ?
                    this.renderResults(this.state.items, this.state.deliveries)
                    :
                    <React.Fragment>
                        <TextField
                            label="Delivery Group"
                            change={(vn, val, er) => {
                                this.setState({ deliveryGroup: val })
                            }}
                        >
                            {this.state.deliveryGroup}
                        </TextField>
                        <br />
                        {this.state.deliveryGroup
                            ?
                            <StockCSVField
                                templates={[
									leichtDeliveryGroupKitchensTemplate, 
									deliveryGroupStockWithDescTemplate, 
									lieferscheinGroupTemplate,
									hackerDeliveryGroupTemplate
								]}
                                // key={Date.now()}
                                customer={this.context.user.customer}
                                preProcess={s => {
                                    let stock = Object.assign({
                                        code: s.code || rid(),
                                        quantity: "1",
                                        customer: s.customer
                                    }, s)
                                    let rows = stock.code.split("\n");
                                    // If only one value, return.
                                    if (rows.length < 2) return stock;
                                    // console.log(rows);
                                    // Otherwise, change vals.
                                    stock.code = rows.map(r => {
                                        return (r.split(":")[1] || "")
                                            .trim();
                                    }).join("_");
                                    return stock;
                                }}
                                onConfirm={(data, cb) => {
                                    // console.log(data);
                                    if (!this.state.deliveryGroup) {
                                        // Makings sure user have created a delivery group
                                        logger.error("You must create a delivery group.")
                                    } else {
                                        this.sortData(data);
                                    }
                                    if (cb) cb(true);
                                }}
                            />
                            :
                            null
                        }
                    </React.Fragment>
                }
            </div>
        )
    }
}

class SelectCustomerCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            customer: ""
        }
    }

    render() {
        return (
            <div
                key={this.props.text}
                className="noCus"
            >
                <span>{`No customer for ${this.props.text}`}</span>
                <CustomerField
                    label={false}
                    change={(vn, val, err) => {
                        this.setState({ customer: val.name })
                    }}
                >
                    {this.state.customer}
                </CustomerField>
                <div
                    className="btn custom-bg-feature1"
                    onClick={() => {
                        this.props.onConfirm(this.state.customer, this.props.text, this.props.type)
                    }}
                >
                    Confirm
                </div>
            </div>
        )
    }
}

GroupDeliveryCreator.contextType = Context;

export default GroupDeliveryCreator;