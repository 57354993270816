import moment from "moment";
import DateField from "../../fields/dateField";
import DataField from "../../fields/dataField";
import TimeField from "../../fields/timeField";
import FileField from "../../fields/fileField";
import RestrictedTextField from "../../fields/restrictedTextField";
import SelectField from "../../fields/selectField"
import CheckboxField from "../../fields/checkboxField";
import VehicleSizeClassField from "../../fields/vehicleSizeClassField";
import VehicleSearchField from "../../fields/vehicleSearchField";
import TextareaField from "../../fields/textareaField";
import FlipMove from "react-flip-move";
import WorkOrderStockList from "./workOrderStockList";
import { renderDate } from "../../helpers";
import Pod from "./pod";
import SplitPod from "./splitPod";
import VehicleListField from "../../fields/vehicleListField";
import { Prompt } from "react-router-dom";
import Spinner from "../../assets/spinner";

/**
 * PROPS:
 * 
 *      onSubmit (Callback Function)
 *          When user attempts to save workOrder.
 * 
 *      onUpdate (Callback Function)
 *          When user attempts to update an existing workOrder.
 * 
 *      workOrder (Object)
 *          An existing workOrder object for editing.
 * 
 *      user (Object) currently looged in user
 * 
 *      apiRoot: STRING
 * 
 *      customers: (Array)
 */

class WorkOrderCreator extends React.Component {
    constructor(props) {
        super(props);
        this.cancelingRepeatedBooking = false;
        this.state = {
            workOrder: this.props.workOrder || {
                dateOutgoing: (dString => {
                    if (!dString) return Date.now();
                    let d = new Date(dString.split("#")[1]);
                    if (d.toString() === "Invalid Date") {
                        return Date.now();
                    } else {
                        return d.getTime();
                    }
                })(window.location.hash),
                dateDelivered: null,
                customer: null,
                status: "pending",
                notes: null,
                vehicle: null,
                vehicles: [],
                timeOutgoing: "all day",
                stock: [],
                sizeClass: null,
                images: [],
                files: [],
                initiator: null
            },
            creator: null,
            destinations: [],
            destinationToAdd: "",
            stock: [],
            changes: {},
            location: "",
            showRiskAssessment: false,
            showSignature: false,
            showPod: false,
            showSplitPod: false,
            showImages: null,
            customerData: null,
            weeksToDuplicate: 1,
            locationCreatorKey: Math.random(),
			creatingRepeatedBooking: false
        }
    }


    displayOnly() {
        return this.props.user.role === 3 || (this.props.user.role === 4 && this.props.workOrder && this.props.workOrder.status !== "pending")
    }

    componentDidMount() {
        //if editing an existing workOrder which has stock items
        if (this.state.workOrder.id && this.state.workOrder.stock.length) {
            this.getStock();
        }
        window.onbeforeunload = () => {
            if (Object.keys(this.state.changes).length) {
                return "You have unsaved changes. Are you sure?";
            } else {
                return null;
            }
        }
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    componentDidUpdate(prevProps, prevState) {
        let hasChanged = this.hasWorkOrderChanged(prevProps.workOrder, this.props.workOrder);
        if (hasChanged) {
            this.setState({
                workOrder: this.props.workOrder || {
                    dateOutgoing: null,
                    dateDelivered: null,
                    customer: null,
                    status: "pending",
                    notes: null,
                    timeOutgoing: "all day",
                    stock: []
                }
            })
        }
        // Getting W/O creator
        if (this.state.workOrder.id && this.state.workOrder.initiator && !this.state.creator) {
            this.getCreator(this.state.workOrder.initiator)
        }
        if (this.state.workOrder.id && this.state.workOrder.stock.length && !this.state.stock.length) {
            this.getStock()
        }
        if (this.state.workOrder.id && !this.state.destinations.length) {
            //Getting stock destination objects
            let destinations = [];
            this.state.workOrder.stock.forEach(s => {
                if (destinations.indexOf(s.destination) === -1) {
                    destinations.push(s.destination)
                }
            })
            // If destinations is not an empty array
            if (destinations.length) {
                this.setState({
                    destinations: destinations.map(d => {
                        return { locator: d }
                    })
                });
                // this.getDestinations(destinations)
            }
        }
        // If we're creating a work order, and the customer changes, go get it.
        if (
            !this.props.workOrder &&
            (this.state.workOrder.customer !== prevState.workOrder.customer) &&
            this.state.workOrder.customer
        )
            this.getCustomer(this.state.workOrder.customer);
        // Handle initial getting of work order customer.
        if (prevProps.workOrder === null && this.props.workOrder)
            this.getCustomer(this.props.workOrder.customer);

    }

    getDestinations(locators) {
        $.post(`${this.props.apiRoot}/locations/multi`, JSON.stringify(locators)).done(response => {
            this.setState({ destinations: response.results });
        }).catch(err => {
            logger.error(err);
        });
    }

    getStock() {
        //Creating an array of stock id's
        let stock = this.state.workOrder.stock.map(s => {
            return s.id
        })
        $.post(`${this.props.apiRoot}/stock/multi`, JSON.stringify(stock)).done(response => {
            this.setState({ stock: response.results })
        }).catch(err => {
            logger.error(err)
        })
    }

    getCreator(id) {
        $.get(`${this.props.apiRoot}/users?id=${id}`).then(response => {
            this.setState({
                creator: response.results[0]
            })
        }).catch(err => {
            logger.error(err);
        })
    }

    getCustomer(name) {
        $.get(`${this.props.apiRoot}/customers/${name}`).then(response => {
            this.setState({
                customerData: response.customer
            });
        }).catch(err => {
            logger.error(err);
        });
    }

    getDefaultNotes(customer) {
        return new Promise((resolve, reject) => {
            $.get(`${this.props.apiRoot}/customers/${customer}/defaults`).then(res => {
                resolve(res.defaults);
            }).catch(err => {
                logger.error(err);
                reject(err);
            });
        });
    }

    hasWorkOrderChanged(oldWorkOrder, newWorkOrder) {
        if (!oldWorkOrder && newWorkOrder) return true;
        if (!newWorkOrder && oldWorkOrder) return true;
        if (!newWorkOrder && !oldWorkOrder) return false;

        let hasChanged = false;
        Object.keys(newWorkOrder).forEach(key => {
            if (newWorkOrder[key] !== oldWorkOrder[key]) hasChanged = true;
        })

        return hasChanged;
    }

    renderSelectCustomerField() {
        return this.state.editingCustomer || !this.state.workOrder.id
            ?
            <React.Fragment>
                <DataField
                    varname="customer"
                    defaults={[
                        {
                            name: "internal",
                            displayName: "Internal User"
                        }
                    ]}
                    optionRenderer={customer => (
                        <div className="option-inner">
                            <b>{customer.displayName}</b> <br />({customer.name})
                        </div>
                    )}
                    label="Customer"
                    route="customers"
                    dataName="name"
                    searchBy="term"
                    change={async (varname, value, error) => {
                        let customer = value ? value.name : null;
                        if (!customer) return;
                        // Getting the default values for the selected customer
                        let defaults = await this.getDefaultNotes(customer);
                        // Getting the default notes
                        let notes = this.state.workOrder.notes || value.defaultNotes || defaults.defaultNotes;
                        // Creating an updated W/O object
                        let workOrder = Object.assign({}, this.state.workOrder, { customer, notes });
                        let changes = Object.assign({}, this.state.changes, { customer, notes });
                        this.setState({
                            workOrder,
                            changes,
                            editingCustomer: false
                        });
                    }}
                >
                    {this.state.workOrder.customer || ""}
                </DataField>
                <br />
            </React.Fragment>
            :
            <div className="show-customer">
                <label className="custom-c-dark">Customer</label>
                <div className="name custom-c-feature2">
                    {this.state.workOrder.customer || "Internal"}
                    {this.props.user.role < 3 ? <button
                        style={{
                            marginLeft: "20px"
                        }}
                        className="btn"
                        onClick={e => {
                            if (window.confirm("Are you sure you want to edit the customer on this work order? This can cause unexpected issues if you do not know what you're doing.")) this.setState({
                                editingCustomer: true
                            });
                        }}
                    >Edit</button> : null}
                </div>
            </div>
    }

    renderStatusButtons() {
        // Don't render the field if user role === 4 and workOrder is not delivered
        if (this.props.user.role === 4 && this.state.workOrder.status !== "delivered") return null;
        if (!this.state.workOrder.id) {
            //If creating a new workOrder.
            return null
        } else if (this.displayOnly() || this.state.workOrder.status === "delivered") {
            //If its only for display (not editing)
            return (
                <div className="display-only custom-c-dark">
                    <label>Work order Status</label>
                    <p>{this.state.workOrder.status}</p>
                </div>
            )
        } else {
            //Editing existing one.
            let status = this.state.workOrder.status;
            let optionsArray = [
                {
                    val: "cancelled",
                    display: "Cancelled"
                },
                {
                    val: "pending",
                    display: "Pending"
                },
                {
                    val: "confirmed",
                    display: "Confirmed"
                },
                {
                    val: "picked",
                    display: "Picked"
                },
                {
                    val: "loaded",
                    display: "Loaded"
                },
                {
                    val: "delivered",
                    display: "Delivered"
                }
            ];
            return (
                <SelectField
                    label="Work Order Status"
                    varname="status"
                    options={optionsArray}
                    change={(varname, value, error) => {
                        if (value !== status) {
                            if ((value === "picked" || value === "loaded" || value === "delivered") && this.props.user.role > 1) {
                                logger.error(`You cannot manually change a work order to ${value}.`)
                            } else {
                                if (window.confirm(`Changing a work order status manually can be dangerous. If the work order is already picked/loaded, those items will be unloaded. Are you sure you want to change the work order's status to ${value}?`)) {
                                    let workOrder = Object.assign({}, this.state.workOrder);
                                    workOrder.status = value;
                                    let changes = Object.assign({}, this.state.changes);
                                    changes.status = value;
                                    this.setState({ changes, workOrder })
                                }
                            }
                        }
                    }}
                >
                    {status}
                </SelectField>
            )
        }
    }

    riskAssessmentTitles(name) {
        let text;
        switch (name) {
            case "vehParked":
                text = "Is the vehicle parked safely?";
                break;
            case "vehObstruction":
                text = "Is the vehicle causing obstruction to others?";
                break;
            case "routeObstruction":
                text = "Is the route clear and free from obstruction?";
                break;
            case "tripHazards":
                text = "Are there any trip hazards?";
                break;
            case "slipHazards":
                text = "Are there any slip hazards?";
                break;
            case "workOnDevRoute":
                text = "Is anyone else working on the delivery route?";
                break;
            case "signage":
                text = "Have you placed signage to warn others of your activity?"
                break
        }
        return text;
    }

    renderRiskAssessment() {
        return Object.keys(this.props.workOrder.riskAssessment).map(key => {
            if (key !== "signedBy") {
                //signedBy is rendered separately
                return (
                    <div className="set" key={key}>
                        <p className="question custom-c-feature2">{this.riskAssessmentTitles(key)}</p>
                        {this.props.workOrder.riskAssessment[key].val
                            ?
                            <img src="/static/media/tick-green.svg" />
                            :
                            <img src="/static/media/close-red.svg" />
                        }
                        {this.props.workOrder.riskAssessment[key].notes
                            ?
                            <p className="notes">{this.props.workOrder.riskAssessment[key].notes}</p>
                            :
                            null
                        }
                    </div>
                )
            }
        })
    }

    groupSizeClass(sizeString) {
        //If nothing passed
        if (!sizeString) return null;
        let sizeArr = sizeString.split(",");
        //If only one
        if (sizeArr.length <= 1) return (
            <p>{sizeString}</p>
        );
        //Else
        let sizeObj = {}
        sizeArr.forEach(item => {
            if (sizeObj[item]) {
                sizeObj[item]++
            } else {
                sizeObj[item] = 1
            }
        })
        return Object.keys(sizeObj).map(size => {
            return (
                <div className="size" key={size}>
                    <span>{`${size}: `}</span>
                    <span>{`x${sizeObj[size]}`}</span>
                </div>
            )
        })
    }

    renderAddress() {
        let destinations = this.state.destinations || [];
        let groups = {}
        destinations.forEach(destination => {
            if (!destination.address) return;
            groups[destination.address] = groups[destination.address] || [];
            groups[destination.address].push(destination.locator);
        });

        return <div className="all-addresses custom-c-dark">
            {this.state.customerData && this.state.customerData.defaultAddress ?
                <label style={{ paddingLeft: 0 }}>Customer Base Address</label> : null}
            {this.state.customerData && this.state.customerData.defaultAddress ?
                <div className="address-group custom-c-light" style={{ paddingLeft: 0 }}>
                    <div className="address">
                        {this.state.customerData.defaultAddress}
                    </div>
                </div >
                : null
            }
            {Object.keys(groups).length ? <label>Destination Addresses</label> : null}
            {Object.keys(groups).length ? Object.keys(groups).map(address => {
                return <React.Fragment key={address}>
                    <div className="address-group custom-c-light">

                        <div className="locators">
                            {groups[address].join(", ")}
                        </div>
                        <div className="address">
                            {address}
                        </div>
                    </div>
                </React.Fragment>
            }) : null}
        </div>
    }

    podStock() {
        return this.props.workOrder.stock
            // Map over the stock in the work order.
            .map(woStock => {
                // Find the matching full stock obj in the state.
                let matchingStock = this.state.stock
                    .filter(s => s.id === woStock.id)[0];
                // Find the matching destination.
                let matchingDest = this.state.destinations
                    .filter(d => woStock.destination === d.locator)[0];
                // If it found them..
                if (matchingStock && matchingDest) {
                    // Merge it and return.
                    return Object.assign({}, matchingStock, woStock, {
                        destination: matchingDest
                    });
                } else {
                    // Otherwise, null for filtering out.
                    return null
                }
            })
            .filter(s => s)
    }

    dateCreated(id) {
        if (!id || typeof id !== "string") {
            return null
        }
        let date = id.split("-");
        date = date[date.length - 1];
        return Number(date) ? moment(Number(date)).format("llll") : null;
    }

    render() {
        // console.log("STATE:", this.state);
        // console.log("PROPS:", this.props);
		// console.log("CHANGES:", this.state.changes);
        let allStockIds = this.state.stock.map(s => s.id);
        let displayOnly = this.displayOnly();
        // If got workOrder prop, generate created on date
        let createdOn = this.dateCreated(this.props.workOrder ? this.props.workOrder.id : null);
        return (
            <div className="work-order-creator">
                <img className="header-image" src="/static/media/POD_header_small.png" style={{ display: "none" }} />
                <Prompt
                    when={Object.keys(this.state.changes).length > 0}
                    message='You have unsaved changes, are you sure?'
                />
                {this.renderSelectCustomerField()}
                {(createdOn && !displayOnly)
                    ?
                    <div className="display-only custom-c-dark">
                        Created on <b>{createdOn}</b> by
                        <b>
                            {this.state.creator
                                ? " " + (this.state.creator.name || null) + ", " + (this.state.creator.email || null)
                                : null
                            }
                        </b>
                    </div>
                    :
                    null
                }
                {this.renderStatusButtons()}
				{(!displayOnly && this.state.changes.status && this.state.changes.status === "cancelled") && 
					<div className="field cancel-reason">
						<label className="custom-c-dark">Reason for cancelling</label>
						<div>
							{["Cancelled", "Changed to other sub customer"].map(reason => {
								return <CheckboxField
									key={reason}
									label={reason}
									change={(vn, val, err) => {
										this.setState({
											changes: {
												...this.state.changes,
												cancelReason: this.state.changes.cancelReason === reason ? null : reason
											}
										});
									}}
								>
									{this.state.changes.cancelReason === reason}
								</CheckboxField>
							})}
						</div>
					</div>
				}
				{(!displayOnly && this.props.workOrder && this.props.workOrder.status === "cancelled" && this.props.workOrder.cancelReason && this.props.workOrder.cancelTime) && <div className="display-only custom-c-dark" style={{ margin: "15px 0px 0px 0px" }}>
					Cancelled on <b>{moment(Number(this.props.workOrder.cancelTime)).format("llll")}</b> - <b>{this.props.workOrder.cancelReason}</b> 
				</div>}
                {this.props.workOrder && this.props.workOrder.riskAssessment
                    ?
                    <div
                        style={{ marginTop: "15px" }}
                        className="show-risk-assessment button custom-bg-feature1"
                        onClick={() => {
                            this.setState({
                                showRiskAssessment: !this.state.showRiskAssessment
                            })
                        }}
                    >
                        {this.state.showRiskAssessment ? "Hide risk assessment" : "Show risk assessment"}
                    </div>
                    :
                    null
                }
                {this.props.workOrder && this.props.workOrder.signature
                    ?
                    <div
                        className="show-risk-assessment button custom-bg-feature1"
                        onClick={() => {
                            this.setState({
                                showSignature: !this.state.showSignature
                            })
                        }}
                    >
                        {this.state.showSignature ? "Hide signature" : "Show signature"}
                    </div>
                    :
                    null
                }
                {this.state.showRiskAssessment
                    ?
                    <div className="risk-assessment custom-bg-light custom-border-3">
                        {this.renderRiskAssessment()}
                        <div className="author">
                            <p className="custom-c-dark">Confirmed by:
                                <br />
                                <b>
                                    {this.props.workOrder.riskAssessment.signedBy.name}
                                    <br />
                                    ({this.props.workOrder.riskAssessment.signedBy.email})
                                </b>
                            </p>
                        </div>
                    </div>
                    :
                    null
                }
                {this.state.showSignature
                    ?
                    <React.Fragment>
                        {this.props.workOrder.signedBy
                            ?
                            <div className="display-only custom-c-dark">
                                <br />
                                <label>Signed by</label>
                                <p>{this.props.workOrder.signedBy}</p>
                            </div>
                            :
                            null
                        }
                        <div className="signature custom-bg-light custom-border-3">
                            <img src={this.props.workOrder.signature} />
                        </div>
                    </React.Fragment>
                    :
                    null
                }
                <div className="date-time-notes">
                    {!displayOnly
                        ?
                        <DateField
                            varname="dateOutgoing"
                            label="Date outgoing"
                            change={(varname, value, error) => {
                                let workOrder = Object.assign({}, this.state.workOrder, {
                                    dateOutgoing: value
                                });
                                this.setState({ workOrder });
                                //Handle changes for editing a workOrder.
                                if (this.state.workOrder.id) {
                                    let changes = Object.assign({}, this.state.changes, {
                                        dateOutgoing: value
                                    });
                                    this.setState({ changes })
                                }
                            }}
                        >
                            {this.state.workOrder.dateOutgoing}
                        </DateField>
                        :
                        <div className="display-only split first custom-c-dark">
                            <label>Date outgoing</label>
                            <p>{renderDate(this.state.workOrder.dateOutgoing)}</p>
                        </div>
                    }
                    {!displayOnly
                        ?
                        <TimeField
                            varname="time"
                            label="Time"
                            from={900}
                            to={1800}
                            change={(varname, value, error) => {
                                let workOrder = Object.assign({}, this.state.workOrder, {
                                    timeOutgoing: value
                                });
                                this.setState({ workOrder })

                                //Handle changes for editing a workOrder.
                                if (this.state.workOrder.id) {
                                    let changes = Object.assign({}, this.state.changes, {
                                        timeOutgoing: value
                                    });
                                    this.setState({ changes });
                                }
                            }}
                        >
                            {this.state.workOrder.timeOutgoing}
                        </TimeField>
                        :
                        <div className="display-only split custom-c-dark">
                            <label>Time</label>
                            <p>{this.state.workOrder.timeOutgoing}</p>
                        </div>
                    }
                    {!displayOnly
                        ?
                        <TextareaField
                            varname="notes"
                            label="Work order notes"
                            change={(varname, value, error) => {
                                let workOrder = Object.assign({}, this.state.workOrder);
                                workOrder.notes = value;
                                this.setState({ workOrder });

                                //Handle changes for editing a delivery.
                                if (this.state.workOrder.id) {
                                    let changes = Object.assign({}, this.state.changes);
                                    changes.notes = value;
                                    this.setState({ changes })
                                }
                            }}
                        >
                            {this.state.workOrder.notes || ""}
                        </TextareaField>
                        :
                        <div className="display-only custom-c-dark">
                            <label>Work order notes</label>
                            <p>{this.state.workOrder.notes || "none"}</p>
                        </div>
                    }
                    {this.props.user.role <= 2
                        ?
                        <div className="sizeClass">
                            <VehicleSizeClassField
                                style={{
                                    marginBottom: "15px"
                                }}
                                label="Size class"
                                varname="sizeClass"
                                change={(varname, value, error) => {
                                    let workOrder = Object.assign({}, this.state.workOrder);
                                    workOrder.sizeClass = value;
                                    let changes = Object.assign({}, this.state.changes);
                                    changes.sizeClass = value;
                                    this.setState({ changes, workOrder })
                                }}
                            >
                                {this.state.workOrder.sizeClass}
                            </VehicleSizeClassField>
                        </div>
                        :
                        <div className="display-only custom-c-dark">
                            <label>Size class</label>
                            {this.groupSizeClass(this.state.workOrder.sizeClass)}
                        </div>
                    }
                    {this.props.user.role <= 2
                        ?
                        <div className="staffNumber">
                            <label className="custom-c-dark">Required staff</label>
                            <SelectField
                                varname="requiredStaff"
                                options={[
                                    {
                                        val: 1,
                                        display: 1
                                    },
                                    {
                                        val: 2,
                                        display: 2
                                    },
                                    {
                                        val: 3,
                                        display: 3
                                    },
                                    {
                                        val: 4,
                                        display: 4
                                    },
                                    {
                                        val: 5,
                                        display: 5
                                    }
                                ]}
                                change={(varname, value, error) => {
                                    let workOrder = Object.assign({}, this.state.workOrder);
                                    workOrder.requiredStaff = value;
                                    let changes = Object.assign({}, this.state.changes);
                                    changes.requiredStaff = value;
                                    this.setState({ changes, workOrder })
                                }}
                            >
                                {this.state.workOrder.requiredStaff}
                            </SelectField>
                        </div>
                        :
                        <div className="display-only custom-c-dark">
                            <label>Required staff</label>
                            <p>{this.state.workOrder.requiredStaff || "Not set"}</p>
                        </div>

                    }
                    {!displayOnly && this.props.user.role < 4
                        ?
                        <VehicleSearchField
                            label="Primary Vehicle."
                            varname="vehicle"
                            defaultSearch="locator"
                            change={(varname, vehicleObject, error) => {
                                let workOrder = Object.assign({}, this.state.workOrder);
                                workOrder.vehicle = vehicleObject ? vehicleObject.locator : "";
                                this.setState({ workOrder });
                                //Handle changes for editing a workOrder.
                                if (this.state.workOrder.id) {
                                    let changes = Object.assign({}, this.state.changes);
                                    changes.vehicle = vehicleObject ? vehicleObject.locator : "";
                                    this.setState({ changes });
                                }
                            }}
                        >
                            {this.state.workOrder.vehicle}
                        </VehicleSearchField>
                        :
                        <div className="display-only custom-c-dark">
                            <label>Vehicle</label>
                            <p>{this.state.workOrder.vehicle || "Vehicle not assigned."}</p>
                        </div>
                    }
                    {!displayOnly && this.state.workOrder.vehicle && this.props.user.role < 4
                        ?
                        <VehicleListField
                            label="Additional vehicles."
                            varname="vehicles"
                            exclude={[this.state.workOrder.vehicle]}
                            change={(varname, vehicles, error) => {
                                let workOrder = Object.assign({}, this.state.workOrder);
                                workOrder.vehicles = vehicles;
                                //Handle changes for editing a delivery.
                                if (this.state.workOrder.id) {
                                    let changes = Object.assign({}, this.state.changes, {
                                        vehicles
                                    });
                                    this.setState({ workOrder, changes });
                                } else {
                                    this.setState({ workOrder });
                                }
                            }}
                        >
                            {this.state.workOrder.vehicles || []}
                        </VehicleListField>
                        :
                        <div className="display-only custom-c-dark">
                            <label>Vehicles</label>
                            <p>{(this.state.workOrder.vehicles || []).join(", ")}</p>
                        </div>
                    }
                </div>
                {this.renderAddress()}
                {/* Showing images added while loading */}
                {this.state.workOrder.images && this.state.workOrder.images.length
                    ?
                    <FileField
                        label="Images"
                        readOnly={displayOnly ? true : false}
                        varname="images"
                        change={(varname, value, error) => {
                            let images = value.map(v => v.url);
                            let changes = Object.assign({}, this.state.changes, { images });
                            let workOrder = Object.assign({}, this.state.workOrder, { images });
                            this.setState({ workOrder, changes });
                        }}
                    >
                        {this.state.workOrder.images
                            .map(f => {
                                return { name: f, url: f, type: "image" }
                            })
                        }
                    </FileField>
                    :
                    null
                }
                {(this.state.customerData && this.state.customerData.defaultWorkOrderFiles && this.state.customerData.defaultWorkOrderFiles.length)
                    ?
                    <FileField
                        label="Work Orders Files"
                        readOnly={true}
                        multiple={true}
                    >
                        {this.state.customerData.defaultWorkOrderFiles.map(file => {
                            let type = file.split(".");
                            type = type[type.length - 1];
                            let name = file.split("/");
                            name = name[name.length - 1]
                            return {
                                name,
                                url: file,
                                type: ["svg", "jpg", "jpeg", "png"].includes(type) ? "image" : "file"
                            }
                        })}
                    </FileField>
                    :
                    null
                }
                {/* Hide stock field when no customer selected */}
                {this.state.workOrder.customer
                    ?
                    <div className="work-order-stock">
                        <p className="custom-c-light">Stock Items</p>
                        <FlipMove>
                            {this.state.destinations.map(destination => {
                                return <div key={destination.locator} className="destination custom-border-color">
                                    <h6>
                                        {`Destination: ${destination.locator}`}
                                        {this.props.workOrder && this.props.workOrder.status === "delivered"
                                            ?
                                            <div
                                                className="icon document-icon toggleFiles"
                                                title="Show files"
                                                onClick={() => {
                                                    if (this.state.showImages === destination.locator) {
                                                        //Close it
                                                        this.setState({ showImages: null })
                                                    } else {
                                                        //Open it
                                                        this.setState({ showImages: destination.locator })
                                                    }
                                                }}
                                            >
                                            </div>
                                            :
                                            null
                                        }
                                        <img src="" />
                                    </h6>
                                    {this.state.showImages === destination.locator
                                        ?
                                        <div className="workOrder-files custom-bg-light">
                                            <FileField
                                                readOnly={displayOnly ? true : false}
                                                change={(varname, value, error) => {
                                                    let files = value.map(v => { return { url: v.url, destination: destination.locator } });
                                                    let allFiles = (this.state.workOrder.files || []).filter(f => f.destination !== destination.locator).concat(files);
                                                    let changes = Object.assign({}, this.state.changes, { files: allFiles });
                                                    let workOrder = Object.assign({}, this.state.workOrder, { files: allFiles });
                                                    this.setState({ changes, workOrder });
                                                }}
                                            >
                                                {(this.state.workOrder.files || [])
                                                    .filter(f => {
                                                        return f.destination === destination.locator
                                                    })
                                                    .map(f => {
                                                        return { name: f.url, url: f.url, type: "image" }
                                                    })
                                                }
                                            </FileField>
                                        </div>
                                        :
                                        null
                                    }
                                    {!displayOnly
                                        ?
                                        <div
                                            className="icon delete-icon"
                                            onClick={() => {
                                                //User confirmation before removing
                                                if (window.confirm(`Are you sure you want to remove destination ${destination.locator}?`)) {
                                                    //Removing destination and all stock inside of it.
                                                    let destinations = [...this.state.destinations]
                                                        .filter(d => {
                                                            return d.locator !== destination.locator;
                                                        })
                                                    let workOrder = Object.assign(this.state.workOrder,
                                                        { stock: [...this.state.workOrder.stock] }
                                                    );

                                                    workOrder.stock = workOrder.stock.filter(s => {
                                                        return s.destination !== destination.locator
                                                    })
                                                    let stock = workOrder.stock.map(s => {
                                                        return this.state.stock.filter(stateStock => {
                                                            return stateStock.id === s.id
                                                        })[0]
                                                    })

                                                    this.setState({ workOrder, destinations, stock })

                                                    //handling changes to existing work order
                                                    if (this.state.workOrder.id) {
                                                        this.setState({
                                                            changes: Object.assign({}, this.state.changes, {
                                                                stock: workOrder.stock
                                                            })
                                                        })
                                                    }
                                                }
                                            }}
                                        >
                                        </div>
                                        :
                                        null
                                    }
                                    <div className="stock">
                                        <WorkOrderStockList
                                            filter={item => {
                                                return !allStockIds.includes(item.id);
                                            }}
                                            columns={(() => {
                                                let columns = [
                                                    {
                                                        name: "Prod. Code",
                                                        renderer: item => item.productCode,
                                                        sorter: item => item.productCode
                                                    },
                                                    {
                                                        name: "Group",
                                                        renderer: item => item.group,
                                                        sorter: item => item.group
                                                    },
                                                    {
                                                        name: "Description",
                                                        renderer: item => item.description,
                                                        sorter: item => item.description
                                                    },
                                                    {
                                                        name: "Location",
                                                        renderer: item => item.location || "-",
                                                        sorter: item => item.location || "-"
                                                    },
                                                    {
                                                        name: "Order pos",
                                                        renderer: item => {
                                                            let result = /order position:\s*([\d]+)\s*/gi.exec(item.description);
                                                            return result ? result[1] : "-"
                                                        },
                                                        sorter: item => {
                                                            let result = /order position:\s*([\d]+)\s*/gi.exec(item.description);
                                                            return result ? Number(result[1]) : "-"
                                                        }
                                                    }
                                                ]
                                                if (this.props.user.role !== 4 || this.state.workOrder.status === "delivered") {
                                                    columns.push({
                                                        name: "Status",
                                                        renderer: item => {
                                                            if (item.status === "loaded" && !displayOnly) {
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            cursor: "pointer"
                                                                        }}
                                                                        className="statusChanger"
                                                                        onClick={() => {
                                                                            if (window.confirm("Are you sure you want to mark this item as unloaded? This should only be used when absolutely necessary. There will be no GPS tag.")) {
                                                                                $.ajax({
                                                                                    type: "PUT",
                                                                                    url: `${this.props.apiRoot}/workorders/${this.props.workOrder.id}/unload/${item.id}`,
                                                                                    data: JSON.stringify({ files: [] })
                                                                                }).then(res => {
                                                                                    this.setState({ workOrder: res.workOrder });
                                                                                    logger.success("Item unloaded successfully.");
                                                                                }).catch(err => {
                                                                                    console.error("Error changing item's status: ", err);
                                                                                    logger.error(err);
                                                                                })
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                display: "inline-block",
                                                                                verticalAlign: "middle"
                                                                            }}
                                                                        >
                                                                            {item.status}
                                                                        </span>
                                                                        <div
                                                                            className="icon double-chevron-icon"
                                                                            style={{
                                                                                display: "inline-block",
                                                                                verticalAlign: "middle",
                                                                                marginLeft: "5px",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )

                                                            } else {
                                                                return item.status
                                                            }
                                                        },
                                                        sorter: item => item.status
                                                    })
                                                }
                                                return columns;
                                            })()}
                                            customer={this.state.workOrder.customer}
                                            user={this.props.user}
                                            displayOnly={displayOnly}
                                            items={(() => this.state.workOrder.stock
                                                .filter(
                                                    workOrderStock => workOrderStock.destination === destination.locator
                                                )
                                                .map(workOrderStock => {
                                                    let item = this.state.stock.filter(stateStock => {
                                                        return stateStock.id === workOrderStock.id;
                                                    })[0];
                                                    if (!item) return null;
                                                    item.files = workOrderStock.files || [];
                                                    item.gps = workOrderStock.gps || null;
                                                    item.status = workOrderStock.status || "-";
                                                    return item;
                                                })
                                                .filter(s => s)
                                            )()}
                                            onNew={data => {
                                                //Getting current stock,workOrder stock and changes Objects  
                                                let stock = [...this.state.stock];
                                                let workOrder = Object.assign({}, this.state.workOrder, {
                                                    stock: [...this.state.workOrder.stock]
                                                });
                                                let changes = Object.assign({}, this.state.changes);
                                                if (data.constructor === Object) {
                                                    // For single objects passed.
                                                    let item = data;
                                                    // If stock not already added..
                                                    if (!workOrder.stock.filter(s => s.id === item.id).length) {
                                                        workOrder.stock.push({
                                                            id: item.id,
                                                            destination: destination.locator
                                                        })
                                                        stock.push(item)
                                                        this.setState({ workOrder, stock })
                                                        //handling changes to existing work order
                                                        if (this.state.workOrder.id) {
                                                            changes.stock = workOrder.stock;
                                                            this.setState({ changes })
                                                        }
                                                    } else {
                                                        logger.error("Error while adding stock - item already exists")
                                                    }
                                                } else if (data.constructor === Array) {
                                                    // For multiple objects.
                                                    data.forEach(item => {
                                                        // If stock not already added..
                                                        if (!workOrder.stock.filter(s => s.id === item.id).length) {
                                                            workOrder.stock.push({
                                                                id: item.id,
                                                                destination: destination.locator
                                                            })
                                                            stock.push(item);
                                                            //handling changes to existing work order
                                                            if (this.state.workOrder.id) {
                                                                changes.stock = workOrder.stock;
                                                            }
                                                        } else {
                                                            logger.error("Error while adding stock - item already exists")
                                                        }
                                                    })
                                                    this.setState({ workOrder, stock, changes });
                                                }
                                            }}
                                            onDelete={id => {
                                                let workOrder = Object.assign({}, this.state.workOrder, {
                                                    stock: [...this.state.workOrder.stock]
                                                });
                                                let stock = [...this.state.stock];

                                                workOrder.stock = workOrder.stock.filter(s => {
                                                    return s.id !== id
                                                })
                                                stock = stock.filter(s => {
                                                    return s.id !== id
                                                })

                                                this.setState({ workOrder, stock })

                                                //handling changes to existing work order
                                                if (this.state.workOrder.id) {
                                                    this.setState({
                                                        changes: Object.assign({}, this.state.changes, {
                                                            stock: workOrder.stock
                                                        })
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            })}
                        </FlipMove>
                        {!displayOnly
                            ?
                            <div className="create-destination">
                                <RestrictedTextField
                                    prefix="EXT-"
                                    placeholder="Add destination"
                                    change={(vn, val, err) => {
                                        this.setState({ destinationToAdd: val })
                                    }}
                                >
                                    {this.state.destinationToAdd}
                                </RestrictedTextField>
                                <div
                                    className="button custom-bg-feature1"
                                    onClick={() => {
                                        if (this.state.destinationToAdd &&
                                            !this.state.destinations.filter(dest => dest.locator === this.state.destinationToAdd).length) {
                                            this.setState({
                                                destinations: [...this.state.destinations, { locator: this.state.destinationToAdd }],
                                                destinationToAdd: ""
                                            })
                                        } else {
                                            logger.error("Incorrect value or already added")
                                        }
                                    }}
                                >
                                    Add
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    null
                }
                {this.props.workOrder && this.props.workOrder.status !== "pending"
                    ?
                    <React.Fragment>
                        <div
                            className="btn custom-bg-feature1 printPod"
                            onClick={() => {
                                if (this.state.destinations.length) {
                                    this.setState({ showPod: true, showSplitPod: false });
                                }
                            }}
                        >
                            Generate POD
                        </div>
                        {this.state.showPod && !this.state.showSplitPod
                            ?
                            <Pod
                                key={"standard"}
                                user={this.props.user}
                                workOrder={this.props.workOrder}
                                address={this.state.customerData && this.state.customerData.defaultAddress ? this.state.customerData.defaultAddress : null}
                                stock={this.podStock()}
                                onExit={e => {
                                    this.setState({ showPod: false })
                                }}
                            />
                            :
                            null
                        }
                        <div
                            style={{ marginLeft: "10px" }}
                            className="btn custom-bg-feature1 printPod"
                            onClick={() => {
                                if (this.state.destinations.length) {
                                    this.setState({ showSplitPod: true, showPod: false });
                                }
                            }}
                        >
                            Generate Split POD
                        </div>
                        {this.state.showSplitPod && !this.state.showPod
                            ?
                            <SplitPod
                                key={"split"}
                                user={this.props.user}
                                workOrder={this.props.workOrder}
                                address={this.state.customerData && this.state.customerData.defaultAddress ? this.state.customerData.defaultAddress : null}
                                stock={this.podStock()}
                                onExit={e => {
                                    this.setState({ showSplitPod: false })
                                }}
                            />
                            :
                            null
                        }
                    </React.Fragment>
                    :
                    null
                }
                {!displayOnly && this.props.workOrder && this.props.workOrder.id
                    ?
                    <div className="repeatedOrder">
                        <span>Repeat booking? Duplicate this booking every week for the next</span>
                        <SelectField
                            varname="weeksToDuplicate"
                            options={Array.apply(null, Array(52)).map((item, index) => ({
								val: index + 1,
								display: index + 1
							}))}
                            change={(varname, value, error) => {
                                this.setState({ weeksToDuplicate: value })
                            }}
                        >
                            {this.state.weeksToDuplicate}
                        </SelectField>
                        <span>weeks.</span>
                        <div
                            className={"btn custom-bg-feature1"}
                            onClick={async () => {
                                //Create workorders
                                if (this.state.workOrder.dateOutgoing && this.state.workOrder.timeOutgoing && !this.state.creatingRepeatedBooking) {
                                    //Disabling the button for spam prevention
                                    this.setState({ creatingRepeatedBooking: true });
                                    let newWorkOrder = Object.assign({}, this.state.workOrder);
                                    delete newWorkOrder.status;
                                    delete newWorkOrder.dateDelivered;
                                    delete newWorkOrder.id;
                                    delete newWorkOrder.client;
                                    delete newWorkOrder.initiator;
                                    delete newWorkOrder.history;
                                    newWorkOrder.createdFrom = this.state.workOrder.id;
                                    newWorkOrder.stock = [];
                                    if (this.props.user.customer !== "internal") {
                                        delete newWorkOrder.customer
                                    }
                                    Object.keys(newWorkOrder).forEach(key => {
                                        if (!newWorkOrder[key])
                                            delete newWorkOrder[key];
                                    });

                                    try {
                                        // Wait for all W/O to be created
                                        for (let i = 0; i < this.state.weeksToDuplicate; i++) {
                                            await new Promise((resolve, reject) => {
                                                // Create a new work order with 
                                                let wo = Object.assign({}, newWorkOrder, {
                                                    dateOutgoing: moment(newWorkOrder.dateOutgoing).add(i + 1, "weeks").valueOf()
                                                });
                                                // Post the new WO.
                                                $.post(`${this.props.apiRoot}/workorders`, JSON.stringify(wo))
                                                    .done(resolve)
                                                    .catch(reject);
                                            });

                                        }
                                        // All good
                                        logger.success("Success");
                                        // Update original work order.
                                        this.props.onUpdate(
                                            { createdFrom: this.state.workOrder.id },
                                            this.state.workOrder.id
                                        );
                                        // Return to schedule view
                                        this.setState({ creatingRepeatedBooking: false });
                                        this.props.router.history.push("/schedule");
                                    } catch (err) {
                                        console.error("err: ", err);
                                        logger.error("Couldn't create repeated bookings, please try again.");
                                        this.setState({ creatingRepeatedBooking: false });
                                    }

                                }
                            }}
                        >
                            Go!
                        </div>
                    </div>
                    :
                    null
                }
                {
                    Boolean(
                        !displayOnly &&
                        this.props.workOrder &&
                        this.props.workOrder.id &&
                        this.props.workOrder.createdFrom
                    ) && (
                        <div
                            className="btn custom-bg-feature3 repeatedOrderCanceller"
                            onClick={() => {
                                if (this.cancelingRepeatedBooking) return;
                                if (window.confirm(`Are you sure you want to cancel all repeated bookings created from order ${this.props.workOrder.createdFrom}?`)) {
                                    // Block any further requests.
                                    this.cancelingRepeatedBooking = true
                                    // Call cancel endpoint.
                                    $.get(`${this.props.apiRoot}/workorders/cancel-repeated/${this.props.workOrder.createdFrom}?from=${this.props.workOrder.dateOutgoing}`)
                                        .done(res => {
                                            logger.success("Repeated bookings cancelled.");
                                            this.cancelingRepeatedBooking = false;
                                            this.props.router.history.push("/schedule");
                                        }).catch(err => {
                                            console.error(err);
                                            logger.error("Error canceling repeated bookings.");
                                            this.cancelingRepeatedBooking = false
                                        })

                                }
                            }}
                        >
                            Cancel repeated bookings
                        </div>
                    )
                }
                <FlipMove>
                    {Object.keys(this.state.changes).length
                        ?
                        <div className="save-changes">
                            {"Changes were made, make sure you save them!"}
                        </div>
                        :
                        null
                    }
                </FlipMove>
                {!displayOnly
                    ?
                    <div
                        className="create-work-order-button button custom-bg-feature1"
                        onClick={() => {
                            if (this.state.workOrder.id) {
								// If canceling, prevent if reason was not selected
								if (
									this.state.changes.status && 
									this.state.changes.status === "cancelled" &&
									!this.state.changes.cancelReason
								) return null;
                                //If changes were made
                                if (Object.keys(this.state.changes).length) {
                                    // Set blank fields to null.
                                    let changes = Object.assign({}, this.state.changes);
                                    Object.keys(changes).forEach(key => {
                                        if (changes[key] === "")
                                            changes[key] = null;
                                    });
                                    // Save them.
                                    this.props.onUpdate(changes, this.state.workOrder.id, () => {
										this.setState({ changes: {} })
									});
                                }
                            } else {
                                //Create a new one
                                if (this.state.workOrder.dateOutgoing && this.state.workOrder.timeOutgoing) {
                                    let newWorkOrder = Object.assign({}, this.state.workOrder);
                                    delete newWorkOrder.status;
                                    delete newWorkOrder.dateDelivered;
                                    if (this.props.user.customer !== "internal") {
                                        delete newWorkOrder.customer
                                    }
                                    Object.keys(newWorkOrder).forEach(key => {
                                        if (!newWorkOrder[key])
                                            delete newWorkOrder[key];
                                    });
                                    this.props.onSubmit(newWorkOrder, () => {
										this.setState({ changes: {} });
									});
                                }
                            }
                        }}
                    >
                        {this.state.workOrder.id ? "Save Changes" : "Create Work Order"}
                    </div>
                    :
                    null
                }
				{this.state.creatingRepeatedBooking && <div style={{
					position: "absolute",
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					backgroundColor: "rgba(232,232,232, 0.6)",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center"
				}}>
					<div>
						<div>
							<Spinner size={180} color="#1e43a9" />
						</div>
						<p className="custom-c-feature2" style={{
							textAlign: "center",
							margin: 0,
							fontSize: "1.5rem"
						}}>Creating bookings - please wait</p>
					</div>
				</div>}
            </div>
        )
    }
}


export default WorkOrderCreator;