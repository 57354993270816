import View from "../view";
import { Route } from "react-router-dom";
import UsersList from "./usersList";
import DataField from "../../fields/dataField";
import StaggeredSearchField from "../../fields/staggeredSearchField";
import { Aux, renderDate } from "../../helpers";

class UsersView extends View {
    constructor(props) {
        super(props);
        this.pageSize = 20;
        this.state = {
            users: [],
            page: 1,
            pageCount: 1,
            filters: {
                customer: "",
                email: "",
                name: "",
            }
        }

        this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);

        this.userListColumns = [
            {
                name: "Name",
                renderer: item => item.name,
                sorter: item => item.name
            },
            {
                name: "Customer",
                renderer: item => {
                    if (item.customer === null) {
                        //Internal user
                        return "Internal"
                    } else {
                        return item.customer
                    }
                },
                sorter: item => {
                    if (item.customer === null) {
                        //Internal user
                        return "Internal"
                    } else {
                        return item.customer
                    }
                }
            },
            {
                name: "Role",
                renderer: item => item.role,
                sorter: item => item.role
            }
        ]

        this.mobileUserListColumns = [
            {
                name: "Name",
                renderer: item => item.name,
                sorter: item => item.name
            },
            {
                name: "Customer",
                renderer: item => {
                    if (item.customer === null) {
                        //Internal user
                        return "Internal"
                    } else {
                        return item.customer
                    }
                },
                sorter: item => {
                    if (item.customer === null) {
                        //Internal user
                        return "Internal"
                    } else {
                        return item.customer
                    }
                }
            }
        ]
    }

    componentDidMount() {
        //Getting users array
        this.getUsers({
            count: this.pageSize,
            offset: (this.state.page - 1) * this.pageSize
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            this.getUsers({
                count: this.pageSize,
                offset: (this.state.page - 1) * this.pageSize
            })
        }
    }

    getUsers(filters) {
        filters = Object.assign(this.state.filters, filters) || this.state.filters;
        $.get(`${this.props.apiRoot}/users${this.createQueryString(filters)}`).done(response => {
            // console.log("users", users);
            this.setState({
                users: response.results,
                pageCount: Math.ceil(response.total / this.pageSize)
            })
        }).catch(err => {
            logger.error(err);
        })
    }

    createUser(user, cb) {
        $.post(`${this.props.apiRoot}/users`, JSON.stringify(user)).done(response => {
            let users = [response.user, ...this.state.users];
            this.setState({ users })
            logger.success(response.msg)
            if (cb) cb()
        }).catch(err => {
            logger.error(err);
        })
    }

    updateUser(user, id, cb) {
        $.ajax({
            type: "PUT",
            url: `${this.props.apiRoot}/users/${id}`,
            data: JSON.stringify(user),
            contentType: "application/json"
        }).done(response => {
            let users = [...this.state.users].map(u => {
                if (u.id === response.user.id) {
                    return response.user
                } else {
                    return u
                }
            })
            this.setState({ users });
            logger.success(response.msg)
            if (cb) cb()
        }).catch(err => {
            logger.error(err);
        })
    }

    deleteUser(id) {
        $.ajax({
            type: "DELETE",
            url: `${this.props.apiRoot}/users/${id}`
        }).done(response => {
            let users = [...this.state.users].filter(u => {
                return u.id !== id
            })
            this.setState({ users })
            logger.success(response.msg)
        }).catch(err => {
            logger.error(err);
        })
    }

    filterChange(varname, val, err) {
        let filters = Object.assign({}, this.state.filters);
        if (varname === "customer") {
            filters[varname] = val ? val.name : "";
        } else {
            filters[varname] = val;
        }
        this.setState({ filters, page: 1 })
        this.getUsers(filters)
    }

    renderView() {
        // console.log("USER VIEW PROPS: ", this.props);
        return (
            <Aux>
                <div className="row heading-row">
                    <div className="col-12">
                        <h1 className="client-view custom-c-dark">{`${this.client} Users`}</h1>
                        <h5 className="date custom-c-mid">{renderDate()}</h5>
                    </div>
                </div>
                <div className="row view usersview splitPanelView">
                    <Route
                        exact
                        path="/users"
                        render={router => (
                            <div className="col-12 col-xl-3 ui-panel-split ui-panel-split-left custom-bg-light custom-c-light shadow-1 filters">
                                <h2 className="panel-name custom-border-color-2">Filter by</h2>
                                <div className="filters">
                                    <StaggeredSearchField
                                        label="Name"
                                        varname="name"
                                        delay={250}
                                        change={this.filterChange.bind(this)}
                                    >
                                        {this.state.filters.name}
                                    </StaggeredSearchField>
                                    <StaggeredSearchField
                                        label="Email"
                                        varname="email"
                                        delay={250}
                                        change={this.filterChange.bind(this)}
                                    >
                                        {this.state.filters.email}
                                    </StaggeredSearchField>
                                    {this.props.user.customer === "internal"
                                        ?
                                        <DataField
                                            varname="customer"
                                            defaults={[
                                                {
                                                    name: "internal",
                                                    displayName: "Internal User"
                                                }
                                            ]}
                                            optionRenderer={customer => (
                                                <div className="option-inner">
                                                    <b>{customer.displayName}</b> <br />({customer.name})
                                                </div>
                                            )}
                                            label="Customer"
                                            route="customers"
                                            dataName="name"
                                            searchBy="term"
                                            change={this.filterChange.bind(this)}
                                        >
                                            {this.state.filters.customer}
                                        </DataField>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        )}
                    />
                    <Route
                        exact
                        path="/users"
                        render={router => (
                            <div className="col-12 col-xl-9 users ui-panel-split ui-panel-split-right custom-bg-dark custom-c-light shadow-1">
                                <div className="icon user-icon title-icon"></div>
                                <h2 className="panel-name">Users</h2>
                                <UsersList
                                    numberOfPages={this.state.pageCount}
                                    page={this.state.page}
                                    icon="user"
                                    user={this.props.user}
                                    uniqueField="id"
                                    columns={this.userListColumns}
                                    mobileColumns={this.mobileUserListColumns}
                                    items={this.state.users}
                                    onPageChange={page => {
                                        this.setState({ page });
                                    }}
                                    onNew={(item, cb)=> {
                                        this.createUser(item, cb);
                                    }}
                                    onUpdate={(item, id, cb) => {
                                        this.updateUser(item, id, cb);
                                    }}
                                    onDelete={id => {
                                        this.deleteUser(id);
                                    }}
                                />
                            </div>
                        )}
                    />
                </div>
            </Aux>
        )
    }

}

export default UsersView;