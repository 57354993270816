import { NavLink } from 'react-router-dom';

const DesktopNavigation = props => (

    <div className="desktop-navigation custom-bg-dark">
        <div className="logo custom-bg-feature1">
            <img src="/static/media/clarks-logo.svg" />
        </div>
        <div className="user custom-bg-feature2">
            <img src="/static/media/user-white.svg" className="user-icon" />
            <div className="name-logout">
                <div className="name">{props.name}</div>
                <div 
                    className="logout"
                    onClick={() => {
                        props.logout()
                    }}
                >
                    Log out
                </div>
            </div>
        </div>
        <div className="nav">
            <ul>
                {props.links.map(link => (
                    <li className="nav-item custom-border-color" key={link.name}>
                        <NavLink 
                            activeClassName="current" 
                            to={link.to} 
                            className="custom-bg-dark custom-c-dark hover-class"
                        >
                            <div className={`icon ${link.icon}-icon`}></div>
                            {link.name}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    </div>

)

export default DesktopNavigation;