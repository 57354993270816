/**
 *  PROPS:
 * 
 *      itemId: "String", id of the item
 *      itemType: "String", item type (stock, report, location...)  
 *      types: [Array] of objects for SelectField options, default passed.
 */


import FlipMove from 'react-flip-move';
import SelectField from '../fields/selectField';
import StockSearchField from '../fields/stockSearchField';
import LocationSearchField from '../fields/locationSearchField';
import ReportSearchField from '../fields/reportSearchField';

import { Link } from "react-router-dom";

class ItemLinker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            links: [],
            itemSelected: null,
            adding: false,
            type: ""
        }
    }

    componentDidMount() {
        this.getItemLinks()
    }

    getItemLinks() {
        //Creating variables
        let type = this.props.itemType;
        let searchBy = "id"
        if (type === "location") {
            type = "locations";
            searchBy = "locator"
        } else if (type === "report") {
            type = "reports"
        }
        //Making the API call
        $.get(`${this.props.apiRoot}/${type}?${searchBy}=${this.props.itemId}`).done(response => {
            //Creating teh links array
            let links = [];
            if (response.results && response.results[0].links) {
                links = response.results[0].links
            } else if (response.links) {
                links = response.links
            }
            if (links.length) {
                this.setState({ links })
            }
        }).catch(err => {
            logger.error(err)
        })

    }

    renderLinks() {
        let links = this.state.links;
        if (!links.length) {
            return <h6 key="no-items">No linked items...</h6>
        } else {
            return links.map(link => {
                return (
                    <div
                        key={link.id}
                        className="link"
                    >
                        <Link
                            className={link.type === "report" ? "inner custom-bg-light list-hover" : "inner custom-bg-light"}
                            to={(() => {
                                if (link.type === "report") {
                                    return `/reports/${link.id}`
                                } else {
                                    return "#"
                                }
                            })()}
                        >
                            <div className="icon-wrap custom-bg-feature1">
                                <div className={`icon-white ${link.type}`}></div>
                            </div>
                            <div className="text">
                                <p>{link.id}</p>
                                <p>{link.type}</p>
                            </div>
                        </Link>

                        <div
                            className="unlink icon close-icon"
                            onClick={() => {
                                if (window.confirm("Are you sure you want to unlink this item?")) {
                                    this.removeLink(link)
                                }
                            }}
                        />
                    </div>
                )
            })
        };
    }

    canIAdd() {
        if (this.state.type && this.state.itemSelected) {
            return true;
        } else {
            return false;
        }
    }

    addLink() {
        //Preparing data to post
        let objPassed = { id: this.props.itemId, type: this.props.itemType };
        let objSelected = { id: this.state.itemSelected.id, type: this.state.type };
        //Check in not linked already
        let linked = false;
        this.state.links.forEach(element => {
            if (element.id === this.state.itemSelected.id) linked = true;
        });
        if (linked) {
            logger.error("Item already linked.")
        } else {
            //API call
            $.post(`${this.props.apiRoot}/misc/link`, JSON.stringify([objPassed, objSelected])).done(response => {
                // console.log(response);
                this.setState(prevState => {
                    return {
                        links: prevState.links.concat(objSelected)
                    }
                })
            }).catch(err => {
                console.log("LINKER:", err)
                logger.error(err)
            })
        }
    }

    removeLink(link) {
        //link to be removed passed as an argument(Object)
        let objPassed = { id: this.props.itemId, type: this.props.itemType };
        $.post(`${this.props.apiRoot}/misc/unlink`, JSON.stringify([objPassed, link])).done(response => {
            // console.log(response)
            this.setState(prevState => {
                return {
                    links: prevState.links.filter(l => {
                        return l.id !== link.id
                    })
                }
            })
        }).catch(err => {
            logger.error(err)
        })
    }

    toogleCreator(bool) {
        this.setState({ adding: bool })
    }

    renderSearchField() {
        if (this.state.type === "report") {
            return (
                <ReportSearchField
                    label="Report"
                    varname="id"
                    change={(varname, val, error) => {
                        this.setState({ itemSelected: val })
                    }}
                >
                    {this.state.itemSelected ? this.state.itemSelected.id : ""}
                </ReportSearchField>
            )
        } else if (this.state.type === "stock") {
            return (
                <StockSearchField
                    label="Stock"
                    varname="code"
                    change={(varname, val, error) => {
                        this.setState({ itemSelected: val })
                    }}
                >
                    {this.state.itemSelected ? this.state.itemSelected.code : ""}
                </StockSearchField>
            )
        } else if (this.state.type === "location") {
            return (
                <LocationSearchField
                    label="Location"
                    varname="locator"
                    change={(varname, val, error) => {
                        this.setState({ itemSelected: val })
                    }}
                >
                    {this.state.itemSelected ? this.state.itemSelected.locator : ""}
                </LocationSearchField>
            )
        } else {
            logger.error("Error selecting type")
        }
    }

    render() {
        return (
            <div className="item-linker custom-bg-dark custom-border-color-3 custom-c-dark">
                <FlipMove className="links">
                    {this.renderLinks()}
                </FlipMove>
                {!this.state.adding
                    ?
                    <div
                        className="add-link custom-bg-feature1"
                        onClick={() => {
                            this.toogleCreator(true)
                        }}
                    >
                        Create link
                    </div>
                    :
                    <div className="link-creator">
                        <hr />
                        <div
                            className="icon close-icon"
                            onClick={() => {
                                this.toogleCreator(false)
                            }}
                        >
                        </div>
                        <FlipMove className="field-combiner">
                            <SelectField
                                key="type"
                                label="Type"
                                varname="type"
                                name="Type"
                                options={this.props.types}
                                change={(varname, value, error) => {
                                    this.setState({ type: value })
                                }}
                            >
                                {this.state.type}
                            </SelectField>
                            {this.state.type
                                ?
                                this.renderSearchField()
                                :
                                null
                            }
                        </FlipMove>

                        <div
                            className="add-btn custom-bg-feature1"
                            onClick={() => {
                                if (this.canIAdd()) {
                                    this.addLink()
                                } else {
                                    logger.error("Make sure all required values are selected");
                                }
                            }}
                        >
                            Link
                        </div>
                    </div>
                }
            </div>
        )
    }
}

ItemLinker.defaultProps = {
    types: [
        {
            val: "report",
            display: "Report"
        },
        {
            val: "stock",
            display: "Stock"
        },
        {
            val: "location",
            display: "Location"
        }
    ],
    itemType: "stock"
}

export default ItemLinker