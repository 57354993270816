import Process from "./index";
import ScanInput from "../../../fields/scanInput";
import NumberField from "../../../fields/numberField";
// Stages.
import ListStage from "../stages/listStage";
import SelectLocation from "../stages/selectLocation";
import Action from "../stages/action";
import Custom from "../stages/custom";
import RemedialData from "../stages/remedialData";
import Success from "../stages/success";

class UnloadDelivery extends Process {
    constructor(props) {
        super(props);
        this.state.stage = 0;
        this.stages = [
            {
                // 0 - Load deliveries.
                component: Action,
                config: {
                    action: ({ finish, error, exit }) => {
                        this.getItems((err, items) => {
                            if (!err) {
                                finish(items);
                            } else {
                                console.error("Couldn't get deliveries: ", err);
                                error("Error getting deliveries");
                                setTimeout(() => {
                                    exit();
                                }, 3000);
                            }
                        });
                    }
                }
            },
            {
                // 1 - List deliveries.
                component: ListStage,
                config: {
                    icon: "delivery",
                    title: "Choose Delivery",
                    state: {
                        inputValue: ""
                    },
                    before: stage => {
                        // Render out the selector.
                        return <div>
                            <div>
                                Scan a stock item to enter delivery.
                            </div>
                            <ScanInput onScan={code => {
                                // Find a match.
                                let codeMatch = this.stageData(0).filter(
                                    item => item.stock.map(s => s.code).includes(code)
                                )[0];
                                let productBarcodeMatch = this.stageData(0).filter(
                                    item => item.stock.map(s => s.productBarcode).includes(code)
                                )[0];
                                // Check for matches, complete with it.
                                if (codeMatch) {
                                    stage.stageComplete({
                                        stockMatch: true,
                                        match: "code",
                                        delivery: codeMatch,
                                        stock: codeMatch.stock.filter(s => s.code === code)[0]
                                    });
                                } else if (productBarcodeMatch) {
                                    stage.stageComplete({
                                        stockMatch: true,
                                        match: "barcode",
                                        delivery: productBarcodeMatch,
                                        stock: productBarcodeMatch.stock.filter(s => s.productBarcode === code)[0]
                                    });
                                } else {
                                    stage.error("No match found for that bar code.");    
                                }
                            }} />
                            <div>
                                Or, select delivery below.
                            </div>
                        </div>

                    },
                    items: () => this.stageData(0),
                    renderItem: item => {
                        return (
                            <div className="content">
                                <p className="customer">{item.customer}</p>
                                <div className="date-time">
                                    <p className="date">{this.msToDate(item.dateIncoming)}</p>
                                    <p className="time">{`At ${item.timeIncoming}`}</p>
                                </div>
                                <p className="stock">{`Stock quantity: ${item.stock.length}`}</p>
                                {item.notes
                                    //Render notes if delivery has them
                                    ?
                                    <div className="notes-wrapper">
                                        <hr />
                                        <p className="notes">{item.notes}</p>
                                    </div>
                                    :
                                    null
                                }
                                <hr />
                                <p className="id">{item.id}</p>
                            </div>
                        )
                    }
                }
            },
            {
                // 2 - Check if stock was also passed.
                // Skip the next stage if there is stock.
                component: Action,
                config: {
                    action: ({ finish, changeStage }) => {
                        if (this.stageData(1).stockMatch) {
                            changeStage(4);
                        } else {
                            finish();
                        }
                    }
                }
            },
            {
                // 3 - List stock items.
                component: ListStage,
                config: {
                    icon: "stock",
                    title: "Scan Remaining Stock",
                    state: {
                        inputValue: ""
                    },
                    before: stage => {
                        // Render out the selector.
                        return <div>
                            <ScanInput onScan={code => {
                                let codeMatch = this.stageData(1).stock.filter(s => s.code === code)[0];
                                let productBarcodeMatch = this.stageData(1).stock.filter(s => s.productBarcode === code)[0];
                                if (codeMatch) {
                                    if (!codeMatch.id) {
                                        stage.stageComplete({
                                            match: "code",
                                            stock: codeMatch
                                        });
                                    } else {
                                        stage.error("Item already unloaded.");
                                    }
                                } else if (productBarcodeMatch) {
                                    if (!productBarcodeMatch.id) {
                                        stage.stageComplete({
                                            match: "barcode",
                                            stock: productBarcodeMatch
                                        })
                                    } else {
                                        stage.error("Item already unloaded.");
                                    }
                                } else {
                                    stage.error("No match found for that bar code.");
                                }
                            }} />
                        </div>
                    },
                    items: () => {
                        // console.log(this.stageData(1).stock);
                        return this.stageData(1).stock.filter(s => !s.id)
                    },
                    disable: item => true,
                    renderItem: item => {
                        return (
                            <div className={"content" + (item.location ? " complete" : "")}>
                                {item.productCode && <div><b>Product code:</b> {item.productCode}</div>}
                                {item.productBarcode && <div><b>Product Barcode:</b> {item.productBarcode}</div>}
                                {item.code && <div><b>Code:</b> {item.code}</div>}
                                {item.description && <div><b>Desc:</b> {item.description}</div>}
                                <div>
                                    <b>Quantity:</b> {item.quantity}
                                </div>
                                {item.location ? <div className="green-tick" /> : null}
                            </div>
                        )
                    }
                }
            },
            {
                // 4 - Standardise data from previous stages into one object.
                component: Action,
                config: {
                    action: ({ finish }) => {
                        // console.log("Standardise: ", this.stageData(1));
                        if (this.stageData(1).stockMatch) {
                            // If a stock item was selected in stage 1, use that data.
                            finish(this.stageData(1));
                        } else {
                            // Otherwise, stitch the delivery found, and the stock selected
                            // together into one object.
                            finish({
                                ...this.stageData(3),
                                delivery: this.stageData(1)
                            });
                        }
                    }
                }
            },
            {
                // 5 - Check item quantity what kind of match was made (code or barcode)
                component: Action,
                config: {
                    action: ({ changeStage }) => {
                        let data = this.stageData(4);
                        if (data.match === "barcode" && data.stock.quantity > 1) {
                            // barcode match, present option to select how many to unload and split the original item.
                            changeStage(6);
                        } else {
                            // code match or quantity = 1, proceed as normal
                            changeStage(7);
                        }
                    }
                }
            },
            {
                // 6 - Select the quantity you want to split
                component: Custom,
                config: {
                    state: {
                        quantity: 1
                    },
                    render: stage => {
                        return (
                            <div className="selectNumberStage">
                                <p><b>Confirm number of items being unloaded</b></p>
                                <NumberField
                                    min={1}
                                    max={this.stageData(4).stock.quantity}
                                    change={(varname, value, error) => {
                                        stage.setState({ quantity: value });
                                    }}
                                >
                                    {stage.state.quantity}
                                </NumberField>
                                <button
                                    className="next-stage-btn custom-bg-feature1"
                                    onClick={() => {
                                        stage.stageComplete(stage.state.quantity);
                                    }}
                                >
                                    <p>Confirm Quantity</p>
                                    <div className="icon-white arrow"></div>
                                </button>
                            </div>
                        )
                    }    
                }
            },
            {
                // 7 - Select location.
                component: SelectLocation,
                config: {
                    defaultValue: () => (window.cookies.lastDeliveryLocation || null),
                    stockItem: () => this.stageData(4),
                    quantity: () => { 
                        if (typeof this.stageData(6) === "number") return this.stageData(6);
                        return null;  
                    }
                }
            },
            {
                // 8 - Check if selected location is remedial
                component: Action,
                config: {
                    action: ({ changeStage }) => {
                        // If the location is remedial..
                        if (/^rem/i.test(this.stageData(5).locator)) {
                            changeStage(9);
                        } else {
                            changeStage(10);
                        }
                    }
                }
            },
            {
                // 9 - Selected location is remedial, add image(s) and description to stock item
                component: RemedialData,
                config: {
                    stockItem: () => this.stageData(4)
                }
            },
            {
                // 10 - Action, make request and see if all done.
                component: Action,
                config: {
                    action: ({ finish, error, changeStage }) => {
                        // Check what type of item we are unloading
                        let searchBy = this.stageData(4).match === "code" ? "code" : "productBarcode";
                        // Get stock position.
                        let stockPosition = this.stageData(4).delivery.stock
                            .map(s => s[searchBy])
                            .indexOf(this.stageData(4).stock[searchBy]);
                        // Creating data object
                        let data = { location: this.stageData(7).locator };
                        // Optionally adding quantity if exists
                        if (typeof this.stageData(6) === "number") data.quantity = this.stageData(6);
                        // Add images and description if unloaded to remedial location
                        if (this.stageData(9)) {
                            data.images = this.stageData(9).images;
                            data.description = this.stageData(9).description;
                        }
                        // Make the call.
                        $.ajax({
                            type: "PUT",
                            url: `${this.context.apiRoot}/deliveries/${this.stageData(4).delivery.id}/stock/${stockPosition}`,
                            data: JSON.stringify(data)
                        }).then(response => {
                            // Place the newly created delivered stock item into the delivery.
                            this.stageData(4).delivery.stock[stockPosition] = response.stock;
                            // Setting the value of the cookie for last used location
                            window.setCookie("lastDeliveryLocation", this.stageData(7).locator);
                            // Check if the delivery has items left.
                            if (this.stageData(4).delivery.stock.filter(s => !s.id).length) {
                                // If so, set the delivery selection data, and clear the rest.
                                this.setStageData(1, this.stageData(4).delivery, () => {
                                    this.clearStageData([3, 4, 6, 7], () => {
                                        // And go to the pick another stage.
                                        changeStage(3);
                                    });
                                });
                            } else {
                                // Otherwise, we're all done, go to final success stage.
                                changeStage(11);
                            }
                        }).catch(err => {
                            console.error("Couldn't unload delivery item: ", err);
                            error("Error unloading delivery item.");
                            setTimeout(() => {
                                changeStage(1);
                            }, 3000);
                        });
                    }
                }
            },
            {
                // 11 - Success, all done.
                component: Success,
                config: {
                    message: () => <div>
                        Success! Delivery completely Unloaded.
                    </div>
                }
            }
        ]
    }

    msToDate(ms) {
        const date = new Date(ms);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    getItems(cb) {
        let date = new Date().getTime();
        //15 days before
        let from = date - (86400000 * 5);
        //15 days after
        let to = date + (86400000 * 5);

        $.get(`${this.context.apiRoot}/deliveries?from=${from}&to=${to}`).done(response => {
            //Not showing deliveries with status === delivered
            let items = response.results.filter(result => !["delivered", "cancelled"].includes(result.status))
            if (cb) cb(null, items);
        }).catch(err => {
            if (cb) cb(err);
        })
    }

}

export default UnloadDelivery;