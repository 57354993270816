import View from "../view";
import UiPanel from "../../assets/ui-panel";
import CustomersList from "./customersList";
import StaggeredSearchField from "../../fields/staggeredSearchField";
import { Aux, renderDate } from "../../helpers";

class CustomerView extends View {
    constructor(props) {
        super(props);
        this.pageSize = 20;
        this.state = {
            customers: [],
            page: 1,
            pageCount: 1,
            filters: {
                term: ""
            }
        }

        this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);

        this.customersListColumns = [
            {
                name: "Name",
                renderer: item => item.displayName,
                sorter: item => item.displayName
            },
            {
                name: "Created",
                renderer: item => {
                    let date = new Date(item.created);
                    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
                },
                sorter: item => item.created
            }
        ]
    }

    componentDidMount() {
        //Getting the list of customers on mount
        this.getCustomers({
            count: this.pageSize,
            offset: (this.state.page - 1) * this.pageSize
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            this.getCustomers({
                count: this.pageSize,
                offset: (this.state.page - 1) * this.pageSize
            })
        }
    }

    createQueryString(obj) {
        let pairs = []
        Object.keys(obj).forEach(name => {
            if (obj[name]) pairs.push(`${name}=${obj[name]}`);
        })
        return pairs.length ? ("?" + pairs.join("&")) : "";
    }

    getCustomers(filters) {
        //Combine passed filters with state.filters
        filters = Object.assign(this.state.filters, filters) || this.state.filters;
        if (this.props.user.role === 1 || this.props.user.role === 4) {
            //Get customers only if user is authorized
            $.get(`${this.props.apiRoot}/customers${this.createQueryString(filters)}`).done(response => {
                this.setState({
                    customers: response.results,
                    pageCount: Math.ceil(response.total / this.pageSize)
                })
            }).catch(err => {
                window.logger.error(err)
            })
        }
    }

    renderView() {
        return (
            <Aux>
                <div className="row heading-row">
                    <div className="col-12">
                        <h1 className="client-view custom-c-dark">{`${this.client} Customers`}</h1>
                        <h5 className="date custom-c-mid">{renderDate()}</h5>
                    </div>
                </div>
                <div className="row view customersView">
                    <div className="col-12 panel-wrapper">
                        <StaggeredSearchField
                            label="Customer search"
                            delay={250}
                            change={(varname, value, error) => {
                                let filters = Object.assign({}, this.state.filters);
                                filters.term = value
                                this.setState({ filters, page: 1 })
                                this.getCustomers(filters)
                            }}
                        >
                            {this.state.filters.term || ""}
                        </StaggeredSearchField>
                        <hr/>
                        <UiPanel
                            name="Customers"
                            icon="customers"
                        >
                            <CustomersList
                                apiRoot={this.props.apiRoot}
                                numberOfPages={this.state.pageCount}
                                page={this.state.page}
                                icon="customers"
                                user={this.props.user}
                                uniqueField="name"
                                columns={this.customersListColumns}
                                items={this.state.customers}
                                onPageChange={page => {
                                    this.setState({ page });
                                }}
                                onNew={(item) => {
                                    // api call to POST
                                    $.post(`${this.props.apiRoot}/customers`, JSON.stringify(item)).done(response => {
                                        // receive new customer
                                        let customers = [response.customer, ...this.state.customers];
                                        //Updating state
                                        this.setState({ customers })
                                        logger.success(response.msg)
                                    }).catch(err => {
                                        window.logger.error(err)
                                    })
                                }}
                                onUpdate={(item, name) => {
                                    $.ajax({
                                        type: "PUT",
                                        url: `${this.props.apiRoot}/customers/${name}`,
                                        data: JSON.stringify(item)
                                    }).done(response => {
                                        // receive updated customer
                                        let customers = [...this.state.customers].map(c => {
                                            if (c.name === response.customer.name) {
                                                return response.customer
                                            } else {
                                                return c
                                            }
                                        });
                                        //Updating state
                                        this.setState({ customers })
                                        logger.success(response.msg)
                                    }).catch(err => {
                                        window.logger.error(err)
                                    })
                                }}
                                onDelete={(name) => {
                                    $.ajax({
                                        type: "DELETE",
                                        url: `${this.props.apiRoot}/customers/${name}`,
                                    }).done(response => {
                                        //Removing the item from the array
                                        let customers = [...this.state.customers].filter(c => {
                                            return c.name !== name
                                        })
                                        //Updating state
                                        this.setState({ customers })
                                        logger.success(response.msg)
                                    }).catch(err => {
                                        window.logger.error(err)
                                    })
                                }}
                            />
                        </UiPanel>
                    </div>
                </div>
            </Aux>
        )
    }
}

export default CustomerView;