import Field from "./field";

class DateRangeField extends Field {

    constructor(props) {
        super(props);
        let children = this.props.children || {};
        this.state.from = children.from ? new Date(children.from) : "";
        this.state.to = children.to ? new Date(children.to) : "";
        this.settings = {
            type: "dateRange"
        }
    }

    makeTwoDigit(val) {
        if (!val) return "";
        return (val + "").length === 2 ? (val + "") : ("0" + val);
    }

    parseDate(d = new Date()) {
        return `${d.getFullYear()}-${this.makeTwoDigit(d.getMonth() + 1)}-${this.makeTwoDigit(d.getDate())}`;
    }

    serializeOutput(from, to) {
        // Returns an object of MS values from the start and end of days.
        return {
            from: new Date(`${this.parseDate(from)} 00:00`).getTime(),
            to: new Date(`${this.parseDate(to)} 23:59:59`).getTime()
        }
    }

    handleDateChange(from, to) {
        // If there are both values, and 'from' is less than 'to', we're all good.
        if (from && to && from <= to) {
            this.change(this.serializeOutput(from, to));
        } else {
            this.change(null);
        }
    }

    renderField() {
        return <div className="field-wrapper">
            <div className="inputWrapper">
                <input
                    className="custom-border-3"
                    type="date"
                    value={this.state.from
                        ? this.parseDate(this.state.from) : ""}
                    onChange={e => {
                        let newDate = e.target.value ? new Date(e.target.value) : "";
                        this.handleDateChange(newDate, this.state.to);
                        if (newDate && newDate > this.state.to) {
                            this.setState({
                                from: newDate || "",
                                to: ""
                            });
                        } else {
                            this.setState({
                                from: newDate || ""
                            });
                        }
                    }}
                />
            </div>
            <span>-</span>
            <div className="inputWrapper">
                <input
                    className="custom-border-3"
                    type="date"
                    value={this.state.to
                        ? this.parseDate(this.state.to) : ""}
                    onChange={e => {
                        let newDate = e.target.value ? new Date(e.target.value) : "";
                        this.handleDateChange(this.state.from, newDate);
                        if (newDate && newDate < this.state.from) {
                            this.setState({
                                to: newDate || "",
                                from: ""
                            });
                        } else {
                            this.setState({
                                to: newDate || ""
                            });
                        }
                    }}
                />
            </div>
        </div>
    }

}

export default DateRangeField;