/**
 *  PROPS:
 * 
 *      item: OBJECT (for editing existing one) or NULL (for creating a new one)
 *      itemCreated: FUNCTION(item) called when creating new item
 *      itemUpdated: FUNCTION(item) called when updating an existing item
 *      closeCreator: FUNCTION
 *      uniqueField: STRING 
 */

class ItemCreator extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            item: {},
            changes: {}
        }
    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({ item: this.props.item })
        }
    }

    itemChangedOLD(varname, value, err) {
        let item = Object.assign({}, this.state.item);
        item[varname] = value;
        this.setState({ item });
        // Handling changes for existing items
        if (this.props.item) {
            let changes = Object.assign({}, this.state.changes);
            changes[varname] = value
            this.setState({ changes })
        }
    }

    removeEmptyStrings(object) {
        let item = {}
        // Loop through, and remove empty strings.
        Object.keys(object).forEach(key => {
            if (object[key] !== "") item[key] = object[key];
        });
        return item;
    }

    itemChanged(varname, value, err) {
        //Make sure emails will be lowercase.
        if (varname === "email") {
            value = value ? value.toLowerCase() : value;
        }
        // Create item to be sent.
        let item = this.removeEmptyStrings(Object.assign({}, this.state.item, {
            [varname]: value
        }));
        // And set state.
        this.setState({ item }, () => {
            //Handling changes for existing items
            if (this.props.item && value !== "") {
                let changes = Object.assign({}, this.state.changes);
                changes[varname] = value;
                this.setState({ changes });
            }
        });
    }

    canSubmit() {
        return 'This method should return a boolean value'
    }

    submit(item) {
        if (this.props.item) {
            //If editing an existing item
            if (this.props.itemUpdated) {
                //if update item handler has been passed
                this.props.itemUpdated(item, this.props.item[this.props.uniqueField])
            }
        } else {
            //creating a new one
            if (this.props.itemCreated) {
                //if create item handler has been passed
                this.props.itemCreated(item)
            }
        }
    }

    renderCreator() {
        return `Creates fields for editing/creating an item`
    }

    render() {
        return (
            <div className="item-creator custom-border-color">
                {this.props.closeCreator ? <div
                    className="icon close-icon"
                    onClick={() => {
                        this.props.closeCreator()
                    }}
                /> : null}
                {this.renderCreator()}
                <div
                    className={"button create-item-button custom-bg-feature2" + (this.canSubmit() ? "" : " disabled")}
                    onClick={() => {
                        if (this.canSubmit()) {
                            //Sending the whole item or just the changes(creating/editing)
                            let item = this.props.item ? this.state.changes : this.state.item;
                            this.submit(item);
                        }
                    }}
                >
                    Save
                </div>
            </div>
        )
    }
}

export default ItemCreator;