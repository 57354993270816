import SuperList from "../../assets/ui-super-list";
import UserCreator from "./userCreator";

class UsersList extends SuperList {

    renderControls(item) {
        return (this.props.controls || [
            {
                icon: this.props.user.data.theme === "dark" ? "pen-white.svg " : "pen.svg",
                label: "edit",
                callback: () => {
                    this.setState({ 
                        editing: item,
                        addingItem: false 
                    })
                },
            },
            {
                icon: this.props.user.data.theme === "dark" ? "delete-bright.svg" : "delete.svg",
                label: "delete",
                callback: this.props.onDelete ? (item) => {
                    if (window.confirm("Are you sure you want to delete this user?")) {
                        this.props.onDelete(item[this.props.uniqueField])
                    }    
                } : null
            }
        ])
            .filter(c => c.callback ? true : false)
            .map(control => {
                return <div
                    title={control.label}
                    key={control.label}
                    className="control"
                    style={{ backgroundImage: `url("/static/media/${control.icon}")` }}
                    onClick={e => { control.callback(item) }}
                ></div>
            })
    }
    
    renderItemCreator() {
        return (
            <UserCreator 
                uniqueField={this.props.uniqueField}
                item={this.state.editing}
                user={this.props.user}
                itemCreated={item => {
                    this.props.onNew(item, () => {
                        this.setState({ addingItem: false })                 
                    })
                }}
                itemUpdated={(item, id) => {
                    this.props.onUpdate(item, id, () => {
                        this.setState({ editing: null })
                    });
                }}
                closeCreator={() => {
                    if (this.state.editing) {
                        //if edditing an item
                        this.setState({ editing: null })
                    } else {
                        //or creating new item
                        this.setState({ addingItem: false })
                    }
                }}
            />
        )
    }
}

export default UsersList;