import Context from "../context";
import Spinner from "./spinner";

class UiPanelItemWo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            customer: null,
            loading: false,
            hovered: false,
        },
            this.getCusTimeout = null
    }

    getCustomer(name) {
        // Stop if already have customer.
        if (this.state.customer) return null;
        // Show loading animation
        this.setState({ loading: true });
        $.get(`${this.context.apiRoot}/customers/${name}`).then(res => {
            this.setState({ customer: res.customer })
        }).catch(err => {
            logger.error(err);
        }).always(() => {
            // Request finished
            this.setState({ loading: false })
        })
    }


    render() {
        return (
            <div
                onMouseEnter={() => {
                    this.setState({ hovered: true });
                    this.getCusTimeout = setTimeout(() => {
                        this.getCustomer(this.props.item.data.customer)
                    }, 500)
                }}
                onMouseLeave={() => {
                    this.setState({ hovered: false });
                    if (this.getCusTimeout) clearTimeout(this.getCusTimeout);
                }}
            >
                <a
                    href={`/${this.props.link}/${this.props.item.ref}`}
                    target="_blank"
                    className={"statusItem custom-bg-light list-hover" + ((this.props.item.data && this.props.item.data.status) === "cancelled" ? " cancelled" : "")}
                >
                    <div
                        className={"icon-wrap " + this.props.bgColor}
                    >
                        <div className={`icon-white ${this.props.icon}`}></div>
                    </div>
                    <div className="text custom-c-dark">{this.props.item.title}</div>
                    {this.props.subtextLength
                        ?
                        <div className="subtext custom-c-dark custom-border-color">
                            {this.props.subtextLength > 1 ? `${this.props.subtextLength}x` : this.props.item.subtext}
                        </div>
                        :
                        null
                    }
                </a>
                <div
                    className={"customer-address custom-bg-dark shadow-1" + ((this.state.customer && this.state.hovered) ? " show" : "") + (this.props.row >= 3 ? " bottomRow" : " topRow")}
                >
                    {this.state.loading
                        ?
                        <Spinner color="#1e43a9" />
                        :
                        <div 
                            className="custom-c-dark" 
                            style={{ whiteSpace: "pre-wrap", textAlign: "left", fontSize: "0.9rem" }}
                        >
                            {(this.state.customer && this.state.customer.defaultAddress)
                                ?
                                this.state.customer.defaultAddress
                                :
                                "No address set."
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

UiPanelItemWo.contextType = Context;

export default UiPanelItemWo;