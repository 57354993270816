import UiCalendarPanel from "./ui-calendar-panel";
import UiCalendarList from "./ui-calendar-list";

/**
 *  PROPS
 * 
 *      currentDate: MS integer
 *      classes: STRING (css class)
 *      daysArray: ARRAY of day name strings
 *      monthsArray: ARRAY of month name STRINGS
 *      eventHandler: FUNCTION(event, val)
 *      viewingDate: MS integer
 *      selectedDay: NULL or OBJECT {}
 *      showWeekend: BOOLEAN
 *      events: ARRAY OF EVENTS
 *      
 */

class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showWeekend: false,
            viewingDate: this.props.currentDate
        }
    }

    getPanelTitle(day) {
        //Converts a ms valuse into a dayName dayNumber format
        const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const date = new Date(day);
        const dayName = weekDays[date.getDay()];
        const dayNumber = date.getDate();
        return `${dayName} ${dayNumber}`
    }

    renderDayNames() {
        return (
            <div className="dayNames overflow-row">
                {this.props.daysArray.map(day => {
                    return <div key={day}>{day}</div>
                })}
            </div>
        )
    }

    formatDate(msDate) {
        let d = new Date(msDate);
        return `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`;
    }

    getEventsForDay(day, events) {
        let formattedDay = this.formatDate(day);
        return events.filter(event => formattedDay === this.formatDate(event.date));
    }

    getDays() {

        let days = []
        const date = new Date(this.props.viewingDate);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;

        //Passing 0 as the day will return the last day of the month.
        const dayCount = new Date(year, month, 0).getDate();

        const firstDay = new Date(`${year}/${month}/${1}`);
        const lastDay = new Date(`${year}/${month}/${dayCount}`);

        let weekdayOffset = firstDay.getDay() - 1;
        if (weekdayOffset < 0) {
            weekdayOffset = 6
        }

        let lastDayOffset = lastDay.getDay() - 1;
        if (lastDayOffset < 0) {
            lastDayOffset = 6
        }

        //Getting number of days in the month before
        let prevMonth = month - 1;
        let prevYear = year;
        if (prevMonth === 0) {
            prevMonth = 12
            prevYear -= 1
        }
        let daysInMonthBefore = new Date(prevYear, prevMonth, 0).getDate();


        //Setting correct value for next month and year
        let nextMonth = month + 1;
        let nextYear = year;
        if (nextMonth > 12) {
            nextMonth = 1;
            nextYear += 1;
        }

        //First we add days from the month before.
        for (let i = daysInMonthBefore - weekdayOffset + 1; i <= daysInMonthBefore; i++) {
            let date = new Date(`${prevYear}/${prevMonth}/${i}`).getTime();
            days.push({
                date,
                events: this.getEventsForDay(date, this.props.events),
                currentMonth: false
            })
        }

        //Then the days in current month
        for (let i = 1; i <= dayCount; i++) {
            let date = new Date(`${year}/${month}/${i}`).getTime();
            days.push({
                date,
                events: this.getEventsForDay(date, this.props.events),
                currentMonth: true
            })
        }

        //And the days for next month to fill in remaining calendar slots
        for (let i = 1; i <= 6 - lastDayOffset; i++) {
            let date = new Date(`${nextYear}/${nextMonth}/${i}`).getTime();
            days.push({
                date,
                events: this.getEventsForDay(date, this.props.events),
                currentMonth: false
            })
        }

        //Returning the days array
        return days;
    }

    checkIfToday(day) {
        let today = new Date();
        let checkedDay = new Date(day);
        if (
            (today.getFullYear() === checkedDay.getFullYear()) &&
            (today.getMonth() === checkedDay.getMonth()) &&
            (today.getDate() === checkedDay.getDate())
        ) {
            return true
        }
        return false;
    }

    checkIfSelected(day) {
        if (this.props.selectedDay === null) {
            return false
        } else {
            let selected = new Date(this.props.selectedDay.date);
            let checkedDay = new Date(day);
            if ((selected.getFullYear() === checkedDay.getFullYear()) && (selected.getMonth() === checkedDay.getMonth()) && (selected.getDate() === checkedDay.getDate())) {
                return true
            } else {
                return false
            }
        }
    }

    checkIfWarning(items) {
        //Result is false by default
        let result = false;

        //Result is true if any item's status property === "warning"
        items.forEach(item => {
            if (item.status === "warning") {
                result = true;
            }
        });

        //Return result
        return result;
    }

    renderDay(day, rowNumber) {
        let classes = ["custom-border-color"];
        if (this.checkIfToday(day.date)) {
            classes.push("today")
        }
        if (this.checkIfSelected(day.date)) {
            classes.push("current")
        }

        // Grouping deliveries with deliveryGroup as a single event
        let events = [];

        day.events.forEach(event => {

            if (!event.data.deliveryGroup) return events.push(event);

            let parentGroup = events.find(e => e.data.deliveryGroup === event.data.deliveryGroup);
            if (parentGroup) {
                // console.log("Found: ", parentGroup);
                parentGroup.events.push(event);
            } else {
                events.push({
                    type: "deliveryGroup",
                    date: event.date,
                    data: {
                        deliveryGroup: event.data.deliveryGroup
                    },
                    title: `DG - ${event.data.deliveryGroup}`,
                    events: [event]
                })
            }
        });

        return (
            <div
                className={day.currentMonth ? "day" : "day faded"}
                key={day.currentMonth ? ("c_" + day.date) : ("n_" + day.date)}
            >
                <UiCalendarDayPanel
                    classes={classes.join(" ")}
                    title={this.getPanelTitle(day.date)}
                    warning={this.checkIfWarning(day.events)}
                    onSelect={() => {
                        this.props.eventHandler("select-day", day);
                    }}
                >
                    <UiCalendarList
                        row={rowNumber}
                        listItems={events}
                        eventHandler={this.props.eventHandler}
                    />
                </UiCalendarDayPanel>
            </div>
        )
    }

    renderDayRows() {
        let rows = [];
        const days = this.getDays();
        const numberOfRows = days.length / 7;
        let month = new Date(this.props.viewingDate).getMonth();
        for (var i = 0; i < numberOfRows; i++) {
            let rowDays = days.slice(i * 7, i * 7 + 7);
            rows.push(
                <div
                    className="days overflow-row"
                    key={`${i}-${month}-row`}
                // delay={i * 100}
                // enterAnimation="elevator"
                // leaveAnimation="fade"
                >
                    {rowDays.map(day => (
                        this.renderDay(day, i)
                    ))}
                </div>
            )
        }

        return rows;
    }

    render() {
        return (
            <UiCalendarPanel
                showWeekend={this.props.showWeekend}
                classes={this.props.classes}
                name={(() => {
                    let date = new Date(this.props.viewingDate);
                    return `${this.props.monthsArray[date.getMonth()]} ${date.getFullYear()}`
                })()}
                icon="calendar"
                clickHandler={this.props.eventHandler}
            >
                <div className={this.props.showWeekend ? "overflow-wrapper weekend" : "overflow-wrapper"}>
                    {this.renderDayNames()}
                    {this.renderDayRows()}
                </div>
            </UiCalendarPanel>
        )
    }
}

const UiCalendarDayPanel = (props) => {
    return (
        <div className={"panel status-panel item-selectable-1 custom-bg-dark custom-c-light " + (props.classes ? props.classes : "")}>
            <div className={props.warning ? "top-border custom-bg-feature3" : "top-border custom-bg-feature1"}></div>
            <div
                className="heading"
                onClick={e => {
                    props.onSelect();
                }}
            >
                {props.title}
            </div>
            {props.children}
        </div>
    )
}

export default Calendar;