// import Stage from "./stage";
import SelectorStage from "./selectorStage";

class SelectLocation extends SelectorStage {
    constructor(props) {
        super(props);
        this.state.stock = [];
    }

    getItem(locator) {
        return new Promise((resolve, reject) => {
            $.get(`${this.context.apiRoot}/locations?locator=${locator}`).then(response => {
                if (!response.total) return reject("No location found.");
                if (response.results.length > 1) return reject("Multiple items matching locator.");
                // if (response.results[0].quantity < 2) return reject("Multiple items matching code.");
                resolve(response.results[0]);
            }).catch(err => {
                reject(err.responseJSON.msg);
            });
        });
    }

}

SelectLocation.defaultProps = {
    title: "Select location.",
    icon: "location"
}

export default SelectLocation;