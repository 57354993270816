import { NavLink, Route, Switch } from 'react-router-dom';

const MobileNavigation = (props) => (

    <div className="mobile-navigation custom-bg-dark">
        <div className="page-name mobile-view-name-bg-c">
            <Switch>
                <Route
                    path="/reports"
                    render={() => "Reports"} />
                <Route
                    path="/metrics"
                    render={() => "Metrics"} />
                <Route
                    path="/settings"
                    render={() => "Settings"} />
                <Route
                    path="/users"
                    render={() => "Users"} />
                <Route
                    path="/customers"
                    render={() => "Customers"} />
                <Route
                    path="/locations"
                    render={() => "Locations"} />
                <Route
                    path="/schedule"
                    render={() => "Schedule"} />
                <Route
                    path="/stock"
                    render={() => "Stock"} />
                <Route
                    path="/processes"
                    render={() => "Processes"} />
                <Route
                    path="/"
                    render={() => "Dashboard"} />
            </Switch>
        </div>
        <div className="nav">
            <ul>
                {props.links.map(link => (
                    <li className="nav-item custom-border-color" key={link.name}>
                        <NavLink activeClassName="current" to={link.to} className="custom-bg-dark custom-c-dark hover-class">
                            <div className={`icon ${link.icon}-icon`}></div>
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    </div>

)

export default MobileNavigation;