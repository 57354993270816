import FlipMove from "react-flip-move";
import Process from "./index";
import ScanInput from "../../../fields/scanInput";
// Stages.
import ListStage from "../stages/listStage";
import SelectLocation from "../stages/selectLocation";
import Action from "../stages/action";
import Success from "../stages/success";
import Custom from "../stages/custom";
//Other components
import CheckboxField from "../../../fields/checkboxField";
import FileField from "../../../fields/fileField";

class LoadWorkOrder extends Process {
    constructor(props) {
        super(props);
        this.state.stage = 0;
        this.state.showUnscannedOnly = false
        this.stages = [
            {
                // 0 - Load workOrders.
                component: Action,
                config: {
                    action: ({ finish, error, exit }) => {
                        this.getItems((err, items) => {
                            if (!err) {
                                finish(items);
                            } else {
                                console.error("Couldn't get work orders: ", err);
                                error("Error getting work orders");
                                setTimeout(() => {
                                    exit();
                                }, 3000);
                            }
                        });
                    }
                }
            },
            {
                // 1 - Showing list of work orders
                component: ListStage,
                config: {
                    icon: "document",
                    title: "Select Work Order",
                    items: () => this.stageData(0),
                    renderItem: item => {
                        return (
                            <div className="content">
                                <p className="customer">{item.customer}</p>
                                <p className="status">status: {item.status}</p>
                                <div className="date-time">
                                    <p className="date">{this.msToDate(item.dateOutgoing)}</p>
                                    <p className="time">{`At ${item.timeOutgoing}`}</p>
                                </div>
                                <p className="stock">{`stock quantity: ${item.stock.length}`}</p>
                                {item.vehicle
                                    ?
                                    <p className="vehicle">{`Vehicle: ${[item.vehicle].concat(item.vehicles || []).join(", ")}`}</p>
                                    :
                                    null
                                }
                                {item.notes
                                    ?
                                    <div className="notes-wrapper">
                                        <hr />
                                        <p className="notes">{item.notes}</p>
                                    </div>
                                    :
                                    null
                                }
                                <hr />
                                <p className="id">{item.id}</p>
                            </div>
                        )
                    }
                }
            },
            {
                // 2 - Getting full stock items object for selected work order
                component: Action,
                config: {
                    action: ({ changeStage, finish, error, exit }) => {
                        this.getFullStock(this.stageData(1).stock, (err, items) => {
                            if (!err) {
                                let finished = true;
                                items.forEach(item => {
                                    if (item.status !== "loaded") finished = false
                                })
                                if (finished) {
                                    changeStage(7);
                                } else {
                                    finish(items);
                                }
                            } else {
                                console.error("Couldn't get full stock data:", err);
                                error("Error getting work orders stock");
                                setTimeout(() => {
                                    exit();
                                }, 3000);
                            }
                        })
                    }
                }
            },
            {
                // 3 - Showing list of stock for selected work order
                component: ListStage,
                config: {
                    icon: "stock",
                    title: "Scan Stock Item",
                    sort: items => {
                        return items.sort((a,b) => {
                            let aLocation = a.location;
                            let bLocation = b.location;
                            if (aLocation < bLocation) return -1;
                            if (aLocation > bLocation) return 1;
                            return 0;
                        })
                    },
                    state: {
                        inputValue: ""
                    },
                    before: stage => {
                        // Getting total numbers for stock and loaded stock
                        let total = this.stageData(1).stock.length;
                        let scanned = this.stageData(1).stock.filter(s => s.status === "loaded").length;
                        // Render scanned stock toggle, total numbers of stock scanned and stock selector
                        return (
                            <div>
                                <div className="totalItems">
                                    {`[${scanned}/${total}]`}
                                </div>
                                <div className="toggle">
                                    <CheckboxField
                                        label="Show unscanned only"
                                        change={(vn, val, err) => {
                                            this.setState({ showUnscannedOnly: val })
                                        }}
                                    >
                                        {this.state.showUnscannedOnly}
                                    </CheckboxField>
                                </div>
                                <ScanInput
                                    focusOnMount={true}
                                    onScan={code => {
                                        let match = this.stageData(2).filter(s => s.code === code)[0];
                                        if (match) {
                                            if (match.status && match.status === "loaded") {
                                                stage.error("Item already loaded.")
                                            } else {
                                                stage.stageComplete(match)
                                            }
                                        } else {
                                            stage.error("No match found for that bar code.")
                                        }
                                    }}
                                />
                            </div>
                        )
                    },
                    items: () => {
                        if (this.state.showUnscannedOnly) {
                            return this.stageData(2).filter(s => (!s.status || s.status !== "loaded"))
                        }
                        return this.stageData(2)
                    },
                    disable: item => true,
                    renderItem: item => {
                        return (
                            <div className="content">
                                <p className="code"><span>Product code:</span>{item.productCode}</p>
                                <p className="code"><span>Barcode:</span><br />{item.code}</p>
                                <p className="group"><span>Group:</span>{item.group}</p>
                                <p className="location"><span>Loc:</span>{item.location}</p>
                                <p className="destination"><span>Dest:</span>{item.destination}</p>
                                <hr />
                                <p className="description"><span>desc:</span>{item.description}</p>
                                {item.status && item.status === "loaded"
                                    ?
                                    <div className="green-tick"></div>
                                    :
                                    null
                                }
                            </div>
                        )
                    }
                }
            },
            {
                // 4 - Select location
                component: SelectLocation,
                config: {
                    // defaultValue: () => (window.cookies.lastLoadLocation || null)
					defaultValue: () => null
                }
            },
            {
                // 5 - Action, make request and check if all done.
                component: Action,
                config: {
                    action: ({ finish, error, changeStage }) => {
                        // Check if the selected location matches any of the workOrder vehicles
                        if ((this.stageData(1).vehicle && this.stageData(4).locator === this.stageData(1).vehicle) 
                            || 
                            (this.stageData(1).vehicles && this.stageData(1).vehicles.filter(v => v === this.stageData(4).locator).length)) {
                                // Make then call to update work order stock
                                $.ajax({
                                    type: "PUT",
                                    url: `${this.context.apiRoot}/workorders/${this.stageData(1).id}/load/${this.stageData(3).id}`,
                                    data: JSON.stringify({ location: this.stageData(4).locator })
                                }).then(response => {
                                    // Check if work order was completed
                                    // Check if work order was completed.
                                    let finished = !Boolean(
                                        this.stageData(2)
                                            // Get list excluding current item.
                                            .filter(s => s.code !== this.stageData(3).code)
                                            // Get items not picked
                                            .filter(s => s.status !== "loaded")
                                            // If there are items not picked, we aren't finished.
                                            .length
                                    );
                                    // Immediately leave, because we're done.
                                    if (finished) return changeStage(7);
        
                                    // Now, update the the selected delivery object
                                    this.setStageData(1, response.workOrder, () => {
                                        // Setting the value of the cookie for last used location
                                        window.setCookie("lastLoadLocation", this.stageData(4).locator);
                                        // Now check for completion of current group.
                                        let groupFinished = !Boolean(this.stageData(2).filter(s =>
                                            s.code !== this.stageData(3).code &&
                                            s.group === this.stageData(3).group &&
                                            s.status !== "loaded"
                                        ).length);
        
                                        if (groupFinished) {
                                            // If group done, go to the group completion message. 
                                            changeStage(6);
                                        } else {
                                            // Otherwise, clear data and go back to the stock list. 
                                            this.clearStageData([3], () => {
                                                changeStage(2);
                                            });
                                        }
        
                                    });
                                }).catch(err => {
                                    console.error("Couldn't load the stock item", err);
                                    error("Error loading work order item.");
                                    setTimeout(() => {
                                        changeStage(1);
                                    }, 3000)
                                })
                        } else {
                            error("This vehicle is not attached to this work order");
                            setTimeout(() => {
                                changeStage(4);
                            }, 3000);
                        }
                    }
                }
            },
            {
                // 6 - Continue loading items
                component: Success,
                config: {
                    action: () => {
                        this.clearStageData([3], () => {
                            this.changeStage(2);
                        });
                    },
                    message: () => <div>
                        <span>Group <b>{this.stageData(3).group}</b> completed.</span>
                        <br />
                        <span>Click to scan remaining items.</span>
                    </div>
                }
            },
            {
                // 7 - Saving required images
                component: Custom,
                config: {
                    state: () => ({
                        files: (this.stageData(1).images || []).map(f => ({
                            url: f,
                            name: f,
                            type: "image"
                        }))
                    }),
                    render: stage => {
                        let workOrder = this.stageData(1);
                        let imagesRequired = 2 + (workOrder.vehicles.length * 2);
                        return (
                            <div className="workOrderStockFileStage">
                                <p>Please add 2 images for every vehicle</p>
                                <p style={{ fontWeight: "bold" }}>{`[${stage.state.files.length}/${imagesRequired}]`}</p>
                                <FileField
                                    varname="files"
                                    apiRoot={this.context.apiRoot}
                                    change={(varname, value, error) => {
                                        $.ajax({
                                            url: `${this.context.apiRoot}/workorders/${this.stageData(1).id}`,
                                            type: "PUT",
                                            data: JSON.stringify({
                                                images: value.map(file => file.url)
                                            })
                                        }).done(res => {
                                            stage.setState({ files: value });
                                        }).catch(err => {
                                            console.error(err);
                                            error("Error adding images");
                                        })
                                    }}
                                >
                                    {stage.state.files}
                                </FileField>
                                <FlipMove>
                                    {stage.state.files.length >= imagesRequired
                                        ?
                                        <button
                                            key="addImagesButton"
                                            className="add custom-bg-feature1"
                                            onClick={() => {
                                                // Creating data object
                                                let data = {
                                                    status: "loaded",
                                                    process: "Load work order"
                                                }
                                                stage.stageComplete(data)
                                            }}
                                        >
                                            Complete Work Order
                                        </button>
                                        :
                                        null
                                    }
                                </FlipMove>
                            </div>
                        )
                    }
                }
            },
            {
                // 8 - Adding images to the work order
                component: Action,
                config: {
                    action: ({ error, finish, changeStage }) => {
                        $.ajax({
                            url: `${this.context.apiRoot}/workorders/${this.stageData(1).id}`,
                            type: "PUT",
                            data: JSON.stringify(this.stageData(7))
                        }).done(res => {
                            finish()
                        }).catch(err => {
                            console.error(err);
                            error("Error while adding images");
                            setTimeout(() => {
                                changeStage(7)
                            }, 3000)
                        })
                    }
                }
            },
            {
                // 9 - Finished loading workOrder
                component: Success,
                config: {
                    message: () => <div>Success! Work order Loaded.</div>
                }
            }
        ]
    }

    msToDate(ms) {
        const date = new Date(ms);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    getFullStock(stock, cb) {
        // Takes an array of stock from previously selected work order
        let ids = stock.map(s => s.id)
        $.post(`${this.context.apiRoot}/stock/multi`, JSON.stringify(ids)).done(response => {
            // Combining work order stock with corresponding full stock object
            let items = stock.map(s => {
                let match = response.results.filter(res => res.id === s.id)[0]
                if (!match) return s;
                return Object.assign({}, match, s);
            })
            if (cb) cb(null, items);
        }).catch(err => {
            if (cb) cb(err)
        })
    }

    getItems(cb) {
        //Creating to and from dates
        let date = new Date().getTime();
        let from = date - (86400000 * 5);
        let to = date + (86400000 * 5);
        //Getting workOrders
        $.get(`${this.context.apiRoot}/workorders?from=${from}&to=${to}`).done(response => {
            // Only saving confirmed workorders
            let items = response.results.filter(result => ["confirmed", "picked"].includes(result.status));
            if (cb) cb(null, items);
        }).catch(err => {
            if (cb) cb(err)
        })
    }
}

export default LoadWorkOrder;