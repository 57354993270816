import React, { useState, useContext, useRef } from "react";
import axios from "axios";
import SelectField from "../../fields/selectField";
import Context from "../../context";
import Spinner from "../../assets/spinner";
import { errorResponse } from "../../../../sls/utils";

// const TEST_ITEMS = [
//     {
//         orderNumber: 2665,
//         address: "Ebay\nAttn: Paul Claridge\nThe Cottage\nChapel Hill\nOxfordshire. OX18 3NB\nBrize Norton\nTel:+44 1993 842268",
//         stock: [
//             {
//                 quantity: 10,
//                 productCode: "SHOPIFY1"
//             },
//             {
//                 quantity: 10,
//                 productCode: "SHOPIFY2"
//             },
//             {
//                 quantity: 10,
//                 productCode: "SHOPIFY3"
//             }
//         ],
//         result: true
//     },
//     {
//         orderNumber: 2667,
//         address: "Ebay\nAttn: Steve Neale\n17 School Lane\nHaslingfield\nCB23 1JL\nCambridge\nTel:+ 44 7929 754522",
//         stock: [
//             {
//                 quantity: 10,
//                 productCode: "SHOPIFY1"
//             },
//             {
//                 quantity: 10,
//                 productCode: "SHOPIFY2"
//             },
//             {
//                 quantity: 10,
//                 productCode: "SHOPIFY3"
//             }
//         ],
//         result: true
//     },
//     {
//         orderNumber: 2668,
//         address: "Amazon EU S.a.r.L (Peterborough - EUK5)\nUK Branch\nc/o PO Box 4558\n60 Holborn Viaduct\nLondon\nEC1A 2FD",
//         stock: [
//             {
//                 quantity: 10,
//                 productCode: "SHOPIFY1"
//             },
//             {
//                 quantity: 10,
//                 productCode: "SHOPIFY2"
//             },
//             {
//                 quantity: 10,
//                 productCode: "SHOPIFY3"
//             }
//         ],
//         result: true
//     }
// ]

export default function FromFileCreator({ onBack, dateOutgoing }) {


    const [collections, setCollections] = useState([]);

    return (
        <div className="from-file-creator">
            <div
                className="icon close-icon"
                onClick={onBack}
            />
            {collections.length
                ?
                <CollectionCreator
                    goBack={() => setCollections([])}
                    dateOutgoing={dateOutgoing}
                    collections={collections}
                />
                :
                <FileUploader
                    onSuccess={collections => setCollections(collections)}
                />
            }
        </div>
    )
}

function FileUploader({ onSuccess }) {

    const [option, setOption] = useState("nuttery-pdf");
    const [file, setFile] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const myContext = useContext(Context);

    function readFile(file) {
        // Promise for reading tye file
        let promise = new Promise((resolve, reject) => {
            if (file.size >= 40000000) {
                logger.error("Files are limited to 40MB.");
                return resolve(null);
            }
            // Construct some serialized file data.
            let ext;
            let fileData = {
                name: file.name,
                url: null,
                type: file.type,
                extension: (ext = /(\.[^\.]+)$/.exec(file.name)) ? ext[1] : ""
            };
            // Construct a file reader to get a buffer for the file.
            let reader = new FileReader();
            // Callback for when the file is read.
            reader.onload = readEvent => {
                fileData.buffer = readEvent.target.result;
                resolve(fileData);
            };
            // Start reading the file.
            reader.readAsArrayBuffer(file);
        });
        // Call promise to upload file
        promise.then(file => uploadFile(file));
    };

    function uploadFile(file) {
        $.post({
            url: `${myContext.apiRoot}/misc/file`,
            data: JSON.stringify({ extension: file.extension })
        }).done(response => {
            let url = response.url;
            if (!file.buffer) {
                logger.error("Cannot upload an empty file.");
            }
            let xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (this.readyState === 4) {
                    if (this.status === 200) {
                        // console.log("Success: ", response.fileName);
                        setFile({ ...file, fileName: response.fileName });
                    } else {
                        // Handle failed upload.
                        logger.error("Couldn't upload file " + file.name);
                        setFile(null);
                    }
                }
            };
            xhr.open("PUT", url);
            xhr.send(file.buffer);
        }).catch(err => {
            console.error("Error getting S3 signed url: ", err);
            logger.error("Error uploading pdf. Please try again.");
        })
    }

    return (
        <>
            <div>
                <SelectField
                    label="File format"
                    varname="option"
                    options={[
                        {
                            val: "nuttery-pdf",
                            display: "Nuttery PDF"
                        }
                    ]}
                    change={(varname, value, error) => {
                        // setMissingStock([]);
                        setOption(value);
                    }}
                >
                    {option}
                </SelectField>
                <div className="wrapper">
                    <label className="button custom-bg-feature1 file-btn">
                        {Boolean(file) ? "Change file" : "Select file"}
                        <input
                            key={file ? "file" : "nofile"}
                            type="file"
                            accept=".pdf"
                            onChange={e => {
                                e.preventDefault();
                                readFile(e.target.files[0]);

                            }}
                        />
                    </label>
                    {Boolean(file) && <div className="selected-file">
                        <div className="file-name">{file.name}</div>
                        <div
                            className="red-cross"
                            onClick={() => {
                                setFile(null);
                            }}
                        />
                    </div>}
                </div>
            </div>
            <br />
            <div
                className={"button custom-bg-feature1" + ((!file || submitting) ? " disabled" : "")}
                style={{ width: "100%", textAlign: "center" }}
                onClick={async () => {
                    // Prevent from sending bad requests.
                    if (!file || !file.fileName || submitting) return;
                    // Block request spamming
                    if (!submitting) setSubmitting(true);
                    // We need to read the content of the file, and turn it into base64 for sending.
                    // Send the file
                    axios.post(`${myContext.apiRoot}/collections/from-file/${option}`, { fileName: file.fileName }, {
                        headers: {
                            Authorization: myContext.token
                        }
                    }).then(res => res.data).then(res => {
                        // Saving collections in state
                        onSuccess(res.collections);
                    }).catch(err => {
                        console.error("Error parsing file: ", err);
                        logger.error("Error parsing file. Please try again");
                        setSubmitting(false);
                    })
                }}
            >
                {submitting ? <Spinner size={24} /> : "Submit file"}
            </div>
        </>
    )
}

function CollectionCreator({ collections = [], goBack = () => null, dateOutgoing = Date.now() }) {

    const myContext = useContext(Context);
    const [creating, setCreating] = useState(false);
    const statuses = useRef({});
    const [statusesState, setStatusesState] = useState({});

    const collectionsToCreate = collections
        .filter(collection => collection.result)
        .map(collection => ({ ...collection, dateOutgoing }));

    const canCreate = Boolean(collectionsToCreate.filter(collection => !statusesState[collection.orderNumber]).length);

    function statusUpdate(status, orderNumber) {
        const newStatuses = {
            ...statuses.current,
            [orderNumber]: status
        }
        statuses.current = newStatuses;
        setStatusesState(newStatuses);
    }

    return (
        <>
            <div className="collections-row">
                {collections.map(collection => {
                    const collectionStatus = statusesState[collection.orderNumber];
                    const classes = [
                        "collection-item",
                        collection.result ? "good" : "bad",
                        collectionStatus
                    ];
                    return (
                        <div
                            key={collection.orderNumber}
                            className={classes.join(" ")}
                        >
                            <div>Order number: <b>{collection.orderNumber}</b></div>
                            <div>Stock count: <b>{collection.stock.length}</b></div>
                            <div style={{ whiteSpace: "pre-wrap" }}>{collection.address}</div>
                            <div className="create-status">
                                {(collectionStatus === "in-progress") && <Spinner size={45} />}
                                {(collectionStatus === "success") && <div className="green-tick" />}
                                {(collectionStatus === "failed") && <div className="red-cross" />}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="button-row">
                <button
                    className={"button custom-bg-feature1 create-btn" + ((creating || !canCreate) ? " disabled" : "")}
                    style={{ marginRight: "10px" }}
                    disabled={creating || !canCreate}
                    onClick={async () => {
                        // Prevent from making bad request calls.
                        if (!canCreate || creating) return;
                        // Block request spamming.
                        if (!creating) setCreating(true);
                        // For each collection create a promise and await it
                        for (const collectionToCreate of collectionsToCreate) {
                            await new Promise(async resolve => {
                                // In progress, update status.
                                statusUpdate("in-progress", collectionToCreate.orderNumber);
                                // Send collection.
                                try {
                                    await axios.post(`${myContext.apiRoot}/collections/from-file/create/clarks-thenuttery`,
                                        { collection: collectionToCreate },
                                        { headers: { Authorization: myContext.token } }
                                    );
                                    // Success, update status.
                                    setTimeout(() => {
                                        statusUpdate("success", collectionToCreate.orderNumber);
                                        resolve();
                                    }, 5000);
                                } catch (error) {
                                    console.error(error);
                                    // Success, update status.
                                    setTimeout(() => {
                                        // Show errors.
                                        logger.error(errorResponse(error));
                                        // Failed, update status.
                                        statusUpdate("failed", collectionToCreate.orderNumber);
                                        resolve();
                                    }, 5000);
                                }
                            });
                        };
                        // Completed.
                        setCreating(false);
                    }}
                >
                    {creating ? <Spinner size={24} /> : 'Create collection(s)'}
                </button>
                <button
                    className="button custom-bg-feature1"
                    onClick={goBack}
                >
                    Select different file
                </button>
            </div>
        </>
    )
}