import jsbarcode from "jsbarcode";

class BarcodeSet extends React.Component {
    componentDidMount() {
        this.renderBarcodes();
        if (this.props.printOnMount)
            this.triggerPrint();
    }
    componentDidUpdate() {
        this.renderBarcodes();
    }
    renderBarcodes() {
        let self = this;
        $(this.refs.target).find(".barcodeTarget").each(function (i) {
            if (self.props.items[i])
                jsbarcode(this, self.props.items[i].code || self.props.items[i].productBarcode, { margin: 0 });
        });
    }
    triggerPrint() {
        this.refs.iframe.contentWindow.document.querySelector("body").innerHTML = "";
        let content = this.refs.target.cloneNode(true);
        this.refs.iframe.contentWindow.document.querySelector("body").appendChild(content);
        this.refs.iframe.contentWindow.print();
    }
    renderPrintButton() {
        return <div className="button custom-bg-feature1" onClick={this.triggerPrint.bind(this)}>
            {this.props.label || "Print barcode set."}
        </div>
    }
    render() {
        return <div className="barcodeSetWidget">
            {/* Print button */}
            {this.renderPrintButton()}
            {/* Hidden parts controlling print content */}
            <div style={{ display: "none" }}>
                {/* Iframe the gets loaded with content for print */}
                <iframe ref="iframe" />
                {/* Actual content to get printed */}
                <div ref="target" >
                    <style>{`
                        svg { 
                            display: block;
                            margin: auto;
                            max-width: 100% !important;
                            max-height: 100%;
                            overflow: initial !important;
                        }
                        svg.moreInfo {
                            max-height: 70px;
                        }
                    `}</style>
                    {/* Map the items to print */}
                    {this.props.items.map(item => {
                        return <div
                            key={item.code || item.id || item.productBarcode}
                            style={{ padding: "5px 0px" }}
                        >
                            <svg className={`barcodeTarget ${item.moreInfo ? "moreInfo" : ""}`}></svg>
                            {item.moreInfo ?
                                <React.Fragment>
                                    <hr />
                                    <div
                                        className="moreInfo"
                                        style={{
                                            // whiteSpace: "pre-wrap",
                                            fontSize: "10px",
                                            fontFamily: "monospace"
                                        }}
                                    >
                                        {item.moreInfo}
                                    </div>
                                </React.Fragment>
                                : null}
                            <div style={{ pageBreakAfter: "always" }} />
                        </div>
                    })}
                </div>
            </div>
        </div>
    }
}

export default BarcodeSet;