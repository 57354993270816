import {Redirect} from "react-router-dom";
import Context from "../context";

class View extends React.Component {
    /**
     * View parent class takes props:
     * 
     * role: (INTEGER) The required role level to view.
     * user: (OBJECT) The user object.
     */

    createQueryString(obj) {
        // console.log(obj)
        let pairs = []
        Object.keys(obj).forEach(name => {
            if (obj[name]) pairs.push(`${name}=${obj[name]}`);
        });
        return pairs.length ? ("?"+pairs.join("&")) : "";
    }

    renderView() {
        return "Overwrite me.";
    }

    checkRole() {
        let role = this.props.role;
        let userRole = this.props.user.role;
        if (role === undefined) {
            return this.renderView();
        } else if (typeof role === "number" && userRole > role) {
            return <Redirect to="/" />
        } else if (role.constructor === Array && role.indexOf(userRole) === -1) {
            return <Redirect to="/" />    
        }
    }

    render() {
        if (!this.props.user) return <Redirect to="/login" />
        this.checkRole()
        return this.renderView();
    }
}

View.contextType = Context;

export default View;