import Stage from "./stage";
import SignaturePad from "signature_pad";
import TextField from "../../../fields/textField";

class SignatureStage extends Stage {
    constructor(props) {
        super(props)
        this.SignaturePad = null;
        this.state.firstName = "";
		this.state.lastName = "";
		this.working = false;
    }

    componentDidMount() {
        super.componentDidMount();
        this.SignaturePad = new SignaturePad(this.refs.canvas);
	}
	
	componentWillUnmount() {
		this.working = false;
	}

    renderStage() {
        let self = this;
        return (
            <div className="signatureStage">
                <TextField
                    placeholder="First Name."
                    change={(vn, val, err) => {
                        this.setState({ firstName: val })
                    }}
                >
                    {this.state.firstName}
                </TextField>
                <br />
                <TextField
                    placeholder="Last Name."
                    change={(vn, val, err) => {
                        this.setState({ lastName: val })
                    }}
                >
                    {this.state.lastName}
                </TextField>
                <br />
                <canvas ref="canvas" className="signaturePad custom-bg-light"></canvas>
                <div className="controls">
                    <button
                        className="button custom-bg-feature1 clear"
                        onClick={() => {
                            this.SignaturePad.clear()
                        }}
                    >
                        Clear
                    </button>
                    <button
                        key="save"
                        className={"button custom-bg-feature1 save" + ((this.state.firstName && this.state.lastName) ? " show" : "")}
                        onClick={() => {
							if (this.working) return;
							this.working = true;
                            this.refs.canvas.toBlob(blob => {
                                const reader = new FileReader();
                                reader.addEventListener('loadend', () => {
                                    const arrayBuffer = reader.result;
                                    //Sending it for a signed url
                                    $.post({
                                        url: `${this.context.apiRoot}/misc/file`,
                                        data: JSON.stringify({ extension: ".png" })
                                    }).done(response => {
                                        let url = response.url;
                                        if (!arrayBuffer) {
                                            self.error("Cannot upload an empty file.")
                                        }
                                        let self = this;
                                        let xhr = new XMLHttpRequest()
                                        xhr.onreadystatechange = function () {
                                            if (this.readyState === 4) {
                                                if (this.status === 200) {
                                                    self.stageComplete({
                                                        signature: response.fileName,
                                                        signedBy: self.state.firstName + " " + self.state.lastName
                                                    });
                                                } else {
                                                    self.error("Couldn't upload file " + file.name);
                                                }
                                            }
                                        };
                                        xhr.open("PUT", url);
                                        xhr.send(arrayBuffer);
                                    }).catch(err => {
                                        console.error("Error getting S3 signed url: ", err);
										self.error("Error uploading document. Please try again.");
										this.working = false;
                                    });
                                });
                                reader.readAsArrayBuffer(blob);
                            });
                        }}
                    >
                        Save
                    </button>
                </div>
            </div>
        )
    }

}

export default SignatureStage;