import Field from "./field";

class TextField extends Field {
    constructor(props) {
        super(props);
        this.settings = {
            type: "text"
        }
    }
    renderField() {
        return (
            <input
				style={this.props.style ? this.props.style : {}}
                autoFocus={this.props.autoFocus}
                onFocus={this.props.onFocus}
                placeholder={this.props.placeholder || ""}
                type="text"
                className="custom-border-3"
                value={this.props.children}
                onChange={e => {
                    this.change(e.target.value);
                }}
            />
        )
    }
}

export default TextField;