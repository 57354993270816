import Field from "./field";
import TextField from "./textField";

/**
 *  PROPS:
 * 
 *      options:  ARRAY of OBJECTS {val, dispaly}
 *      name: STRING
 */

class SelectField extends Field {
	constructor(props) {
		super(props);
		this.state.open = false;
		this.state.filter = "";
		this.settings = {
			type: "select"
		}
	}

	getCurrentValue() {
		// Default to the name of the field as a placeholder.
		if (
			this.props.children === null ||
			this.props.children === undefined
		) return this.props.name;
		// find a value match in the current options.
		let match = this.props.options.filter(option => option.val === this.props.children)[0]
		return match ? match.display : this.props.name;
	}

	renderField() {
		return (
			<div
				className="field-wrapper"
			// onMouseLeave={() => {
			//     if (this.state.open)
			//         this.setState({ open: false })
			// }}
			>
				<div
					className="head custom-border-3"
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<span>{this.getCurrentValue()}</span>
					<div className="icon chevron-icon"></div>
				</div>
				{(this.state.open && this.props.withFilter) && <TextField
					style={{
						zIndex: 10,
						borderTop: "none",
						backgroundColor: "#e8e8e8"
					}}
					placeholder="Filter results..."
					varname="filter"
					change={(varname, value, err) => {
						this.setState({ filter: value })
					}}
				>
					{this.state.filter}
				</TextField>}
				<div className={"options custom-bg-dark" + (this.state.open ? " open custom-border-3" : "") + (this.props.withFilter ? " with-filter" : "")}>
					{this.props.options
						.filter(option => {
							if (!this.state.filter || !this.props.withFilter) return true;
							return option.display.toLowerCase().includes(this.state.filter.toLowerCase())
						})
						.map(option => {
							return (
								<div
									key={option.val}
									className={"option custom-c-light hover-class" + (this.props.children === option.val ? " custom-bg-light" : "")}
									onClick={() => {
										if (this.props.withFilter) this.setState({ filter: "" });
										this.setState({ open: false });
										this.change(option.val);
									}}
								>
									{option.display}
								</div>
							)
						})}
				</div>
			</div>
		)
	}

}

export default SelectField;