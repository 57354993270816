import DateField from "../../fields/dateField";
import DataField from "../../fields/dataField";
import TimeField from "../../fields/timeField";
import SelectField from "../../fields/selectField";
import TextareaField from "../../fields/textareaField";
import DeliveryStockList from "./DeliveryStockList";
import FlipMove from "react-flip-move";
import { rid, renderDate } from "../../helpers";
import StockCSVField from "../../fields/csvField/stockCsvField";
import BooleanField from "../../fields/booleanField";
import BarcodeSet from "../../assets/barcodeSet";
import Context from "../../context";
import { Prompt } from "react-router-dom";

/**
 * PROPS:
 * 
 *      onSubmit (Callback Function)
 *          When user attempts to save delivery.
 * 
 *      onUpdate (Callback Function)
 *          When user attempts to update an existing delivery.
 * 
 *      delivery (Object)
 *          An existing delivery object for editing.
 * 
 *      user (Object) currently logged in user
 * 
 *      apiRoot: STRING
 * 
 *      customers: (Array)
 */


class DeliveryCreator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            delivery: this.props.delivery
                ?
                Object.assign({}, this.props.delivery, {
                    // Any changes needed.
                }) : {
                    dateIncoming: (dString => {
                        if (!dString) return Date.now();
                        let d = new Date(dString.split("#")[1]);
                        if (d.toString() === "Invalid Date") {
                            return Date.now();
                        } else {
                            return d.getTime();
                        }
                    })(window.location.hash),
                    dataDelivered: null,
                    customer: null,
                    status: "incoming",
                    notes: null,
                    timeIncoming: "all day",
                    stock: []
                },
            changes: {},
            stockPage: 1,
            deliveryStock: []
        }
        this.pageSize = 20;
        this.stockItemColumns = [
            {
                name: "Code",
                renderer: item => item.code || "...",
                sorter: item => item.code
            },
            {
                name: "Product Code",
                renderer: item => item.productCode || "...",
                sorter: item => item.productCode
            },
            {
                name: "Location",
                renderer: item => item.location || "...",
                sorter: item => item.location
            },
            {
                name: "Description",
                renderer: item => item.description || "...",
                sorter: item => item.description
            },
            {
                name: "Group",
                renderer: item => item.group || "...",
                sorter: item => item.group
            },
            {
                name: "Reportable",
                renderer: item => {
                    return this.props.user.role < 3 ? <BooleanField
                        change={(vn, val) => {
                            this.setState({
                                delivery: Object.assign({}, this.state.delivery, {
                                    stock: this.state.delivery.stock.map(s => {
                                        if (s.id === item.id) {
                                            s.reportable = val;
                                        }
                                        return s;
                                    })
                                })
                            });
                        }}
                    >
                        {item.reportable}
                    </BooleanField>
                        : String(Boolean(item.reportable))
                },
                sorter: item => String(item.reportable)
            },
        ]
    }

    displayOnly() {
        return this.props.user.role === 3
    }

    hasDeliveryChanged(oldDelivery, newDelivery) {
        if (!oldDelivery && newDelivery) return true;
        if (!newDelivery && oldDelivery) return true;
        if (!newDelivery && !oldDelivery) return false;
        let hasChanged = false;
        Object.keys(newDelivery).forEach(key => {
            if (newDelivery[key] !== oldDelivery[key])
                hasChanged = true;
        });
        // console.log("Haschanged: ", hasChanged);
        return hasChanged;
    }

    componentDidUpdate(prevProps) {
        let hasChanged = this.hasDeliveryChanged(prevProps.delivery, this.props.delivery);
        if (hasChanged) {
            this.setState({
                delivery: this.props.delivery || {
                    dateIncoming: null,
                    dataDelivered: null,
                    customer: null,
                    status: "incoming",
                    notes: null,
                    timeIncoming: "all day",
                    stock: []
                }
            });
            this.getDeliveryStock(this.props.delivery.stock
                .filter(s =>
                    s.id &&
                    /^[\w\d]{8}\-[\w\d]{4}\-[\w\d]{4}\-[\w\d]{4}\-[\w\d]{12}$/.test(s.id)
                )
                .map(s => s.id)
            );
        }
    }

    getDeliveryStock(ids) {
        if (!ids.length) return null;
        let uniqueIds = [...new Set(ids)];
        $.ajax({
            url: `${this.context.apiRoot}/stock/multi`,
            method: "POST",
            contentType: "json",
            data: JSON.stringify(uniqueIds)
        }).done(response => {
            // console.log(response.results);
            this.setState({
                deliveryStock: response.results
            });
        }).catch(err => {
            console.error("Error getting delivery stock: ", err);
        });
    }

    componentDidMount() {
        if (this.props.delivery)
            this.getDeliveryStock(this.props.delivery.stock
                .filter(s =>
                    s.id &&
                    /^[\w\d]{8}\-[\w\d]{4}\-[\w\d]{4}\-[\w\d]{4}\-[\w\d]{12}$/.test(s.id)
                )
                .map(s => s.id)
            );
        window.onbeforeunload = () => {
            if (Object.keys(this.state.changes).length) {
                return "You have unsaved changes. Are you sure?";
            } else {
                return null;
            }
        }
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    renderSelectCustomerField() {
        if (this.props.user.customer === "internal") {
            //If the logged in user is a client
            if (this.state.editingCustomer || !this.state.delivery.id) {
                //Creating a new delivery
                return (
                    <React.Fragment>
                        <DataField
                            varname="customer"
                            defaults={[
                                {
                                    name: "internal",
                                    displayName: "Internal User"
                                }
                            ]}
                            optionRenderer={customer => (
                                <div className="option-inner">
                                    <b>{customer.displayName}</b> <br />({customer.name})
                                </div>
                            )}
                            label="Customer"
                            route="customers"
                            dataName="name"
                            searchBy="term"
                            change={(varname, value, error) => {
                                let customer = value ? value.name : null;
                                if (!customer) return;
                                let delivery = Object.assign({}, this.state.delivery, { customer });
                                let changes = Object.assign({}, this.state.changes, { customer });
                                this.setState({
                                    delivery,
                                    changes,
                                    editingCustomer: false
                                });
                            }}
                        >
                            {this.state.delivery.customer || ""}
                        </DataField>
                        <br />
                    </React.Fragment>
                )
            } else {
                //If you are editing an existing delivery only display the customer
                return (
                    <div className="show-customer">
                        <label className="custom-c-dark">Customer</label>
                        <div className="name custom-c-feature2">
                            {this.state.delivery.customer || "Internal"}
                            {this.props.user.role < 3 ? <button
                                style={{
                                    marginLeft: "20px"
                                }}
                                className="btn"
                                onClick={e => {
                                    if (window.confirm("Are you sure you want to edit the customer on this delivery? This can cause unexpected issues if you do not know what you're doing."))
                                        this.setState({
                                            editingCustomer: true
                                        });
                                }}
                            >Edit</button> : null}
                        </div>
                    </div>
                )
            }
        } else {
            //If a logged in user is a customer
            return null
        }
    }

    renderStatusButtons() {
        if (!this.state.delivery.id) {
            //If creating new delivery.
            return null
        } else if (this.displayOnly()) {
            //If its only for display (not edditing)
            return (
                <div className="display-only custom-c-dark">
                    <label>Delivery Status</label>
                    <p>{this.state.delivery.status}</p>
                </div>
            )
        } else {
            //Editing existing one.
            let client = this.props.user.customer;
            let status = this.state.delivery.status;
            let btnArray = [
                {
                    val: "cancelled",
                    display: "Cancelled"
                },
                {
                    val: "delivered",
                    display: "Delivered"
                },
				{
					val: "in progress",
					display: "In progress"
				},
                {
                    val: "incoming",
                    display: "Incoming"
                }
            ];
            return (
                <SelectField
                    label="Delivery Status"
                    varname="status"
                    options={btnArray}
                    change={(varname, value, error) => {
                        if (value !== status) {
                            let delivery = Object.assign({}, this.state.delivery);
                            delivery.status = value;
                            let changes = Object.assign({}, this.state.changes);
                            changes.status = value;
                            this.setState({ changes, delivery })
                        }
                    }}
                >
                    {status}
                </SelectField>

            )
        }
    }

    render() {
        // console.log("DELIVERY CREATOR STATE:", this.state);
        // console.log("DELIVERY CREATOR PROPS:", this.props);

        // If there are stock items, generate array of unique groups.
        let uniqueGroups;
        if (this.state.delivery.stock.length) {
            uniqueGroups = {}
            this.state.delivery.stock
                .filter(s => s.group)
                .forEach(stockItem => {
                    uniqueGroups[stockItem.group] = uniqueGroups[stockItem.group] || 0
                    uniqueGroups[stockItem.group] += 1;
                })
        }
        // Render
        return (
            <div className="delivery-creator">
                <Prompt
                    when={Object.keys(this.state.changes).length > 0}
                    message='You have unsaved changes, are you sure?'
                />
                {this.renderSelectCustomerField()}
                {this.state.delivery.deliveryGroup
                    ?
                    <div className="display-only custom-c-dark">
                        <label>Delivery group</label>
                        <p>{this.state.delivery.deliveryGroup}</p>
                    </div>
                    :
                    null
                }
                    {this.renderStatusButtons()}
                <div className="date-time-notes">
                    {!this.displayOnly()
                        ?
                        <DateField
                            varname="dateIncoming"
                            label="Date incoming"
                            change={(varname, value, error) => {
                                //Prevent change for role 3 users
                                if (this.props.user.role !== 3) {
                                    let delivery = Object.assign({}, this.state.delivery, {
                                        dateIncoming: value
                                    })
                                    this.setState({ delivery });

                                    // Handle changes for editing a delivery.
                                    if (this.state.delivery.id) {
                                        let changes = Object.assign({}, this.state.changes, {
                                            dateIncoming: value
                                        });
                                        this.setState({ changes });
                                    }
                                }
                            }}
                        >
                            {this.state.delivery.dateIncoming}
                        </DateField>
                        :
                        <div className="display-only split first custom-c-dark">
                            <label>Date incoming</label>
                            <p>{renderDate(this.state.delivery.dateIncoming)}</p>
                        </div>
                    }
                    {!this.displayOnly()
                        ?
                        <TimeField
                            varname="time"
                            label="Time"
                            from={900}
                            to={1800}
                            change={(varname, value, error) => {
                                let delivery = Object.assign({}, this.state.delivery, {
                                    timeIncoming: value
                                })
                                this.setState({ delivery });

                                // Handle changes for editing a delivery.
                                if (this.state.delivery.id) {
                                    let changes = Object.assign({}, this.state.changes, {
                                        timeIncoming: value
                                    });
                                    this.setState({ changes });
                                }
                            }}
                        >
                            {this.state.delivery.timeIncoming}
                        </TimeField>
                        :
                        <div className="display-only split custom-c-dark">
                            <label>Time</label>
                            <p>{this.state.delivery.timeIncoming}</p>
                        </div>
                    }
                    {!this.displayOnly()
                        ?
                        <TextareaField
                            varname="notes"
                            label="Delivery notes"
                            change={(varname, value, error) => {
                                let delivery = Object.assign({}, this.state.delivery);
                                delivery.notes = value;
                                this.setState({ delivery });
                                // Handle changes for editing a delivery.
                                if (this.state.delivery.id) {
                                    let changes = Object.assign({}, this.state.changes);
                                    changes.notes = value;
                                    this.setState({ changes });
                                }
                            }}
                        >
                            {this.state.delivery.notes || ""}
                        </TextareaField>
                        :
                        <div className="display-only custom-c-dark">
                            <label>Delivery notes</label>
                            <p>{this.state.delivery.notes || "none"}</p>
                        </div>
                    }
                </div>
                {/* Group list */}
                {uniqueGroups && Object.keys(uniqueGroups).length ?
                    <div className="delivery-groups">
                        <p className="custom-c-light">Groups in Delivery</p>
                        <br />
                        <div className="groups">
                            {Object.keys(uniqueGroups).map(group => <div
                                className="group-item custom-bg-light custom-border-color"
                                key={group}
                            >
                                <b>{group}</b> - {uniqueGroups[group]} item{uniqueGroups[group] > 1
                                    ? "s" : ""} left to unload
                                {this.props.user.role < 3 ? <BooleanField
                                    change={(vn, val) => {
                                        // Recreate the stock list.
                                        let stock = this.state.delivery.stock.map(s => {
                                            if (s.group === group) {
                                                s.reportable = val;
                                            }
                                            return s;
                                        });
                                        // And update state.
                                        this.setState({
                                            delivery: Object.assign({}, this.state.delivery, {
                                                stock
                                            }),
                                            changes: Object.assign({}, this.state.changes, {
                                                stock
                                            })
                                        });
                                    }}
                                >
                                    {!(this.state.delivery.stock
                                        .filter(s => s.group === group && !s.reportable).length)}
                                </BooleanField> : null}
                            </div>)}
                        </div>
                    </div>
                    : null
                }
                <div className="delivery-stock">
                    <p className="custom-c-light">Stock Items</p>
                    <DeliveryStockList
                        customer={this.state.delivery.customer}
                        user={this.props.user}
                        displayOnly={this.displayOnly()}
                        controls={(() => {
                            if (this.state.delivery.status !== "incoming") {
                                if (this.props.user.role < 4) {
                                    return ["print"]
                                } else {
                                    return [];
                                }
                            } else {
                                return ["edit", "delete", "print"];
                            }
                        })()}
                        allCreatorFields={false}
                        columns={this.stockItemColumns}
                        items={this.state.delivery.stock
                            .map(s => this.state.deliveryStock.find(ds => ds.id === s.id) || s)
                            .filter(s => (s.code || s.productBarcode))
                            .slice((this.state.stockPage - 1) * this.pageSize, (this.state.stockPage - 1) * this.pageSize + this.pageSize)}
                        onNew={item => {
                            item.id = item.id || rid();
                            let stock = [...this.state.delivery.stock, item];
                            if (this.state.delivery.id) {
                                this.setState({
                                    delivery: Object.assign({}, this.state.delivery, {
                                        stock
                                    }),
                                    changes: Object.assign({}, this.state.changes, {
                                        stock
                                    })
                                })
                            } else {
                                this.setState({
                                    delivery: Object.assign({}, this.state.delivery, {
                                        stock
                                    })
                                });
                            }
                        }}
                        onUpdate={(changes, itemId) => {
                            // Handle local state for creating/editing a delivery.
                            let delivery = Object.assign({}, this.state.delivery);
                            let newStock = this.state.delivery.stock.map(s => {
                                if (s.id === itemId) {
                                    return Object.assign({}, s, changes);
                                }
                                return s;
                            });
                            delivery.stock = newStock;
                            this.setState({ delivery });

                            // Handle changes for editing a delivery.
                            if (this.state.delivery.id) {
                                let changes = Object.assign({}, this.state.changes);
                                changes.stock = newStock;
                                this.setState({ changes });
                            }
                        }}
                        onDelete={id => {
                            let delivery = Object.assign({}, this.state.delivery);
                            delivery.stock = this.state.delivery.stock.filter(s => {
                                return s.id !== id;
                            });
                            this.setState({ delivery });
                            // Handle changes for editing a delivery.
                            if (this.state.delivery.id) {
                                let changes = Object.assign({}, this.state.changes);
                                changes.stock = delivery.stock;
                                this.setState({ changes });
                            }
                        }}
                        numberOfPages={Math.ceil(this.state.delivery.stock.length / 20)}
                        page={this.state.stockPage}
                        onPageChange={stockPage => {
                            this.setState({ stockPage });
                        }}
                    />
                </div>
                <div className="barcodeSet">
                    <BarcodeSet
                        items={this.state.delivery.stock
                            .map(s => this.state.deliveryStock.find(ds => ds.id === s.id) || s)
                            .filter(s => s.code)
                            .map(s => {
                                return {
                                    code: s.code,
                                    moreInfo: <div>
                                        <div>
                                            CUST: {this.state.delivery.customer} | PC: {s.productCode || "NO CODE"}
                                        </div>
                                        <hr />
                                        <div>{s.description || "NO DESC"}</div>
                                    </div>
                                }
                            })}
                    />
                </div>
                <hr />
                <div className="col-12" style={{ padding: "0px" }}>
                    <StockCSVField
                        key={Date.now()}
                        customer={this.state.delivery.customer}
                        onConfirm={(data, cb) => {
                            console.log("DATA: ", data);
                            this.setState({
                                delivery: Object.assign({}, this.state.delivery, {
                                    stock: [...this.state.delivery.stock, ...data.map(d => {
                                        return {
                                            id: rid(),
                                            code: d.code,
                                            description: d.description,
                                            group: d.group,
                                            quantity: d.quantity,
                                            productCode: d.productCode,
                                            supplier: d.supplier,
                                            productBarcode: d.productBarcode
                                        }
                                    })]
                                })
                            })
                            if (cb) cb(true);
                        }}
                    />

                </div>
                <hr />
                <FlipMove>
                    {Object.keys(this.state.changes).length
                        ?
                        <div className="save-changes">
                            {"Changes were made, make sure you save them!"}
                        </div>
                        :
                        null
                    }
                </FlipMove>
                {!this.displayOnly()
                    ?
                    <div
                        className={"create-delivery-button button custom-bg-feature1" + ((this.state.delivery.dateIncoming && this.state.delivery.timeIncoming && this.state.delivery.stock.length && this.state.delivery.customer) ? "" : " disabled")}
                        onClick={() => {
                            if (this.state.delivery.id) {
                                //Change existing one
                                if (Object.keys(this.state.changes).length) {
                                    //If changes were made
                                    this.props.onUpdate(this.state.changes, this.state.delivery.id);
                                    this.setState({ changes: {} })
                                }
                            } else {
                                //Create a new one
                                if (this.state.delivery.dateIncoming && this.state.delivery.timeIncoming && this.state.delivery.stock.length && this.state.delivery.customer) {
                                    let newDelivery = Object.assign({}, this.state.delivery);
                                    //Removing delivery status and dateDelivered
                                    delete newDelivery.status;
                                    delete newDelivery.dataDelivered;
                                    if (this.props.user.customer !== "internal") {
                                        //If a delivery is created by a customer
                                        delete newDelivery.customer
                                    } else {
                                        //If its created by a client and its an internal delivery
                                        if (newDelivery.customer === this.props.user.client) newDelivery.customer = "internal"
                                    }
                                    this.props.onSubmit(newDelivery);
                                    this.setState({ changes: {} });
                                }
                            }
                        }}
                    >
                        {this.state.delivery.id ? "Save Changes" : "Create Delivery"}
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

DeliveryCreator.contextType = Context;

export default DeliveryCreator;