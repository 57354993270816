class Pod extends React.Component {
    componentDidMount() {
        let wrapper = window.document.createElement("div");
        if (this.props.user.role === 4) {
            let image = window.document.createElement('img');
            image.src = "/static/media/POD_header_small.png";
            image.style.width = "100%";
            image.style.margin = "-10px 0px 0px 0px";
            wrapper.append(image.cloneNode(true));
        }
        wrapper.append(this.refs.content.cloneNode(true));
        let newWindow = window.open('', '' + Math.random(), 'width=100, height=100'),
            document = newWindow.document.open(),
            pageContent = wrapper.innerHTML;
        document.write(pageContent);
        // Deciding which css file to attach based on the user role
        if (this.props.user.role === 4) {
            // Its a customer
            document.querySelector("head").innerHTML = '<link rel="stylesheet" href="/static/css/PODCus.css">';
        } else {
            // Internal staff
            document.querySelector("head").innerHTML = '<link rel="stylesheet" href="/static/css/POD.css">';
        }
        document.close();
        newWindow.moveTo(0, 0);
        newWindow.resizeTo(screen.width, screen.height);
        setTimeout(() => {
            newWindow.print();
            newWindow.close();
            if (this.props.onExit)
                this.props.onExit();
        }, 1000);
    }

    formatDate(ms) {
        //dd/mm/yyyy
        let date = new Date(ms);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }

    render() {
        // console.log("USER: ", this.props.user)
        let workOrder = this.props.workOrder;
        //Stores unique destinations locator
        let uniqueDestinations = [];
        //Array used for rendering POD
        let destinations = [];
        // Splitting name into first and last name
        let names = [];
        if (workOrder.signedBy) names = workOrder.signedBy.split(" ");

        this.props.stock.forEach(s => {
            let position = uniqueDestinations.indexOf(s.destination.locator);
            let vehicle = (!workOrder.vehicles.length || workOrder.status === "confirmed" || workOrder.status === "picked") ? workOrder.vehicle : null;
            if (!vehicle) {
                vehicle = s.history.filter(h => h.type === "workorder-loaded");
                vehicle = vehicle.length ? vehicle[0].ref : workOrder.vehicle;
            }
            if (position < 0) {
                //If location was not already added
                let destination = {
                    locator: s.destination.locator,
                    address: s.destination.address,
                    stock: [],
                    stockWithGroup: [],
                    groups: []
                }
                // Checking if stock has a group
                if (s.group) {
                    destination.stockWithGroup.push({
                        description: s.description,
                        group: s.group,
                        code: s.code,
                        vehicle
                    })
                } else {
                    destination.stock.push({
                        description: s.description,
                        productCode: s.productCode,
                        code: s.code,
                        vehicle
                    })
                }

                uniqueDestinations.push(s.destination.locator);

                destinations.push(destination);
            } else {
                //Just add new stock to existing location
                if (s.group) {
                    destinations[position].stockWithGroup.push({
                        description: s.description,
                        group: s.group,
                        code: s.code,
                        vehicle
                    })
                } else {
                    destinations[position].stock.push({
                        description: s.description,
                        productCode: s.productCode,
                        code: s.code,
                        vehicle
                    })
                }
            }
        })
        // Getting all unique groups for each destination
        destinations.forEach(d => {
            let uniqueGroups = []
            d.stockWithGroup.forEach(s => {
                let position = uniqueGroups.indexOf(s.group);
                // If group not already added
                if (position < 0) {
                    d.groups.push({
                        group: s.group,
                        description: s.description,
                        total: 1,
                        vehicles: [s.vehicle]
                    })
                    uniqueGroups.push(s.group)
                } else {
                    d.groups[position].total++;
                    if (!d.groups[position].vehicles.includes(s.vehicle))
                        d.groups[position].vehicles.push(s.vehicle)
                }
            })
        })
        return <div style={{ display: "none" }} className="pod">
            {/* Holder for actual POD markup. */}
            <div
                ref="content"
                className="content"
                style={{
                    width: "100%",
                    fontFamily: "sans-serif",
                    fontSize: "12px",
                    color: "#1a1a1a"
                }}
            >
                <h1>Proof of delivery</h1>
                <p
                    style={{
                        marginBottom: "0px"
                    }}
                >
                    {`ID: ${workOrder.id}`}
                </p>
                <p
                    style={{
                        marginBottom: "0px"
                    }}
                >
                    {`Customer: ${workOrder.customer}`}
                </p>
                {this.props.address
                    ?
                    <p
                        style={{
                            marginBottom: "0px"
                        }}
                    >
                        {`Customer Base Address: ${this.props.address}`}
                    </p>
                    :
                    null
                }
                <p
                    style={{
                        marginBottom: "0px"
                    }}
                >
                    {`Delivery date: ${this.formatDate(workOrder.dateOutgoing)}`}
                </p>
                <p
                    style={{
                        marginBottom: "0px"
                    }}
                >
                    {`Vehicle: ${workOrder.vehicle}`}
                </p>
                {workOrder.sizeClass
                    ?
                    <p
                        style={{
                            marginBottom: "0px"
                        }}
                    >
                        {`Vehicle size class: ${workOrder.sizeClass}`}
                    </p>
                    :
                    null
                }
                {workOrder.requiredStaff
                    ?
                    <p
                        style={{
                            marginBottom: "0px"
                        }}
                    >
                        {`Required staff: ${workOrder.requiredStaff}`}
                    </p>
                    :
                    null
                }
                {workOrder.notes
                    ?
                    <p
                        style={{
                            marginBottom: "0px"
                        }}
                    >
                        {`Notes: ${workOrder.notes}`}
                    </p>
                    :
                    null
                }
                <hr
                    style={{ marginBottom: "20px" }}
                />

                {destinations.map(dest => {
                    return (
                        <div
                            key={dest.locator}
                            className="group"
                            style={{
                                marginBottom: "30px"
                            }}
                        >
                            <div
                                className="destination"
                                style={{
                                    marginBottom: "10px"
                                }}
                            >
                                <span
                                    className="locator"
                                    style={{
                                        display: "inline-block",
                                        verticalAlign: "top",
                                        width: "30%",
                                        fontSize: "16px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {dest.locator}
                                </span>
                                <span
                                    className="address"
                                    style={{
                                        display: "inline-block",
                                        verticalAlign: "top",
                                        width: "70%",
                                        fontSize: "16px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {dest.address}
                                </span>
                            </div>
                            {dest.stock.length
                                ?
                                <table
                                    style={{
                                        width: "100%",
                                        border: "1px solid #1a1a1a",
                                        borderCollapse: "collapse",
                                        textAlign: "left"
                                    }}
                                >
                                    <tbody>
                                        <tr>
                                            <th
                                                style={{
                                                    border: "1px solid #1a1a1a",
                                                    padding: "5px",
                                                    width: "25%"
                                                }}
                                            >
                                                Product Code
                                            </th>
                                            <th
                                                style={{
                                                    border: "1px solid #1a1a1a",
                                                    padding: "5px",
                                                    width: "25%"
                                                }}
                                            >
                                                Vehicle
                                                </th>
                                            <th
                                                style={{
                                                    border: "1px solid #1a1a1a",
                                                    padding: "5px",
                                                    width: "50%"
                                                }}
                                            >
                                                Description
                                            </th>
                                        </tr>
                                        {dest.stock.map((s, index) => {
                                            return (
                                                <tr key={s.code + "stock" + index} >
                                                    <td
                                                        style={{
                                                            border: "1px solid #1a1a1a",
                                                            padding: "5px"
                                                        }}
                                                    >
                                                        {s.productCode}
                                                    </td>
                                                    <td
                                                        style={{
                                                            border: "1px solid #1a1a1a",
                                                            padding: "5px"
                                                        }}
                                                    >
                                                        {s.vehicle}
                                                    </td>
                                                    <td
                                                        style={{
                                                            border: "1px solid #1a1a1a",
                                                            padding: "5px"
                                                        }}
                                                    >
                                                        {s.description}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                :
                                null
                            }
                            {dest.groups.length
                                ?
                                <table
                                    style={{
                                        width: "100%",
                                        border: "1px solid #1a1a1a",
                                        borderCollapse: "collapse",
                                        textAlign: "left",
                                        marginTop: "20px"
                                    }}
                                >
                                    <tbody>
                                        <tr>
                                            <th
                                                style={{
                                                    border: "1px solid #1a1a1a",
                                                    padding: "5px",
                                                    width: "25%"
                                                }}
                                            >
                                                Group
                                            </th>
                                            <th
                                                style={{
                                                    border: "1px solid #1a1a1a",
                                                    padding: "5px",
                                                    width: "20%"
                                                }}
                                            >
                                                Vehicle(s)
                                                </th>
                                            <th
                                                style={{
                                                    border: "1px solid #1a1a1a",
                                                    padding: "5px",
                                                    width: "25%"
                                                }}
                                            >
                                                Total
                                            </th>
                                            <th
                                                style={{
                                                    border: "1px solid #1a1a1a",
                                                    padding: "5px",
                                                    width: "50%"
                                                }}
                                            >
                                                Description
                                            </th>
                                        </tr>
                                        {dest.groups.map((s, index) => {
                                            return (
                                                <tr key={s.code + "group" + index} >
                                                    <td
                                                        style={{
                                                            border: "1px solid #1a1a1a",
                                                            padding: "5px"
                                                        }}
                                                    >
                                                        {s.group}
                                                    </td>
                                                    <td
                                                        style={{
                                                            border: "1px solid #1a1a1a",
                                                            padding: "5px"
                                                        }}
                                                    >
                                                        {s.vehicles.map(v => <>{v}<br /></>)}
                                                    </td>
                                                    <td
                                                        style={{
                                                            border: "1px solid #1a1a1a",
                                                            padding: "5px"
                                                        }}
                                                    >
                                                        {s.total}
                                                    </td>
                                                    <td
                                                        style={{
                                                            border: "1px solid #1a1a1a",
                                                            padding: "5px"
                                                        }}
                                                    >
                                                        {(() => {
                                                            let result = /commission text:\s*([^\n]+)/gi.exec(s.description);
                                                            if (result && result[1]) return result[1];
                                                            return s.description
                                                        })()}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                :
                                null
                            }
                        </div>
                    )
                })}
                <div
                    className="signDate"
                    style={{
                        marginTop: "40px",
                        fontWeight: "bold",
                        fontSize: "18px"
                    }}
                >
                    <p className="name">Forename: {names[0] ? names[0] : ""}</p>
                    <p className="name">Surname: {names[1] ? names[1] : ""}</p>
                    <p className="signature">Signature: {
                        workOrder.signature
                            ?
                            <img src={workOrder.signature} style={{
                                display: "inline-block",
                                verticalAlign: "middle"
                            }} />

                            : null
                    }</p>
                    {!workOrder.signature
                        ?
                        <p className="date">Date:</p>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    }
}

export default Pod;