import Field from "./field";

class TextareaField extends Field {
    constructor(props) {
        super(props);
        this.settings = {
            type: "textarea"
        }
    }
    renderField() {
        return (
            <textarea
                placeholder={this.props.placeholder}
                className="custom-border-color"
                value={this.props.children}
                onChange={e => {
                    this.change(e.target.value);
                }}
            >
            </textarea>
        )   
    }
}

export default TextareaField;