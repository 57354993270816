import Field from "./field";
import VehicleSearchField from "./vehicleSearchField";
import FlipMove from "react-flip-move";
class VehicleListField extends Field {
    constructor(props) {
        super(props);
        this.settings = {
            type: "vehicleList"
        }
    }
    renderField() {
        return (
            <FlipMove
                enterAnimation="fade"
                leaveAnimation="fade"
                duration={200}
            >
                {this.props.children.map(locator => {
                    return <div key={locator} className="vehicle-item custom-bg-light custom-border-color">
                        {locator}
                        <span className="remove" onClick={e => {
                            this.change(this.props.children.filter(l => l !== locator));
                        }}>x</span>
                    </div>
                })}
                <VehicleSearchField
                    key={"addItem_"+this.props.children.length}
                    varname="vehicle"
                    defaultSearch="locator"
                    change={(vn, vehicle, error) => {
                        // Check exclusion list.
                        if (this.props.exclude && this.props.exclude.includes(vehicle.locator))
                            return null;
                        // Check there actually is a vehicle, and change.
                        if (vehicle)
                            this.change([...new Set([...this.props.children, vehicle.locator])]);
                    }}
                >
                    {null}
                </VehicleSearchField>
            </FlipMove>
        )
    }
}

export default VehicleListField;