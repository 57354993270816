import View from "./view";
import UiPanel from "../assets/ui-panel";
import CalendarPreview from "../assets/calendar-preview";
import UiList from "../assets/ui-list";
import MetricPreview from "../assets/metric-preview";
import { Aux, renderDate } from "../helpers";

class DashboardView extends View {
    constructor(props) {
        super(props)
        this.state = {
            startDay: ((d) => (
                new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime()
            ))(new Date()),
            calendarEvents: []
        }
        this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);

    }

    componentDidMount() {
        this.getCalendarEvents()
    }

    eventHandler(type, val) {
        if (type === "delivery") {
            this.props.router.history.push(`/deliveries/${val}`);    
        } else if (type === "workOrder") {
            this.props.router.history.push(`/workorders/${val}`);    
        }
    }

    getCalendarEvents() {
        let from = this.state.startDay;
        let to = this.state.startDay + 86400000 * 7;
        $.get(`${this.props.apiRoot}/misc/schedule/?from=${from}&to=${to}`).done(events => {
            // Filtering delivery events for role 4 users
            if (this.props.user.role === 4) events = events.filter(event => {
                return event.type !== "delivery"
            })
            this.setState({ calendarEvents: events })
        }).catch(err => {
            logger.error(err)
        })
    }

    renderView() {
        return (
            <Aux>
                <div className="row heading-row">
                    <div className="col-12">
                        <h1 className="client-view custom-c-dark">{`${this.client} Dashboard`}</h1>
                        <h5 className="date custom-c-mid">{renderDate()}</h5>
                    </div>
                </div>
                <div className="row view dashboardView">
                    <div className="col-12 col-lg-6 panel-wrapper">
                        <UiPanel
                            name="Reports"
                            icon="report"
                        >
                            <UiList
                                listItems={[]}
                                itemSelectedHandler={() => {
                                    console.log("Selected")
                                }}
                            />
                        </UiPanel>
                    </div>
                    <div className="col-12 col-lg-6 panel-wrapper">
                        <UiPanel
                            name="Deliveries"
                            icon="delivery"
                        >
                            <UiList
                                listItems={[]}
                                itemSelectedHandler={() => {
                                    console.log("Selected")
                                }}
                            />
                        </UiPanel>
                    </div>
                    {/* <div className="col-12 col-lg-4 panel-wrapper">
                        <UiPanel
                            name="Metrics"
                            icon="chart"
                        >
                            <MetricPreview />
                        </UiPanel>
                    </div> */}
                    <div className="col-12 panel-wrapper">
                        <UiPanel
                            name="Calendar"
                            icon="calendar"
                        >
                            <CalendarPreview
                                startDay={this.state.startDay}
                                items={this.state.calendarEvents.filter(item => item.data.status !== "cancelled")}
                                dayCount={6}
                                showWeekends={false}
                                eventHandler={this.eventHandler.bind(this)}
                            />
                        </UiPanel>
                    </div>
                </div>
            </Aux>
        )
    }
}
export default DashboardView;