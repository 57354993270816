

import Field from "./field";
import SelectFied from "./selectField";

class TimeField extends Field {
    constructor(props) {
        super(props);
        this.props.children || null;
        this.settings = {
            type: "time"
        }
    }

    formatHour(hour) {
        hour = hour + "";
        if (hour.length === 3) {
            hour = `0${hour}`;
        }
        return `${hour.slice(0, 2)}:${hour.slice(2)}`    
    }

    createHoursArray() {
        let from = this.props.from;
        let to = this.props.to;
            
        let arr = []
        for (var i = from; i <= to; i+=100) {
            for (var j = 0; j <= 30; j+=30) {
                let hour = i + j;
                if (hour <= to) {
                    let val = this.formatHour(hour);
                    arr.push({val, display: val})
                } 
            }
        }

        return arr
    }
    
    renderField() {
        return (
            <div className="field-wrpper">
                <SelectFied
                    varname="time"
                    name="Time"
                    options={[
                        {
                            val: "am",
                            display: "AM"    
                        },
                        {
                            val: "pm",
                            display: "PM"    
                        },
                        {
                            val: "all day",
                            display: "All Day"    
                        }
                    ].concat(this.createHoursArray())}     
                    change={(varname, value, error) => {
                        this.change(value);
                    }} 
                >
                    {this.props.children}
                </SelectFied>
            </div>        
        )
    }

}

export default TimeField;