const Aux = props => {
    return props.children
}

const rid = length => {
    length = length || 20;
    let id = [];
    let charset = "abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = 0; i < length; i++) {
        id.push(charset[Math.round(Math.random() * (charset.length - 1))]);
    }
    return id.join("");
}

const renderDate = (d) => {
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = d ? new Date(d) : new Date();
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}

const createQueryString = (obj) => {
    return "?" + Object.keys(obj).map(name => {
        if (!obj[name]) return "";
        return `${name}=${obj[name]}`;
    }).join("&");
}

const readFileAsB64 = function (file) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = function (event) {
            resolve(event.target.result)
        };
        reader.readAsDataURL(file);
    });
}

export { Aux, rid, renderDate, createQueryString, readFileAsB64 };