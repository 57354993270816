import Field from "../field";
import Papa from "papaparse";

import { rid } from "../../helpers";
import BarcodeSet from "../../assets/barcodeSet";
import SelectField from "../selectField";
// Upload templates.
import leichtKitchenTemplate from "./templates/leichtKitchenTemplate";
import kitchenTemplate from "./templates/kitchenTemplate";
import stockTemplate from "./templates/stockTemplate";
import skuProductTemplate from "./templates/skuProductTemplate";
// import applianceTransferTemplate from "./templates/applianceTransfer";
import Context from "../../context";

const SETSIZE = 10;

class StockCSVField extends Field {
	constructor(props) {
		super(props);
		this.settings = {
			type: "csv"
		}
		this.templates = props.templates || [
			kitchenTemplate,
			leichtKitchenTemplate,
			stockTemplate,
			skuProductTemplate
			// applianceTransferTemplate
		];
		this.state = {
			rows: [],
			rowObjects: [],
			barcodeEnabled: false,
			offset: 0,
			setSize: SETSIZE,
			columns: this.props.columns || [],
			template: props.template || this.templates[0]
		}
		this.canConfirm = true;
	}

	renderRows(rows) {
		return rows.map(row => {
			// console.log("row: ", row)
			let key = Math.random();
			// console.log(row)
			return Object.keys(row).length ?
				<div className="data-row custom-bg-dark custom-border-color" key={key}>
					{row.map((col, index) => {
						let data = (col || "").trim();
						return <div
							title={data}
							key={index + "_" + this.state.offset}
							className="data-col custom-border-color"
							style={{
								width: (100 / row.length) + "%",
								display: "inline-block"
							}}
						>
							{data}
						</div>
					})}
				</div>
				:
				null
		})
	}

	read(file) {
		return new Promise((resolve, reject) => {
			let ext = /(\.[^\.]+)$/.exec(file.name);
			if (!ext || (ext[1].toLowerCase() !== ".csv"))
				return reject("Not a CSV file.");

			let fr = new FileReader();
			fr.onload = readEvent => {
				resolve(readEvent.target.result);
			}
			fr.readAsText(file);
		})
	}

	parse(data) {
		let rows = Papa.parse(data).data
		let baseLength = rows[0].length;
		return rows.filter(r => r.length === baseLength);
	}

	renderHeaders(count) {
		let headers = [];
		let columnChoices = [...new Set((this.props.columnChoices || []).concat(this.state.template.columns))];
		for (let i = 0; i < count; i++) {
			headers.push(
				<div
					className="headerDropdown custom-bg-light" key={i}
					style={{
						width: (100 / count) + "%",
						display: "inline-block"
					}}
				>
					<select
						value={this.state.columns[i] || "ignore"}
						onChange={e => {
							let columns = [...this.state.columns];
							columns[i] = e.target.value;
							this.setState({ columns })
						}}
					>
						{columnChoices.map(choice => {
							return <option key={choice} value={choice}>
								{choice}
							</option>
						})}
					</select>
				</div>
			)
		}
		return headers;
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			// Change of customer must re-render.
			(this.props.customer !== prevProps.customer && this.state.rows.length) ||
			(this.state.template !== prevState.template && this.state.rows.length) ||
			(JSON.stringify(this.state.columns) !== JSON.stringify(prevState.columns) && this.state.rows.length)
		)
			this.setState({
				rowObjects: this.convertToObjects(this.state.rows)
			})
	}

	convertToObjects(csvData) {
		// Create a guide for the columns and which indexes 
		// they map to.
		let columnMap = {}
		this.state.columns.forEach((col, index) => {
			columnMap[col] = columnMap[col] || [];
			columnMap[col].push(index);
		});
		let labels = this.state.template.columnLabels || [];
		// console.log("Col map: ", this.state.columns)
		// Map through rows.
		let rows = csvData.map(row => {
			// Create an object for each row;
			let rowObj = {}
			// Loop through the desired columns
			this.state.columns.forEach(col => {
				// Skip if 'ignore'.
				if (col === "ignore") return;
				// console.log("Column: ", col)
				// Get the values that the column will use.
				rowObj[col] = (columnMap[col] || [])
					.map((index, i, array) => {
						if (!row[index]) return null;
						// If there are multiple values, check for a label.
						if (array.length > 1) {
							return `${labels[index] ? (labels[index] + ": ") : ""} ${row[index].trim()}`
						} else {
							return row[index].trim();
						}
					})
					.join("\n");
			});
			// Run the preprocessor if there is one, and if it is not disabled.
			return this.props.preProcess ?
				(this.props.preProcess.bind(this)(rowObj) || rowObj) : rowObj;
		});

		// For each item, check for multiple quantities, expanding.
		if (!this.state.template.retainQuantities)
			rows.forEach(row => {
				let quantity = Number(row.quantity);
				if (quantity > 1 && quantity < 50) {
					// If there are multiple, iterate over and generate additional items.
					for (let i = 1; i < quantity; i++) {
						// Give each item a new code.
						rows.push(Object.assign({}, row, {
							code: row.code + "_" + i,
							quantity: "1"
						}));
					}
					// And finally set the original's quantity to 1.
					row.quantity = "1";
				}
			});

		// Return and sort by product code.
		rows.sort((a, b) => {
			if (a.productCode > b.productCode) {
				return 1;
			} else if (a.productCode < b.productCode) {
				return -1;
			} else {
				return 0;
			}
		});
		// If there is a postProcess on the template, run it.
		if (this.state.template.postProcess) {
			rows = this.state.template.postProcess(rows, this.context);
		}
		// Aaaand all done.
		return rows;
	}

	renderStaticHeaders(headers) {
		return headers.map((col, i) => {
			return <div
				className="column-header custom-bg-light custom-border-color-3"
				title={col}
				key={i}
				style={{
					width: (100 / headers.length) + "%",
					display: "inline-block"
				}}
			>
				{col}
			</div>
		})
	}

	renderField() {
		let rowSet = this.state.rows.slice(
			this.state.offset,
			this.state.offset + this.state.setSize
		);
		let rowObjects = this.state.rowObjects.slice(
			this.state.offset,
			this.state.offset + this.state.setSize
		);
		// Local ease of use vars.
		let labels = this.state.template.columnLabels;
		let columns = this.state.template.columns;
		// Render.
		return (
			<div className="field-wrapper">
				{/* DROPDOWN HERE */}
				<SelectField
					label="Select template"
					varname="template"
					options={this.templates.map(t => {
						return {
							val: t.name,
							display: t.name
						}
					})}
					change={(varname, value, error) => {
						//Getting the template
						let template = this.templates.filter(t => {
							return t.name === value;
						})[0];
						//Checking if its different than currently selected template
						if (value !== this.state.template.name) {
							this.setState({ template, columns: template.columns });
						}
					}}
				>
					{this.state.template.name}
				</SelectField>
				<label className="fileLabel button custom-bg-feature1">
					{this.state.rows.length ? "Change" : "Select"} CSV file.
                    <input
						type="file"
						onChange={e => {
							e.preventDefault();
							this.read(e.target.files[0]).then(data => {
								let rows = this.parse(data);
								this.setState({
									rows,
									rowObjects: this.convertToObjects(rows),
									columns: rows[0].map((r, index) => {
										return columns[index] || "ignore";
									}),
									offset: 0,
									setSize: SETSIZE
								});
							}).catch(err => {
								console.log("Error reading file: ", err);
								logger.error(err);
								this.setState({
									rows: []
								});
							});
						}}
						ref="fileInput"
						multiple={false}
						style={{ display: "none" }}
					/>
				</label>
				{/* Static headers */}
				{labels && this.state.rows.length ? <div className="column-headers">
					<br />
					{this.renderStaticHeaders(labels)}
				</div> : null}

				{/* Dropdown headers */}
				{this.state.rows.length ? <div className="column-headers">
					{this.renderHeaders(this.state.rows[0].length)}
				</div> : null}
				{this.state.rows.length ? <div
					className="data-rows"
				>
					{this.renderRows(rowSet)}
				</div> : null}

				{/* Counter */}
				{this.state.rows.length ? <div className="counter">
					<button className="button custom-bg-feature1" onClick={e => {
						this.setState({
							offset: this.state.offset - this.state.setSize
						});
					}}>Previous</button>
					{this.state.offset} / {this.state.rows.length}
					<button className="button custom-bg-feature1" onClick={e => {
						this.setState({
							offset: this.state.offset + this.state.setSize
						});
					}}>Next</button>
				</div> : null}

				{/* Headers */}
				{this.state.rows.length ? <div className="column-headers">
					{this.renderStaticHeaders(Object.keys(rowObjects[0] || {}))}
				</div> : null}

				{/* Converted rows */}
				{this.state.rows.length ? <div className="converted data-rows">
					{this.renderRows(rowObjects.map(obj => {
						return Object.keys(obj).map(key => obj[key])
					}))}
					<br />
				</div> : null}
				{rowObjects.length
					?
					<React.Fragment>
						<div className="barcodeSet">
							{this.state.barcodeEnabled
								?
								<BarcodeSet
									items={this.state.rowObjects.map(s => {
										return {
											code: s.code,
											moreInfo: <div>
												<div>
													CUST: {s.customer} | PC: {s.productCode || "NO CODE"}
												</div>
												<hr />
												<div>{s.description || "NO DESC"}</div>
											</div>
										}
									})}
								/>
								:
								<button
									className="confirm button custom-bg-feature1"
									onClick={e => {
										this.setState({ barcodeEnabled: true });
									}}
								>Prep for print.</button>
							}
						</div>
						<div
							className={"confirm button custom-bg-feature1" + (this.canConfirm ? "" : " disabled")}
							onClick={e => {
								if (this.canConfirm) {
									if (this.props.onConfirm) {
										this.canConfirm = false
										let cb = (shouldClear) => {
											if (shouldClear) this.setState({
												rows: [],
												offset: 0,
												rowObjects: []
											});
											this.canConfirm = true
										}
										let data = this.state.rowObjects;
										if (this.props.postProcess) {
											this.props.onConfirm(data.map(this.props.postProcess), cb)
										} else {
											this.props.onConfirm(data, cb);
										}
									}
								}
							}}
						>
							Confirm
                        </div>
					</React.Fragment>
					:
					null
				}
			</div>
		)
	}
}

StockCSVField.contextType = Context;


StockCSVField.defaultProps = {
	columnChoices: [
		"code",
		"quantity",
		"description",
		"location",
		"customer",
		"ignore",
		"group",
		"productCode"
	],
	preProcess: function (s) {
		// console.log("Preprocessing: ", s)
		let stock = Object.assign({
			code: s.code || rid(),
			quantity: "1",
			customer: s.customer || this.props.customer
		}, s)
		let rows = stock.code.split("\n");
		// If only one value, return.
		if (rows.length < 2) return stock;
		// console.log(rows);
		// Otherwise, change vals.
		stock.code = rows.map(r => {
			return (r.split(":")[1] || "")
				.trim();
		}).join("_");
		return stock;
	},
	postProcess: s => {
		return Object.assign({}, s, {
			quantity: s.quantity ? Number(s.quantity) : 1,
			location: s.location || "UNCONFIRMED"
		})
	}
}

export default StockCSVField;