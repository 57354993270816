import View from "../view";
import { Route, Link } from "react-router-dom";
import ReportsList from "./reportsList";
import Report from "./report";
import DateField from "../../fields/dateField";
import DataField from "../../fields/dataField";
import SelectField from "../../fields/selectField";
import { Aux, renderDate } from "../../helpers";

class ReportsView extends View {
    constructor(props) {
        super(props);
        this.pageSize = 20;
        this.state = {
            report: null,
            reports: [],
            page: 1,
            pageCount: 1,
            filters: {
                to: "",
                from: "",
                customer: "",
                type: ""
            }
        }

        this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);

        this.reportsListColumns = [
            {
                name: "Title",
                renderer: item => item.title

            },
            {
                name: "Customer",
                renderer: item => item.customer,
                sorter: item => item.customer
            },
            {
                name: "Type",
                renderer: item => item.type,
                sorter: item => item.type
            },
            {
                name: "Last Edited",
                renderer: item => {
                    let date = new Date(item.edited || item.created);
                    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
                },
                sorter: item => item.edited
            }
        ]
        this.mobileReportsListColumns = [
            {
                name: "Title",
                renderer: item => {
                    return item.title
                }
            },
            {
                name: "Type",
                renderer: item => {
                    return item.type
                },
                sorter: item => item.type
            }
        ]
    }

    componentDidMount() {
        let reportId = this.props.router.match.params.reportId;
        if (reportId) {
            //Getting single report matching the reportId 
            this.getReports({ id: reportId }, (report) => {
                this.setState({
                    report: report || null,
                    pageCount: 1
                })
            })
        } else {
            //Getting all Reports matching passed query
            this.getReports({
                count: this.pageSize,
                offset: (this.state.page - 1) * this.pageSize
            }, (response) => {
                this.setState({
                    reports: response.results,
                    pageCount: Math.ceil(response.total / this.pageSize)
                })
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.router.match.params.reportId !== this.props.router.match.params.reportId) && !this.state.reports.length) {
            //if you changed the url and there are no reports in state run getReports
            this.getReports({
                count: this.pageSize,
                offset: (this.state.page - 1) * this.pageSize
            }, (response) => {
                this.setState({
                    reports: response.results,
                    pageCount: Math.ceil(response.total / this.pageSize)
                })
            });
        } else if (prevState.page !== this.state.page) {
            //If page was changed
            this.getReports({
                count: this.pageSize,
                offset: (this.state.page - 1) * this.pageSize
            }, (response) => {
                this.setState({
                    reports: response.results,
                    pageCount: Math.ceil(response.total / this.pageSize)
                })
            });
        }
    }

    getReports(query, cb) {
        $.get(`${this.props.apiRoot}/reports/${this.createQueryString(query)}`).done(reports => {
            if (cb) cb(reports);
        }).catch(err => {
            logger.error(err)
        });
    }

    createReport(report, cb) {
        $.post(`${this.props.apiRoot}/reports`, JSON.stringify(report)).done((response) => {
            let reports = [...this.state.reports];
            reports.push(response.report);
            this.setState({ reports });
            if (cb) cb()
        }).catch((err) => {
            console.error(err);
            logger.error(err)
        })
    }

    updateReport(report, id) {
        $.ajax({
            type: "PUT",
            data: JSON.stringify(report),
            url: `${this.props.apiRoot}/reports/${id}`
        }).done(response => {
            let reports = [...this.state.reports].map(r => {
                if (r.id === id) {
                    return response.report
                } else {
                    return l
                }
            })
            logger.success(response.msg)
        }).catch(err => {
            logger.error(err)
        })
    }

    reGetReport() {
        let reportId = this.props.router.match.params.reportId;
        this.getReports({ id: reportId }, (report) => {
            this.setState({
                report: report || null,
                pageCount: 1
            })
        })
    }

    selectReport(report) {
        this.setState({ report });
        this.props.router.history.push(`/reports/${report.id}`);
    }

    filterChange(varname, val, err) {
        let filters = Object.assign({}, this.state.filters);
        if (varname === "customer") {
            filters[varname] = val ? val.name : "";
        } else {
            filters[varname] = val;
        }

        this.setState({ filters })
        this.getReports(filters, (response) => {
            this.setState({
                reports: response.results,
                pageCount: Math.ceil(response.total / this.pageSize)
            })
        })
    }

    renderView() {
        // console.log("PROPS:", this.props);
        // console.log("STATE:", this.state);
        return (
            <Aux>
                <div className="row heading-row">
                    <div className="col-12">
                        <h1 className="client-view custom-c-dark">{`${this.client} Reports`}</h1>
                        <h5 className="date custom-c-mid">{renderDate()}</h5>
                    </div>
                </div>
                <div className="row view reportsView splitPanelView">
                    <Route
                        exact
                        path="/reports/:reportId"
                        render={router => (
                            <div className="col-12 col-xl-1 ui-panel-split ui-panel-split-left custom-bg-light custom-c-light shadow-1 go-back-panel">
                                <Link to="/reports" className="go-back">
                                    <div className="icon report-icon"></div>
                                    <p className="custom-c-dark">Back to Reports</p>
                                    <div className="icon chevron-icon"></div>
                                </Link>
                            </div>
                        )}
                    />
                    <Route
                        exact
                        path="/reports/:reportId"
                        render={router => (
                            <div className="col-12 col-xl-11 report ui-panel-split ui-panel-split-right custom-bg-dark custom-c-light shadow-1">
                                <Report
                                    user={this.props.user}
                                    getReport={this.reGetReport.bind(this)}
                                    router={router}
                                    report={this.state.report}
                                    apiRoot={this.props.apiRoot}
                                    onUpdate={(item, id) => {
                                        this.updateReport(item, id);
                                    }}
                                />
                            </div>
                        )}
                    />
                    <Route
                        exact
                        path="/reports"
                        render={router => (
                            <div className="col-12 col-xl-3 ui-panel-split ui-panel-split-left custom-bg-light custom-c-light shadow-1 filters">
                                <h2 className="panel-name custom-border-color-2">Filter by</h2>
                                <div className="filters">
                                    <DateField
                                        varname="from"
                                        label="From"
                                        change={this.filterChange.bind(this)}
                                    >
                                        {this.state.filters.from}
                                    </DateField>
                                    <DateField
                                        varname="to"
                                        label="To"
                                        change={this.filterChange.bind(this)}
                                    >
                                        {this.state.filters.to}
                                    </DateField>
                                    <SelectField
                                        label="Type"
                                        varname="type"
                                        name="Type"
                                        options={[
                                            {
                                                val: "",
                                                display: "All"
                                            },
                                            {
                                                val: "damage",
                                                display: "Damage"
                                            },
                                            {
                                                val: "general",
                                                display: "General"
                                            },
                                            {
                                                val: "complaint",
                                                display: "Complaint"
                                            }
                                        ]}
                                        change={this.filterChange.bind(this)}
                                    >
                                        {this.state.filters.type}
                                    </SelectField>
                                    {this.props.user.customer === "internal"
                                        ?
                                        <DataField
                                            varname="customer"
                                            defaults={[
                                                {
                                                    name: "internal",
                                                    displayName: "Internal User"
                                                }
                                            ]}
                                            optionRenderer={customer => <div className="option-inner">
                                                <b>{customer.displayName}</b> <br />({customer.name})
                                            </div>
                                            }
                                            label="Customer"
                                            route="customers"
                                            dataName="name"
                                            searchBy="term"
                                            change={this.filterChange.bind(this)}
                                        >
                                            {this.state.filters.customer}
                                        </DataField>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        )}
                    />
                    <Route
                        exact
                        path="/reports"
                        render={router => (
                            <div className="col-12 col-xl-9 reports ui-panel-split ui-panel-split-right custom-bg-dark custom-c-light shadow-1">
                                <div className="icon report-icon title-icon"></div>
                                <h2 className="panel-name">Reports</h2>
                                <ReportsList
                                    numberOfPages={this.state.pageCount}
                                    page={this.state.page}
                                    icon="document"
                                    user={this.props.user}
                                    uniqueField="id"
                                    columns={this.reportsListColumns}
                                    mobileColumns={this.mobileReportsListColumns}
                                    items={this.state.reports}
                                    onPageChange={page => {
                                        this.setState({ page });
                                    }}
                                    onSelect={this.selectReport.bind(this)}
                                    onNew={(report, cb) => {
                                        this.createReport(report, cb);
                                    }}
                                />
                            </div>
                        )}
                    />
                </div>
            </Aux>
        )
    }
}

export default ReportsView;