import "core-js/stable";
import "regenerator-runtime/runtime";
import Context from "./context";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import DesktopNavigation from "./navigation/desktop-navigation";
import MobileNavigation from "./navigation/mobile-navigation";
import DashboardView from "./views/dashboard-view";
import CustomersView from "./views/customer-view";
import ScheduleView from "./views/schedule-view";
import DeliveryView from "./views/delivery-view";
import WorkOrderView from "./views/workOrder-view";
import CollectionView from "./views/collection-view";
import CourierCollectionView from "./views/courier-collection-view";
import RemovalView from "./views/removal-view";
// import MetricsView from "./views/metrics-view";
import SettingsView from "./views/settings-view";
import UtilitiesView from "./views/utilities-view";
import LocationsView from "./views/locations-view";
import UsersView from "./views/users-view";
import ReportsView from "./views/reports-view";
import StockView from "./views/stock-view";
import SandboxView from "./views/sandbox";
import LoginForm from "./assets/loginForm";
import Logger from './assets/logger';
import RemovalInventory from "./views/removal-view/RemovalInventory";
// Processes v2
// import ProcessesView from "./views/processes-view";
import ProcessesView from "./views/processes-view-2";
// Mappings
import MappingsView from "./views/mapping-view";

const loginBackground = "/static/media/login-bg.png";

window.GMAPS_KEY = "AIzaSyBqdcVt7IQIfRzz36dam8hlmmTjyuhDym0";

// Getting jQuery to work in browser console.
window.$ = $;

console.log(process.env.ENV_MODE);

class App extends React.Component {
	constructor(props) {
		super(props);
		// Extracting user token in case of redirect from native app.
		const tkn = new URLSearchParams(window.location.search).get("token");
		if (tkn) this.setCookie("token", tkn);
		this.cookies = this.parseCookies(document.cookie);
		window.cookies = this.cookies;
		this.state = {
			// apiRoot: "https://api.stockjet.co.uk/dev",
			// apiRoot: "http://localhost:3001",
			apiRoot: process.env.ENV_MODE === "DEVELOPMENT" ?
				"http://localhost:3001/v0" : process.env.API_ROOT,
			user: null,
			ready: false,
			mappings: [],
			token: this.cookies.token || null
		}
		this.getUserAttempts = 0;
		window.apiRoot = this.state.apiRoot;
		window.setCookie = this.setCookie.bind(this);
		this.linkData = [
			{ name: "Dashboard", to: "/dashboard", icon: "dash", role: [1, 2, 4] },
			{ name: "Users", to: "/users", icon: "user", role: 1 },
			{ name: "Customers", to: "/customers", icon: "customers", role: [1, 4] },
			{ name: "Locations", to: "/locations", icon: "location", role: [1, 4] },
			{ name: "Reports", to: "/reports", icon: "report", role: 4 },
			{ name: "Schedule", to: "/schedule", icon: "calendar", role: 4 },
			{ name: "Stock", to: "/stock", icon: "stock", role: [1, 2, 4] },
			{ name: "Processes", to: "/processes", icon: "process", role: 3 },
			{ name: "Mappings", to: "/mappings", icon: "mapping", role: [1, 2] },
			// { name: "Metrics", to: "/metrics", icon: "chart", role: 1 },
			{ name: "Settings", to: "/settings", icon: "settings", role: 4 },
			{ name: "Utilities", to: "/utilities", icon: "wrench", role: [1, 2] }
		];
		// Logout timeout
		this.logoutTimeout;
	}

	setCookie(key, value = "") {
		if (!key) return;
		document.cookie = `${key}=${value};expires=${new Date(new Date().getTime() + 1000 * 60 * 60 * 24).toGMTString()};path=/`;
		this.cookies = this.parseCookies(document.cookie);
		window.cookies = this.cookies;
	}

	componentDidMount() {
		if (this.cookies.token) {
			this.gotToken(this.cookies.token, () => {
				this.getCurrentUser(user => {
					this.setState({
						ready: true,
						user
					})
				})
				this.getMappings()
			});
		} else {
			this.setState({ ready: true })
		}
		//Logging out after 10 min
		const logout = () => {
			if (this.state.user && this.state.user.role === 3) {
				this.logout()
			}
		}
		this.logoutTimeout = setTimeout(logout, 600000)
		//Clearing the logout timeout and restarting it on selected events
		$(document).on("click touchstart mousedown", () => {
			//Clearing currently running timeout
			clearTimeout(this.logoutTimeout);
			//Starting new timeout
			this.logoutTimeout = setTimeout(logout, 600000);
		});
	}

	getMappings() {
		$.get(`${this.state.apiRoot}/mappings`).done(res => {
			this.setState({ mappings: res.results })
		}).catch(err => {
			console.error("Error getting mappings: ", err)
		})
	}

	gotToken(token, callback) {
		// console.log(token);
		document.cookie = `token=${token}`;
		// Bind to all ajax requests.
		$.ajaxSetup({
			headers: {
				Authorization: token,
				"content-type": "application/json"
			}
		});
		this.setState({ token }, () => {
			if (callback) callback(token);
		});
	}

	getCurrentUser(cb) {
		this.getUserAttempts++;
		$.get(`${this.state.apiRoot}/security/me`).done(user => {
			this.getUserAttempts = 0;
			if (cb) {
				cb(user);
			} else {
				this.setState({ user });
			}
		}).catch(err => {
			console.error("Error getting current user: ", err);
			setTimeout(() => {
				console.log("Retrying...");
				if (this.getUserAttempts > 3) {
					this.getUserAttempts = 0;
					// window.logger.error("Couldn't get user. Please try again.");
					cb(null);
				} else {
					this.getCurrentUser(cb);
				}
			}, 1000)
		})
	}

	parseCookies(rawCookies) {
		if (!rawCookies) return {};
		let cookies = {};
		rawCookies.split(";").forEach(cookie => {
			let pair = cookie.split("=");
			if (pair[0] && pair[1]) {
				cookies[pair[0].trim()] = pair[1].trim();
			}
		});
		return cookies;
	}

	logout() {
		document.cookie = "token=";
		this.setState({
			user: null
		})
	}

	filterLinkData(user) {
		if (!user) {
			return []
		} else {
			return this.linkData.filter(link => {
				if (typeof link.role === "number") {
					return user.role <= link.role
				} else if (link.role.constructor === Array) {
					return link.role.indexOf(user.role) >= 0
				}
			})
		}
	}

	render() {
		// Check if login page
		let isLoginPage = window.location.pathname === "/login";
		let user = this.state.user;
		// console.log("APP STATE:", this.state);
		let links = this.filterLinkData(user)
		return (
			<Context.Provider
				value={{
					apiRoot: this.state.apiRoot,
					token: this.state.token,
					user: this.state.user,
					mappings: this.state.mappings,
					mapValue: (val = "") => {
						if (!val) return null;
						let match = this.state.mappings.find(m => {
							switch (m.type) {
								case "includes":
									return val.includes(m.value);
								case "startsWith":
									return new RegExp(`^${m.value}`).test(val);
								default:
									return false
							}
						});
						return match ? match.result : null;
					},
					setMappings: mappings => {
						this.setState({ mappings })
					}
				}}
			>
				<BrowserRouter>
					{this.state.ready ? <div
						id="app"
						className={"container-fluid " + ((user && user.data.theme) ? " " + user.data.theme : " light") + (isLoginPage ? " add-bg-img" : "")}
					>
						<Switch>
							<Route path="/login" render={() => (
								<div className="fixed-background" style={{
									backgroundImage: `url("${loginBackground}")`
								}} />
							)} />
							<Route path="/" render={() => (
								<div className="fixed-background custom-bg-main" />
							)} />
						</Switch>
						{user
							?
							<div className="navigation-col">
								<div className="desktopScrollHider custom-bg-main"></div>
								<DesktopNavigation
									name={user.name}
									links={links}
									logout={this.logout.bind(this)}
								/>
								<MobileNavigation
									links={links}
								/>
							</div>
							:
							null
						}
						{user
							?
							<div className="mobile-header">
								<div className="logo custom-bg-feature1">
									<img src="/static/media/clarks-logo.svg" />
								</div>
								<div className="user custom-bg-feature2">
									<img src="/static/media/user-white.svg" className="user-icon" />
									<div className="name-logout">
										<div className="name">{user.name}</div>
										<div
											className="logout"
											onClick={this.logout.bind(this)}
										>Log out
										</div>
									</div>
								</div>
							</div>
							:
							null
						}
						<div className="row">
							<div className={"col-12 ui-main" + (user ? " has-user" : "")}>
								<Logger />
								<div className="container bigger-container">
									{user
										?
										<Switch>
											<Route
												path="/reports/:reportId?"
												render={router => {
													return <ReportsView
														role={this.linkData.filter(l => l.name === "Reports")[0].role}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
													/>
												}}
											/>
											<Route
												path="/schedule"
												render={(router) => {
													return <ScheduleView
														role={this.linkData.filter(l => l.name === "Schedule")[0].role}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
													/>
												}}
											/>
											<Route
												path="/deliveries/:deliveryId?"
												render={router => {
													return <DeliveryView
														role={4}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
													/>
												}}
											/>
											<Route
												path="/workorders/:workOrderId?"
												render={router => {
													return <WorkOrderView
														role={4}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
														customers={this.props.customers}
													/>
												}}
											/>
											<Route
												path="/collections/:collectionId?"
												render={router => {
													return <CollectionView
														role={4}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
														customers={this.props.customers}
													/>
												}}
											/>
											<Route
												path="/courier-collections/:collectionId?"
												render={router => {
													return <CourierCollectionView
														role={4}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
														customers={this.props.customers}
													/>
												}}
											/>
											<Route
												path="/removals/inventory/:removalId"
												render={router => {
													return <RemovalInventory 
														router={router}
														apiRoot={this.state.apiRoot}	
													/>
												}}
											/>
											<Route
												path="/removals/:removalId"
												render={router => {
													return <RemovalView
														role={4}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
													/>
												}}
											/>
											<Route
												path="/customers/:customerName?"
												render={(router) => {
													return <CustomersView
														role={this.linkData.filter(l => l.name === "Customers")[0].role}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
													/>
												}}
											/>
											<Route
												path="/locations/:locationId?"
												render={(router) => {
													return <LocationsView
														role={this.linkData.filter(l => l.name === "Locations")[0].role}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
														locations={this.state.locations}
													/>
												}}
											/>
											<Route
												path="/users/:userId?"
												render={(router) => {
													return <UsersView
														role={this.linkData.filter(l => l.name === "Users")[0].role}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
													/>
												}}
											/>
											<Route
												path="/stock/:stockId?"
												render={(router) => {
													return <StockView
														role={this.linkData.filter(l => l.name === "Stock")[0].role}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
														locations={this.state.locations}
													/>
												}}
											/>
											<Route
												path="/processes/:processesId?"
												render={(router) => {
													return <ProcessesView
														role={this.linkData.filter(l => l.name === "Processes")[0].role}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
													/>
												}}
											/>
											<Route
												path="/mappings/:mappingId?"
												render={(router) => {
													return <MappingsView
														role={this.linkData.filter(l => l.name === "Mappings")[0].role}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
													/>
												}}
											/>
											{/* <Route
                                            path="/metrics"
                                            render={(router) => {
                                                return <MetricsView
                                                    role={this.linkData.filter(l => l.name === "Metrics")[0].role}
                                                    router={router}
                                                    user={user}
                                                />
                                            }}
                                        /> */}
											<Route
												path="/settings"
												render={(router) => {
													return <SettingsView
														role={this.linkData.filter(l => l.name === "Settings")[0].role}
														router={router}
														user={user}
														change={(user) => {
															this.setState({ user })
														}}
														apiRoot={this.state.apiRoot}
													/>
												}}
											/>
											<Route
												path="/utilities"
												render={(router) => {
													return <UtilitiesView
														role={this.linkData.filter(l => l.name === "Utilities")[0].role}
														router={router}
														user={user}
													/>
												}}
											/>
											{user.role !== 3 ? (
												<Route
													path="/dashboard"
													render={(router) => {
														return <DashboardView
															role={this.linkData.filter(l => l.name === "Dashboard")[0].role}
															router={router}
															user={user}
															apiRoot={this.state.apiRoot}
														/>
													}}
												/>
											) : null}
											<Route
												path="/sandbox"
												render={(router) => {
													return <SandboxView
														role={1}
														router={router}
														user={user}
														apiRoot={this.state.apiRoot}
													/>
												}}
											/>
											<Route
												path="/"
												render={router => (
													user.role !== 3 ?
														<Redirect to="/dashboard" />
														:
														<Redirect to="/processes" />
												)}
											/>
										</Switch>
										:
										<Switch>
											<Route
												path="/removals/inventory/:removalId"
												render={router => {
													return <RemovalInventory 
														router={router}
														apiRoot={this.state.apiRoot}	
													/>
												}}
											/>
											<Route
												path="/login"
												render={router => (
													<LoginForm
														onSuccess={token => {
															this.gotToken(token, () => {
																this.getCurrentUser(user => {
																	if (!user) return this.setState({
																		ready: true,
																		user: null
																	});
																	this.setState({
																		ready: true,
																		user
																	})
																	if (user.role === 3) {
																		router.history.push("/processes")
																	}
																});
															});
														}}
														apiRoot={this.state.apiRoot}
													/>
												)}
											/>
											<Route
												path="/"
												render={router => (
													<Redirect to="/login" />
												)}
											/>
										</Switch>
									}
								</div>
							</div>
						</div>
					</div>
					:
					<div className="loading-screen">
						<div className="loading-icon" />
					</div>
				}
				</BrowserRouter>
			</Context.Provider>
		)
	}
}

ReactDOM.render(<App />, document.getElementById("root"));