let converter = require('json-2-csv');

const csvCreator = {}

csvCreator.create = items => {
    return new Promise((resolve, reject) => {
        // map items into flat objects;
        let data = items.map(i => {
            let result = {};
            Object.keys(i).forEach(k => {
                result[k] = i[k] || "";
            });
            return result;
        });
        // Convert.
        converter.json2csv(data, (err, csv) => {
            err ? reject(err) : resolve(csv);
        });
    })
}

csvCreator.download = csv => {
    let blob = new Blob([csv], { type: "text/csv" });
    let a = document.createElement('a');
    a.download = "export.csv";
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = ["text/csv", a.download, a.href].join(':');
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function () {
        URL.revokeObjectURL(a.href);
    }, 1500);
}

export default csvCreator;