import Field from "./field";
import FlipMove from "react-flip-move";

/**
 *  PROPS
 * 
 *      delay: NUMBER(ms) optional
 */

class StaggeredSearchField extends Field {
    constructor(props) {
        super(props);
        this.settings = {
            type: "staggeredSearch",
        }
        this.state.value = this.props.children || "";
        this.state.suggestions = [];
        this.state.showSuggestions = false;
        this.timer = null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.children !== this.props.children)
            this.setState({ value: this.props.children });
    }

    getSuggestions(query) {
        if (!query) return this.setState({
            suggestions: [],
            showSuggestions: false
        });
        $.get(`${window.apiRoot}/${this.props.autoFillItem}/suggestions?field=${this.props.autoFillField}&value=${query}`).then(response => {
            this.setState({
                suggestions: response.results
            });
        }).catch(err => {
            console.error("Error getting suggestions: ", err);
        });
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    startTimer() {
        if (this.timer !== null) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.change(this.state.value);
            // If we have autofill enabled..
            if (this.props.autoFillItem && this.props.autoFillField) {
                this.getSuggestions(this.state.value);
            }
        }, this.props.delay || 2000);
    }

    renderField() {
        return (
            <React.Fragment>
                <input
                    onFocus={e => {
                        this.setState({
                            showSuggestions: true
                        });
                    }}
                    onBlur={e => {
                        setTimeout(() => {
                            this.setState({
                                showSuggestions: false
                            });
                        }, 300);
                    }}
                    type="text"
                    className="custom-border-3"
                    value={this.state.value}
                    onChange={e => {
                        this.setState({
                            value: e.target.value,
                            showSuggestions: true
                        });
                        this.startTimer();
                    }}
                />
                <FlipMove
                    duration={200}
                    enterAnimation="fade"
                    leaveAnimation="fade"
                >
                    {this.state.showSuggestions && this.state.suggestions.length ? <div
                        className="suggestions"
                        key="suggestions"
                    >
                        {this.state.suggestions.map(suggestion => {
                            return <div
                                key={suggestion}
                                title={suggestion}
                                className="suggestion"
                                onClick={e => {
                                    this.change(suggestion);
                                }}
                            >{suggestion}</div>
                        })}
                    </div> : null}
                </FlipMove>
            </React.Fragment>
        )
    }
}

export default StaggeredSearchField;