/**
 * UiPanel with added contorls to the header for changing months etc.
 */

import UiPanel from "./ui-panel"

/**
 *  PROPS
 * 
 *      clickHandler: FUNCTION
 *      showWeekend: BOOLEAN
 *      classes: STRING
 *      name: STRING
 *      icon: STRING
 */

class UiCalendarPanel extends UiPanel {
    renderHeader() {
        return <div className="custom-c-light">
            <div className="monthControls">
                <span>
                    <span
                        className="arrow left-arrow"
                        onClick={e => {
                            if (this.props.clickHandler)
                                this.props.clickHandler("month", -1);
                        }}
                    >
                    </span>
                    <span className="title">
                        {this.props.name}
                    </span>
                    <span
                        className="arrow right-arrow"
                        onClick={e => {
                            if (this.props.clickHandler)
                                this.props.clickHandler("month", 1);
                        }}
                    ></span>
                </span>
                <span
                    className="show-weekend"
                    onClick={e => {
                        if (this.props.clickHandler)
                            this.props.clickHandler("showWeekend", !this.props.showWeekend);
                    }}
                >
                    {this.props.showWeekend ? "Hide weekend days" : "Show weekend days"}
                </span>
            </div>
        </div>
    }
}

export default UiCalendarPanel;