import Field from "./field";
import TextField from "./textField";
import { rid } from "../helpers" 

class CodeField extends Field {
    constructor(props) {
        super(props);
        this.settings = {
            type: "code"
        }
    }

    allowRandomGenerating() {
        //only allow to auto generate a code if the text field is empty
        if (this.props.children) {
            return false;
        } else {
            return true;
        }
    }

    renderField() {
        return (
            <div className="field-wrapper">
                <TextField
                    varname="code"
                    change={(varname, value, err) => {
                        this.change(value)
                    }}    
                >
                    {this.props.children}
                </TextField>
                <div 
                    className={this.allowRandomGenerating() ? "custom-bg-feature1 btn" : "disabled btn"}
                    title="generate code"
                    onClick={() => {
                        if (this.allowRandomGenerating())
                        this.change(rid())
                    }}
                >#</div>
            </div>    
        )
    }

}

export default CodeField;