import Field from "./field";
import FlipMove from "react-flip-move";
import Context from "../context";

/**
 *  STATE:
 * 
 *      files: ARRAY of file(s) 
 *
 *  PROPS:
 * 
 *      multiple: BOOLEAN
 *      children: ARRAY of file(s)
 */

class FileField extends Field {
	constructor(props) {
		super(props);
		this.state.files = this.props.children || [];
		this.state.previewing = null;
		this.settings = {
			type: "file"
		}
	}

	componentDidUpdate() {
		if (this.props.children && this.props.children.length && !this.state.files.length) {
			this.setState({ files: this.props.children })
		};
	}

	readFiles(files) {
		let promises = files.map(file => {
			return new Promise((resolve, reject) => {
				//If file is too large
				if (file.size >= 40000000) {
					logger.error("Files are limited to 40MB.");
					return resolve(null);
				}
				// Construct some serialized file data.
				let ext;
				let fileData = {
					name: file.name,
					url: null,
					type: file.type,
					extension: (ext = /(\.[^\.]+)$/.exec(file.name)) ? ext[1] : ""
				}
				// Construct a file reader to get a buffer for the file.
				let reader = new FileReader();
				// Callback for when the file is read.
				reader.onload = readEvent => {
					fileData.buffer = readEvent.target.result;
					resolve(fileData);
				}
				// Start reading the file.
				reader.readAsArrayBuffer(file);
			});
		});

		Promise.all(promises).then(files => {
			// Filter out any null files.
			files = files.filter(f => f);
			// Set the state for rendering.
			this.setState({ files: [...this.state.files, ...files] });
			// Make an upload call for each.
			files.forEach(file => {
				this.uploadFile(file);
			});
		});
	}

	serialize(files) {
		return files.map(f => {
			return {
				url: f.url,
				name: f.name,
				type: f.type
			}
		})
	}

	uploadFile(file) {
		$.post({
			url: `${this.context.apiRoot}/misc/file`,
			data: JSON.stringify({
				extension: file.extension,
				type: file.type
			})
		}).done(response => {
			let url = response.url;
			// console.log("URL: ", url);
			if (!file.buffer) {
				logger.error("Cannot upload an empty file.");
			}
			let self = this;
			let xhr = new XMLHttpRequest()
			xhr.onreadystatechange = function () {
				if (this.readyState === 4) {
					let newFiles;
					if (this.status === 200) {
						// put response url into the state for rendering.
						newFiles = self.state.files.map(f => {
							if (f.name === file.name) f.url = response.fileName;
							return f;
						})
					} else {
						// Handle failed upload.
						logger.error("Couldn't upload file " + file.name);
						newFiles = self.state.files.filter(f => f.name !== file.name)
					}
					// Set the state for rendering.
					self.setState({ files: newFiles });
					// If all files uploaded, we can call a change.
					if (!newFiles.filter(f => !f.url).length)
						self.change(self.serialize(newFiles));
				}
			};
			xhr.open("PUT", url);
			xhr.setRequestHeader("Content-Type", file.type);
			xhr.send(file.buffer);
		}).catch(err => {
			console.error("Error getting S3 signed url: ", err);
			logger.error("Error uploading document. Please try again.");
		});
	}

	renderField() {
		// console.log("CHILDREN: ", this.props.children);
		// console.log("FILES: ", this.state.files);
		return (
			<div>
				<FlipMove>
					{this.state.files.map(file => {
						let image, hasImage;
						if (!file.url) {
							image = "/static/media/loading.gif"
						} else if (file.type.includes("image")) {
							image = file.url;
							hasImage = true;
						} else {
							image = "/static/media/file.svg";
						}
						return (
							<div className="file-wrapper" key={file.name} >
								<div
									className="frame"
									style={{ cursor: file.url ? "pointer" : "default" }}
									onClick={e => {
										if (!file.url) {
											return null;
										} else if (file.type.includes("image")) {
											this.setState({ previewing: file })
										} else {
											window.open(file.url, file.name)
										}
									}}
								>
									<div
										className={hasImage ? "file" : "file default"}
										style={{ backgroundImage: `url("${image}")` }}
									/>
								</div>
								{!this.props.readOnly && <div
									className="remove-file"
									onClick={() => {
										if (this.props.readOnly) return null;
										let files = [...this.state.files];
										files.forEach((elem, index) => {
											if (elem.name === file.name) {
												files.splice(index, 1)
											}
										})
										this.setState({ files });
										if (!files.filter(f => !f.url).length)
											this.change(this.serialize(files));
									}}
								>
									{this.props.readOnly ? null : <div className="icon remove-icon"></div>}
									<span className="custom-c-dark">{file.name}</span>
								</div>}
							</div>
						)
					})}
					{this.props.readOnly ? null : <label className="frame" key="add">
						<div className="addFile icon add-file-icon"></div>
						<input
							type="file"
							onChange={(e) => {
								e.preventDefault();
								if (!this.refs.fileInput.files || !this.refs.fileInput.files.length) {
									return null;
								}
								let files = [];
								for (var i = 0; i < this.refs.fileInput.files.length; i++) {
									files.push(this.refs.fileInput.files.item(i));
								}
								this.readFiles(files);
							}}
							ref="fileInput"
							multiple={this.props.multiple}
						/>
					</label>}
				</FlipMove>
				{this.state.previewing
					?
					<div className="preview">
						<div
							className="icon-white close"
							onClick={() => {
								this.setState({ previewing: null })
							}}
						>
						</div>
						<div className="img-wrapper">
							<div
								className="img"
								style={{
									backgroundImage: `url('${this.state.previewing.url}')`
								}}
							>
							</div>
							<a className="download" download href={this.state.previewing.url}>download</a>
						</div>
					</div>
					:
					null
				}
			</div>
		)
	}
}

FileField.contextType = Context;

export default FileField;