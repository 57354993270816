import Process from "./index";
// Stages
import MultiStockSelect from "../stages/multiStockSelect";
import ListStage from "../stages/listStage";
import Action from "../stages/action";
import Custom from "../stages/custom";
import Confirm from "../stages/confirm";
import Success from "../stages/success";
//Other components
import NumberField from "../../../fields/numberField";

class SplitStock extends Process {
    constructor(props) {
        super(props);
        this.state.stage = 0;
        this.stages = [
            {
                // 0 - Selecting stock item(s) to split
                component: MultiStockSelect
            },
            {
                // 1 - Checking hom many matches
                component: Action,
                config: {
                    action: ({ changeStage }) => {
                        if (this.stageData(0).length > 1) {
                            changeStage(2)
                        } else {
                            changeStage(3)
                        }
                    }
                }
            },
            {
                // 2 - Selecting item from the list of matches
                component: ListStage,
                config: {
                    icon: "stock",
                    title: "Select Stock Item",
                    items: () => this.stageData(0),
                    renderItem: item => {
                        return (
                            <div className="content">
                                <div><b>Customer:</b> {item.customer}</div>
                                {item.productCode && <div><b>Product code:</b> {item.productCode}</div>}
                                {item.code && <div><b>Code:</b> {item.code}</div>}
                                {item.productBarcode && <div><b>Product Barcode:</b> {item.productBarcode}</div>}
                                <div><b>Location:</b> {item.location}</div>
                                <div><b>Quantity:</b> {item.quantity}</div>
                            </div>
                        );
                    }
                },
            },
            {
				// 3 - Standardise data from previous stages into one object
				component: Action,
				config: {
					action: ({ finish }) => {
						if (this.stageData(0).length > 1) {
							finish(this.stageData(2))
						} else {
							finish(this.stageData(0)[0])
						}
					}
				}
			},
            {
                // 4 - Check the selected item's quantity
                component: Action,
                config: {
                    action: ({ changeStage, finish, error }) => {
                        // If stock quantity is bigger than 1 continue
                        if (this.stageData(3).quantity > 1) {
                            finish();
                        } else {
                            error("Stock item quantity must be greater than 1 to split.");
                            setTimeout(() => {
                                changeStage(0);
                            }, 3000);
                        }
                    }
                }
            },
            {
                // 5 - Select the quantity you want to split
                component: Custom,
                config: {
                    state: {
                        quantity: 1
                    },
                    render: stage => {
                        return (
                            <div className="selectNumberStage">
                                <NumberField
                                    min={1}
                                    max={this.stageData(3).quantity - 1}
                                    change={(varname, value, error) => {
                                        stage.setState({ quantity: value });
                                    }}
                                >
                                    {stage.state.quantity}
                                </NumberField>
                                <button
                                    className="next-stage-btn custom-bg-feature1"
                                    onClick={() => {
                                        stage.stageComplete(stage.state.quantity);
                                    }}
                                >
                                    <p>Confirm Split</p>
                                    <div className="icon-white arrow"></div>
                                </button>
                            </div>
                        )
                    }
                }
            },
            {
                component: Confirm,
                config: {
                    icon: "split-stock",
                    message: () => <div className="summary custom-c-light">
                        <p>You are about to split
                            <span className="custom-c-dark"> {this.stageData(3).code} </span>
                            into two stacks of
                            <span className="custom-c-dark"> {this.stageData(3).quantity - this.stageData(5)} </span>
                            and
                            <span className="custom-c-dark"> {this.stageData(5)} </span>
                            items.
                        </p>
                    </div>
                }
            },
            {
                component: Action,
                config: {
                    action: ({ finish }) => {
                        let changes = {
                            quantity: this.stageData(5)
                        };
                        this.splitStock(this.stageData(3).id, changes, () => {
                            finish();
                        });
                    }
                }
            },
            {
                component: Success
            }
        ]
    }

    splitStock(id, changes, cb) {
        $.ajax({
            type: "PUT",
            url: `${this.context.apiRoot}/stock/${id}/split`,
            data: JSON.stringify(changes)
        }).done(response => {
            if (cb) cb(response)
        }).catch(err => {
            logger.error(err);
        })
    }
}

export default SplitStock;