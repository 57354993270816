import SuperList from "../../assets/ui-super-list/";

class GroupList extends SuperList {

    renderItemCreator() {
        return null;
    }

    renderControls(item) {
        return <div
            title="Find items"
            key="findItems"
            className="control icon search-icon"
            onClick={e => {
                if (this.props.onFindStock) 
                    this.props.onFindStock(item);
            }}
        />
    }

}

GroupList.defaultProps = {
    icon: "stock",
    uniqueField: "code",
    columns: [
        {
            name: "Group Code",
            renderer: item => item.code,
            sorter: item => item.code
        },
        {
            name: "Items",
            renderer: item => item.items,
            sorter: item => item.items || ""
        }
    ],
    mobileColumns: [
        {
            name: "Group Code",
            renderer: item => item.code,
            sorter: item => item.code
        }
    ]
}


export default GroupList;