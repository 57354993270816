import React from "react";
import FlipMove from "react-flip-move";
import Context from "../../../context";

class Stage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null
        }
        // Any passed state in configuration.
        if (props.config && props.config.state) {
            if (typeof props.config.state === "function") {
                this.state = Object.assign(this.state, props.config.state())
            } else {
                this.state = Object.assign(this.state, props.config.state);
            }
        } 
        
        this.errorInterval = null;
        this.stageData = props.stageData;
    }

    config() {
        return this.props.config ? this.props.config : {}
    }

    error(err) {
        this.setState({
            error: err,
            errorTime: Date.now()
        });
    }

    exit() {
        this.props.onExit();
    }

    changeStage(stageNumber) {
        this.props.onChangeStage(stageNumber);
    }

    stageComplete(data) {
        this.props.onComplete(data);
    }

    componentDidMount() {
        this.errorInterval = setInterval(() => {
            if (this.state.error && Date.now() - this.state.errorTime > 3000) this.setState({
                error: null,
                errorTime: null
            });
        }, 1500);
    }

    componentWillUnmount() {
        if (this.errorInterval) clearInterval(this.errorInterval);
    }

    renderStage() {
        return <p>Please extend me with some content.</p>
    }

    render() {
        return <div className="stage custom-c-dark">
            {this.config().itemLink
                ?
                this.config().itemLink()
                :
                null
            }
            <div className="stage-heading custom-border-color">
                {(this.props.title || this.props.config.title) || null}
            </div>
            <div className="stage-content">
                {this.config().before ? <div className="before">
                    {this.config().before.bind(this)(this)}
                </div> : null}
                {this.renderStage()}
            </div>
            <div className="stage-errors">
                <FlipMove>
                    {this.state.error
                        ?
                        <div className="error-msg" key="error">
                            {this.state.error}
                        </div>
                        :
                        null
                    }
                </FlipMove>
            </div>
        </div>
    }
}

Stage.contextType = Context;

export default Stage;
