import Field from "./field";

/**
 *  PROPS
 * 
 *      delay: NUMBER(ms) optional 
 */

class PasswordField extends Field {
    constructor(props) {
        super(props);
        this.state.password = this.props.children;
        this.state.confirm = ""
        this.settings = {
            type: "password"
        }
        this.timer = null;
    }

    change(password, confirm) {
        if ((password === confirm) && this.props.change) {
            //If both values are matching and change handler is passed.
            // console.log("TRIGGERING PROP CHANGE");
            this.props.change(this.props.varname, this.state.password, null);
        } else {
            //Logg error message
            this.props.change(this.props.varname, "", null);
            logger.error("Password and Confirm Password fields have to match");
        }
    }

    startTimer(password, confirm) {
            // console.log("PASSWORD:", password);
            // console.log("CONFIRM:", confirm);
            if (this.timer !== null) clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                if (password && confirm) {
                    this.change(password, confirm);
                }
            }, this.props.delay || 1000);
    }

    renderField() {
        return (
            <div className="field-wrapper">
                <div className="password">
                    <label>Password</label>
                    <input
                        type="password"
                        className="custom-border-3"
                        value={this.state.password}
                        onChange={e => {
                            this.setState({ password: e.target.value });
                            this.startTimer(e.target.value, this.state.confirm);
                        }}
                    />
                </div>
                <div className="confirm-password">
                    <label>Confirm Password</label>
                    <input
                        type="password"
                        className="custom-border-3"
                        value={this.state.confirm}
                        onChange={e => {
                            this.setState({ confirm: e.target.value });
                            this.startTimer(this.state.password, e.target.value);
                        }}
                    />
                </div>
            </div>
        )
    }
}

export default PasswordField;