import View from "./view";
import { Aux, renderDate } from "../helpers";
import NumberField from "../fields/numberField";
import JsBarcode from "jsbarcode";
import { rid } from "../helpers";

class UtilitiesView extends View {
	constructor(props) {
		super(props);

		this.state = {
			barcodes: 1,
			printing: false
		}

		this.iframe = React.createRef();
		this.target = React.createRef();
		this.bcTarget = React.createRef();
		this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);

	}

	renderView() {
		return (
			<Aux>
				<div className="row heading-row">
					<div className="col-12">
						<h1 className="client-view custom-c-dark">{`${this.client} Utilities`}</h1>
						<h5 className="date custom-c-mid">{renderDate()}</h5>
					</div>
				</div>
				<div className="row view utilitiesView">
					<div className="col-6">
						<NumberField
							min={1}
							label="Number of barcodes to print"
							change={(name, val) => this.setState({ barcodes: val })}
						>
							{this.state.barcodes}
						</NumberField>
						<br />
						<div
							className={`button custom-bg-feature2 ${this.state.printing ? "disabled" : ""}`}
							onClick={() => {
								// Stop if already printing
								if (this.state.printing) return null;
								// Start print process
								this.setState({ printing: true });
								// Create a list of barcode values
								const codes = Array.from(Array(this.state.barcodes).keys())
									.map(item => rid());
								// Empty the iframe 
								this.iframe.current.contentWindow.document.querySelector("body").innerHTML = "";
								for (const code of codes) {
									// Create a barcode
									JsBarcode(this.bcTarget.current, code, { margin: 0 });
									let content = this.target.current.cloneNode(true);
									// Append a barcode for each code generated
									this.iframe.current.contentWindow.document.querySelector("body").appendChild(content);
								}
								// Trigger print
								this.iframe.current.contentWindow.print();
								// End print process
								this.setState({ printing: false });
							}}
						>
							Print
						</div>
					</div>
					{/* The container used generating a barcode */}
					<div style={{ display: "none" }}>
						<div ref={this.target} style={{ width: "100%" }}>
							<style>{`
								svg { 
									display: block;
									margin: auto;
									max-width: 100% !important;
									max-height: 100%;
									overflow: initial !important;
								}	
							`}</style>
							<svg ref={this.bcTarget}></svg>
							<div style={{ pageBreakAfter: "always" }} />
						</div>
					</div>
					{/* Hidden iframe that gets print triggered on it. */}
					<iframe style={{ display: "none" }} ref={this.iframe} />
				</div>
			</Aux>
		)
	}
}

export default UtilitiesView;
