import ItemCreator from "../../assets/ui-super-list/itemCreator";
import FlipMove from "react-flip-move";
import TextField from "../../fields/textField";
import SelectField from "../../fields/selectField";
import CustomerField from "../../fields/customerField";
import LocationSearchField from "../../fields/locationSearchField";

class MappingCreator extends ItemCreator {
    constructor(props) {
        super(props)
    }
    canSubmit() {
        if (this.props.item) {
            // Updating existing mapping
            if (
                (this.props.item.value !== this.state.item.value && this.state.item.value) ||
                (this.props.item.type !== this.state.item.type && this.state.item.type) ||
                (this.props.item.result !== this.state.item.result && this.state.item.result)
            ) return true;
        } else {
            // Creating a new one
            if (this.state.item.value && this.state.item.type && this.state.item.result && this.state.item.resultType) return true;
        }

        // Return false by default
        return false;
    }

    change(vn, val) {
        let item = this.removeEmptyStrings(Object.assign({}, this.state.item, {
            [vn]: val
        }));
        this.setState({ item }, () => {
            if (this.props.item && val !== "") {
                let changes = Object.assign({}, this.state.changes, { [vn]: val });
                this.setState({ changes });
            }
        })
    }

    resultsField(type) {
        switch (type) {
            case "customer":
                return (
                    <CustomerField
                        varname="result"
                        key="cus"
                        label={false}
                        defaults={this.props.user.role >= 4
                            ?
                            []
                            :
                            [
                                {
                                    name: "internal",
                                    displayName: "Internal User"
                                }
                            ]
                        }
                        change={(vn, val, err) => {
                            this.change("result", val ? val.name : "")
                        }}
                    >
                        {this.state.item.result || ""}
                    </CustomerField>
                );
            case "location":
                return (
                    <LocationSearchField
                        varname="result"
                        key="loc"
                        change={(vn, val, err) => {
                            this.change("result", val ? val.locator : "")
                        }}
                    >
                        {this.state.item.result || ""}
                    </LocationSearchField>
                );
            default:
                return null
        }
    }

    renderCreator() {
        return (
            <div className="creator">
                <div className="field-combiner">
                    <SelectField
                        label="Type"
                        varname="type"
                        name="Select Type"
                        options={[
                            {
                                val: "includes",
                                display: "Includes"
                            },
                            {
                                val: "startsWith",
                                display: "Starts With"
                            }
                        ]}
                        change={(vn, val, err) => {
                            this.change(vn, val)
                        }}
                    >
                        {this.state.item.type || ""}
                    </SelectField>
                    <TextField
                        varname="value"
                        label="Value"
                        change={(vn, val, err) => {
                            this.change(vn, val)
                        }}
                    >
                        {this.state.item.value || ""}
                    </TextField>
                </div>
                <br />
                <div className="custom-c-dark" style={{ textAlign: "center", fontWeight: "bold" }}>Maps To:</div>
                <br />
                <FlipMove className="field-combiner">
                    <SelectField
                        key="res"
                        varname="resultType"
                        name="Select Result Type"
                        options={[
                            {
                                val: "customer",
                                display: "Customer"
                            },
                            {
                                val: "location",
                                display: "Location"
                            }
                        ]}
                        change={(vn, val, err) => {
                            if (val !== this.state.item.resultType) {
                                let item = this.removeEmptyStrings(Object.assign({}, this.state.item, {
                                    [vn]: val,
                                    result: ""
                                }));
                                this.setState({ item }, () => {
                                    if (this.props.item && val !== "") {
                                        let changes = Object.assign({}, this.state.changes, { [vn]: val, result: "" });
                                        this.setState({ changes });
                                    }
                                })
                            }
                        }}
                    >
                        {this.state.item.resultType}
                    </SelectField>
                    {this.resultsField(this.state.item.resultType)}
                </FlipMove>
                <br />
            </div>
        )
    }
}

export default MappingCreator;