import Stage from "./stage";
// import FlipMove from "react-flip-move";

class Success extends Stage {

    renderStage() {
        return (
            <div className="confirmStage">
                <div className="icon-wrap">
                    {this.config().icon ? <div className={`icon ${this.config().icon}-icon`} /> : null}
                </div>
                <div className="message">
                    {this.config().message ? this.config().message() : "Success!"}
                </div>
                <br /><br />
                <button
                    className="confirm button custom-bg-feature1"
                    onClick={() => {
                        if (this.config().action) {
                            this.config().action();
                        } else {
                            this.stageComplete();
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        )
    }
}

Success.defaultProps = {
    title: "Success.",
}

export default Success;