import FlipMove from "react-flip-move";
import Process from "./index";
import ScanInput from "../../../fields/scanInput";
// Stages.
import ListStage from "../stages/listStage";
import Action from "../stages/action";
import Success from "../stages/success";
import Custom from "../stages/custom";
import RiskAssessmentStage from "../stages/riskAssessmentStage";
import SignatureStage from "../stages/signatureStage";
//Other components
import CheckboxField from "../../../fields/checkboxField";
import FileField from "../../../fields/fileField";

class UnloadWorkOrder extends Process {
    constructor(props) {
        super(props);
        this.state.stage = 0;
        this.state.showUnscannedOnly = false
        this.stages = [
            {
                // 0 - Load workOrders.
                component: Action,
                config: {
                    action: ({ finish, error, exit }) => {
                        this.getItems((err, items) => {
                            if (!err) {
                                finish(items);
                            } else {
                                console.error("Couldn't get work orders: ", err);
                                error("Error getting work orders");
                                setTimeout(() => {
                                    exit();
                                }, 3000);
                            }
                        });
                    }
                }
            },
            {
                // 1 - Showing list of work orders
                component: ListStage,
                config: {
                    icon: "document",
                    title: "Select Work Order",
                    items: () => this.stageData(0),
                    renderItem: item => {
                        return (
                            <div className="content">
                                <p className="customer">{item.customer}</p>
                                <p className="status">status: {item.status}</p>
                                <div className="date-time">
                                    <p className="date">{this.msToDate(item.dateOutgoing)}</p>
                                    <p className="time">{`At ${item.timeOutgoing}`}</p>
                                </div>
                                <p className="stock">{`stock quantity: ${item.stock.length}`}</p>
                                {item.vehicle
                                    ?
                                    <p className="vehicle">{`Vehicle: ${[item.vehicle].concat(item.vehicles || []).join(", ")}`}</p>
                                    :
                                    null
                                }
                                {item.notes
                                    ?
                                    <div className="notes-wrapper">
                                        <hr />
                                        <p className="notes">{item.notes}</p>
                                    </div>
                                    :
                                    null
                                }
                                <hr />
                                <p className="id">{item.id}</p>
                            </div>
                        )
                    }
                }
            },
            {
                // 2 - Checking if workOrder has risk assessment
                component: Action,
                config: {
                    action: ({ changeStage }) => {
                        if (this.stageData(1).riskAssessment) {
                            changeStage(5)
                        } else {
                            changeStage(3)
                        }
                    }
                }
            },
            {
                // 3 - Risk assessment stage
                component: RiskAssessmentStage,
                config: {
                    title: "Risk Assessment",
                    itemLink: () => {
                        return (
                            <div style={{ padding: "5px 0px" }}>
                                <a href={`/workorders/${this.stageData(1).id}`} target="_blank">
                                    View work order
                                </a>
                            </div>
                        )
                    } 
                }
            },
            {
                // 4 - Saving risk assessment
                component: Action,
                config: {
                    action: ({ changeStage, error, exit }) => {
                        this.saveRiskAssessment((err) => {
                            if (!err) {
                                changeStage(5)
                            } else {
                                error("Error while saving risk assessment");
                                setTimeout(() => {
                                    exit()
                                }, 3000);
                            }
                        })
                    }
                }
            },
            {
                // 5 - Getting full stock items object for selected work order
                component: Action,
                config: {
                    action: ({ changeStage, error, exit }) => {
                        this.getFullStock(this.stageData(1).stock, (err, items) => {
                            if (!err) {
                                let finished = true;
                                items.forEach(item => {
                                    if (item.status !== "unloaded") finished = false
                                })
                                this.setStageData(5, items, () => {
                                    if (finished) {
                                        // All items have been loaded
                                        if (this.stageData(1).signedBy && this.stageData(1).signature) {
                                            // Already got name and signature
                                            changeStage(10)
                                        } else {
                                            changeStage(9)
                                        }
                                    } else {
                                        changeStage(6)
                                    }
                                })
                                // finish(items);
                            } else {
                                console.error("Couldn't get full stock data:", err);
                                error("Error getting work orders stock");
                                setTimeout(() => {
                                    exit();
                                }, 3000);
                            }
                        })
                    }
                }
            },
            {
                // 6 - Showing list of stock for selected work order
                component: ListStage,
                config: {
                    icon: "stock",
                    title: "Scan Stock Item",
                    state: {
                        inputValue: ""
                    },
                    itemLink: () => {
                        return (
                            <div style={{ padding: "5px 0px" }}>
                                <a href={`/workorders/${this.stageData(1).id}`} target="_blank">
                                    View work order
                                </a>
                            </div>
                        )
                    },
                    before: stage => {
                        // Getting total numbers for stock and loaded stock
                        let total = this.stageData(1).stock.length;
                        let scanned = this.stageData(1).stock.filter(s => s.status === "unloaded").length;
                        // Render scanned stock toggle, total numbers of stock scanned and stock selector
                        return (
                            <div>
                                <div className="totalItems">
                                    {`[${scanned}/${total}]`}
                                </div>
                                <div className="toggle">
                                    <CheckboxField
                                        label="Show unscanned only"
                                        change={(vn, val, err) => {
                                            this.setState({ showUnscannedOnly: val })
                                        }}
                                    >
                                        {this.state.showUnscannedOnly}
                                    </CheckboxField>
                                </div>
                                <ScanInput
                                    onScan={code => {
                                        let match = this.stageData(5).filter(s => s.code === code)[0];
                                        if (match) {
                                            if (match.status && match.status === "unloaded") {
                                                stage.error("Item already unloaded.")
                                            } else {
                                                stage.stageComplete(match)
                                            }
                                        } else {
                                            stage.error("No match found for that bar code.")
                                        }
                                    }}
                                />
                            </div>
                        )
                    },
                    items: () => {
                        if (this.state.showUnscannedOnly) {
                            return this.stageData(5).filter(s => (!s.status || s.status !== "unloaded"))
                        }
                        return this.stageData(5)
                    },
                    disable: item => true,
                    renderItem: item => {
                        return (
                            <div className="content">
                                <p className="code"><span>Product code:</span>{item.productCode}</p>
                                <p className="code"><span>Barcode:</span><br />{item.code}</p>
                                <p className="group"><span>Group:</span>{item.group}</p>
                                <p className="location"><span>Loc:</span>{item.location}</p>
                                <p className="destination"><span>Dest:</span>{item.destination}</p>
                                <hr />
                                <p className="description"><span>desc:</span>{item.description}</p>
                                {item.status && item.status === "unloaded"
                                    ?
                                    <div className="green-tick"></div>
                                    :
                                    null
                                }
                            </div>
                        )
                    }
                }
            },
            {
                // 7 - Rendering a message to inform user how many stock left to unload for each group
                component: Action,
                config: {
                    action: ({ finish }) => {
                        // Group of the scanned stock item
                        let currentGroup = this.stageData(6).group;
                        // Total stock for that group
                        let total = this.stageData(5).filter(s => s.group === currentGroup);
                        //Number of items from that group already unloaded
                        let unloaded = total.filter(s => s.status === "unloaded").length + 1;
                        // finish the stage with the message
                        finish(`Item ${unloaded} of ${total.length} from group ${currentGroup}`);
                    }
                }
            },
            {
                // 8 - Add optional images and location data to previously selected 
                component: Custom,
                config: {
                    state: {
                        files: [],
                        gettingGps: false
                    },
                    itemLink: () => {
                        return (
                            <div style={{ padding: "5px 0px" }}>
                                <a href={`/workorders/${this.stageData(1).id}`} target="_blank">
                                    View work order
                                </a>
                            </div>
                        )
                    },
                    render: stage => {
                        return (
                            <div className="workOrderStockFileStage">
                                <p className="items-left-info">{this.stageData(7)}</p>
                                <FileField
                                    varname="files"
                                    apiRoot={this.context.apiRoot}
                                    change={(varname, value, error) => {
                                        stage.setState({ files: value })
                                    }}
                                >
                                    {stage.state.files}
                                </FileField>
                                <div
                                    className="add custom-bg-feature1"
                                    onClick={!stage.state.gettingGps ? () => {
                                        let data = { files: [] };
                                        stage.setState({ gettingGps: true });
                                        // Getting coordinates of where the item was unloaded.
                                        this.getCoordinates().then(gps => {
                                            if (stage.state.files.length) {
                                                data = {
                                                    files: stage.state.files.map(f => {
                                                        return f.url
                                                    })
                                                }
                                            }
                                            // Adding coordinates to the item data
                                            data.gps = gps;
                                            $.ajax({
                                                type: "PUT",
                                                url: `${this.context.apiRoot}/workorders/${this.stageData(1).id}/unload/${this.stageData(6).id}`,
                                                data: JSON.stringify(data)
                                            }).then(response => {
                                                // Check if work order was completed
                                                let finished = true;
                                                response.workOrder.stock.forEach(s => {
                                                    if (s.status !== "unloaded") finished = false
                                                });
                                                // Update the selected work order object
                                                this.setStageData(1, response.workOrder, () => {
                                                    if (finished) {
                                                        // All items are unloaded
                                                        if (response.workOrder.signature && response.workOrder.signedBy) {
                                                            // Already got a signature
                                                            this.changeStage(10)
                                                        } else {
															this.changeStage(9)
                                                        }
                                                    } else {
														this.changeStage(5)
                                                    }
                                                })
                                            }).catch(err => {
												console.error(err);
                                                stage.error("Error unloading stock item.");
												stage.setState({ gettingGps: false });
                                            });
                                        }).catch(err => {
                                            stage.error(err);
                                            stage.setState({ gettingGps: false });
                                        });
                                    } : null}
                                >
                                    {!stage.state.gettingGps ? "Next" : "Getting GPS.."}
                                </div>
                            </div>
                        )
                    }
                }
            },
            {
                // 9 - Storing signature
                component: SignatureStage,
                config: {
                    title: "Add signature",
                    itemLink: () => {
                        return (
                            <div style={{ padding: "5px 0px" }}>
                                <a href={`/workorders/${this.stageData(1).id}`} target="_blank">
                                    View work order
                                </a>
                            </div>
                        )
                    }
                }
            },
            {
                // 10 - Adding signature to the work order
                component: Action,
                config: {
                    action: ({ error, finish, changeStage }) => {
                        let data = this.stageData(9);
                        $.ajax({
                            url: `${this.context.apiRoot}/workorders/${this.stageData(1).id}`,
                            type: "PUT",
                            data: JSON.stringify(data)
                        }).done(res => {
                            finish()
                        }).catch(err => {
                            console.error(err);
                            error("Error while adding signature");
                            setTimeout(() => {
                                changeStage(9)
                            }, 3000)
                        })
                    }
                }
            },
            {
                // 11 - Add required images to workOrder
                component: Custom,
                config: {
                    state: () => {
                        //TODO check logging for image count here.
                        let files = {}
                        let destinations = [...new Set(this.stageData(1).stock.map(s => s.destination))]
                        destinations.forEach(destination => {
                            files[destination] = (this.stageData(1).files || [])
                                .filter(f => f.destination === destination)
                                .map(f => ({
                                    url: f.url,
                                    name: f.url,
                                    type: "image"
                                }))
                        });
                        return { files }
                    },
                    itemLink: () => {
                        return (
                            <div style={{ padding: "5px 0px" }}>
                                <a href={`/workorders/${this.stageData(1).id}`} target="_blank">
                                    View work order
                                </a>
                            </div>
                        )
                    },
                    render: stage => {
                        let workOrder = this.stageData(1);
                        let destinations = Object.keys(stage.state.files);
                        return (
                            <div className="workOrderAddFileStage">
                                <div className="title">Add images</div>
                                {destinations.map(dest => {
                                    let fileCount = this.fileCount(dest, stage.state.files, workOrder);
                                    return (
                                        <div className="destination" key={dest}>
                                            <div className="existing">
                                                <div className="dest-name">
                                                    {`${dest} ( ${fileCount.filesUploaded}/${fileCount.required} )`}
                                                </div>
                                                <div className="files">
                                                    {(() => {
                                                        let files = []
                                                        workOrder.stock
                                                            .filter(s => s.destination === dest)
                                                            .forEach(s => {
                                                                files = files.concat(s.files);
                                                            });
                                                        return files.map((file, index) => {
                                                            return <div
                                                                key={`${file}-${index}-${dest}`}
                                                                className="file"
                                                                style={{ backgroundImage: `url("${file}")` }}
                                                            />
                                                        })
                                                    })()}
                                                </div>
                                            </div>
                                            <div className="add-more">Add more</div>
                                            <FileField
                                                varname="files"
                                                apiRoot={this.context.apiRoot}
                                                change={(vn, files) => {
                                                    let allFiles = [];
                                                    destinations.forEach(destination => {
                                                        allFiles = allFiles.concat(
                                                            (dest === destination
                                                                ?
                                                                files
                                                                :
                                                                stage.state.files[destination]
                                                            ).map(f => ({
                                                                destination, url: f.url
                                                            }))
                                                        )
                                                    });
                                                    $.ajax({
                                                        url: `${this.context.apiRoot}/workorders/${workOrder.id}`,
                                                        type: "PUT",
                                                        data: JSON.stringify({
                                                            files: allFiles
                                                        })
                                                    }).done(res => {
                                                        stage.setState({
                                                            files: Object.assign({}, stage.state.files, {
                                                                [dest]: files
                                                            })
                                                        });
                                                    }).catch(err => {
                                                        stage.error("Error updating work order")
                                                    })
                                                }}
                                            >
                                                {stage.state.files[dest]}
                                            </FileField>
                                        </div>
                                    )
                                })}
                                <FlipMove>
                                    {this.canFinish(destinations, stage.state.files, workOrder)
                                        ?
                                        <button
                                            key="button"
                                            className="custom-bg-feature1 finishStage"
                                            onClick={() => {
                                                // Finish stage
                                                this.nextStage()
                                            }}
                                        >
                                            FINISH STAGE
                                        </button>
                                        :
                                        null
                                    }
                                </FlipMove>
                            </div>
                        )
                    }
                }
            },
            {
                // 12 - changing workOrder status to delivered
                component: Action,
                config: {
                    action: ({ error, finish, changeStage }) => {
                        let data = { status: "delivered", process: "Unload work order" }
                        $.ajax({
                            url: `${this.context.apiRoot}/workorders/${this.stageData(1).id}`,
                            type: "PUT",
                            data: JSON.stringify(data)
                        }).done(res => {
                            finish()
                        }).catch(err => {
                            console.error(err);
                            error("Error while changing workOrder status");
                            setTimeout(() => {
                                changeStage(11)
                            }, 3000)
                        })
                    }
                }
            },
            {
                // 13 - Success msg
                component: Success,
                config: {
                    itemLink: () => {
                        return (
                            <div style={{ padding: "5px 0px" }}>
                                <a href={`/workorders/${this.stageData(1).id}`} target="_blank">
                                    View work order
                                </a>
                            </div>
                        )
                    },
                    message: () => <div>Success! Work order Unloaded.</div>
                }
            }
        ]
    }

    msToDate(ms) {
        const date = new Date(ms);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    fileCount(destination, files, workOrder) {
        // TODO check logic
        //Getting the number of required files for destination passed
        let stock = workOrder.stock.filter(s => {
            return s.destination === destination;
        });
        let required = stock.length > 3 ? 3 : stock.length;
        //And getting number of files already uploaded
        let filesUploaded = 0;
        //Adding files from stock
        stock.forEach(s => {
            filesUploaded += s.files.length;
        });
        //Adding files from state
        filesUploaded = filesUploaded + (files[destination] || []).length;
        //Returning a string
        return { filesUploaded, required };
    }

    canFinish(destinations, files, workOrder) {
        // TODO check logic
        // Returns a boolean value based on number of images added per destination. takes an array of destination strings
        let result = true;
        destinations.forEach(des => {
            let fileCount = this.fileCount(des, files, workOrder);
            if (fileCount.filesUploaded < fileCount.required) {
                result = false
            }
        })
        return result;
    }

    getCoordinates() {
        return new Promise((resolve, reject) => {
            let timer = setTimeout(() => {
                resolve(`NO_DATA|NO_DATA`);
            }, 3000);
            navigator.geolocation.getCurrentPosition(pos => {
                clearTimeout(timer);
                resolve(`${pos.coords.latitude}|${pos.coords.longitude}`);
            }, err => {
                clearTimeout(timer);
                console.error("Error getting location: ", err);
                reject("You must accept location access. If you have rejected it you must change your location setting in your browser for this website.");
            });
        });
    }

    saveRiskAssessment(cb) {
        $.ajax({
            type: "PUT",
            url: `${this.context.apiRoot}/workorders/${this.stageData(1).id}/riskassessment`,
            data: JSON.stringify(this.stageData(3))
        }).done(res => {
            if (cb) cb()
        }).catch(err => {
            if (cb) cb(err)
        })
    }

    getFullStock(stock, cb) {
        // Takes an array of stock from previously selected work order
        let ids = stock.map(s => s.id)
        $.post(`${this.context.apiRoot}/stock/multi`, JSON.stringify(ids)).done(response => {
            // Combining work order stock with corresponding full stock object
            let items = stock.map(s => {
                let match = response.results.filter(res => res.id === s.id)[0]
                if (!match) return s;
                return Object.assign({}, match, s);
            })
            if (cb) cb(null, items);
        }).catch(err => {
            if (cb) cb(err)
        })
    }

    getItems(cb) {
        //Creating to and from dates
        let date = new Date().getTime();
        let from = date - (86400000 * 5);
        let to = date + (86400000 * 5);
        //Getting workOrders
        $.get(`${this.context.apiRoot}/workorders?from=${from}&to=${to}`).done(response => {
            // Only saving confirmed workorders
            let items = response.results.filter(result => ["loaded"].includes(result.status));
            if (cb) cb(null, items);
        }).catch(err => {
            if (cb) cb(err)
        })
    }
}

export default UnloadWorkOrder;