import View from "../view";
import { Route } from "react-router-dom";
import LocationsList from "./locationsList";
import StaggeredSearchField from "../../fields/staggeredSearchField";
import SelectField from "../../fields/selectField";
import { Aux, renderDate } from "../../helpers";
import DataField from "../../fields/dataField";
import BarcodeSet from "../../assets/barcodeSet";

class LocationsView extends View {
    constructor(props) {
        super(props);
        this.pageSize = 10;
        this.state = {
            locations: [],
            itemsToPrint: [],
            page: 1,
            pageCount: 1,
            filters: {
                type: "",
                description: "",
                locator: "",
                customer: ""
            }
        }

        this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);

        this.locationListColumns = [
            {
                name: "Locator",
                renderer: item => item.locator,
                sorter: item => item.locator
            },
            {
                name: "Type",
                renderer: item => item.type,
                sorter: item => item.type
            },
            {
                name: "Description",
                renderer: item => item.description,
                sorter: item => item.description
            },
            {
                name: "Customer",
                renderer: item => item.customer,
                sorter: item => item.customer
            }
        ]
        this.mobileLocationListColumns = [
            {
                name: "Locator",
                renderer: item => item.locator,
                sorter: item => item.locator
            },
            {
                name: "Customer",
                renderer: item => item.customer,
                sorter: item => item.customer
            }
        ]
    }

    componentDidMount() {
        this.getLocations({
            count: this.pageSize,
            offset: (this.state.page - 1) * this.pageSize
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            this.getLocations();
        }
    }

    getLocations(filters = {}, cb) {
        filters = Object.assign({
            count: this.pageSize,
            offset: (this.state.page - 1) * this.pageSize
        }, this.state.filters, filters);
        $.get(`${this.props.apiRoot}/locations${this.createQueryString(filters)}`).done(response => {
            if (!cb) {
                this.setState({
                    locations: response.results,
                    pageCount: Math.ceil(response.total / this.pageSize)
                });
            } else {
                cb(response.results);
            }
        }).catch(err => {
            logger.error(err);
        });
    }

    createLocation(location) {
        $.post(`${this.props.apiRoot}/locations`, JSON.stringify(location)).done(response => {
            let locations = [response.location, ...this.state.locations];
            this.setState({ locations });
            logger.success(response.msg)
        }).catch(err => {
            logger.error(err)
        })
    }

    updateLocation(location, locator) {
        $.ajax({
            type: "PUT",
            data: JSON.stringify(location),
            url: `${this.props.apiRoot}/locations/${locator}`
        }).done(response => {
            let locations = [...this.state.locations].map(l => {
                if (l.locator === response.location.locator) {
                    return response.location
                } else {
                    return l
                }
            })
            this.setState({ locations });
            logger.success(response.msg)
        }).catch(err => {
            logger.error(err)
        })
    }

    deleteLocation(locator) {
        $.ajax({
            type: "DELETE",
            url: `${this.props.apiRoot}/locations/${locator}`
        }).done(response => {
            let locations = [...this.state.locations].filter(l => {
                return l.locator !== locator
            })
            this.setState({ locations });
            logger.success(response.msg)
        }).catch(err => {
            logger.error(err)
        })
    }

    filterChange(varname, val, err) {
        let filters = Object.assign({}, this.state.filters, {
            [varname]: val
        });
        this.setState({ filters, page: 1, itemsToPrint: [] }, () => {
            this.getLocations(filters);
        });
    }

    renderView() {
        return (
            <Aux>
                <div className="row heading-row">
                    <div className="col-12">
                        <h1 className="client-view custom-c-dark">{`${this.client} Locations`}</h1>
                        <h5 className="date custom-c-mid">{renderDate()}</h5>
                    </div>
                </div>
                <div className="row view locationsView splitPanelView">
                    <Route
                        exact
                        path="/locations"
                        render={router => (
                            <div className="col-12 col-xl-3 ui-panel-split ui-panel-split-left custom-bg-light custom-c-light shadow-1 filters">
                                <h2 className="panel-name custom-border-color-2">Filter by</h2>
                                <div className="filters">
                                    <StaggeredSearchField
                                        label="Locator"
                                        varname="partialLocator"
                                        delay={250}
                                        change={this.filterChange.bind(this)}
                                    >
                                        {this.state.filters.partialLocator}
                                    </StaggeredSearchField>
                                    <StaggeredSearchField
                                        label="Description"
                                        varname="description"
                                        delay={250}
                                        change={this.filterChange.bind(this)}
                                    >
                                        {this.state.filters.description}
                                    </StaggeredSearchField>
                                    {this.props.user.role <= 1
                                        ?
                                        <SelectField
                                            label="Type"
                                            varname="type"
                                            name="Type"
                                            options={[
                                                {
                                                    val: "",
                                                    display: "All"
                                                },
                                                {
                                                    val: "warehouse",
                                                    display: "Warehouse"
                                                },
                                                {
                                                    val: "vehicle",
                                                    display: "Vehicle"
                                                },
                                                {
                                                    val: "holding",
                                                    display: "Holding Area"
                                                },
                                                {
                                                    val: "external",
                                                    display: "External"
                                                },
                                                {
                                                    val: "remedial",
                                                    display: "Remedial"
                                                },
                                                {
                                                    val: "other",
                                                    display: "Other"
                                                }
                                            ]}
                                            change={this.filterChange.bind(this)}
                                        >
                                            {this.state.filters.type}
                                        </SelectField>
                                        :
                                        null
                                    }
                                    {this.props.user.role <= 4
                                        ?
                                        <DataField
                                            label="Customer"
                                            varname="customer"
                                            defaults={this.props.user.role >= 4 ? [] : [{
                                                name: "internal",
                                                displayName: "Internal User"
                                            }]}
                                            optionRenderer={customer => (
                                                <div className="option-inner">
                                                    <b>{customer.displayName}</b>
                                                    <br />{customer.name}
                                                </div>
                                            )}
                                            route="customers"
                                            dataName="name"
                                            searchBy="term"
                                            change={(vn, customer) => {
                                                this.filterChange("customer", customer ? customer.name : null);
                                            }}
                                        >
                                            {this.state.filters.customer}
                                        </DataField>
                                        :
                                        null
                                    }
                                    <hr/>
                                    {this.state.itemsToPrint.length
                                        ?
                                        <BarcodeSet
                                            items={this.state.itemsToPrint.map(s => {
                                                return {
                                                    code: s.locator
                                                }
                                            })} />
                                        :
                                        <button
                                            className="manualRefresh btn custom-bg-feature1"
                                            onClick={e => {
                                                this.setState({ itemsToPrint: [] });
                                                this.getLocations(
                                                    Object.assign({}, this.state.filters, {
                                                        exportMode: "true"
                                                    }),
                                                    (itemsToPrint => {
                                                        if (itemsToPrint.length > 200 && !window.confirm(`This is a large dataset - ${itemsToPrint.length} items. Are you sure?`)) {
                                                            return null;
                                                        } else {
                                                            this.setState({ itemsToPrint });
                                                        }
                                                    })
                                                );
                                            }}
                                        >Prep query for print</button>
                                    }
                                </div>
                            </div>
                        )}
                    />
                    <Route
                        exact
                        path="/locations"
                        render={router => (
                            <div className="col-12 col-xl-9 locations ui-panel-split ui-panel-split-right custom-bg-dark custom-c-light shadow-1">
                                <div className="icon location-icon title-icon"></div>
                                <h2 className="panel-name">Locations</h2>
                                <LocationsList
                                    apiRoot={this.props.apiRoot}
                                    router={this.props.router}
                                    user={this.props.user}
                                    numberOfPages={this.state.pageCount}
                                    page={this.state.page}
                                    icon="location"
                                    columns={this.locationListColumns}
                                    mobileColumns={this.mobileLocationListColumns}
                                    items={this.state.locations}
                                    onPageChange={page => {
                                        this.setState({ page });
                                    }}
                                    onNew={item => {
                                        this.createLocation(item);
                                    }}
                                    onUpdate={(item, locator) => {
                                        this.updateLocation(item, locator);
                                    }}
                                    onDelete={locator => {
                                        this.deleteLocation(locator);
                                    }}
                                />
                            </div>
                        )}
                    />
                </div>
            </Aux>
        )
    }
}

export default LocationsView
