import Field from "./field";

class BooleanField extends Field {
    constructor(props) {
        super(props);
        this.settings = {
            type: "boolean"
        }
    }

    renderField() {
        return (
            <div 
                className={this.props.children ? "slider on" : "slider"}
                onClick={() => {
                    this.change(!this.props.children);     
                }}
            >
                <div className="sliderDot"></div>
            </div>
        )
    }
}

export default BooleanField;