import Process from "./index";
// Stages.
import MultiStockSelect from "../stages/multiStockSelect";
import ListStage from "../stages/listStage";
import SelectLocation from "../stages/selectLocation";
import Confirm from "../stages/confirm";
import Action from "../stages/action";
import Success from "../stages/success";

class ReturnStock extends Process {
    constructor(props) {
        super(props);
        this.state.stage = 0;
        this.stages = [
            {
                // 0 - Select returned stock item
                component: MultiStockSelect
            },
            {
                // 1 - Checking how many matches
                component: Action,
                config: {
                    action: ({ changeStage }) => {
                        if (this.stageData(0).length > 1) {
                            changeStage(2)
                        } else {
                            changeStage(3)
                        }
                    }
                }
            },
            {
                // 2 - Selecting item from the list of matches
                component: ListStage,
                config: {
                    icon: "stock",
                    title: "Select Stock Item",
                    items: () => this.stageData(0),
                    renderItem: item => {
                        return (
                            <div className="content">
                                <div><b>Customer:</b> {item.customer}</div>
                                {item.productCode && <div><b>Product code:</b> {item.productCode}</div>}
                                {item.code && <div><b>Code:</b> {item.code}</div>}
                                {item.productBarcode && <div><b>Product Barcode:</b> {item.productBarcode}</div>}
								<div><b>Location:</b> {item.location}</div>
								<div><b>Quantity:</b> {item.quantity}</div>
                            </div>
                        );
                    }
                },
            },
            {
                // 3 - Standardise data from previous stages into one object
                component: Action,
                config: {
                    action: ({ finish }) => {
                        if (this.stageData(0).length > 1) {
                            finish(this.stageData(2))
                        } else {
                            finish(this.stageData(0)[0])
                        }
                    }
                }
            },
            { 
                // 4 - Select location
                component: SelectLocation,
                config: {
                    defaultValue: () => (window.cookies.lastReturnLocation || null)
                },
            },
            {
                // 5 - Confirm all changes
                component: Confirm,
                config: {
                    icon: "stock",
                    message: () => <div className="summary custom-c-light">
                        <p>You are returning an item of stock '<span className="custom-c-dark">{this.stageData(3).code}</span>', to <span className="custom-c-dark">{this.stageData(4).locator}</span></p>
                    </div>
                }
            },
            {
                // API call to update stock item
                component: Action,
                config: {
                    action: ({ finish }) => {
                        let changes = { location: this.stageData(4).locator };
                        // Changes stock item call
                        this.updateStock(this.stageData(3).id, changes, () => {
                            finish();
                        });
                    }
                }
            },
            {
                component: Success
            }
        ]
    }

    updateStock(id, changes, cb) {
        $.ajax({
            type: "POST",
            url: `${this.context.apiRoot}/stock/return/${id}`,
            contentType: "application/json",
            data: JSON.stringify(changes)
        }).done(response => {
            if (cb) cb(response)
        }).catch(err => {
            logger.error(err);
        });
    }

}

export default ReturnStock;