import LocationSearchField from "./locationSearchField";

class VehicleSearchField extends LocationSearchField {
    constructor(props) {
        super(props);
        this.settings = {
            type: "vehicleSearch"
        }
        this.searchTypes = [
            {
                val: "partialLocator",
                display: "Locator"
            },
            {
                val: "description",
                display: "Description"
            }
        ];
        this.searchDefaults = [];
        this.queryDefaults = {
            type: "vehicle"
        };
    }
}

export default VehicleSearchField;