/**
 *  PROPS:
 * 
 *      name: "STRING"
 *      icon: "PATH STRING"
 *          
 *            
 */

class UiPanel extends React.Component {
    renderHeader() {
        return <p className={"custom-c-light"}>{this.props.name}</p>
    }
    render() {
        let headerClass = this.props.darkHeader ? "heading":"heading custom-bg-light";
        return (
            <div className={"panel custom-bg-dark custom-c-light shadow-1 "+(this.props.classes ? this.props.classes : "")}>
                <div className={headerClass}>
                    {this.renderHeader()}
                    {this.props.icon 
                    ? 
                        <div className={`icon ${this.props.icon}-icon`}></div>
                    :
                    null
                    }
                </div>
                {this.props.children}
            </div>
        )
    }
}

export default UiPanel;