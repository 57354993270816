import Stage from "./stage";
import BooleanField from "../../../fields/booleanField";
import TextareaField from "../../../fields/textareaField";
import FlipMove from "react-flip-move";

class RiskAssessmentStage extends Stage {
    constructor(props) {
        super(props)
        this.fields = [
            {
                type: "vehParked",
                title: "Is the vehicle parked safely?"
            },
            {
                type: "vehObstruction",
                title: "Is the vehicle causing obstruction to others?"
            },
            {
                type: "routeObstruction",
                title: "Is the route clear and free from obstruction?"
            },
            {
                type: "tripHazards",
                title: "Are there any trip hazards?"
            },
            {
                type: "slipHazards",
                title: "Are there any slip hazards?"
            },
            {
                type: "workOnDevRoute",
                title: "Is anyone else working on the delivery route?"
            },
            {
                type: "signage",
                title: "Have you placed signage to warn others of your activity?"
            },
        ]
        this.state.pwd = "";
        this.state.riskAssessment = {
            vehParked: {
                val: false,
                notes: null
            },
            vehObstruction: {
                val: false,
                notes: null
            },
            routeObstruction: {
                val: false,
                notes: null
            },
            tripHazards: {
                val: false,
                notes: null
            },
            slipHazards: {
                val: false,
                notes: null
            },
            workOnDevRoute: {
                val: false,
                notes: null
            },
            signage: {
                val: false,
                notes: null
            }
        }
    }

    fieldUpdated(key, subKey, value) {
        let riskAssessment = Object.assign({}, this.state.riskAssessment);
        riskAssessment[key][subKey] = value;
        this.setState({ riskAssessment })
    }

    renderStage() {
        return (
            <div className="riskAssessmentStage">
                {/* Rendering risk assessment fields */}
                {this.fields.map(field => {
                    return (
                        <div key={field.type} className="raField">
                            <label>{field.title}</label>
                            <span className={this.state.riskAssessment[field.type].val ? "custom-c-feature2" : "custom-c-feature3"}>
                                {this.state.riskAssessment[field.type].val ? "Yes" : "No"}
                            </span>
                            <BooleanField
                                change={(name, value) => {
                                    this.fieldUpdated(field.type, "val", value);
                                }}
                            >
                                {this.state.riskAssessment[field.type].val}
                            </BooleanField>
                            <TextareaField
                                placeholder="Notes/Action Taken"
                                change={(name, value) => {
                                    this.fieldUpdated(field.type, "notes", value || null);
                                }}
                            >
                                {this.state.riskAssessment[field.type].notes || ""}
                            </TextareaField>
                        </div>
                    )
                })}
                <div className="selector">
                    <p>I confirm that all information is correct.</p>
                    <input
                        className="custom-border-3"
                        ref="code"
                        placeholder="Scan QR to confirm"
                        onInput={e => {
                            // Pull the data out of field, and hide field value.
                            let data = e.target.value.split(" ");
                            // Check the format of credentials is correct.
                            if (data[0] !== this.context.user.email) {
                                this.error("Not a valid login code.");
                                return e.target.value = "";
                            } else {
                                e.target.value = "";
                                this.stageComplete({ riskAssessment: this.state.riskAssessment })
                            }

                        }}
                    />
                </div>
                <div className="manualLogin">
                    <p>Or</p>
                    <form autoComplete="off">
                        <input
                            autoComplete="off"
                            className="custom-border-3"
                            type="password"
                            placeholder="Enter password"
                            value={this.state.pwd}
                            onChange={e => {
                                this.setState({ pwd: e.target.value })
                            }}
                        />
                    </form>
                    <FlipMove>
                        {this.state.pwd
                            ?
                            <button
                                key="button"
                                className="button custom-bg-feature1"
                                onClick={() => {
                                    let data = {
                                        pwd: this.state.pwd,
                                        email: this.context.user.email
                                    };
                                    $.ajax({
                                        type: "POST",
                                        url: `${this.context.apiRoot}/security/login?noToken=true`,
                                        data: JSON.stringify(data),
                                        contentType: "application/json",
                                        success: res => {
                                            //Sending risk assessment if user verified
                                            this.stageComplete({ riskAssessment: this.state.riskAssessment })
                                        },
                                        error: err => {
                                            console.error(err)
                                            this.error("Login error, please try again");
                                        }
                                    })
                                }}
                            >
                                Submit
                            </button>
                            :
                            null
                        }
                    </FlipMove>
                </div>
            </div>
        )
    }

}

export default RiskAssessmentStage;
