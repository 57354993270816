import View from "../view";
// import { Route } from "react-router-dom";
import StockList from "./stockList";
import StaggeredSearchField from "../../fields/staggeredSearchField";
import LocationSearchField from "../../fields/locationSearchField";
import { renderDate } from "../../helpers";
import UiSimplePanel from "../../assets/ui-simple-panel";
import StockCSVField from "../../fields/csvField/stockCsvField";
import GroupList from "./groupList";
import DateRangeField from "../../fields/dateRangeField";
import csvCreator from "../../assets/csvCreator";
import CustomerField from "../../fields/customerField";
import CheckboxField from "../../fields/checkboxField";
import DateField from "../../fields/dateField";
import SelectField from "../../fields/selectField";
import BarcodeSet from "../../assets/barcodeSet";
import ProductReporter from "./productReports";
import moment from "moment";

class StockView extends View {
    constructor(props) {
        super(props);
        this.pageSize = 15;
        this.state = {
            stockList: [],
            groups: [],
            stockToPrint: [],
            productList: [],
            productPage: 1,
            productPageCount: 1,
            stockPage: 1,
            stockPageCount: 1,
            groupPage: 1,
            groupPageCount: 1,
            viewMode: "stock",
            customerForBulkUpload: null,
            dateForBulkUpload: Date.now(),
            stockFilters: {
                location: "",
                code: "",
                description: "",
                customer: "",
                supplier: "",
                subcustomer: "false",
                group: "",
                productCode: "",
                productBarcode: "",
                show: "all",
                groups: "all"
            },
            groupFilters: {
                group: "",
                description: "",
                supplier: "",
                customer: "",
                subcustomer: "false",
                show: "in"
            }
        }
        this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);
    }
    componentDidMount() {
        //Getting stock array
        this.getStock({
            count: this.pageSize,
            offset: (this.state.stockPage - 1) * this.pageSize
        });
        // this.getGroups({
        //     count: this.pageSize,
        //     offset: (this.state.groupPage - 1) * this.pageSize
        // });
        // this.getProducts({
        //     count: this.pageSize,
        //     offset: (this.state.productPage - 1) * this.pageSize
        // });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.stockPage !== this.state.stockPage) {
            this.getStock({
                count: this.pageSize,
                offset: (this.state.stockPage - 1) * this.pageSize
            });
        }
        // if (prevState.groupPage !== this.state.groupPage) {
        //     this.getGroups({
        //         count: this.pageSize,
        //         offset: (this.state.groupPage - 1) * this.pageSize
        //     });
        // }
        if (prevState.productPage !== this.state.productPage) {
            this.getProducts({
                count: this.pageSize,
                offset: (this.state.productPage - 1) * this.pageSize
            });
        }
    }

    getGroups(filters) {
        filters = Object.assign({}, this.state.groupFilters, filters) || this.state.groupFilters;
        $.get(`${this.props.apiRoot}/stock/stock-groups${this.createQueryString(filters)}`).done(response => {
            this.setState({
                groups: response.results,
                groupPageCount: Math.ceil(response.total / this.pageSize)
            })
        }).catch(err => {
            logger.error(err);
        });
    }

    getStock(filters, cb) {
        this.setState({ stockToPrint: [] });
        filters = Object.assign(
            {},
            this.state.stockFilters,
            filters,
            {
                count: this.pageSize,
                offset: (this.state.stockPage - 1) * this.pageSize
            }
        );

        // Now handle date ranges.
        if (filters.in) {
            // In ranges.
            if (filters.in.from)
                filters.in_from = filters.in.from;
            if (filters.in.to)
                filters.in_to = filters.in.to;
            delete filters.in;
        }
        if (filters.out) {
            // Out ranges.
            if (filters.out.from)
                filters.out_from = filters.out.from;
            if (filters.out.to)
                filters.out_to = filters.out.to;
            delete filters.out;
        }
        // And exporting
        if (filters.exportMode) {
            delete filters.count;
            delete filters.offset;
        }

        $.get(`${this.props.apiRoot}/stock${this.createQueryString(filters)}`).done(response => {
            if (!filters.exportMode) {
                this.setState({
                    stockList: response.results.slice(0, this.pageSize),
                    stockPageCount: Math.ceil(response.total / this.pageSize)
                });
                if (cb) cb(response.results);
            } else {
                if (cb) cb(response.fileName)
            }
        }).catch(err => {
            logger.error(err);
        });
    }

    getProducts(filters, cb) {
        filters = Object.assign(
            {},
            this.state.productFilters,
            filters,
            {
                count: this.pageSize,
                offset: (this.state.productPage - 1) * this.pageSize
            }
        );

        // Now handle date ranges.
        if (filters.in) {
            // In ranges.
            if (filters.in.from)
                filters.in_from = filters.in.from;
            if (filters.in.to)
                filters.in_to = filters.in.to;
            delete filters.in;
        }
        if (filters.out) {
            // Out ranges.
            if (filters.out.from)
                filters.out_from = filters.out.from;
            if (filters.out.to)
                filters.out_to = filters.out.to;
            delete filters.out;
        }
        // And exporting
        if (filters.exportMode) {
            delete filters.count;
            delete filters.offset;
        }

        $.get(`${this.props.apiRoot}/stock/product-report${this.createQueryString(filters)}`).done(response => {
            if (!filters.exportMode) {
                this.setState({
                    productList: response.results.slice(0, this.pageSize),
                    productPageCount: Math.ceil(response.total / this.pageSize)
                });
            }
            if (cb) cb(response.results);
        }).catch(err => {
            logger.error(err);
        });
    }

    createStock(stock) {
        $.post(`${this.props.apiRoot}/stock`, JSON.stringify(stock)).done(response => {
            let stockList = [...this.state.stockList].concat(response.stock);
            this.setState({ stockList });
            logger.success(response.msg);
        }).catch(err => {
            logger.error(err)
        })
    }

    updateStock(stock, id) {
        $.ajax({
            type: "PUT",
            url: `${this.props.apiRoot}/stock/${id}`,
            data: JSON.stringify(stock)
        }).done(response => {
            let stockList = [...this.state.stockList].map(s => {
                if (s.id === response.stock.id) {
                    return response.stock
                } else {
                    return s
                }
            })
            this.setState({ stockList });
            logger.success(response.msg);
        }).catch(err => {
            logger.error(err)
        })
    }

    unlockStock(id) {
        $.get(`${this.props.apiRoot}/stock/${id}/unlock`).done(res => {
            let stockList = [...this.state.stockList].map(s => {
                if (s.id === res.stock.id) {
                    return res.stock
                } else {
                    return s
                }
            })
            this.setState({ stockList });
            logger.success(res.msg);
        }).catch(err => {
            logger.error(err);     
        })
    }

    splitStock(quantity, id) {
        $.ajax({
            type: "PUT",
            url: `${this.props.apiRoot}/stock/${id}/split`,
            data: JSON.stringify(quantity)
        }).done(response => {
            let stockList = [...this.state.stockList].filter(s => {
                return s.id !== id
            })
            stockList = stockList.concat(response.split);
            this.setState({ stockList })
            logger.success(response.msg)
        }).catch(err => {
            logger.error(err)
        })
    }

    deleteStock(id) {
        $.ajax({
            type: "DELETE",
            url: `${this.props.apiRoot}/stock/${id}`
        }).done(response => {
            let stockList = [...this.state.stockList].filter(s => {
                return s.id !== id
            })
            this.setState({ stockList });
            logger.success(response.msg);
        }).catch(err => {
            logger.error(err)
        })
    }

    groupFilterChange(vn, val) {
        let groupFilters = Object.assign({}, this.state.groupFilters, {
            [vn]: val
        });
        this.setState({ groupFilters });
        this.getGroups(groupFilters);
    }

    stockFilterChange(varname, val, err) {
        // console.log("FILTER CHANGE VAL:", val);
        // console.log("FILTER CHANGE VARNAME:", varname);
        let stockFilters = Object.assign({}, this.state.stockFilters);
        if (varname === "location") {
            stockFilters[varname] = val ? val.locator : "";
        } else if (varname === "customer") {
            stockFilters[varname] = val ? val.name : "";
        } else {
            stockFilters[varname] = val;
        }
        // Update state with a clone of these filters.
        this.setState({ stockFilters: Object.assign({}, stockFilters), stockPage: 1 });
        // console.log(stockFilters)
        this.getStock(stockFilters);
    }

    renderStockMode() {
        return <React.Fragment>
            <div className="row view stockView splitPanelView">
                <div className="col-12 col-xl-3 ui-panel-split ui-panel-split-left custom-bg-light custom-c-light shadow-1 filters">
                    <h2 className="panel-name custom-border-color-2">Filter by</h2>
                    <div className="filters" key="stockFilters">
                        <LocationSearchField
                            label="Location"
                            varname="location"
                            change={this.stockFilterChange.bind(this)}
                        >
                            {this.state.stockFilters.location}
                        </LocationSearchField>
                        <StaggeredSearchField
                            label="Product code"
                            varname="productCode"
                            delay={250}
                            autoFillItem={"stock"}
                            autoFillField={"productCode"}
                            change={this.stockFilterChange.bind(this)}
                        >
                            {this.state.stockFilters.productCode}
                        </StaggeredSearchField>
                        <StaggeredSearchField
                            label="Product barcode"
                            varname="productBarcode"
                            delay={250}
                            autoFillItem={"stock"}
                            autoFillField={"productBarcode"}
                            change={this.stockFilterChange.bind(this)}
                        >
                            {this.state.stockFilters.productBarcode}
                        </StaggeredSearchField>
                        <SelectField
                            label="Show"
                            varname="show"
                            name="Show"
                            options={[
                                {
                                    val: "all",
                                    display: "All"
                                },
                                {
                                    val: "in",
                                    display: "In"
                                },
                                {
                                    val: "out",
                                    display: "Out"
                                },
                            ]}
                            change={this.stockFilterChange.bind(this)}
                        >
                            {this.state.stockFilters.show}
                        </SelectField>
                        <DateRangeField
                            varname="in"
                            label="Date in"
                            change={this.stockFilterChange.bind(this)}
                        >
                            {this.state.stockFilters.in}
                        </DateRangeField>
                        <DateRangeField
                            varname="out"
                            label="Date out"
                            change={this.stockFilterChange.bind(this)}
                        >
                            {this.state.stockFilters.out}
                        </DateRangeField>
                        <StaggeredSearchField
                            label="Supplier"
                            varname="supplier"
                            delay={250}
                            change={this.stockFilterChange.bind(this)}
                        >
                            {this.state.stockFilters.supplier}
                        </StaggeredSearchField>
                        <StaggeredSearchField
                            label="Description"
                            varname="description"
                            delay={250}
                            change={this.stockFilterChange.bind(this)}
                        >
                            {this.state.stockFilters.description}
                        </StaggeredSearchField>
                        <StaggeredSearchField
                            label="Code (Barcode)"
                            varname="code"
                            delay={250}
                            autoFillItem={"stock"}
                            autoFillField={"code"}
                            change={this.stockFilterChange.bind(this)}
                        >
                            {this.state.stockFilters.code}
                        </StaggeredSearchField>
                        <StaggeredSearchField
                            label="Group"
                            varname="group"
                            delay={250}
                            change={this.stockFilterChange.bind(this)}
                        >
                            {this.state.stockFilters.group}
                        </StaggeredSearchField>
                        <SelectField
                            label="Items with group"
                            varname="groups"
                            name="Groups"
                            options={[
                                {
                                    val: "all",
                                    display: "Show all items"
                                },
                                {
                                    val: "show",
                                    display: "Show only in groups"
                                },
                                {
                                    val: "hide",
                                    display: "Show only individual"
                                }
                            ]}
                            change={this.stockFilterChange.bind(this)}
                        >
                            {this.state.stockFilters.groups}
                        </SelectField>
                        {this.props.user.role <= 4
                            ?
                            <CustomerField
                                defaults={this.props.user.role >= 4
                                    ?
                                    []
                                    :
                                    [
                                        {
                                            name: "internal",
                                            displayName: "Internal User"
                                        }
                                    ]
                                }
                                change={this.stockFilterChange.bind(this)}
                            >
                                {this.state.stockFilters.customer}
                            </CustomerField>
                            :
                            null
                        }
                        {this.props.user.role <= 4
                            ?
                            <CheckboxField
                                varname="subcustomer"
                                label="Show subcustomer stock"
                                change={(vn, val, error) => {
                                    this.stockFilterChange(vn, val ? "true" : "false", error);
                                }}
                            >
                                {this.state.stockFilters.subcustomer === "true"}
                            </CheckboxField>
                            :
                            null
                        }
                        <button
                            className="manualRefresh btn custom-bg-feature1"
                            onClick={e => {
                                this.getStock(this.state.stockFilters);
                            }}
                        >Refresh data</button>
                        <hr />
                        <button
                            disabled={!Boolean(this.state.stockFilters.customer) && this.props.user.role !== 4}
                            className="manualRefresh btn custom-bg-feature1"
                            onClick={e => {
                                this.getStock(
                                    Object.assign({}, this.state.stockFilters, {
                                        exportMode: "true"
                                    }),
                                    (fileName => {
                                        let a = document.createElement('a');
                                        a.download = fileName.split("/").slice(-1);
                                        a.href = "https://data.tad-system.co.uk/" + fileName;
                                        a.dataset.downloadurl = ["text/csv", a.download, a.href].join(':');
                                        a.style.display = "none";
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                    })
                                );
                            }}
                        >Export dataset</button>
                        <hr />
                        <button
                            disabled={!Boolean(this.state.stockFilters.customer) && this.props.user.role !== 4}
                            className="manualRefresh btn custom-bg-feature1"
                            onClick={e => {
                                this.getStock(
                                    Object.assign({}, this.state.stockFilters, {
                                        exportMode: "true",
                                        combineExport: "true"
                                    }),
                                    (fileName => {
                                        let a = document.createElement('a');
                                        a.download = fileName.split("/").slice(-1);
                                        a.href = "https://data.tad-system.co.uk/" + fileName;
                                        a.dataset.downloadurl = ["text/csv", a.download, a.href].join(':');
                                        a.style.display = "none";
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                    })
                                );
                            }}
                        >Export combined dataset</button>
                        {this.props.user.role < 4 ? <React.Fragment>
                            <hr />
                            {this.state.stockToPrint.length
                                ?
                                <BarcodeSet
                                    items={this.state.stockToPrint.map(s => {
                                        return {
                                            code: s.code,
                                            moreInfo: <div>
                                                <div>
                                                    CUST: {s.customer} | PC: {s.productCode || "NO CODE"}
                                                </div>
                                                <hr />
                                                <div>{s.description || "NO DESC"}</div>
                                            </div>
                                        }
                                    })} />
                                :
                                <button
                                    className="manualRefresh btn custom-bg-feature1"
                                    onClick={e => {
                                        this.setState({ stockToPrint: [] });
                                        this.getStock(
                                            Object.assign({}, this.state.stockFilters, {
                                                exportMode: "true"
                                            }),
                                            (stockToPrint => {
                                                if (stockToPrint.length > 200 && !window.confirm(`This dataset is greater than ${stockToPrint.length} items. Are you sure?`)) {
                                                    return null;
                                                } else {
                                                    this.setState({ stockToPrint });
                                                }
                                            })
                                        );
                                    }}
                                >Prep query for print</button>
                            }
                        </React.Fragment> : null}
                    </div>
                </div>
                <div className="col-12 col-xl-9 stock ui-panel-split ui-panel-split-right custom-bg-dark custom-c-light shadow-1">
                    <div className="icon stock-icon title-icon"></div>
                    <h2 className="panel-name">Stock</h2>
                    <StockList
                        displayOnly={this.props.user.role === 4}
                        user={this.props.user}
                        apiRoot={this.props.apiRoot}
                        router={this.props.router}
                        allCreatorFields={true}
                        controls={["image", "split", "edit", "delete"]}
                        numberOfPages={this.state.stockPageCount}
                        page={this.state.stockPage}
                        items={this.state.stockList}
                        onPageChange={stockPage => {
                            this.setState({ stockPage });
                        }}
                        onNew={item => {
                            this.createStock(item)
                        }}
                        onUpdate={this.props.user.role <= 1
                            ? (item, id) => {
                                this.updateStock(item, id)
                            }
                            : null
                        }
                        onSplit={this.props.user.role <= 1
                            ?
                            (item, id) => {
                                this.splitStock(item, id)
                            }
                            :
                            null
                        }
                        onDelete={this.props.user.role <= 1
                            ? id => {
                                this.deleteStock(id)
                            }
                            : null
                        }
                        onUnlock={this.props.user.role <= 1
                            ? id => this.unlockStock(id)
                            : null
                        }
                    />
                </div>
            </div>

            {/* Bulk stock uploading */}
            {false && this.props.user.role < 3 ? <div className="row">
                <div className="col-12">
                    <hr />
                </div>
                <div className="col-12" style={{ padding: "0px" }}>
                    <UiSimplePanel header="Bulk upload stock.">
                        <CustomerField
                            label="Default customer (will be overwritten by data)."
                            defaults={this.props.user.role >= 4
                                ?
                                []
                                :
                                [
                                    {
                                        name: "internal",
                                        displayName: "Internal User"
                                    }
                                ]
                            }
                            change={(vn, customer) => {
                                this.setState({
                                    customerForBulkUpload: customer ? customer.name : null
                                })
                            }}
                        >
                            {this.state.customerForBulkUpload}
                        </CustomerField>
                        <br />
                        <DateField
                            label="Date stock in"
                            change={(vn, dateForBulkUpload) => {
                                this.setState({
                                    dateForBulkUpload
                                })
                            }}
                        >
                            {this.state.dateForBulkUpload || Date.now()}
                        </DateField>
                        {this.state.customerForBulkUpload ? <hr /> : null}
                        {this.state.customerForBulkUpload
                            ?
                            <StockCSVField
                                customer={this.state.customerForBulkUpload}
                                onConfirm={(data, cb) => {
                                    // console.log(data.map(s => {
                                    //     s.customer = this.state.customerForBulkUpload;
                                    //     s.in = this.state.dateForBulkUpload;
                                    //     return s;
                                    // }));
                                    $.post({
                                        url: `${this.props.apiRoot}/stock/bulk`,
                                        data: JSON.stringify(data.map(s => {
                                            s.customer = s.customer || this.state.customerForBulkUpload;
                                            s.in = this.state.dateForBulkUpload;
                                            return s;
                                        })),
                                        contentType: "application/json",
                                        dataType: "json"
                                    }).done(response => {
                                        // console.log(response);
                                        logger.success(response.msg)
                                        if (cb) cb(true);
                                    }).catch(err => {
                                        console.error(err);
                                        logger.error(err);
                                        if (cb) cb(false);
                                    });
                                }}
                            /> : null}
                    </UiSimplePanel>
                </div>
            </div> : null}
        </React.Fragment>
    }

    renderGroupMode() {
        return <div className="row view stockView splitPanelView">
            <div className="col-12 col-xl-3 ui-panel-split ui-panel-split-left custom-bg-light custom-c-light shadow-1 filters">
                <h2 className="panel-name custom-border-color-2">Filter by</h2>
                <div className="filters" key="groupFilters">
                    <StaggeredSearchField
                        label="Supplier"
                        varname="supplier"
                        delay={1000}
                        change={this.groupFilterChange.bind(this)}
                    >
                        {this.state.groupFilters.supplier}
                    </StaggeredSearchField>
                    <StaggeredSearchField
                        label="Description"
                        varname="description"
                        delay={1000}
                        change={this.groupFilterChange.bind(this)}
                    >
                        {this.state.groupFilters.description}
                    </StaggeredSearchField>
                    <StaggeredSearchField
                        label="Group Code"
                        varname="group"
                        delay={1000}
                        change={this.groupFilterChange.bind(this)}
                    >
                        {this.state.groupFilters.group}
                    </StaggeredSearchField>
                    <SelectField
                        label="Show"
                        varname="show"
                        name="Show"
                        options={[
                            {
                                val: "all",
                                display: "All"
                            },
                            {
                                val: "in",
                                display: "In"
                            },
                            {
                                val: "out",
                                display: "Out"
                            },
                        ]}
                        change={this.groupFilterChange.bind(this)}
                    >
                        {this.state.groupFilters.show}
                    </SelectField>
                    {this.props.user.role <= 4
                        ?
                        <CustomerField
                            defaults={this.props.user.role >= 4
                                ?
                                []
                                :
                                [
                                    {
                                        name: "internal",
                                        displayName: "Internal User"
                                    }
                                ]
                            }
                            change={(vn, customer) => [
                                this.groupFilterChange("customer", customer ? customer.name : null)
                            ]}
                        >
                            {this.state.groupFilters.customer}
                        </CustomerField>
                        :
                        null
                    }
                    {this.props.user.role <= 4
                        ?
                        <CheckboxField
                            varname="subcustomer"
                            label="Show subcustomer stock"
                            change={(vn, val) => [
                                this.groupFilterChange("subcustomer", val ? "true" : "false")
                            ]}
                        >
                            {this.state.groupFilters.subcustomer === "true"}
                        </CheckboxField>
                        :
                        null
                    }
                    <button
                        disabled={!Boolean(this.state.groupFilters.customer) && this.props.user.role !== 4}
                        className="manualRefresh btn custom-bg-feature1"
                        onClick={e => {
                            $.ajax({
                                type: "POST",
                                url: `${this.props.apiRoot}/stock/multi-groups`,
                                data: JSON.stringify(this.state.groups.map(g => g.code))
                            }).then(res => {
                                if (res && res.groupData.length) {
                                    let result = res.groupData
                                        .map(s => {
                                            let items = this.state.groups.filter(g => g.code === s.group)[0].items;
                                            return { ...s, items }
                                        }).map(s => {
                                            if (s.in) s.in = moment(s.in).format("DD-MM-YYYY HH:mm");
                                            if (s.out) s.out = moment(s.out).format("DD-MM-YYYY HH:mm");
                                            return s;
                                        })
                                    // console.log("RESULTS: ", result);
                                    csvCreator.create(result).then(csv => {
                                        csvCreator.download(csv);
                                    });
                                } else {
                                    logger.error("Could not find stock items for selected groups")
                                }
                            }).catch(err => {
                                logger.error(err);
                            })
                        }}
                    >Export data</button>
                    <button
                        className="manualRefresh btn custom-bg-feature1"
                        onClick={e => {
                            this.getGroups(this.state.groupFilters);
                        }}
                    > Refresh data</button>
                </div>
            </div>
            <div className="col-12 col-xl-9 stock ui-panel-split ui-panel-split-right custom-bg-dark custom-c-light shadow-1">
                <div className="icon stock-icon title-icon"></div>
                <h2 className="panel-name">Groups</h2>
                <GroupList
                    user={this.props.user}
                    apiRoot={this.props.apiRoot}
                    router={this.props.router}
                    controls={[]}
                    numberOfPages={this.state.groupPageCount}
                    page={this.state.groupPage}
                    items={this.state.groups.slice(this.pageSize * (this.state.groupPage - 1), (this.pageSize * (this.state.groupPage - 1)) + this.pageSize)}
                    onPageChange={groupPage => {
                        this.setState({ groupPage });
                    }}
                    onFindStock={item => {
                        let stockFilters = {
                            location: "",
                            code: "",
                            description: "",
                            customer: "",
                            group: item.code
                        }
                        this.getStock(stockFilters);
                        this.setState({
                            viewMode: "stock",
                            stockFilters
                        })
                    }}
                />
            </div>
        </div>
    }

    renderProductReportMode() {
        return <ProductReporter
            user={this.props.user}
            apiRoot={this.props.apiRoot}
        />
    }
    renderView() {
        // console.log("PROPS", this.props);
        // console.log("STATE", this.state);
        return (
            <React.Fragment>
                <div className="row heading-row">
                    <div className="col-12">
                        <h1 className="client-view custom-c-dark">{`${this.client} Stock`}</h1>
                        <h5 className="date custom-c-mid">{renderDate()}</h5>
                    </div>
                </div>
                <div className="row modeControls">
                    <span>Select viewing mode:</span><br />
                    {[
                        ["Stock", "stock"],
                        ["Groups", "group"],
                        ["Product Report", "productReport"]
                    ].filter(i => i).map(btnPair => {
                        return <button
                            key={btnPair[1]}
                            className={"btn" + (this.state.viewMode === btnPair[1] ? " custom-bg-feature1" : "")}
                            onClick={e => {
                                this.setState({ viewMode: btnPair[1] })

                                if (btnPair[1] === "group" && !this.state.groups.length) {
                                    this.getGroups({
                                        count: this.pageSize,
                                        offset: (this.state.groupPage - 1) * this.pageSize
                                    });    
                                }
                                if (btnPair[1] === "productReport" && !this.state.productList.length) {
                                    this.getProducts({
                                        count: this.pageSize,
                                        offset: (this.state.productPage - 1) * this.pageSize
                                    });
                                }
                            }}
                        >{btnPair[0]}</button>
                    })}
                </div>

                {/* Standard stock view */}
                {this.state.viewMode === "stock" ? this.renderStockMode() : null}

                {/* Stock group view */}
                {this.state.viewMode === "group" ? this.renderGroupMode() : null}

                {/* Product reporting view */}
                {this.state.viewMode === "productReport" ? this.renderProductReportMode() : null}

            </React.Fragment>
        )
    }
}


export default StockView;