import FlipMove from "react-flip-move";
import Process from "./index";
import ScanInput from "../../../fields/scanInput";
// Stages.
import ListStage from "../stages/listStage";
import Action from "../stages/action";
import Success from "../stages/success";
import Custom from "../stages/custom";
import SignatureStage from "../stages/signatureStage";
// Other components.
import CheckboxField from "../../../fields/checkboxField";
import FileField from "../../../fields/fileField";

class LoadCollection extends Process {
    constructor(props) {
        super(props);
        this.state.stage = 0;
        this.state.showUnscannedOnly = false;
        this.stages = [
            {
                // 0 - Load Collections
                component: Action,
                config: {
                    action: ({ finish, error, exit }) => {
                        this.getItems((err, items) => {
                            if (!err) {
                                finish(items);
                            } else {
                                console.error("Couldn't get collections: ", err);
                                error("Error getting collections");
                                setTimeout(() => {
                                    exit();
                                }, 3000);
                            }
                        });
                    }
                }
            },
            {
                // 1 - Showing list of collections
                component: ListStage,
                config: {
                    icon: "collection",
                    title: "Select Collection",
                    items: () => this.stageData(0),
                    renderItem: item => {
                        return (
                            <div className="content">
                                <p className="customer">{item.customer}</p>
                                <p className="status">status: {item.status}</p>
                                <div className="date-time">
                                    <p className="date">{this.msToDate(item.dateOutgoing)}</p>
                                    <p className="time">{`At ${item.timeOutgoing}`}</p>
                                </div>
                                <p className="stock">{`stock quantity: ${item.stock.length}`}</p>
                                {item.notes
                                    ?
                                    <div className="notes-wrapper">
                                        <hr />
                                        <p className="notes">{item.notes}</p>
                                    </div>
                                    :
                                    null
                                }
                                <hr />
                                <p className="id">{item.id}</p>
                            </div>
                        )
                    }
                }
            },
            {
                // 2 - Getting full stock items object for selected collection
                component: Action,
                config: {
                    action: ({ changeStage, error, exit }) => {
                        this.getFullStock(this.stageData(1).stock, (err, items) => {
                            if (!err) {
                                let finished = true;
                                items.forEach(item => {
                                    if (item.status !== "collected") finished = false
                                })
                                this.setStageData(2, items, () => {
                                    if (finished) {
                                        // All items have been loaded
                                        if (this.stageData(1).signedBy && this.stageData(1).signature) {
                                            // Already got name and signature
                                            changeStage(10)
                                        } else {
                                            changeStage(9)
                                        }
                                    } else {
                                        changeStage(3)
                                    }
                                })
                                // finish(items);
                            } else {
                                console.error("Couldn't get full stock data:", err);
                                error("Error getting collection's stock");
                                setTimeout(() => {
                                    exit();
                                }, 3000);
                            }
                        })
                    }
                }
            },
            {
                // 3 - Showing list of stock for selected collection
                component: ListStage,
                config: {
                    icon: "stock",
                    title: "Scan Stock Item",
                    sort: items => {
                        return items.sort((a,b) => {
                            let aLocation = a.location;
                            let bLocation = b.location;
                            if (aLocation < bLocation) return -1;
                            if (aLocation > bLocation) return 1;
                            return 0;
                        })
                    },
                    state: {
                        inputValue: ""
                    },
                    before: stage => {
                        // Getting total numbers for stock and collected stock
                        let total = this.stageData(1).stock.length;
                        let scanned = this.stageData(1).stock.filter(s => s.status === "collected").length;
                        // Render scanned stock toggle, total numbers of stock scanned and stock selector
                        return (
                            <div>
                                <div className="totalItems">
                                    {`[${scanned}/${total}]`}
                                </div>
                                <div className="toggle">
                                    <CheckboxField
                                        label="Show unscanned only"
                                        change={(vn, val, err) => {
                                            this.setState({ showUnscannedOnly: val })
                                        }}
                                    >
                                        {this.state.showUnscannedOnly}
                                    </CheckboxField>
                                </div>
                                <ScanInput
                                    onScan={code => {
                                        let codeMatch = this.stageData(2).filter(s => s.code === code);
                                        let productBarcodeMatch = this.stageData(2).filter(s => s.productBarcode === code);
                                        if (codeMatch.length) {
                                            stage.stageComplete(codeMatch)
                                        } else if (productBarcodeMatch.length) {
                                            stage.stageComplete(productBarcodeMatch)
                                        } else {
                                            stage.error("No match found for that bar code.")
                                        }
                                    }}
                                />
                            </div>
                        )
                    },
                    items: () => {
                        if (this.state.showUnscannedOnly) {
                            return this.stageData(2).filter(s => (!s.status || s.status !== "collected"))
                        }
                        return this.stageData(2);
                    },
                    disable: item => true,
                    renderItem: (item, index, stage) => {
                        return (
                            <div className="content">
                                 {item.productCode && <p className="code"><span>Product code:</span>{item.productCode}</p>}
                                {item.productBarcode && <p className="productBarcode"><span>Product barcode:</span>{item.productBarcode}</p>}
                                {item.code && <p className="code"><span>Barcode:</span><br />{item.code}</p>}
                                {item.group && <p className="group"><span>Group:</span>{item.group}</p>}
                                <p className="quantity"><span>Quantity:</span>{item.quantity}</p>
                                <p className="location"><span>Loc:</span>{item.location}</p>
                                <hr />
                                <p className="description"><span>desc:</span>{item.description}</p>
                                {!item.barcodeRequired || !item.code
                                    ?
                                    <React.Fragment>
                                        <hr />
                                        <button
                                            className="button custom-bg-feature1 fullWidth"
                                            onClick={e => {
                                                let match = this.stageData(2).filter(s => s.id === item.id);
                                                stage.stageComplete(match);
                                            }}
                                        >
                                            Select item.
                                        </button>
                                    </React.Fragment>
                                    : null
                                }
                                {item.status && item.status === "collected"
                                    ?
                                    <div className="green-tick"></div>
                                    :
                                    null
                                }
                            </div>
                        )
                    }
                }
            },
            {
                // 4 - Checking how many matches
                component: Action,
                config: {
                    action: ({ changeStage }) => {
                        console.log("Stage data 3: ", this.stageData(3));
                        if (this.stageData(3).length > 1) {
                            changeStage(5)
                        } else {
                            changeStage(6)
                        }
                    }
                }
            },
            {
                // 5 - Selecting item from the list of matches
                component: ListStage,
                config: {
                    icon: "stock",
                    title: "Select Stock Item",
                    items: () => this.stageData(3),
                    renderItem: item => {
						return (
							<div className="content">
								<div>
									<b>Customer:</b> {item.customer}
								</div>
                                {item.productCode && <div><b>Product code:</b> {item.productCode}</div>}
                                {item.code && <div><b>Code:</b> {item.code}</div>}
                                {item.productBarcode && <div><b>Product Barcode:</b> {item.productBarcode}</div>}
								<div>
									<b>Location:</b> {item.location}
								</div>
								<div>
									<b>Quantity:</b> {item.quantity}
								</div>
                                {item.status && item.status === "collected"
                                    ?
                                    <div className="green-tick"></div>
                                    :
                                    null
                                }
							</div>
						);
					}
                }
            },
            {
                // 6 - Standardise data from previous stages into one object
                component: Action,
				config: {
					action: ({ finish }) => {
						if (this.stageData(3).length > 1) {
							finish(this.stageData(5))
						} else {
							finish(this.stageData(3)[0])
						}
					}
				}
            },
            {
                // 7 - Checking if selected item was already picked
                component: Action,
                config: {
                    action: ({ finish, error, changeStage }) => {
                        if (this.stageData(6).status && this.stageData(6).status === "collected") {
                            error("Item already loaded.")
                            setTimeout(() => {
                                changeStage(3)
                            }, 3000)
                        } else {
                            finish()
                        }
                    }
                }
            },
            {
                // 8 - Add optional images to selected stock item
                component: Custom,
                config: {
                    state: {
                        files: []
                    },
                    render: stage => {
                        return (
                            <div className="workOrderStockFileStage">
                                <div>
                                    Add images if necessary.
                                </div>
                                <FileField
                                    varname="files"
                                    apiRoot={this.context.apiRoot}
                                    change={(varname, value, error) => {
                                        stage.setState({ files: value })
                                    }}
                                >
                                    {stage.state.files}
                                </FileField>
                                <div
                                    className="add custom-bg-feature1"
                                    onClick={() => {
                                        let data = { files: [] };
                                        if (stage.state.files.length) {
                                            data = {
                                                files: stage.state.files.map(f => {
                                                    return f.url
                                                })
                                            }
                                        }
                                        $.ajax({
                                            type: "PUT",
                                            url: `${this.context.apiRoot}/collections/${this.stageData(1).id}/collect/${this.stageData(6).id}`,
                                            data: JSON.stringify(data)
                                        }).then(response => {
                                            // Check if collection was completed
                                            let finished = true;
                                            response.collection.stock.forEach(s => {
                                                if (s.status !== "collected") finished = false
                                            });
                                            // Update the selected collection object
                                            this.setStageData(1, response.collection, () => {
                                                if (finished) {
                                                    // All items are unloaded
                                                    if (response.collection.signature && response.collection.signedBy) {
                                                        // Already got name and signature
                                                        this.changeStage(10)
                                                    } else {
                                                        this.changeStage(9)
                                                    }
                                                } else {
                                                    this.changeStage(2)
                                                }
                                            })
                                        }).catch(err => {
                                            console.error(err)
                                            stage.error("Error collecting stock item.")
                                        });
                                    }}
                                >
                                    Next
                                </div>
                            </div>
                        )
                    }
                }
            },
            {
                // 9 - Storing signature
                component: SignatureStage,
                config: {
                    title: "Add signature"
                }
            },
            {
                // 10 - Adding signature to the collection
                component: Action,
                config: {
                    action: ({ error, finish, changeStage }) => {
                        let data = this.stageData(9);
                        $.ajax({
                            url: `${this.context.apiRoot}/collections/${this.stageData(1).id}`,
                            type: "PUT",
                            data: JSON.stringify(data)
                        }).done(res => {
                            finish()
                        }).catch(err => {
                            console.error(err);
                            error("Error while adding signature");
                            setTimeout(() => {
                                changeStage(9)
                            }, 3000)
                        })
                    }
                }
            },
            {
                // 11 - Add required images to collection
                component: Custom,
                config: {
                    state: () => {
                        let files = this.stageData(1).images ? this.stageData(1).images.map(img => {
                            return {
                                url: img,
                                name: img,
                                type: "image"
                            }
                        }) : []
                        return { files }
                    },
                    render: stage => {
                        let collection = this.stageData(1);
                        let fileCount = this.fileCount(stage.state.files, collection);
                        return (
                            <div className="workOrderAddFileStage">
                                <div className="title">Add images</div>
                                <div className="destination">
                                    <div className="existing">
                                        <div className="dest-name">
                                            {`${fileCount}/3`}
                                        </div>
                                        <div className="files">
                                            {(() => {
                                                let files = []
                                                collection.stock
                                                    .forEach(s => {
                                                        files = files.concat(s.files);
                                                    });
                                                return files.map((file, index) => {
                                                    return <div
                                                        key={`${file}-${index}`}
                                                        className="file"
                                                        style={{ backgroundImage: `url("${file}")` }}
                                                    />
                                                })
                                            })()}
                                        </div>
                                    </div>
                                </div>
                                <div className="add-more">Add more</div>
                                <FileField
                                    varname="files"
                                    apiRoot={this.context.apiRoot}
                                    change={(vn, files) => {
                                        $.ajax({
                                            url: `${this.context.apiRoot}/collections/${collection.id}`,
                                            type: "PUT",
                                            data: JSON.stringify({
                                                images: files.map(f => {
                                                    return f.url
                                                })
                                            })
                                        }).done(res => {
                                            stage.setState({ files });
                                        }).catch(err => {
                                            stage.error("Error updating collection")
                                        })
                                    }}
                                >
                                    {stage.state.files}
                                </FileField>
                                <FlipMove>
                                    {fileCount >= 3
                                        ?
                                        <button
                                            style={{
                                                marginTop: '20px'
                                            }}
                                            key="button"
                                            className="custom-bg-feature1 finishStage"
                                            onClick={() => {
                                                // Finish stage
                                                this.nextStage()
                                            }}
                                        >
                                            FINISH STAGE
                                        </button>
                                        :
                                        null
                                    }
                                </FlipMove>
                            </div>
                        )
                    }
                }
            },
            {
                // 12 - Changing collection status to collected
                component: Action,
                config: {
                    action: ({ error, finish, changeStage }) => {
                        let data = { status: "collected" }
                        $.ajax({
                            url: `${this.context.apiRoot}/collections/${this.stageData(1).id}`,
                            type: "PUT",
                            data: JSON.stringify(data)
                        }).done(res => {
                            finish()
                        }).catch(err => {
                            console.error(err);
                            error("Error while changing collection status");
                            setTimeout(() => {
                                changeStage(11)
                            }, 3000)
                        })
                    }
                }
            },
            {
                // 13 - Success msg
                component: Success,
                config: {
                    message: () => <div>Success! Collection Loaded.</div>
                }
            }
        ]
    }

    msToDate(ms) {
        const date = new Date(ms);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    fileCount(files, collection) {
        //And getting number of files already uploaded
        let filesUploaded = 0;
        //Adding files from stock
        collection.stock.forEach(s => {
            filesUploaded += s.files.length;
        });
        //Adding files from state
        filesUploaded = filesUploaded + (files || []).length;
        //Returning a string
        return filesUploaded;
    }

    getFullStock(stock, cb) {
        // Takes an array of stock from previously selected collection
        let ids = stock.map(s => s.id)
        $.post(`${this.context.apiRoot}/stock/multi`, JSON.stringify(ids)).done(response => {
            // Combining collection stock with corresponding full stock object
            let items = stock.map(s => {
                let match = response.results.filter(res => res.id === s.id)[0]
                if (!match) return s;
                return Object.assign({}, match, s);
            })
            if (cb) cb(null, items);
        }).catch(err => {
            if (cb) cb(err)
        })
    }

    getItems(cb) {
        //Creating to and from dates
        let date = new Date().getTime();
        let from = date - (86400000 * 15);
        let to = date + (86400000 * 15);
        //Getting collections
        $.get(`${this.context.apiRoot}/collections?from=${from}&to=${to}`).done(response => {
            // Only saving confirmed collections
            let items = response.results.filter(result => ["picked", "confirmed"].includes(result.status) && !result.isCourier);
            if (cb) cb(null, items);
        }).catch(err => {
            if (cb) cb(err)
        })
    }

}

export default LoadCollection;