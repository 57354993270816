import View from "../view";
import UiPanel from "../../assets/ui-panel";
import Spinner from "../../assets/spinner";
import { Aux, renderDate } from "../../helpers";
import RemovalCreator from "./RemovalCreator";
import RemovalEditor from "./RemovalEditor";

class RemovalView extends View {
	constructor(props) {
		super(props);

		this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);

		this.state = {
			removal: null,
			ready: false
		}
	}

	componentDidMount() {
		// Hide loading spinner after 1 seconds
		setTimeout(() => {
			this.setState({ ready: true })
		}, 1500);
		// Check if got removal id 
		const removalId = this.props.router.match.params.removalId;
		if (removalId && removalId !== "create") {
			// Get a single removal collection matching the removalId
			this.getRemoval({ id: removalId });
		}
	};

	componentDidUpdate(prevProps) {
		const prevRemovalId = prevProps.router.match.params.removalId;
		const removalId = this.props.router.match.params.removalId;

		if (prevRemovalId !== removalId) {
			if (removalId && removalId !== "create") {
				this.getRemoval({ id: removalId });
			}
		}
	};

	createQueryString(obj) {
		return "?" + Object.keys(obj).map(name => {
			if (!obj[name]) return "";
			return `${name}=${obj[name]}`;
		}).join("&");
	}

	getRemoval(query, cb) {
		$.get(`${this.props.apiRoot}/removals/${this.createQueryString(query)}`)
			.done(removal => {
				if (cb) {
					cb(removal);
				} else {
					this.setState({ removal });
				}
			})
			.catch(err => {
				logger.error(err);
			});
	};

	updateRemoval(changes, id, cb) {
		$.ajax({
			type: "PUT",
			url: `${this.props.apiRoot}/removals/${id}`,
			data: JSON.stringify(changes)
		}).done(res => {
			logger.success(res.msg);
			this.setState({ removal: res.removal });
			if (cb) cb();
		}).catch(err => {
			logger.error(err)
		})
	}

	renderView() {
		return (
			<Aux>
				<div className="row heading-row">
					<div className="col-12">
						<h1 className="client-view custom-c-dark">{`${this.client} Removal Collections`}</h1>
						<h5 className="data custom-c-mid">{renderDate()}</h5>
					</div>
				</div>
				<div className="row view removalsView">
					<div className="col-12 panel-wrapper">
						{this.state.ready
							?
							<UiPanel
								name={this.state.removal ? `Editing Removal Collection - ${this.state.removal.id}` : "Removal Collection Creator"}
								icon="home"
							>
								{(this.state.removal && this.state.removal.id)
									?
									<RemovalEditor 
										router={this.props.router}
										apiRoot={this.props.apiRoot}
										removal={this.state.removal}
										user={this.props.user}
										onUpdate={(changes, id, cb) => {
											this.updateRemoval(changes, id, cb)
										}}
									/>
									:
									<RemovalCreator
										apiRoot={this.props.apiRoot}
									/>
								}
							</UiPanel>
							:
							<Spinner size={90} />
						}
					</div>
				</div>
			</Aux>
		)
	}
}

export default RemovalView;