import UiStatusPanel from "./ui-status-panel";
import CalendarList from "./ui-calendar-list";

/**
 *  PROPS: 
 * 
 *      startDay: MS number, first day to render
 *      dayCount: NUMBER, number of days to render
 *      showWeekends: BOOLEAN
 */

class CalendarPreview extends React.Component {
    constructor(props) {
        super(props)
        this.items = [
            {
                date: 1532300400000,
                title: "item 1",
                type: "document",
                status: "warning",
                ref: "1"
            }
        ]
    }


    formatDate(date) {
        //Takes a date object and returns a date string "YYYY/MM/DD"
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return `${year}/${month}/${day}`;
    }
    
    getPanelTitle(day) {
        //Converts a ms valuse into a dayName dayNumber format
        const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        const date = new Date(day);
        const dayName = weekDays[date.getDay()];
        const dayNumber = date.getDate();

        return `${dayName} ${dayNumber}`
    }

    getDaysToRender() {
        let start = this.props.startDay; // MS INTEGER
        const secondsInDay = 1000 * 60 * 60 * 24;
        // Will hold the final days.
        let result = [];
        // Loop through, adding days, until we reach our max
        let dayCount = 0;
        while (result.length < this.props.dayCount) {
            // Create the date we're checking.
            let date = new Date(start + (dayCount * secondsInDay));
            // check if a weekend, continue if so.
            if (this.props.showWeekends === true) {
                result.push(this.formatDate(date));
            } else if ( date.getDay() !== 0 && date.getDay() !== 6) {
                // Otherwise
                result.push(this.formatDate(date));
            }
            dayCount++;
        }

        return result;

    }

    // checkIfWarning(day) {
    //     //Gets all items for selected day
    //     let items = this.getItemsForDay(day);
    //     //Result is false by default
    //     let result = false;

    //     //Result is true if any item's status property === "warning"
    //     items.forEach(item => {
    //         if (item.status === "warning") {
    //             result = true;
    //         }
    //     });

    //     //Return result
    //     return result;

    // }


    getItemsForDay(day) {
        //Iterates through all items, if item.date === day, append the item into the arr and return the arr
        let arr = [];
        day = day.split("/");
        this.props.items.forEach(item => {
            let date = new Date(item.date);
            if (Number(day[0]) === date.getFullYear() && Number(day[1]) === date.getMonth() + 1 && Number(day[2]) === date.getDate()) {
                arr.push(item)
            }
        })

        return arr;
    }

    renderDayFields() {
        const days = this.getDaysToRender()
        return days.map(day => {
            return (
                <div className="col-12 col-sm-6 col-lg-4 col-xl-2 calendar-col" key={day}>
                    <UiStatusPanel
                        classes="custom-border-color"
                        title={this.getPanelTitle(day)}
                        warning={false}
                    >
                        <CalendarList
                            listItems={this.getItemsForDay(day)}
                            eventHandler={this.props.eventHandler}
                        />
                    </UiStatusPanel>
                </div>
            )
        })
    }

    render() {
        // console.log("items", this.props.items);
        return (
            <div className="content-calendar row">
                {this.renderDayFields()}
            </div>
        )
    }

}

export default CalendarPreview;