import FlipMove from "react-flip-move";
import Process from "./index";
// Stages
import Action from "../stages/action";
import Success from "../stages/success";
import Custom from "../stages/custom";
import SelectStock from "../stages/selectStock";
// Field Components
import TextareaField from "../../../fields/textareaField";
import FileField from "../../../fields/fileField";

class DamageReport extends Process {
    constructor(props) {
        super(props);
        this.state.stage = 0;
        this.stages = [
            {
                // 0 - Selecting stock
                component: SelectStock
            },
            {
                // 1 - Creating damage report data
                component: Custom,
                config: {
                    state: {
                        files: "",
                        text: ""
                    },
                    render: stage => {
                        return (
                            <div className="damageReportStage">
                                <div className="title">Create damage report</div>
                                <div className="icon report-icon largeIcon" />
                                <TextareaField
                                    label="Notes"
                                    varname="text"
                                    validator={value => {
                                        if (value.length <= 250) {
                                            return null;
                                        } else {
                                            stage.setState({
                                                error: "Message to long, up to 250 characters."
                                            })
                                        }
                                    }}
                                    change={(vn, value, error) => {
                                        stage.setState({ text: value })
                                    }}
                                >
                                    {stage.state.text}
                                </TextareaField>
                                <FileField
                                    readOnly={false}
                                    label="Files"
                                    varname="files"
                                    apiRoot={this.context.apiRoot}
                                    change={(vn, value, error) => {
                                        stage.setState({ files: value })
                                    }}
                                >
                                    {stage.state.files}
                                </FileField>
                                <FlipMove>
                                    {stage.state.files.length
                                        ?
                                        <button
                                            key="createDataButton"
                                            className="create custom-bg-feature1"
                                            onClick={() => {
                                                let data = {
                                                    content: {
                                                        text: stage.state.text || null,
                                                        files: stage.state.files,
                                                        status: null
                                                    }
                                                }
                                                stage.stageComplete(data)
                                            }}
                                        >
                                            Create
                                        </button>
                                        :
                                        null
                                    }
                                </FlipMove>
                            </div>
                        )
                    }
                }
            },
            {
                // 3 - making the create report call
                component: Action,
                config: {
                    action: ({ finish, error, changeStage }) => {
                        //create the report
                        let data = JSON.stringify({
                            type: "damage",
                            title: `Damaged stock - ${this.stageData(0).code}`,
                            customer: "internal"
                        });

                        $.ajax({
                            type: "POST",
                            url: `${this.context.apiRoot}/reports`,
                            data
                        }).done(response => {
                            //Create a msg for this report
                            $.ajax({
                                type: "POST",
                                url: `${this.context.apiRoot}/reports/${response.report.id}/messages`,
                                data: JSON.stringify(this.stageData(1))
                            }).done(res => {
                                // Link stock with report
                                let stockObj = {
                                    type: 'stock',
                                    id: this.stageData(0).id
                                };
                                let reportObj = {
                                    type: "report",
                                    id: response.report.id
                                }
                                // Api call to link them
                                $.ajax({
                                    type: "POST",
                                    url: `${this.context.apiRoot}/misc/link`,
                                    data: JSON.stringify([stockObj, reportObj])    
                                }).done(r => {
                                    // Link successful, go to next stage
                                    finish();
                                }).catch(e => {
                                    console.error(e);
                                    error("Error linking stock with report");
                                    setTimeout(() => {
                                        changeStage(1)
                                    }, 3000);
                                })
                            }).catch(err => {
                                console.error(err);
                                error("Error creating report message");
                                setTimeout(() => {
                                    changeStage(1)    
                                }, 3000);
                            })
                        }).catch(error => {
                            console.error(error);
                            error("Error creating report");
                            setTimeout(() => {
                                changeStage(1)
                            }, 3000);    
                        })
                    }
                }
            },
            {
                // 4 - show success message and finish process
                component: Success,
                config: {
                    message: () => <div>Success! Report created.</div>
                }
            }
        ]
    }
}

export default DamageReport;