import View from "./view";
import CSVField from "../fields/csvField";
import { rid } from "../helpers";

class Sandbox extends View {
    constructor(props) {
        super(props);
        this.state = {
            val: "",
            files: []
        }
    }
    renderView() {
        // console.log("PROPS", this.props);
        return <div className="row">
            <div className="col-12">
                <CSVField
                    change={(vn, value, err) => {
                        console.log("Value changed: ", value);
                    }}
                    onConfirm={data => {
                        console.log(data);
                    }}
                    columnChoices={[
                        "code",
                        "quantity",
                        "description",
                        "location",
                        "customer",
                        "ignore"
                    ]}
                    preProcess={s => {
                        let stock = Object.assign({
                            code: rid(),
                            quantity: "1"
                        }, s)
                        let rows = stock.code.split("\n");
                        // If only one value, return.
                        if (rows.length < 2) return stock;
                        // console.log(rows);
                        // Otherwise, change vals.
                        stock.code = rows.map(r => {
                            return (r.split(":")[1] || "")
                                .trim();
                        }).join("_");
                        return stock;
                    }}
                    postProcess={s => {
                        return Object.assign({}, s, {
                            quantity: s.quantity ? Number(s.quantity) : 1
                        })
                    }}
                    columns={[
                        "ignore",
                        "code",
                        "code",
                        "ignore",
                        "ignore",
                        "ignore",
                        "ignore",
                        "ignore",
                        "description",
                        "description",
                        "ignore",
                        "description"
                    ]}
                    columnLabels={[
                        "Transport number",
                        "Order number",
                        "Order position",
                        "Status",
                        "Production Order   ",
                        "Description",
                        "Colli number",
                        "Colli of",
                        "Commission text",
                        "Customer number",
                        "Relation to first/original order number",
                        "Net value"
                    ]}
                />
            </div>
        </div>
    }
}

export default Sandbox;