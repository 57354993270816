/**
 *  
 */

class UiSimplePanel extends React.Component {
    render() {
        return (
            <div className={"panel ui-simple-panel custom-bg-dark custom-c-light shadow-1 " + (this.props.classes ? this.props.classes : "")}>
                {this.props.header ?
                    <div className="heading custom-bg-light">{this.props.header}</div>
                    : null
                }
                <div className="panel-content">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default UiSimplePanel;