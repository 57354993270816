const lieferscheinGroupTemplate = {
	name: "Lieferschein Delivery Group",
	columns: [
		"description",
		"description",
		"description",
		"customer",
		"group",
		"group2",
		"description",
		"description",
		"description",
		"description",
		"description",
		"description",
		"description",
		"productCode",
		"description",
		"productCode",
		"code",
		"ignore",
		"ignore",
		"ignore",
		"ignore"
	],
	columnLabels: [
		"Order ref",
		"Note",
		"Note 1",
		"Note 2",
		"Order no 1",
		"Order no 2",
		"Tour no",
		"Stop no",
		"Sequence no",
		"Serie",
		"Pack",
		"Qty",
		"Order position",
		"",
		"A",
		"",
		"ID",
		"kg",
		"cbm",
		"State",
		"Mesasurements"
	],
	postProcess: (rows, context) => {
		let result = [];
		rows.forEach(item => {
			if (item.code && item.code !== "ID") {
				let newItem = Object.assign({}, item);
				// Prefix the code with a 0
				newItem.code = `0${newItem.code}`;
				// Find a customer ion existing mappings
				if (newItem.customer) {
					newItem.description += `\nCustomer_:  ${newItem.customer}`;
					newItem.customer = context.mapValue(newItem.customer) || null;
				};
				// Create a productCode
				if (newItem.productCode) {
					let pCode = newItem.productCode.split("\n");
					if (pCode.length > 1) {
						newItem.productCode = pCode.map(c => c.trim()).join("_");
					}
				}
				// Adding combining group and group2
				if (newItem.group2) {
					newItem.group = (newItem.group || "") + ".0" + newItem.group2
				}
				// Remove group2
				delete newItem.group2;
				// Add it
				result.push(newItem);
			}
		})
		return result;
	}
}

export default lieferscheinGroupTemplate;