const deliveryGroupTemplate = {
    name: "Leicht Delivery Group - Kitchens",
    columns: [
        "description",
        "group",
        "description",
        "ignore",
        "code",
        "productCode",
        "colli",
        "description",
        "customer",
        "description",
        "ignore",
        "description"
    ],
    columnLabels: [
        "Transport number",
        "Order number",
        "Order position",
        "Status",
        "Production Order",
        "Product code",
        "Colli number",
        "Colli of",
        "Customer",
        "Customer number",
        "Relation to first/original order number",
        "Net value"
    ],
    postProcess: (rows, context) => {
        let result = []
        rows.forEach(item => {
            let newItem = Object.assign({}, item);
            // First ensure colli number is used as code if it exists.
            if (newItem.colli !== "0") {
                newItem.code = newItem.colli;
            }
            // And remove the colli.
            delete newItem.colli;

            if (newItem.customer) {
                newItem.description += `\nCustomer_:  ${newItem.customer}`;
                newItem.customer = context.mapValue(newItem.customer) || null;
            }

            // Now look for a match.
            for (let i = 0; i < result.length; i++) {
                // If one is found, increase quantity, and exit.
                if (result[i].code === newItem.code) {
                    result[i].quantity = String(Number(result[i].quantity) + 1);
                    return;
                }
            }
            // Otherwise, add it on.
            result.push(newItem);
        });
        return result;
    }
}

export default deliveryGroupTemplate;