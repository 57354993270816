import FlipMove from "react-flip-move";
import MessageCreator from "./messageCreator";
import DataField from "../../fields/dataField";
import FileField from "../../fields/fileField";
import ItemLinker from "../../assets/itemLinker";

class Report extends React.Component {
    /**
     * PROPS:
     * 
     *  report (Object) - The report being rendered.
     */
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            users: {},
            editReport: false,
            initiator: "Loading...",
            customer: this.props.report ? this.props.report.customer : ""
        }
    }

    componentDidMount() {
        //If report props is available on mount
        if (this.props.report) {
            //Get the initiator name
            // console.log("Getting initiator name on mount")
            this.getUser(this.props.report.initiator, (user) => {
                this.setState({
                    initiator: user.name,
                    customer: this.props.report.customer
                })
            })
        }
        //Getting messages for the report
        this.getMessages((messages) => {
            this.setState({ messages })
        })
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.report === null && this.props.report) || (prevProps.report !== this.props.report)) {
            // console.log("Getting initiator name on update")
            this.getUser(this.props.report.initiator, (user) => {
                this.setState({
                    initiator: user.name,
                    customer: this.props.report.customer
                })
            })
        }
    }


    renderDate(ms) {
        const date = new Date(ms);

        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} at ${date.getHours()}:${date.getMinutes()}`
    }

    getMessages(cb) {
        let reportId = this.props.router.match.params.reportId;
        $.get(`${this.props.apiRoot}/reports/${reportId}/messages`).done(messages => {
            messages.forEach(message => {
                if (!this.state.users[message.from]) {
                    this.getUser(message.from, (user) => {
                        this.setState({
                            users: Object.assign(this.state.users, {
                                [message.from]: user
                            })
                        });
                    });
                }
            })
            if (cb) cb(messages);
        }).catch(err => {
            window.logger.error(err)
        });
    }

    getUser(id, cb) {
        $.get(`${this.props.apiRoot}/users?id=${id}`).then(response => {
            let user = response.results[0];
            if (!user) return logger.error(`User with id '${id}' could not be found.`);
            if (cb) cb(user)
        });
    }

    messageCreated(msg) {
        let reportId = this.props.router.match.params.reportId;
        $.post(`${this.props.apiRoot}/reports/${reportId}/messages`, JSON.stringify(msg)).done(() => {
            if (msg.content.status && msg.content.status !== null && msg.content.status !== this.props.report.status) {
                this.props.getReport()
            }
            this.getMessages((messages) => {
                this.setState({
                    messages,
                    editReport: !this.state.editReport
                })
            })
        }).catch(err => {
            window.logger.error(err)
        })
    }

    renderMessages() {
        let messages = this.state.messages
        if (messages.length) {
            return messages
                .sort((a, b) => {
                    return a.created - b.created
                })
                .map(msg => {
                    return (
                        <div className="message custom-bg-light" key={msg.id}>
                            <div className="border-color custom-bg-feature1"></div>
                            <div className="made-by">
                                <h2>{this.state.users[msg.from] ? this.state.users[msg.from].name : "Loading..."}</h2>
                                <p>{this.renderDate(msg.created)}</p>
                            </div>
                            <div className="content custom-c-dark">
                                {msg.content.text
                                    ?
                                    <p className="msg-text">{msg.content.text}</p>
                                    :
                                    null
                                }
                                {msg.content.files && msg.content.files.length
                                    ?
                                    <div className="files">
                                        <FileField
                                            readOnly={true}
                                        >
                                            {msg.content.files}
                                        </FileField>
                                    </div>
                                    :
                                    null
                                }
                                {msg.content.status
                                    ?
                                    <div className="custom-c-light">
                                        {`Status changed to ${msg.content.status}`}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    )
                })
        }
        return null
    }

    render() {
        let report = this.props.report;
        return report ? (
            <div className="report-content">
                <div className="header">
                    <div className="customer-initiator custom-c-dark">
                        {this.props.user.role <= 1
                            ?
                            <div className="customer">
                                <DataField
                                    varname="customer"
                                    defaults={[
                                        {
                                            name: "internal",
                                            displayName: "Internal User"
                                        }
                                    ]}
                                    optionRenderer={customer => <div className="option-inner">
                                        <b>{customer.displayName}</b> <br />({customer.name})
                                    </div>
                                    }
                                    label="Customer"
                                    route="customers"
                                    dataName="name"
                                    searchBy="term"
                                    change={(varname, val, error) => {
                                        if (val.name === this.state.customer) return;
                                        let confirmed = false;
                                        if (val.name === "internal") {
                                            confirmed = window.confirm("You are about to set this report to 'Internal'. Customers will no longer be able to see this report. Are you sure?");
                                        } else {
                                            confirmed = window.confirm(`You are about to make this report visible to ${val.displayName} users. Are you sure?`);
                                        }
                                        if (confirmed) {
                                            this.setState({
                                                customer: val.name
                                            });
                                            this.props.onUpdate({ customer: val.name }, this.props.report.id)
                                        }
                                    }}
                                >
                                    {this.state.customer}
                                </DataField>
                            </div>
                            :
                            <div className="customer">
                                <p>Customer</p>
                                <p className="name">{this.state.customer}</p>
                            </div>
                        }
                        <div className="initiator">
                            <p>Initiator</p>
                            <p className="name">{this.state.initiator}</p>
                        </div>
                    </div>
                    <div className="linker-wrapper">
                        <label className="custom-c-dark">Link items</label>
                        <ItemLinker
                            apiRoot={this.props.apiRoot}
                            router={this.props.router}
                            itemType="report"
                            itemId={this.props.report.id}
                        />
                    </div>
                    <hr />
                    <div className="status custom-c-dark">
                        <p>{report.status}</p>
                    </div>
                    <div className="report-title">
                        <div className="icon document-icon"></div>
                        <h2>{report.title}</h2>
                    </div>
                </div>
                <FlipMove className="messages">
                    {this.renderMessages()}
                </FlipMove>
                <div className="add-to-report custom-bg-light">
                    <div className="border-color"></div>
                    <div
                        className="wrapper custom-c-dark"
                        onClick={() => {
                            this.setState({ editReport: !this.state.editReport })
                        }}
                    >
                        <div className="icon add-icon"></div>
                        <div style={{ fontWeight: "bold" }}>Add to this report</div>
                    </div>
                    <FlipMove appearAnimation="fade" enterAnimation="fade">
                        {this.state.editReport
                            ?
                            <MessageCreator
                                onSubmit={this.messageCreated.bind(this)}
                                apiRoot={this.props.apiRoot}
                            />
                            :
                            null
                        }
                    </FlipMove>
                </div>
            </div>

        ) : (
                <div className="loading">Loading report...</div>
            )
    }
}

export default Report;