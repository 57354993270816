import SelectorStage from "./selectorStage";

class MultiStockSelect extends SelectorStage {
    constructor(props) {
        super(props);
        this.state.stock = [];
    }

    getItem(code) {
        return new Promise((resolve, reject) => {
            $.get(`${this.context.apiRoot}/stock/combine/${code}`).then(response => {
                if (!response.total) {
                    reject("No item found.");
                } else {
                    // console.log("RESULTS: ", response.results);
                    resolve(response.results);
                }
            }).catch(err => {
                reject(err.responseJSON.msg)
            });
        });
    }
}

MultiStockSelect.defaultProps = {
    title: "Select stock.",
    icon: "stock"
}

export default MultiStockSelect;