const hackerDeliveryGroupTemplate = {
	name: "Hacker Delivery Group",
	columns: [
		"description",
		"description",
		"code",
		"quantity",
		"description",
		"description",
		"group",
		"description",
		"description",
		"description",
		"ignore",
		"description",
		"description",
		"description",
		"description",
		"description",
		"description"
	],
	columnLabels: [
		"Desc",
		"Desc",
		"Barcode",
		"Quantity",
		"Plot",
		"Purchase order",
		"Group",
		"Desc",
		"Desc",
		"Order position",
		"",
		"Desc",
		"Desc",
		"Desc",
		"Desc",
		"Desc",
		"Desc"
	],
	postProcess: (rows, context) => {
		let result = [];
		rows.forEach(item => {
			if (item.code && !["barcode", "barocde"].includes(item.code)) {
				let newItem = Object.assign({}, item);
				// Find a customer ion existing mappings
				// newItem.description += `\nCustomer_:  Hacker`;
				// newItem.customer = context.mapValue("Hacker") || null;
				result.push(newItem);
			}
		})
		return result;
	}
}

export default hackerDeliveryGroupTemplate;