
import CollectionStockList from "./collectionStockList";
import FlipMove from "react-flip-move";
import { renderDate } from "../../helpers";
import TextareaField from "../../fields/textareaField";
import SelectField from "../../fields/selectField"
import FileField from "../../fields/fileField";
import TimeField from "../../fields/timeField";
import DateField from "../../fields/dateField";
import DataField from "../../fields/dataField";
import NumberField from "../../fields/numberField";
import TextField from "../../fields/textField";
import CheckboxField from "../../fields/checkboxField";
import Pod from "./pod";
import Context from "../../context";
import { Prompt } from "react-router-dom";
import BooleanField from "../../fields/booleanField";
import FromFileCreator from "../courier-collection-view/fromFileCreator";

class CollectionCreator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collection: this.props.collection || {
                dateOutgoing: (dString => {
                    if (!dString) return Date.now();
                    let d = new Date(dString.split("#")[1]);
                    if (d.toString() === "Invalid Date") {
                        return Date.now();
                    } else {
                        return d.getTime();
                    }
                })(window.location.hash),
                customer: null,
                status: "pending",
                notes: null,
                timeOutgoing: "all day",
                stock: [],
                images: [],
                isCourier: this.props.isCourier
            },
            stock: [],
            changes: {},
            showPod: false,
            showImages: null,
            editingCustomer: false,
            showSignature: false,
            fileView: false
        }
        this.gotStock = false;
    }

    displayOnly() {
        return this.props.user.role === 3 || (this.props.user.role === 4 && this.props.collection && this.props.collection.status !== "pending")
    }

    pageChangeWarning() {
        if (Object.keys(this.state.changes).length) {
            return "You have unsaved changes. Are you sure?";
        } else {
            return null;
        }
    }

    componentDidMount() {
        //if editing an existing collection which ha stock items
        if (this.state.collection.id && this.state.collection.stock.length) {
            this.getStock()
        }
        window.onbeforeunload = () => {
            if (Object.keys(this.state.changes).length) {
                return "You have unsaved changes. Are you sure?";
            } else {
                return null;
            }
        }
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    componentDidUpdate(pp, ps) {
        let hasChanged = this.hasCollectionChanged(pp.collection, this.props.collection);
        if (hasChanged) {
            this.setState({
                collection: this.props.collection || {
                    dateOutgoing: null,
                    customer: null,
                    status: "pending",
                    notes: null,
                    timeOutgoing: "all day",
                    stock: [],
                    images: []
                }
            })
        }
        if (this.state.collection.id && !this.gotStock) {
            this.getStock();
        }
        // else if (ps.stock.map(s => s.id).join(" ") !== this.state.stock.map(s => s.id).join(" ")) {
        //     this.getStock();
        // }
    }

    getStock(ids) {
        // Creating array of stock id's
        this.gotStock = true;
        let stock = ids || this.state.collection.stock.map(s => s.id);
        if (stock.length) {
            $.post(`${this.props.apiRoot}/stock/multi`, JSON.stringify(stock))
                .done(res => {
                    this.setState({ stock: res.results })
                })
                .catch(err => {
                    logger.error(err)
                })
        } else {
            this.setState({
                stock: []
            });
        }
    }

    merge(id1, id2, cb) {
        $.ajax({
            url: `${this.context.apiRoot}/stock/merge/${id1}/${id2}`,
            method: "POST"
        }).then(response => {
            if (cb) cb();
        }).catch(err => {
            console.error("Error merging: ", err);
        });
    }

    unlockStock(id, cb) {
        $.get(`${this.context.apiRoot}/stock/${id}/unlock`).then(res => {
            if (cb) cb();
        }).catch(err => {
            console.error("Error unlocking stock: ", err);
        })
    }

    hasCollectionChanged(oldCollection, newCollection) {
        if (!oldCollection && newCollection) return true;
        if (!newCollection && oldCollection) return true;
        if (!oldCollection && !newCollection) return false;

        let hasChanged = false;
        Object.keys(newCollection).forEach(key => {
            if (newCollection[key] !== oldCollection[key]) hasChanged = true;
        })

        return hasChanged;
    }

    renderSelectCustomerField() {
        return this.state.editingCustomer || !this.state.collection.id
            ?
            <React.Fragment>
                <DataField
                    varname="customer"
                    defaults={[
                        {
                            name: "internal",
                            displayName: "Internal User"
                        }
                    ]}
                    optionRenderer={customer => (
                        <div className="option-inner">
                            <b>{customer.displayName}</b> <br />({customer.name})
                        </div>
                    )}
                    label="Customer"
                    route="customers"
                    dataName="name"
                    searchBy="term"
                    change={(varname, value, error) => {
                        let customer = value ? value.name : null;
                        if (!customer) return;
                        let collection = Object.assign({}, this.state.collection, { customer });
                        let changes = Object.assign({}, this.state.changes, { customer })
                        this.setState({
                            collection,
                            changes,
                            editingCustomer: false
                        });
                    }}
                >
                    {this.state.collection.customer || ""}
                </DataField>
                <br />
            </React.Fragment>
            :
            <div className="show-customer">
                <label className="custom-c-dark">Customer</label>
                <div className="name custom-c-feature2">
                    {this.state.collection.customer || "Internal"}
                    {this.props.user.role < 3
                        ?
                        <button
                            style={{
                                marginLeft: "20px"
                            }}
                            className="btn"
                            onClick={e => {
                                if (window.confirm("Are you sure you want to edit the customer on this collection? This can cause unexpected issues if you do not know what you're doing.")) {
                                    this.setState({
                                        editingCustomer: true
                                    });
                                }
                            }}
                        >
                            Edit
                        </button>
                        :
                        null
                    }
                </div>
            </div>
    }

    renderStatusButtons() {
        if (!this.state.collection.id) return null;
        if (this.displayOnly() || this.state.collection.status === "collected") {
            // Not for editing
            return (
                <div className="display-only custom-c-dark">
                    <label>Collection Status</label>
                    <p>{this.state.collection.status}</p>
                </div>
            )
        } else {
            let status = this.state.collection.status;
            let optionsArray = [
                {
                    val: "cancelled",
                    display: "Cancelled"
                },
                {
                    val: "pending",
                    display: "Pending"
                },
                {
                    val: "confirmed",
                    display: "Confirmed"
                },
            ];
            if (this.state.collection.isCourier) {
                optionsArray.push(
                    {
                        val: "prepared",
                        display: "Prepared"
                    },
                    {
                        val: "collected",
                        display: "Collected"
                    })
            } else {
                optionsArray.push(
                    {
                        val: "picked",
                        display: "Picked"
                    },
                    {
                        val: "collected",
                        display: "Collected"
                    })
            }
            return (
                <div style={{ paddingBottom: "20px" }}>
                    <SelectField
                        label="Collection status"
                        varname="status"
                        options={optionsArray}
                        change={(varname, value, error) => {
                            if (value !== status) {
                                if ((value === "picked" || value === "collected" || value === "prepared") && this.props.user.role > 1) {
                                    logger.error(`You cannot manually change a collection status to ${value}.`)
                                } else {
                                    if (window.confirm("Are you sure you want to manually change the status of this collection? This can cause unexpected issues if you do not know what you're doing.")) {
                                        let collection = Object.assign({}, this.state.collection);
                                        collection.status = value;
                                        let changes = Object.assign({}, this.state.changes);
                                        changes.status = value;
                                        this.setState({ changes, collection })
                                    }
                                }
                            }
                        }}
                    >
                        {status}
                    </SelectField>
                </div>
            )
        }
    }

    render() {
        let allStockIds = this.state.stock.map(s => s.id);
        let displayOnly = this.displayOnly();
        const isCourier = this.state.collection.isCourier;

        if (this.state.fileView) {
            return <FromFileCreator
                dateOutgoing={this.state.collection.dateOutgoing} 
                onBack={() => this.setState({ fileView: false })}
            />
        }

        return (
            <div className="collection-creator">
                <Prompt
                    when={Object.keys(this.state.changes).length > 0}
                    message='You have unsaved changes, are you sure?'
                />
                {this.renderSelectCustomerField()}
                {this.renderStatusButtons()}
                {this.props.collection && this.props.collection.signature
                    ?
                    <div
                        className="show-risk-assessment button custom-bg-feature1"
                        onClick={() => {
                            this.setState({
                                showSignature: !this.state.showSignature
                            })
                        }}
                    >
                        {this.state.showSignature ? "Hide signature" : "Show signature"}
                    </div>
                    :
                    null
                }
                {this.state.showSignature
                    ?
                    <React.Fragment>
                        {this.props.collection.signedBy
                            ?
                            <div className="display-only custom-c-dark">
                                <br />
                                <label>Signed by</label>
                                <p>{this.props.collection.signedBy}</p>
                            </div>
                            :
                            null
                        }
                        <div className="signature custom-bg-light custom-border-3">
                            <img src={this.props.collection.signature} />
                        </div>
                    </React.Fragment>
                    :
                    null
                }
                <div style={{ paddingBottom: "10px" }}>
                    {isCourier
                        ?
                        (!displayOnly
                            ?
                            <div style={{ width: "200px" }} className="display-inline">
                                <SelectField
                                    name="Select"
                                    label="Courier"
                                    varname="courier"
                                    options={[
                                        {
                                            val: "royal-mail-uk",
                                            display: "Royal Mail UK"
                                        },
                                        {
                                            val: "royal-mail-eu",
                                            display: "Royal Mail EU"
                                        },
                                        {
                                            val: "aylebsury-logistics",
                                            display: "Aylebsury Logistics"
                                        },
                                        {
                                            val: "dhl",
                                            display: "DHL"
                                        },
                                        {
                                            val: "dpd",
                                            display: "DPD"
                                        },
                                        {
                                            val: "parcelforce",
                                            display: "Parcelforce"
                                        },
                                        {
                                            val: "fed-ex",
                                            display: "Fed-Ex"
                                        },
                                        {
                                            val: "apx",
                                            display: "APC"
                                        },
                                        {
                                            val: "other",
                                            display: "Other"
                                        }
                                    ]}
                                    change={(varname, value, error) => {
                                        let collection = Object.assign({}, this.state.collection, {
                                            courier: value
                                        });
                                        this.setState({ collection });
                                        //Handle changes for editing a collection.
                                        if (this.state.collection.id) {
                                            let changes = Object.assign({}, this.state.changes, {
                                                courier: value
                                            });
                                            this.setState({ changes })
                                        }
                                    }}
                                >
                                    {this.state.collection.courier}
                                </SelectField>
                            </div>
                            :
                            <div className="display-only split custom-c-dark">
                                <label>Courier</label>
                                <p>{this.state.collection.courier}</p>
                            </div>
                        )
                        :
                        null
                    }
                    {isCourier
                        ?
                        (!displayOnly
                            ?
                            <div style={{ width: "160px" }} className="display-inline">
                                <NumberField
                                    label="Parcels"
                                    varname="parcels"
                                    change={(varname, value, error) => {
                                        let collection = Object.assign({}, this.state.collection, {
                                            parcels: value
                                        });
                                        this.setState({ collection });
                                        //Handle changes for editing a collection.
                                        if (this.state.collection.id) {
                                            let changes = Object.assign({}, this.state.changes, {
                                                parcels: value
                                            });
                                            this.setState({ changes })
                                        }
                                    }}
                                >
                                    {this.state.collection.parcels || 1}
                                </NumberField>
                            </div>
                            :
                            <div className="display-only split custom-c-dark display-inline">
                                <label>Parcels</label>
                                <p>{this.state.collection.parcels || ""}</p>
                            </div>
                        )
                        :
                        null
                    }
                    {isCourier
                        ?
                        (!displayOnly
                            ?
                            <div style={{ width: "200px" }} className="display-inline">
                                <TextField
                                    label="Weight"
                                    varname="weight"
                                    onFocus={e => e.target.select()}
                                    change={(varname, value, error) => {
                                        let newValue = String(value);
                                        if (/^[0-9\.]*$/.test(newValue)) {
                                            let collection = Object.assign({}, this.state.collection, {
                                                weight: newValue
                                            });
                                            this.setState({ collection });
                                            //Handle changes for editing a collection.
                                            if (this.state.collection.id) {
                                                let changes = Object.assign({}, this.state.changes, {
                                                    weight: newValue
                                                });
                                                this.setState({ changes });
                                            }
                                        }
                                    }}
                                >
                                    {this.state.collection.weight || ""}
                                </TextField>
                            </div>
                            :
                            <div className="display-only split custom-c-dark display-inline">
                                <label>Weight</label>
                                <p>{this.state.collection.weight || ""}</p>
                            </div>
                        )
                        :
                        null
                    }
                </div>
                <div style={{ paddingBottom: "10px" }}>
                    {isCourier
                        ?
                        (!displayOnly
                            ?
                            <div style={{ width: "300px" }} className="display-inline">
                                <TextField
                                    label="Order Number"
                                    varname="orderNumber"
                                    onFocus={e => e.target.select()}
                                    change={(varname, value, error) => {
                                        let collection = Object.assign({}, this.state.collection, {
                                            orderNumber: value
                                        });
                                        this.setState({ collection });
                                        //Handle changes for editing a collection.
                                        if (this.state.collection.id) {
                                            let changes = Object.assign({}, this.state.changes, {
                                                orderNumber: value
                                            });
                                            this.setState({ changes })
                                        }
                                    }}
                                >
                                    {this.state.collection.orderNumber || ""}
                                </TextField>
                            </div>
                            :
                            <div className="display-only split custom-c-dark display-inline">
                                <label>Order Number</label>
                                <p>{this.state.collection.orderNumber || ""}</p>
                            </div>
                        )
                        :
                        null
                    }
                    {isCourier
                        ?
                        (!displayOnly
                            ?
                            <div style={{ width: "300px" }} className="display-inline">
                                <TextField
                                    label="Tracking number"
                                    varname="trackingNumber"
                                    onFocus={e => e.target.select()}
                                    change={(varname, value, error) => {
                                        let collection = Object.assign({}, this.state.collection, {
                                            trackingNumber: value
                                        });
                                        this.setState({ collection });
                                        //Handle changes for editing a collection.
                                        if (this.state.collection.id) {
                                            let changes = Object.assign({}, this.state.changes, {
                                                trackingNumber: value
                                            });
                                            this.setState({ changes })
                                        }
                                    }}
                                >
                                    {this.state.collection.trackingNumber || ""}
                                </TextField>
                            </div>
                            :
                            <div className="display-only split custom-c-dark display-inline">
                                <label>Tracking number</label>
                                <p>{this.state.collection.trackingNumber || ""}</p>
                            </div>
                        )
                        :
                        null
                    }
                </div>
                <div>
                    {isCourier
                        ?
                        (!displayOnly
                            ?
                            <div className="display-inline">
                                <CheckboxField
                                    label="Extra packaging"
                                    varname="extraPackaging"
                                    change={(varname, value, error) => {
                                        let collection = Object.assign({}, this.state.collection, {
                                            extraPackaging: value
                                        });
                                        this.setState({ collection });
                                        //Handle changes for editing a collection.
                                        if (this.state.collection.id) {
                                            let changes = Object.assign({}, this.state.changes, {
                                                extraPackaging: value
                                            });
                                            this.setState({ changes })
                                        }
                                    }}
                                >
                                    {this.state.collection.extraPackaging || false}
                                </CheckboxField>
                            </div>
                            :
                            <div className="display-only split custom-c-dark display-inline">
                                <label>Extra Packaging</label>
                                <p>{this.state.collection.extraPackaging ? "Yes" : "No"}</p>
                            </div>
                        )
                        :
                        null
                    }
                </div>
                <div className="date-time-notes">
                    {!displayOnly
                        ?
                        <DateField
                            varname="dateOutgoing"
                            label="Date outgoing"
                            change={(varname, value, error) => {
                                let collection = Object.assign({}, this.state.collection, {
                                    dateOutgoing: value
                                });
                                this.setState({ collection });
                                //Handle changes for editing a collection.
                                if (this.state.collection.id) {
                                    let changes = Object.assign({}, this.state.changes, {
                                        dateOutgoing: value
                                    });
                                    this.setState({ changes })
                                }
                            }}
                        >
                            {this.state.collection.dateOutgoing}
                        </DateField>
                        :
                        <div className="display-only split first custom-c-dark">
                            <label>Date outgoing</label>
                            <p>{renderDate(this.state.collection.dateOutgoing)}</p>
                        </div>
                    }
                    {!displayOnly
                        ?
                        <TimeField
                            varname="time"
                            label="Time"
                            from={900}
                            to={1800}
                            change={(varname, value, error) => {
                                let collection = Object.assign({}, this.state.collection, {
                                    timeOutgoing: value
                                });
                                this.setState({ collection })

                                //Handle changes for editing a collection.
                                if (this.state.collection.id) {
                                    let changes = Object.assign({}, this.state.changes, {
                                        timeOutgoing: value
                                    });
                                    this.setState({ changes });
                                }
                            }}
                        >
                            {this.state.collection.timeOutgoing}
                        </TimeField>
                        :
                        <div className="display-only split custom-c-dark">
                            <label>Time</label>
                            <p>{this.state.collection.timeOutgoing}</p>
                        </div>
                    }
                    {isCourier
                        ?
                        !displayOnly
                            ?
                            <DateField
                                varname="deliveryDate"
                                label="Delivery date"
                                change={(varname, value, error) => {
                                    let collection = Object.assign({}, this.state.collection, {
                                        deliveryDate: value
                                    });
                                    this.setState({ collection });
                                    //Handle changes for editing a collection.
                                    if (this.state.collection.id) {
                                        let changes = Object.assign({}, this.state.changes, {
                                            deliveryDate: value
                                        });
                                        this.setState({ changes })
                                    }
                                }}
                            >
                                {this.state.collection.deliveryDate}
                            </DateField>
                            :
                            <div className="display-only split first custom-c-dark">
                                <label>Delivery date</label>
                                <p>{renderDate(this.state.collection.deliveryDate)}</p>
                            </div>
                        :
                        null
                    }
                    {!displayOnly
                        ?
                        <TextareaField
                            varname="notes"
                            label="Collection notes"
                            change={(varname, value, error) => {
                                let collection = Object.assign({}, this.state.collection);
                                collection.notes = value;
                                let state = { collection };
                                // Handle changes for editing a collection.
                                if (this.state.collection.id) {
                                    state.changes = Object.assign({}, this.state.changes, {
                                        notes: value
                                    });
                                }
                                this.setState(state);
                            }}
                        >
                            {this.state.collection.notes || ""}
                        </TextareaField>
                        :
                        <div className="display-only custom-c-dark">
                            <label>Collection notes</label>
                            <p>{this.state.collection.notes || "none"}</p>
                        </div>
                    }
                    {this.state.collection.images && this.state.collection.images.length
                        ?
                        <FileField
                            label="Images"
                            readOnly={displayOnly ? true : false}
                            varname="images"
                            change={(varname, value, error) => {
                                let images = value.map(v => v.url);
								let collection = Object.assign({}, this.state.collection, { images });
                                let changes = Object.assign({}, this.state.changes, { images });
                                this.setState({ collection, changes });
                            }}
                        >
                            {this.state.collection.images
                                .map(f => {
                                    return { name: f, url: f, type: "image" }
                                })
                            }
                        </FileField>
                        :
                        null
                    }
                    {this.state.collection.id && !displayOnly
                        ?
                        <BooleanField
                            varname="barcodesRequired"
                            label="Set all barcodes required?"
                            change={(varname, value, error) => {
                                let stock = this.state.collection.stock.map(stockItem => ({
                                    ...stockItem,
                                    barcodeRequired: value
                                }));
                                this.setState({
                                    changes: {
                                        ...this.state.changes,
                                        stock
                                    },
                                    collection: {
                                        ...this.state.collection,
                                        stock
                                    }
                                });
                            }}
                        >
                            {!Boolean(this.state.collection.stock.filter(s => !s.barcodeRequired).length)}
                        </BooleanField>
                        :
                        null
                    }
                    {/* Hide stock list if no customer selected */}
                    {this.state.collection.customer
                        ?
                        <div className="collection-stock">
                            <p className="custom-c-light">Stock Items</p>
                            <CollectionStockList
                                filter={item => {
                                    return !allStockIds.includes(item.id);
                                }}
                                columns={(() => {
                                    let columns = [
                                        {
                                            name: "Prod. Code",
                                            renderer: item => item.productCode,
                                            sorter: item => item.productCode
                                        },
                                        {
                                            name: "Group",
                                            renderer: item => item.group,
                                            sorter: item => item.group
                                        },
                                        // {
                                        //     name: "Description",
                                        //     renderer: item => item.description,
                                        //     sorter: item => item.description
                                        // },
                                        {
                                            name: "Location",
                                            renderer: item => item.location || "-",
                                            sorter: item => item.location || "-"
                                        },
                                        {
                                            name: "Order pos",
                                            renderer: item => {
                                                let result = /order position:\s*([\d]+)\s*/gi.exec(item.description);
                                                return result ? result[1] : "-"
                                            },
                                            sorter: item => {
                                                let result = /order position:\s*([\d]+)\s*/gi.exec(item.description);
                                                return result ? Number(result[1]) : "-"
                                            }
                                        },
                                        {
                                            name: "Quantity",
                                            renderer: item => item.quantity || "-",
                                            sorter: item => item.quantity || "-"
                                        },
                                        {
                                            name: "Barcoded?",
                                            renderer: item => item.code
                                                ?
                                                <BooleanField
                                                    varname="barcodesRequired"
                                                    change={(varname, value, error) => {
                                                        let stock = this.state.collection.stock.map(stockItem => {
                                                            if (stockItem.id === item.id) {
                                                                return {
                                                                    ...stockItem,
                                                                    barcodeRequired: value
                                                                }
                                                            } else {
                                                                return stockItem;
                                                            }
                                                        });
                                                        this.setState({
                                                            changes: {
                                                                ...this.state.changes,
                                                                stock
                                                            },
                                                            collection: {
                                                                ...this.state.collection,
                                                                stock
                                                            }
                                                        });
                                                    }}
                                                >
                                                    {Boolean(this.state.collection.stock.find(s => s.id === item.id).barcodeRequired)}
                                                </BooleanField>
                                                :
                                                <i>No code.</i>
                                            ,
                                            sorter: item => item.description
                                        },
                                    ]
                                    if (this.props.user.role !== 4 || this.state.collection.status === "collected") {
                                        columns.push({
                                            name: "Status",
                                            renderer: item => item.status,
                                            sorter: item => item.status
                                        })
                                    }
                                    return columns;
                                })()}
                                customer={this.state.collection.customer}
                                user={this.props.user}
                                displayOnly={displayOnly}
                                items={this.state.collection.stock
                                    .map(collectionStock => {
                                        let item = this.state.stock.filter(stateStock => {
                                            return stateStock.id === collectionStock.id;
                                        })[0];
                                        if (!item) return null;
                                        item.files = collectionStock.files || [];
                                        item.status = collectionStock.status || "-";
                                        return item;
                                    })
                                    .filter(s => s)
                                }
                                onNew={data => {
                                    // Getting current stock, workOrder stock and changes Objects
                                    let stock = [...this.state.stock];
                                    let collection = Object.assign({}, this.state.collection, {
                                        stock: [...this.state.collection.stock]
                                    })
                                    let changes = Object.assign({}, this.state.changes);
                                    if (data.constructor === Object) {
                                        // For single objects passed.
                                        let item = data;
                                        // Checking if already added.
                                        if (!collection.stock.filter(s => s.id === item.id).length) {
                                            collection.stock.push(item.splitRef ?
                                                {
                                                    id: item.id,
                                                    splitRef: item.splitRef,
                                                    barcodeRequired: item.code ? true : false,
                                                } : {
                                                    id: item.id,
                                                    barcodeRequired: item.code ? true : false,
                                                }
                                            );
                                            stock.push(item);
                                            this.setState({ collection, stock });
                                            // Handling changes to existing collection
                                            if (this.state.collection.id) {
                                                changes.stock = collection.stock;
                                                this.setState({ changes })
                                            }
                                        } else {
                                            logger.error("Error while adding stock - item already exists")
                                        }
                                    } else if (data.constructor === Array) {
                                        // Array of objects passed.
                                        data.forEach(item => {
                                            if (!collection.stock.filter(s => s.id === item.id).length) {
                                                collection.stock.push({
                                                    id: item.id
                                                })
                                                stock.push(item);
                                                // Handling changes to existing collection
                                                if (this.state.collection.id) {
                                                    changes.stock = collection.stock;
                                                }
                                            } else {
                                                logger.error("Error while adding stock - item already exists")
                                            }
                                            this.setState({ collection, stock, changes })
                                        })
                                    }

                                }}
                                onDelete={id => {

                                    let stockChanges = {
                                        collection: Object.assign({}, this.state.collection, {
                                            stock: this.state.collection.stock
                                                .filter(s => s.id !== id)
                                        }),
                                        stock: this.state.stock.filter(s => s.id !== id)
                                    };

                                    // handling changes to existing collections.
                                    if (this.state.collection.id) {
                                        stockChanges = Object.assign(stockChanges, {
                                            changes: Object.assign({}, this.state.changes, {
                                                stock: stockChanges.collection.stock
                                            })
                                        })
                                    }

                                    // Handling merging stock back.
                                    let currentStock = this.state.collection.stock.find(i => i.id === id);
                                    let fullStock = this.state.stock.find(i => i.id === id)

                                    if (
                                        currentStock &&
                                        currentStock.splitRef &&
                                        window.confirm("Re merge with original split stock?")
                                    ) {
                                        // If there is stock to merge..
                                        this.merge(currentStock.id, currentStock.splitRef, () => {
                                            if (this.state.collection.id) {
                                                this.props.onUpdate({
                                                    stock: stockChanges.collection.stock
                                                }, this.state.collection.id);
                                            }
                                        });
                                    } else if (
                                        fullStock &&
                                        fullStock.lockedBy
                                    ) {
                                        this.unlockStock(fullStock.id)
                                    }
                                    // Update state.
                                    this.setState(stockChanges);

                                }}
                            />
                        </div>
                        :
                        null
                    }
                    {!displayOnly && this.props.collection && !isCourier && this.props.collection.status !== "pending"
                        ?
                        <React.Fragment>
                            <div
                                className="btn custom-bg-feature1 printPod"
                                onClick={() => {
                                    this.setState({ showPod: true });
                                }}
                            >
                                Generate POD
                            </div>
                            {this.state.showPod
                                ?
                                <Pod
                                    user={this.props.user}
                                    collection={this.props.collection}
                                    stock={this.props.collection.stock
                                        // Map over the stock in the work order.
                                        .map(cStock => {
                                            // Find the matching full stock obj in the state.
                                            let matchingStock = this.state.stock
                                                .filter(s => s.id === cStock.id)[0];
                                            // If it found them..
                                            if (matchingStock) {
                                                // Merge it and return.
                                                return Object.assign({}, matchingStock, cStock);
                                            } else {
                                                // Otherwise, null for filtering out.
                                                return null
                                            }
                                        })
                                        .filter(s => s)
                                    }
                                    onExit={e => {
                                        this.setState({ showPod: false })
                                    }}
                                />
                                :
                                null
                            }
                        </React.Fragment>
                        :
                        null
                    }
                    {!displayOnly && this.props.collection && this.props.asCollected && isCourier && ["confirmed", "prepared"].includes(this.props.collection.status)
                        ?
                        <div>
                            <div
                                className="btn custom-bg-feature1 asCollected"
                                onClick={() => {
                                    if (window.confirm("Are you sure you want to change the status to collected?"))
                                        this.props.asCollected()
                                }}
                            >
                                Set as Collected
                            </div>
                        </div>
                        :
                        null
                    }
                    <FlipMove>
                        {Object.keys(this.state.changes).length
                            ?
                            <div className="save-changes">
                                {"Changes were made, make sure you save them!"}
                            </div>
                            :
                            null
                        }
                    </FlipMove>
                    {!displayOnly
                        ?
                        <div
                            className="create-collection-button button custom-bg-feature1"
                            onClick={() => {
                                if (this.state.collection.id) {
                                    //If changes were made
                                    if (Object.keys(this.state.changes).length) {
                                        // Set blank fields to null.
                                        let changes = Object.assign({}, this.state.changes);
                                        Object.keys(changes).forEach(key => {
                                            if (changes[key] === "")
                                                changes[key] = null;
                                        });
                                        // Save them.
                                        this.props.onUpdate(changes, this.state.collection.id);
                                        this.setState({ changes: {} })
                                    }
                                } else {
                                    //Create a new one
                                    if (this.state.collection.dateOutgoing && this.state.collection.timeOutgoing) {
                                        let newCollection = Object.assign({}, this.state.collection);
                                        delete newCollection.status;
                                        if (this.props.user.customer !== "internal") {
                                            delete newCollection.customer
                                        }
                                        Object.keys(newCollection).forEach(key => {
                                            if (!newCollection[key])
                                                delete newCollection[key];
                                        });
                                        this.props.onSubmit(newCollection);
                                        this.setState({ changes: {} });
                                    }
                                }
                            }}
                        >
                            {this.state.collection.id ? "Save Changes" : "Create Collection"}
                        </div>
                        :
                        null
                    }
                    {(!displayOnly && isCourier)
                        ?
                        <div
                            style={{
                                width: "100%",
                                marginTop: "15px",
                                textAlign: "center"
                            }}
                            className="button custom-bg-feature1"
                            onClick={() => {
                                this.setState({ fileView: true })
                            }}
                        >
                            Create from file
                        </div>
                        :
                        null
                    }
                </div>
            </div >
        )
    }

}

CollectionCreator.contextType = Context;

export default CollectionCreator;