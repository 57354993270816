import SuperList from "../../assets/ui-super-list";
import LocationCreator from "./locationCreator";

class LocationsList extends SuperList {

    renderControls(item) {
        let controls = this.props.controls || []
        controls.push({
            icon: this.props.user.data.theme === "dark" ? "pen-white.svg " : "pen.svg",
            label: "edit",
            callback: () => {
                this.setState({
                    editing: item,
                    addingItem: false
                })
            },
        })
        if (this.props.user.role <= 1) {
            controls.push({
                icon: this.props.user.data.theme === "dark" ? "delete-bright.svg" : "delete.svg",
                label: "delete",
                callback: this.props.onDelete ? (item) => {
                    if (window.confirm("Are you sure you want to delete this location?")) {
                        this.props.onDelete(item.locator)
                    }
                } : null
            })
        }
        return controls
            .filter(c => c.callback ? true : false)
            .map(control => {
                return <div
                    title={control.label}
                    key={control.label}
                    className="control"
                    style={{ backgroundImage: `url("/static/media/${control.icon}")` }}
                    onClick={e => { control.callback(item) }}
                ></div>
            })
    }

    renderItemCreator() {
        return (
            <LocationCreator
                apiRoot={this.props.apiRoot}
                user={this.props.user}
                item={this.state.editing}
                itemCreated={item => {
                    this.props.onNew(item)
                    this.setState({ addingItem: false })
                }}
                itemUpdated={(item, locator) => {
                    this.props.onUpdate(item, locator)
                    this.setState({ editing: null })
                }}
                closeCreator={() => {
                    if (this.state.editing) {
                        //if editing an item
                        this.setState({ editing: null })
                    } else {
                        //or creating new item
                        this.setState({ addingItem: false })
                    }
                }}
            />
        )
    }
}

LocationsList.defaultProps = {
    uniqueField: "locator"
}

export default LocationsList;