import Field from "../field";
import Papa from "papaparse";

const SETSIZE = 10;

class CSVField extends Field {
    constructor(props) {
        super(props);
        this.settings = {
            type: "csv"
        }
        this.state = {
            rows: [],
            rowObjects: [],
            offset: 0,
            setSize: SETSIZE,
            columns: this.props.columns || []
        }
    }
    renderRows(rows) {
        return rows.map(row => {
            // console.log("row: ", row)
            let key = Math.random();
            // console.log(row)
            return Object.keys(row).length ?
                <div className="data-row custom-bg-dark custom-border-color" key={key}>
                    {row.map((col, index) => {
                        let data = (col || "").trim();
                        return <div
                            title={data}
                            key={index + "_" + this.state.offset}
                            className="data-col custom-border-color"
                            style={{
                                width: (100 / row.length) + "%",
                                display: "inline-block"
                            }}
                        >
                            {data}
                        </div>
                    })}
                </div>
                :
                null
        })
    }
    read(file) {
        return new Promise((resolve, reject) => {
            let ext = /(\.[^\.]+)$/.exec(file.name);
            if (!ext || (ext[1].toLowerCase() !== ".csv"))
                return reject("Not a CSV file.");

            let fr = new FileReader();
            fr.onload = readEvent => {
                resolve(readEvent.target.result);
            }
            fr.readAsText(file);
        })
    }
    parse(data) {
        let rows = Papa.parse(data).data
        let baseLength = rows[0].length;
        return rows.filter(r => r.length === baseLength);
    }
    renderHeaders(count) {
        let headers = [];
        let columnChoices = [...new Set((this.props.columnChoices || []).concat(this.state.template.columns))];
        for (let i = 0; i < count; i++) {
            headers.push(
                <div
                    className="headerDropdown custom-bg-light" key={i}
                    style={{
                        width: (100 / count) + "%",
                        display: "inline-block"
                    }}
                >
                    <select
                        value={this.state.columns[i] || "ignore"}
                        onChange={e => {
                            let columns = [...this.state.columns];
                            columns[i] = e.target.value;
                            this.setState({ columns })
                        }}
                    >
                        {columnChoices.map(choice => {
                            return <option key={choice} value={choice}>
                                {choice}
                            </option>
                        })}
                    </select>
                </div>
            )
        }
        return headers;
    }
    convertToObjects(rows) {
        // Create a guide for the columns and which indexes 
        // they map to.
        let columnMap = {}
        this.state.columns.forEach((col, index) => {
            columnMap[col] = columnMap[col] || [];
            columnMap[col].push(index);
        });
        let labels = this.state.template.columnLabels || [];
        // console.log("Col map: ", this.state.columns)
        // Map through rows.
        return rows.map(row => {
            // Create an object for each row;
            let rowObj = {}
            // Loop through the desired columns
            this.state.columns.forEach(col => {
                // Skip if 'ignore'.
                if (col === "ignore") return;
                // console.log("Column: ", col)
                // Get the values that the column will use.
                rowObj[col] = (columnMap[col] || [])
                    .map((index, i, array) => {
                        if (!row[index]) return null;
                        // If there are multiple values, check for a label.
                        if (array.length > 1) {
                            return `${labels[index] ? (labels[index] + ": ") : ""} ${row[index].trim()}`
                        } else {
                            return row[index].trim();
                        }
                    })
                    .join("\n");
            });
            // Run the preprocessor if there is one.
            return this.props.preProcess ?
                (this.props.preProcess.bind(this)(rowObj) || rowObj) : rowObj;
        });
    }
    renderStaticHeaders(headers) {
        return headers.map((col, i) => {
            return <div
                className="column-header custom-bg-light custom-border-color-3"
                title={col}
                key={i}
                style={{
                    width: (100 / headers.length) + "%",
                    display: "inline-block"
                }}
            >
                {col}
            </div>
        })
    }
    renderField() {
        let rowSet = this.state.rows.slice(
            this.state.offset,
            this.state.offset + this.state.setSize
        );
        let rowObjects = this.convertToObjects(rowSet);
        let colsWithContent = [...new Set(this.state.columns)]
            .filter(c => c !== "ignore");
        return (
            <div className="field-wrapper">
                <label className="fileLabel button custom-bg-feature1">
                    {this.state.rows.length ? "Change" : "Select"} CSV file.
                    <input
                        type="file"
                        onChange={e => {
                            e.preventDefault();
                            this.read(e.target.files[0]).then(data => {
                                let rows = this.parse(data);
                                this.setState({
                                    rows,
                                    rowObjects: this.convertToObjects(rows),
                                    columns: rows[0].map((r, index) => {
                                        return this.state.columns[index] || "ignore";
                                    }),
                                    offset: 0,
                                    setSize: SETSIZE
                                });
                            }).catch(err => {
                                console.log("Error reading file: ", err);
                                logger.error(err);
                                this.setState({
                                    rows: []
                                });
                            });
                        }}
                        ref="fileInput"
                        multiple={false}
                        style={{ display: "none" }}
                    />
                </label>
                {/* Static headers */}
                {this.state.template.columnLabels && this.state.rows.length ? <div className="column-headers">
                    <br />
                    {this.renderStaticHeaders(this.state.template.columnLabels)}
                </div> : null}

                {/* Dropdown headers */}
                {this.state.rows.length ? <div className="column-headers">
                    {this.renderHeaders(this.state.rows[0].length)}
                </div> : null}
                {this.state.rows.length ? <div
                    className="data-rows"
                    onWheel={e => {
                        e.preventDefault();
                        // Create the offset based on scroll direction.
                        let offset = this.state.offset + (e.deltaY > 0 ?
                            this.state.setSize : this.state.setSize * -1
                        );
                        // Wrap value.
                        if (offset > this.state.rows.length - this.state.setSize) {
                            offset = this.state.rows.length - this.state.setSize;
                        } else if (offset < 0) {
                            offset = 0;
                        }
                        // Set state.
                        this.setState({
                            offset
                        })
                    }}
                >
                    {this.renderRows(rowSet)}
                </div> : null}

                {/* Counter */}
                {this.state.rows.length ? <div className="counter">
                    {this.state.offset} / {this.state.rows.length}
                </div> : null}

                {/* Headers */}
                {this.state.rows.length ? <div className="column-headers">
                    {this.renderStaticHeaders(Object.keys(rowObjects[0]))}
                </div> : null}

                {/* Converted rows */}
                {this.state.rows.length ? <div className="converted data-rows">
                    {this.renderRows(rowObjects.map(obj => {
                        return Object.keys(obj).map(key => obj[key])
                    }))}
                    <br />
                </div> : null}
                {rowObjects.length ? <div
                    className="confirm button custom-bg-feature1"
                    onClick={e => {
                        if (this.props.onConfirm) {
                            let cb = (shouldClear) => {
                                if (shouldClear) this.setState({
                                    rows: [],
                                    offset: 0,
                                });
                            }
                            let data = this.convertToObjects(this.state.rows);
                            if (this.props.postProcess) {
                                this.props.onConfirm(data.map(this.props.postProcess), cb)
                            } else {
                                this.props.onConfirm(data, cb);
                            }
                        }
                    }}
                >
                    Confirm
                </div> : null}
            </div>
        )
    }
}

CSVField.defaultProps = {
    columnChoices: [
        "ignore",
        "id"
    ]
}

export default CSVField;