import StockList from "../stock-view/stockList";
import StockCreator from "../stock-view/stockCreator";   

class DeliveryStockList extends StockList {
    renderItemCreator() {
        return (
            <StockCreator
                hideInOut={true}
                user={this.props.user}
                apiRoot={this.props.apiRoot}
                router={this.props.router}
                allCreatorFields={this.props.allCreatorFields}
                uniqueField={this.props.uniqueField}
                item={this.state.editing}
                itemCreated={item => {
                    if (this.props.onNew) {
                        this.props.onNew(item)
                        this.setState({ addingItem: false })
                    }
                }}
                itemUpdated={(item, id) => {
                    if (this.props.onUpdate) {
                        this.props.onUpdate(item, id);
                        this.setState({ editing: null })
                    }
                }}
                closeCreator={() => {
                    if (this.state.editing) {
                        //if editing an item
                        this.setState({ editing: null })
                    } else {
                        //or creating new item
                        this.setState({ addingItem: false })
                    }
                }}
            />
        )
    }
}

DeliveryStockList.defaultProps = Object.assign({}, StockList.defaultProps, {
    uniqueField: "id",
    columns: [
        {
            name: "Code",
            renderer: item => item.code,
            sorter: item => item.code
        },
        {
            name: "Description",
            renderer: item => item.description,
            sorter: item => item.description
        },
        {
            name: "Group",
            renderer: item => item.group,
            sorter: item => item.group
        },
        {
            name: "Location",
            renderer: item => item.location,
            sorter: item => item.location
        }
    ]
})

export default DeliveryStockList;

