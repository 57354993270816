import ItemCreator from "../../assets/ui-super-list/itemCreator";
import RestrictedTextField from "../../fields/restrictedTextField";
import SelectField from "../../fields/selectField";
import TextareaField from "../../fields/textareaField";
import FlipMove from 'react-flip-move';
import Barcode from '../../assets/barcode';
import DataField from "../../fields/dataField";
import ItemLinker from "../../assets/itemLinker";
import LocationSearchField from "../../fields/locationSearchField";

class LocationCreator extends ItemCreator {

    constructor(props) { 
        super(props);
        this.state.showMap = false;
        this.state.item = {
            customer: props.item ? props.item.customer : props.user.customer,
            type: props.user.role <= 1 ? "warehouse" : "external"
        }
    }

    canSubmit() {
        if (this.props.item) {
            //Updating existing one
            let canSubmit = false;
            Object.keys(this.state.item).forEach(key => {
                if (this.props.item[key] !== this.state.item[key]) {
                    //if change was made
                    canSubmit = true
                }
            })
            if (canSubmit) return true
        } else {
            //Creating a new location
            if (this.state.item.locator && this.state.item.locator.length >= 5 && this.state.item.type) {
                return true
            }
        }
        //Return false by default
        return false
    }

    renderCreator() {
        // console.log("state: ", this.state);
        // console.log("props: ", this.props);
        let postcode;
        if (this.props.item && this.props.item.address) {
            postcode = /([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]?\ {0,1}[0-9][A-Za-z]{2}|[Gg][Ii][Rr] 0[Aa]{2})/.exec(this.props.item.address)
            postcode = postcode ? postcode[1] : null;
        }

        return (
            <div className="creator">
                {!this.props.item ?
                    <React.Fragment>
                        <br />
                        <LocationSearchField
                            label="Inherit from existing location?"
                            varname="locator"
                            searchDefaults={[]}
                            change={(varname, loc, error) => {
                                let changes = {
                                    address: loc.address,
                                    description: loc.description,
                                    customer: loc.customer,
                                    type: loc.type
                                }
                                this.setState({
                                    item: Object.assign({}, this.state.item, changes),
                                    changes
                                })
                            }}
                        >
                            {this.state.itemSelected ? this.state.itemSelected.locator : ""}
                        </LocationSearchField>
                        <hr />
                    </React.Fragment>
                    : null
                }
                {!this.props.item
                    //If creating a new location
                    ?
                    <FlipMove className="field-combiner">
                        <SelectField
                            key="type"
                            varname="type"
                            name="Type"
                            label="Location Type"
                            change={this.itemChanged.bind(this)}
                            options={this.props.user.role <= 1
                                ?
                                [
                                    {
                                        val: "warehouse",
                                        display: "Warehouse"
                                    },
                                    {
                                        val: "vehicle",
                                        display: "Vehicle"
                                    },
                                    {
                                        val: "holding",
                                        display: "Holding Area"
                                    },
                                    {
                                        val: "external",
                                        display: "External"
                                    },
                                    {
                                        val: "remedial",
                                        display: "Remedial"
                                    },
                                    {
                                        val: "other",
                                        display: "Other"
                                    }
                                ]
                                :
                                [
                                    {
                                        val: "external",
                                        display: "External"
                                    }
                                ]
                            }
                        >
                            {this.state.item.type || ""}
                        </SelectField>
                        {this.state.item.type
                            ?
                            //If item.type was selected render the locator field
                            <RestrictedTextField
                                varname="locator"
                                label="Locator"
                                change={this.itemChanged.bind(this)}
                                regex={/^[\w\d\-]{0,46}$/}
                                prefix={(() => {
                                    let type = this.state.item.type
                                    if (type === "warehouse") {
                                        return "WAR-"
                                    } else if (type === "vehicle") {
                                        return "VEH-"
                                    } else if (type === "holding") {
                                        return "HOL-"
                                    } else if (type === "other") {
                                        return "OTH-"
                                    } else if (type === "external") {
                                        return "EXT-"
                                    } else if (type === "remedial") {
                                        return "REM-"
                                    }
                                })()}
                            >
                                {this.state.item.locator || ""}
                            </RestrictedTextField>
                            :
                            null
                        }
                    </FlipMove>
                    :
                    //Editing an existing one
                    <div className="field-combiner">
                        <div className="field placeholder">
                            <label className="custom-c-dark">Type</label>
                            <div className="field-value custom-c-feature2">
                                {this.props.item.type}
                            </div>
                        </div>
                        <div className="field placeholder">
                            <label className="custom-c-dark">Locator</label>
                            <div className="field-value custom-c-feature2">
                                {this.props.item.locator}
                            </div>
                        </div>
                    </div>
                }
                <DataField
                    varname="customer"
                    defaults={(() => {
                        if (this.props.user.role <= 3) return [
                            {
                                name: "internal",
                                displayName: "Internal User"
                            }
                        ]
                        return [
                            {
                                name: this.props.user.customer,
                                displayName: this.props.user.customer
                            }
                        ]
                    })()}
                    optionRenderer={customer => (
                        <div className="option-inner">
                            <b>{customer.displayName}</b> <br />({customer.name})
                        </div>
                    )}
                    label="Customer"
                    route="customers"
                    dataName="name"
                    searchBy="term"
                    change={(varname, customer, err) => {
                        if (customer && customer.defaultAddress) {
                            this.setState({
                                item: Object.assign({}, this.state.item, {
                                    customer: customer.name,
                                    address: customer.defaultAddress
                                }),
                                changes: Object.assign({}, this.state.changes, {
                                    customer: customer.name,
                                    address: customer.defaultAddress
                                })
                            })
                        } else {
                            this.itemChanged(varname, customer ? customer.name : "", err);
                        }
                    }}
                >
                    {this.state.item.customer || ""}
                </DataField>
                {!this.state.showMap
                    ?
                    <TextareaField
                        varname="address"
                        label={(
                            <React.Fragment>
                                <span>Address</span>
                                {/* {Only show the button if location has the address} */}
                                {postcode
                                    ?
                                    <span
                                        className="toggle-map custom-c-feature1"
                                        onClick={() => {
                                            this.setState({
                                                showMap: true
                                            })
                                        }}
                                    >
                                        Show map
                                    </span>
                                    :
                                    null
                                }
                            </React.Fragment>
                        )}
                        change={(vn, val) => {
                            this.itemChanged(vn, val || null)
                        }}
                    >
                        {this.state.item.address || ""}
                    </TextareaField>
                    :
                    <div className="map-section">
                        <label className="custom-c-dark">
                            <span>Map</span>
                            <span
                                className="toggle-map custom-c-feature1"
                                onClick={() => {
                                    this.setState({
                                        showMap: false
                                    })
                                }}
                            >
                                Show address
                            </span>
                        </label>
                        <div className="iframe-wrapper custom-border-color">
                            <iframe
                                src={`https://www.google.com/maps/embed/v1/place?key=${window.GMAPS_KEY}&q=${postcode}`}
                                frameBorder="0"
                                allowFullScreen
                            />
                        </div>
                    </div>
                }

                <TextareaField
                    varname="description"
                    label="Description"
                    change={(vn, val) => {
                        this.itemChanged(vn, val || null)
                    }}
                >
                    {this.state.item.description || ""}
                </TextareaField>
                {this.props.item
                    ?
                    <div className="linker-wrapper">
                        <label className="custom-c-dark">Link items</label>
                        <ItemLinker
                            apiRoot={this.props.apiRoot}
                            router={this.props.router}
                            itemType="location"
                            itemId={this.props.item.locator}
                        />
                    </div>
                    :
                    null
                }
                {this.props.item && this.props.item.locator ?
                    <Barcode>{this.props.item.locator}</Barcode>
                    : null
                }
            </div>
        )
    }
}

LocationCreator.defaultProps = {
    uniqueField: "locator"
}

export default LocationCreator;