const stockTemplate = {
    name: "Delivery Group - Stock",
    columns: [
        "supplier",
        "ignore",
        "description",
        "description",
        "customer",
        "productCode",
        "ignore"
    ],
    columnLabels: [
        "Supplier",
        "Ignore",
        "Customer reference",
        "Sales Order",
        "Customer",
        "Product code",
        "quantity"
    ],
    postProcess: (rows, context) => {
        let result = []
        rows.forEach(item => {
            let newItem = Object.assign({}, item);

            if (newItem.customer) {
                newItem.description += `\nCustomer_:  ${newItem.customer}`;
                newItem.customer = context.mapValue(newItem.customer) || null;
            }

            // Otherwise, add it on.
            result.push(newItem);
        });
        return result;
    }
}

export default stockTemplate;