import Spinner from "../../assets/spinner";
import { renderDate } from "../../helpers";
import FileField from "../../fields/fileField";

class RemovalInventory extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ready: false,
			removal: null,
		}
	}

	componentDidMount() {
		// Get removal id
		const removalId = this.props.router.match.params.removalId;
		// If got removal id, get it
		if (removalId) {
			// If got removal id, get it
			this.getRemoval(removalId)
		}
	}

	componentDidUpdate(prevProps) {
		const prevRemovalId = prevProps.router.match.params.removalId;
		const removalId = this.props.router.match.params.removalId;
		if (removalId && prevRemovalId !== removalId) {
			this.getRemoval(removalId)
		}
	}


	getRemoval(id) {
		$.get(`${this.props.apiRoot}/public/removal/${id}/inventory`)
			.done(removal => {
				console.log("removal: ", removal);
				this.setState({
					ready: true,
					removal
				})
			})
			.catch(err => {
				logger.error(err);
			})
	}


	render() {

		return (
			<div className="row view removalInventory">
				<div className="col-12">
					<img src="/static/media/POD_header_small.png" style={{ width: "100%" }} />
					<div style={{
						padding: "15px 35px",
						backgroundColor: "white"
					}}>
						{(this.state.ready && this.state.removal)
							?
							<div>
								{this.state.removal.customer && this.state.removal.customer.defaultAddress && <div
									className="field"
									style={{
										marginBottom: "16px"
									}}
								>
									<label>Collection/Delivery address</label>
									<div style={{ fontWeight: "bold" }}>{this.state.removal.customer.defaultAddress}</div>
								</div>}
								<div className="field" style={{
									marginBottom: "16px"
								}}>
									<label>Collection date:</label>
									<div style={{ fontWeight: "bold" }}>{renderDate(this.state.removal.dateIncoming)}</div>
								</div>
								<div className="field" style={{
									marginBottom: "16px"
								}}>
									<label>Items:</label>
									{this.state.removal.stock.map((stock, index) => (
										<div style={{ fontWeight: "bold" }} key={index}>
											{`${index + 1}. ${stock.productCode}${stock.description ? ` - ${stock.description}` : ""}`}
											{Boolean(stock.images && stock.images.length) && <div style={{
												marginTop: "4px"
											}}>
												<FileField
													readOnly={true}
												>
													{stock.images.map(f => ({
														name: f, url: f, type: "image"
													}))}
												</FileField>
											</div>}
										</div>
									))}
								</div>
								{this.state.removal.signedBy && <div className="field" style={{
									marginBottom: "16px"
								}}>
									<label>Signed by</label>
									<div style={{ fontWeight: "bold" }}>{this.state.removal.signedBy}</div>
									<div className="signature" style={{
										marginTop: "8px",
										display: "inline-block"
									}}>
										<img
											style={{
												width: "100%",
												maxWidth: "300px"
											}}
											src={this.state.removal.signature}
										/>
									</div>
								</div>}
							</div>
							:
							<Spinner size={90} color="#1e43a9" />
						}
					</div>
				</div>
			</div>
		)
	}
}

export default RemovalInventory;