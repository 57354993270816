// import Stage from "./stage";
import SelectorStage from "./selectorStage";

class SelectStock extends SelectorStage {
    constructor(props) {
        super(props);
        this.state.stock = [];
    }

    getItem(code) {
        return new Promise((resolve, reject) => {
            $.get(`${this.context.apiRoot}/stock?code=${code}`).then(response => {
                if (!response.total)
                    return reject("No item found.");
                if (response.results.length > 1)
                    return reject("Multiple items matching code.");
                // if (response.results[0].quantity < 2) return reject("Multiple items matching code.");
                resolve(response.results[0]);
            }).catch(err => {
                reject(err.responseJSON.msg);
            });
        });
    }

}

SelectStock.defaultProps = {
    title: "Select stock.",
    icon: "stock"
}

export default SelectStock;