import ItemCreator from "../../assets/ui-super-list/itemCreator";
import TextField from "../../fields/textField";
import SelectField from "../../fields/selectField";
import DataField from "../../fields/dataField";

class ReportCreator extends ItemCreator {

    canSubmit() {
        if (this.props.user.role <= 2) {
            if (this.state.item.title && this.state.item.type && this.state.item.customer) {
                return true
            }
        } else {
            if (this.state.item.title && this.state.item.type) {
                return true
            }
        }
        return false
    }

    renderCreator() {
        // console.log("PROPS", this.props);
        // console.log("STATE", this.state);
        return (
            <div className="creator">
                <div className="field-combiner">
                    <TextField
                        varname="title"
                        label="Report Title"
                        change={this.itemChanged.bind(this)}
                    >
                        {this.state.item.title || ""}
                    </TextField>
                    {this.props.user.role <= 2
                    ?
                        <DataField
                            varname="customer"
                            defaults={[
                                {
                                    name: "internal",
                                    displayName: "Internal User"
                                }
                            ]}
                            optionRenderer={customer => (
                                <div className="option-inner">
                                    <b>{customer.displayName}</b> <br/>({customer.name}) 
                                </div>
                                )
                            }
                            label="Customer"
                            route="customers"
                            dataName="name"
                            searchBy="term"
                            change={(varname, value, err) => {
                                let item = Object.assign({}, this.state.item);
                                item.customer = value.name;
                                this.setState({ item });    
                            }}
                        >
                            {this.state.item.customer || ""}
                        </DataField>
                    :
                        null
                    }                
                </div>
                <div className="field-combiner">
                    <SelectField
                        label="Report Type"
                        varname="type"
                        name="Report Type"
                        options={[
                            {
                                val: "general",
                                display: "General"
                            },
                            {
                                val: "damage",
                                display: "Damage"
                            },
                            {
                                val: "complaint",
                                display: "Complaint"
                            }
                        ]}
                        change={this.itemChanged.bind(this)}
                    >
                        {this.state.item.type || ""}
                    </SelectField>
                    <SelectField
                        label="Report Status"
                        varname="status"
                        name="Report Status"
                        options={[
                            {
                                val: "urgent",
                                display: "Urgent"
                            },
                            {
                                val: "resolved",
                                display: "Resolved"
                            },
                            {
                                val: "unresolved",
                                display: "Unresolved"
                            }
                        ]}
                        change={this.itemChanged.bind(this)}
                    >
                        {this.state.item.status || ""}
                    </SelectField>
                </div>
            </div>
        )
    }

}

export default ReportCreator