import ItemCreator from "../../assets/ui-super-list/itemCreator";
import StockSearchField from "../../fields/stockSearchField";
import Logger from "../../assets/logger";

class WorkOrderStockCreator extends ItemCreator {
    constructor(props) {
        super(props)
        this.addingAll = false;
    }

    canSubmit() {
        if (this.state.item.id) {
            return true
        }
        return false
    }

    renderCreator() {
        //Removing sub customers.
        let customer = this.props.customer.split("-");
        customer = `${customer[0]}-${customer[1]}`;
        return (
            <div className="creator">
                <StockSearchField
                    queryDefaults={{
                        show: "in",
                        count: 250
                    }}
                    filter={item => {
                        if (this.props.filter && !this.props.filter(item))
                            return false;

                        if (!item.customer.includes(this.props.customer))
                            return false;

                        if (item.lockedBy)
                            return false;

                        return true;
                    }}
                    customer={customer}
                    label="Stock"
                    defaultSearch="location"
                    varname="id"
                    change={this.itemChanged.bind(this)}
                    submit={this.submit.bind(this)}
                >
                    {this.state.item.code || null}
                </StockSearchField>
            </div>
        )
    }

    render() {
        return (
            <div className="item-creator custom-border-color">
                {this.props.closeCreator ? <div
                    className="icon close-icon"
                    onClick={() => {
                        this.props.closeCreator()
                    }}
                /> : null}
                {this.renderCreator()}
                <div
                    className={"button create-item-button custom-bg-feature2" + (this.canSubmit() ? "" : " disabled")}
                    onClick={() => {
                        if (this.canSubmit()) {
                            //Sending the whole item or just the changes(creating/editing)
                            let item = this.props.item ? this.state.changes : this.state.item;
                            this.submit(item);
                        }
                    }}
                >
                    Save
                </div>
                {Boolean(this.props.user.role <= 2 || this.props.user.role === 4)
                    ?
                    <div
                        style={{ marginTop: "15px" }}
                        className={"button create-item-button custom-bg-feature2" + (this.addingAll ? " disabled" : "")}
                        onClick={() => {
                            if (!this.addingAll) {
                                if (window.confirm("Are you sure you want to add all of client's stock? This can cause issues if there is to many items.")) {
                                    this.addingAll = true;
                                    // Getting all customers stock items
                                    $.get(`${window.apiRoot}/stock/?customer=${this.props.customer}&show=in&offset=0&count=10000`)
                                        .done(data => {
                                            // console.log(data.results)
                                            this.submit({ id: data.results });
                                            this.addingAll = false;
                                        }).catch(err => {
                                            logger.error(err);
                                            this.addingAll = false;
                                        })
                                }
                            }
                        }}
                    >
                        Add all client's stock
                    </div>
                    :
                    null
                }
            </div>
        )
    }

}

export default WorkOrderStockCreator;