/**
 *  PROPS:
 *
 *      Apart from standard field props, numberField also takes:
 * 
 *      min: NUMBER (minimun value)
 *      max: NUMBER (maximum value) 
 */


import Field from "./field";

class NumberField extends Field {
    constructor(props) {
        super(props);
        this.settings = {
            type: "number"
        }
    }

    renderField() {
        return (
            <div className="field-wrapper">
                <input
                    className="custom-border-3"
                    type="text"
                    value={(() => {
                        if (this.props.max && (this.props.children > this.props.max)) {
                            return this.props.max;
                        } else if (this.props.min && (this.props.children < this.props.min)) {
                            return this.props.min;
                        } else {
                            return this.props.children;
                        }
                    })()}
                    onBlur={e => {
                        let val = e.target.value;
                        if (this.props.min && val < this.props.min) {
                            val = this.props.min;
                        }
                        this.change(Number(val));
                    }}
                    onChange={e => {
                        let val = e.target.value
                            .replace(/[^\d]/g, "")
                            .replace(/^0+/, "");
                        if (this.props.max && (val > this.props.max)) {
                            this.change(this.props.max);
                        } else if (this.props.min && (val < this.props.min)) {
                            this.change(this.props.min);
                        } else {
                            this.change(val);
                        }
                    }}
                />
                <div
                    className="deduct custom-bg-feature1"
                    onClick={() => {
                        let newValue = Number(this.props.children) - 1
                        if (this.props.min && newValue < this.props.min) {
                            this.change(this.props.min)
                        } else {
                            this.change(newValue)
                        }
                    }}
                >
                    -
                </div>
                <div
                    className="add custom-bg-feature1"
                    onClick={() => {
                        let newValue = Number(this.props.children) + 1
                        if (this.props.max && newValue > this.props.max) {
                            this.change(this.props.max)
                        } else {
                            this.change(newValue)
                        }
                    }}
                >
                    +
                    </div>
            </div>
        )
    }
}

export default NumberField;