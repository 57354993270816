import Field from "./field";

/**
 *  PROPS
 * 
 *      prefix: STRING (optional)
 *      suffix: STRING (optional)
 *      regex: RegEx (optional)
 */

class RestrictedTextField extends Field {
    constructor(props) {
        super(props);
        this.settings = {
            type: "restricted"
        }
    }

    renderField() {
        return (
            <div className="field-wrapper custom-border-color">
                {this.props.prefix
                    ?
                    <div 
                        className="prefix custom-bg-light custom-c-light custom-border-color"
                    >
                        {this.props.prefix}
                    </div>
                    :
                    null
                }
                <input
                    placeholder={this.props.placeholder || ""}
                    type="text"
                    style={(() => {
                        let width = "100%";
                        if (this.props.prefix && this.props.suffix) {
                            width = "40%";
                        } else if (this.props.prefix || this.props.suffix) {
                            width = "70%";
                        }
                        return { width }
                    })()}
                    value={((value) => {
                        if (this.props.prefix) {
                            //If prefix is passed remove it form the input value
                            let prefixReplacer = new RegExp(`^${this.props.prefix}`, "i");
                            value = value.replace(prefixReplacer, "")
                        }
                        if (this.props.suffix) {
                            //Same for suffix
                            let suffixReplacer = new RegExp(`${this.props.suffix}$`, "i");
                            value = value.replace(suffixReplacer, "");
                        }
                        return value;
                    })(this.props.children)}
                    onChange={e => {
                        //Use the regex passed as a prop or a default value
                        let regex = this.props.regex || /^[a-z\d\_]*$/;
                        let value = e.target.value;
                        if (regex.test(value)) {
                            //If input valu has correct characters
                            if (this.props.prefix && this.props.suffix) {
                                this.change(this.props.prefix + value + this.props.suffix);
                            } else if (this.props.prefix) {
                                this.change(this.props.prefix + value);
                            } else if (this.props.suffix) {
                                this.change(value + this.props.suffix)
                            } else {
                                this.change(value)
                            }
                        }
                    }}
                />
                {this.props.suffix
                    ?
                    <div className="suffix custom-bg-light custom-c-light custom-border-color">
                        {this.props.suffix}
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

export default RestrictedTextField;