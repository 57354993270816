import QRCode from "qrcode";

class Qrcode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }
    componentDidMount() {
        this.createBarcode(this.props.children);
    }
    componentDidUpdate(prevProps) {
        if (this.props.children !== prevProps.children)
            this.createBarcode(this.props.children);
    }
    createBarcode(code) {
        QRCode.toDataURL(code, { margin: 1 }, (error, data) => {
            if (error) {
                logger.error("Error creating QR code.");
                console.error("Error creating QR code: ", error);
            } else {
                this.setState({ data });
            }
        });
    }
    makeTwoDigit(val) {
        return (val + "").length === 1 ? ("0" + val) : (val + "");
    }
    print() {
        // Target the iframe's body.
        let body = this.refs.iframe.contentWindow.document.querySelector("body");
        // Put in content, and trigger print.
        body.style = "margin: 0px;";
        body.innerHTML = "";
        let content = this.refs.content.cloneNode(true);
        body.appendChild(content);
        this.refs.iframe.contentWindow.print();
    }
    render() {
        let d = new Date();
        return (
            <div className="barcode-generator qr-code custom-border-3">
                <img src={this.state.data} />
                <div className="controls">
                    <button
                        onClick={this.print.bind(this)}
                        className="print custom-bg-dark custom-c-dark"
                    >Print</button>
                </div>
                {/* Hidden printable iframe */}
                <div style={{ display: "none" }}>
                    {/* content */}
                    <div
                        ref="content"
                        style={{
                            fontFamily: "monospace"
                        }}
                    >
                        <div style={{
                            height: "23mm",
                            padding: "0.5mm",
                            width: "38mm",
                            borderRight: "1px dotted black",
                            borderBottom: "1px dotted black"
                        }}>
                            <img
                                src={this.state.data}
                                style={{
                                    marginLeft: "2mm",
                                    marginTop: "2mm",
                                    height: "20mm",
                                    width: "20mm"
                                }}
                            />
                        </div>
                        <div style={{
                            height: "23mm",
                            padding: "1mm",
                            width: "37mm",
                            borderRight: "1px dotted black",
                        }}>
                            {this.props.label || "NO LABEL"}
                            <br />
                            {d.getFullYear()} / {this.makeTwoDigit(d.getMonth() + 1)} / {this.makeTwoDigit(d.getDate())}
                            <br />
                            {this.makeTwoDigit(d.getHours())}:{this.makeTwoDigit(d.getMinutes())}
                        </div>
                    </div>
                    {/* Iframe for print triggering. */}
                    <iframe ref="iframe" />
                </div>
            </div>
        )
    }
}

export default Qrcode;