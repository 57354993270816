import Field from "./field";
import FlipMove from "react-flip-move";

class VehicleSizeClassField extends Field {
    constructor(props) {
        super(props);
        this.state.open = false;
        this.settings = {
            type: "vehicleSizeClass"
        }
		this.options = [
			{
				val: "1-REM",
				display: "1-REM"
			},
			{
				val: "2-REM",
				display: "2-REM"
			},
			{
				val: "3.5t",
				display: "3.5t"
			},
			{
				val: "7.5t",
				display: "7.5t"
			},
			{
				val: "13t",
				display: "13t"
			},
			{
				val: "18t",
				display: "18t"
			},
			{
				val: "1-man-run",
				display: "1-man-run"
			},
			{
				val: "2-man-run",
				display: "2-man-run"
			},
			{
				val: "site-manager",
				display: "site-manager"
			},
			{
				val: "multi-drop",
				display: "multi-drop"
			},
			{
				val: "ORBITAL",
				display: "ORBITAL"
			}
		]
    }

    renderField() {
        return (
            <FlipMove
                enterAnimation="fade"
                leaveAnimation="fade"
                duration={200}    
            >
                {this.props.children 
                    ?
                    this.props.children.split(",").map((size, index) => {
                        return (
                            <div
                                key={size + index}
                                className="size-class custom-bg-light custom-border-color"
                            >
                                {size}
                                <span
                                    className="remove"
                                    onClick={e => {
                                        let value = this.props.children.split(",");
                                        value.splice(index, 1);
                                        value = value.join(",");
                                        this.change(value);
                                    }}
                                >
                                    x
                                </span>
                            </div>
                        )
                    })
                    :
                    null
                }
                <div className="field-wrapper">
                    <div
                        className="head custom-border-3"
                        onClick={() => {
                            this.setState({ open: !this.state.open })
                        }}
                    >
                        <span>ADD</span>
                        <div className="icon chevron-icon"></div>
                    </div>
                    <div
                        className={"options custom-bg-dark"+(this.state.open ? " open custom-border-3" : "")}
                    >
                        {this.options.map(option => {
                            return (
                                <div
                                    key={option.val}
                                    className={"option custom-c-light hover-class" + (this.props.children === option.val ? " custom-bg-light" : "")}
                                    onClick={() => {
                                        // console.log(`Adding ${option.val}`)
                                        let value = this.props.children ? this.props.children.split(",") : [];
                                        value.push(option.val);
                                        value = value.join(",");
                                        this.change(value);
                                        this.setState({ open: false })
                                    }}
                                >
                                    {option.display}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </FlipMove>
        )
    }
}

export default VehicleSizeClassField