import View from "../view";
import MappingList from "./mappingsList";
import { Aux, renderDate } from "../../helpers";
import TextField from "../../fields/textField";

class MappingView extends View {
    constructor(props) {
        super(props);
        this.pageSize = 20;
        this.state = {
            mappings: [],
            page: 1,
            pageCount: 1,
            filters: {
                result: "",
                value: ""
            }
        };
        this.client = this.props.user.client.charAt(0).toUpperCase() + this.props.user.client.slice(1);

        this.columns = [
            {
                name: "Result",
                renderer: item => item.result,
                sorter: item => item.result
            },
            {
                name: "Type",
                renderer: item => item.type,
                sorter: item => item.type
            },
            {
                name: "Value",
                renderer: item => item.value,
                sorter: item => item.value
            }
        ]
    }

    filterChange(vn, val) {
        let filters = Object.assign({}, this.state.filters, { [vn]: val });
        this.setState({ filters });
    }

    filterMappings(items) {
        let mappings = items;
        if (this.state.filters.result) {
            mappings = mappings.filter(m => m.result.toLowerCase().includes(this.state.filters.result.toLocaleLowerCase()));
        }
        if (this.state.filters.value) {
            mappings = mappings.filter(m => m.value.toLowerCase().includes(this.state.filters.value.toLocaleLowerCase()));
        }
        return mappings
    }

    renderView() {
        return (
            <Aux>
                <div className="row heading-row">
                    <div className="col-12">
                        <h1 className="client-view custom-c-dark">{`${this.client} Mappings`}</h1>
                        <h5 className="date custom-c-mid">{renderDate()}</h5>
                    </div>
                </div>
                <div className="row view mappingView splitPanelView">
                    <div className="col-12 col-xl-3 ui-panel-split ui-panel-split-left custom-bg-light custom-c-light shadow-1 filters">
                        <h2 className="panel-name custom-border-color-2">Filter by</h2>
                        <div className="filters">
                            <TextField
                                varname="result"
                                label="Result"
                                change={(vn, val, err) => {
                                    this.filterChange(vn, val);
                                }}
                            >
                                {this.state.filters.result || ""}
                            </TextField>
                            <TextField
                                varname="value"
                                label="Value"
                                change={(vn, val, err) => {
                                    this.filterChange(vn, val);
                                }}
                            >
                                {this.state.filters.value || ""}
                            </TextField>
                        </div>
                    </div>
                    <div className="col-12 col-xl-9 users ui-panel-split ui-panel-split-right custom-bg-dark custom-c-light shadow-1">
                        <div className="icon mapping-icon title-icon"></div>
                        <h2 className="panel-name">Mappings</h2>
                        <MappingList
                            numberOfPages={this.state.pageCount}
                            page={this.state.page}
                            icon="mapping"
                            user={this.props.user}
                            uniqueField="id"
                            columns={this.columns}
                            mobileColumns={this.columns}
                            items={this.filterMappings(this.context.mappings)}
                            onPageChange={page => {
                                this.setState({ page });
                            }}
                            onNew={(item, cb) => {
                                $.post(`${this.context.apiRoot}/mappings`, JSON.stringify(item)).done(res => {
                                    this.context.setMappings([...this.context.mappings, res.mapping]);
                                    logger.success(res.msg);
                                    if (cb) cb();
                                }).catch(err => {
                                    logger.error(err)
                                })
                            }}
                            onUpdate={(item, id, cb) => {
                                $.ajax({
                                    type: "PUT",
                                    url: `${this.context.apiRoot}/mappings/${id}`,
                                    data: JSON.stringify(item)
                                }).done(res => {
                                    let mappings = [...this.context.mappings].map(m => {
                                        if (m.id === id) return res.mapping;
                                        return m;
                                    })
                                    this.context.setMappings(mappings);
                                    logger.success(res.msg);
                                    if (cb) cb();
                                }).catch(err => {
                                    logger.error(err)
                                })
                            }}
                            onDelete={id => {
                                $.ajax({
                                    type: "DELETE",
                                    url: `${this.context.apiRoot}/mappings/${id}`
                                }).done(res => {
                                    this.context.setMappings([...this.context.mappings].filter(m => m.id !== id));
                                    logger.success(res.msg);
                                }).catch(err => {
                                    logger.error(err)
                                })
                            }}
                        />
                    </div>
                </div>
            </Aux>
        )
    }

}

export default MappingView;