import DataField from "./dataField";

class CustomerField extends DataField {

}

CustomerField.defaultProps = {
    label: "Customer",
    varname: "customer",
    defaults: [],
    optionRenderer: customer => (
        <div className="option-inner">
            <b>{customer.displayName}</b>
            <br />{customer.name}
        </div>
    ),
    route: "customers",
    dataName: "name",
    searchBy: "term"
}

export default CustomerField;