import Stage from "./stage";
// import FlipMove from "react-flip-move";

class Action extends Stage {

    constructor(props) {
        super(props);
        this.state.working = true;
    }

    renderStage() {
        return (
            <div className="actionStage">
                {this.state.working
                    ?
                    <img src="/static/media/loading.gif" alt="loading..." />
                    :
                    <button onClick={e => {
                        this.props.exit();
                    }} >Process failed. Go back.</button>
                }
            </div>
        )
    }

    componentDidMount() {
        // Callback for finishing.
        const finish = data => {
            this.stageComplete(data);
        }
        const error = this.error.bind(this);
        // Callback for completing.
        const changeStage = newStage => {
            this.changeStage(newStage);
        }
        // Callback to end process.
        const exit = () => {
            this.exit();
        }

        this.config().action({ finish, changeStage, exit, error });
    }
}

Action.defaultProps = {
    title: "Action.",
}

export default Action;