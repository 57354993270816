function rid(length) {
    length = length || 20;
    let id = [];
    let charset = "abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = 0; i < length; i++) {
        id.push(charset[Math.round(Math.random() * (charset.length - 1))]);
    }
    return id.join("");    
}

function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
}

function errorResponse(error) {
	let message;
	if (error.response) {
		message = error.response.data;
		if (message instanceof Array) {
			message = message.map(m => m.messages.map(m2 => m2.message).join(" ")).join(" ");
		}
	} else if (error.request) {
		message = "No response from server."
	} else if (error.message) {
		message = error.message;
	} else {
		message = "Unknown error.";
	}
	return String(message);
}

module.exports =  { 
    rid,
    flatten,
    errorResponse
}