import Process from "./index";
// Stages.
import MultiStockSelect from "../stages/multiStockSelect";
import ListStage from "../stages/listStage";
import Confirm from "../stages/confirm";
import Action from "../stages/action";
import Success from "../stages/success";

class MergeStock extends Process {
    constructor(props) {
        super(props);
        this.state.stage = 0;
        this.stages = [
            {
                // 0 - Selecting first stock item
                component: MultiStockSelect
            },
            {
                // 1 - Checking how many matches
                component: Action,
                config: {
                    action: ({ changeStage }) => {
                        if (this.stageData(0).length > 1) {
                            changeStage(2)
                        } else {
                            changeStage(3)
                        }
                    }
                }
            },
            {
                // 2 - Selecting item from the list of matches
                component: ListStage,
                config: {
                    icon: "stock",
                    title: "Select Stock Item",
                    items: () => this.stageData(0),
                    renderItem: item => {
                        return (
                            <div className="content">
                                <div><b>Customer:</b> {item.customer}</div>
                                {item.productCode && <div><b>Product code:</b> {item.productCode}</div>}
                                {item.code && <div><b>Code:</b> {item.code}</div>}
                                {item.productBarcode && <div><b>Product Barcode:</b> {item.productBarcode}</div>}
                                <div><b>Location:</b> {item.location}</div>
                                <div><b>Quantity:</b> {item.quantity}</div>
                            </div>
                        );
                    }
                }
            },
            {
                // 3 - Standardise data from previous stages into one object
                component: Action,
                config: {
                    action: ({ finish }) => {
                        if (this.stageData(0).length > 1) {
                            finish(this.stageData(2))
                        } else {
                            finish(this.stageData(0)[0])
                        }
                    }
                }
            },
            {
                // 4 - Selecting second stock item
                component: MultiStockSelect
            },
            {
                // 5 - Checking how many matches
                component: Action,
                config: {
                    action: ({ changeStage }) => {
                        if (this.stageData(4).length > 1) {
                            changeStage(6)
                        } else {
                            changeStage(7)
                        }
                    }
                }
            },
            {
                // 6 - Selecting item from the list of matches excluding the one already selected in stage 3
                component: ListStage,
                config: {
                    icon: "stock",
                    title: "Select Stock Item",
                    items: () => this.stageData(4).filter(s => s.id !== this.stageData(3).id),
                    renderItem: item => {
                        return (
                            <div className="content">
                                <div><b>Customer:</b> {item.customer}</div>
                                {item.productCode && <div><b>Product code:</b> {item.productCode}</div>}
                                {item.code && <div><b>Code:</b> {item.code}</div>}
                                {item.productBarcode && <div><b>Product Barcode:</b> {item.productBarcode}</div>}
                                <div><b>Location:</b> {item.location}</div>
                                <div><b>Quantity:</b> {item.quantity}</div>
                            </div>
                        );
                    }
                }
            },
            {
                // 7 - Standardise data from previous stages into one object
                component: Action,
                config: {
                    action: ({ finish }) => {
                        if (this.stageData(4).length > 1) {
                            finish(this.stageData(6))
                        } else {
                            finish(this.stageData(4)[0])
                        }
                    }
                }
            },
            {
                // 8 - Confirm all changes
                component: Confirm,
                config: {
                    icon: "stock",
                    message: () => <div className="summary custom-c-light">
                        <p>You are about to merge <span className="custom-c-dark">{this.stageData(3).quantity}</span> items from <span className="custom-c-dark">{this.stageData(3).location}</span> with <span className="custom-c-dark">{this.stageData(7).quantity}</span> items in <span className="custom-c-dark">{this.stageData(7).location}</span></p>
                    </div>
                }
            },
            {
                // 9 - API call to merge stock
                component: Action,
                config: {
                    action: ({ finish, changeStage }) => {
                        $.ajax({
                            type: "POST",
                            url: `${this.context.apiRoot}/stock/merge/${this.stageData(3).id}/${this.stageData(7).id}`     
                        }).done(res => {
                            finish()
                        }).catch(err => {
                            logger.error(err);
                            changeStage(8)
                        })
                    }
                }
            },
            {
                // 10 - Showing success message
                component: Success
            }
        ]
    }
}

export default MergeStock;
