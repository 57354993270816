import React from "react";

class ScanInput extends React.Component {
    constructor(props) {
        super(props);
        this.settings = {
            type: "text"
        }
        this.state = {
            inputValue: ""
        }
    }

    componentDidMount() {
        if (this.props.focusOnMount) this.refs.input.focus();
    }
    render() {
        return (
            <div className="scanInput selector">
                <input
                    autoFocus={true}
                    className="custom-border-3"
                    ref="input"
                    value={this.state.inputValue}
                    onChange={event => {
                        let newValue = event.target.value;
                        let changedCharCount = newValue.length - this.state.inputValue.length;
                        this.setState({ inputValue: newValue }, () => {
                            // If more than one, assume it's from a scanner.
                            if (changedCharCount > 3 && this.props.onScan)
                                this.props.onScan(newValue);
                        });
                    }}
                    onKeyDown={event => {
                        if (event.keyCode === 13 && this.state.inputValue && this.props.onScan)
                            this.props.onScan(event.target.value);
                    }}
                />
                <div
                    className="go-btn custom-bg-feature1"
                    onClick={e => {
                        if (this.state.inputValue && this.props.onScan)
                            this.props.onScan(this.state.inputValue);
                    }}
                >
                    <div className="icon-white tick" />
                </div>
            </div>
        )
    }
}

export default ScanInput;