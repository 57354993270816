import Field from "./field";
import SelectField from "./selectField";
import StockDataField from "./stockDataField";

class StockSearchField extends Field {
    constructor(props) {
        super(props);
        this.settings = {
            type: "stockSearch"
        }
        this.state.searchBy = this.props.defaultSearch || "";
    }

    renderField() {
        return (
            <div className="field-wrapper">
                <SelectField
                    varname="searchBy"
                    name="Search By"
                    options={[
                        {
                            val: "group",
                            display: "Group"
                        },
                        {
                            val: "productCode",
                            display: "Product code"
                        },
                        {
                            val: "location",
                            display: "Location"
                        },
                        {
                            val: "description",
                            display: "Description"
                        },
                        {
                            val: "code",
                            display: "Code"
                        },
                        {
                            val: "productBarcode",
                            display: "Product Barcode"
                        }
                    ]}
                    change={(varname, val, err) => {
                        this.setState({ searchBy: val })
                    }}
                >
                    {this.state.searchBy}
                </SelectField>
                <StockDataField
                    queryDefaults={this.props.queryDefaults}
                    customer={this.props.customer}
                    dataName="code"
                    searchBy={this.state.searchBy}
                    route="stock"
                    filter={this.props.filter}
                    defaults={[]}
                    multiAdd={arr => {
                        this.props.submit({ id: arr });
                    }}
                    change={(varname, val, err) => {
                        this.change(val);
                    }}
                    optionRenderer={stock => (
                        <div className="option-inner">
                            <div>
                                <b>{stock.productCode ? `Product code: ${stock.productCode}` : `Code: ${stock.code}`}  , {`quantity: ${stock.quantity}`}</b>
                            </div>
                            <div>
                                <b>{`Customer: ${stock.customer}`}</b>
                            </div>
                            <div>
                                Customer: {stock.customer}
                            </div>
                            <div>
                                Location: {stock.location}
                            </div>
                            <div>
                                {stock.description}
                            </div>
                        </div>
                    )
                    }
                >
                    {this.props.children}
                </StockDataField>
            </div>
        )
    }
}

export default StockSearchField