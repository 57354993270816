/**
 *  STATE:
 * 
 *      item: OBJECT {description, code, id} for edditing existing stock
 *      or { } (empty object) for creating a new one
 * 
 * 
 *  PROPS:
 * 
 *      item: OBJECT (for edditing existing one) or NULL (for creating a new one)
 *      itemCreated: FUNCTION(item).
 *      closeCreator: FUNCTION.
 *      allCreatorFields: BOOLEAN (false if creating s stock item for a delivery)
 */

import ItemCreator from "../../assets/ui-super-list/itemCreator";
import TextareaField from "../../fields/textareaField";
import TextField from "../../fields/textField";
import NumberField from "../../fields/numberField";
import LocationSearchField from "../../fields/locationSearchField";
import CodeField from "../../fields/codeField";
import Barcode from '../../assets/barcode';
import DataField from '../../fields/dataField';
import ItemLinker from "../../assets/itemLinker";
import RestrictedTextField from "../../fields/restrictedTextField";
import DateField from "../../fields/dateField";

class StockCreator extends ItemCreator {
    constructor(props) {
        super(props)
        this.state.item.quantity = this.props.item ? this.props.item.quantity : 1;
        this.state.showHistory = false;
    }

    canSubmit() {
        if (this.props.item) {
            //Updating existing one
            let canSubmit = false;
            Object.keys(this.state.item).forEach(key => {
                if (this.props.item[key] !== this.state.item[key]) {
                    //if change was made
                    canSubmit = true
                }
            })
            if (canSubmit) return true
        }
        else {
            //Creating a new one
            if (this.props.allCreatorFields) {
                //if location field was created
                if (this.state.item.quantity && this.state.item.code && this.state.item.location && this.state.item.customer) {
                    return true
                }
            } else {
                if (this.state.item.quantity && this.state.item.code) {
                    return true
                }
            }
        }
        //Return false by default
        return false
    }

    formatDate(ms) {
        let date = new Date(ms);
        return {
            date: `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}/${(date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)}/${date.getFullYear()}`,
            time: `${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`
        }
    }

    renderStockHistory() {
        let history = this.props.item.history;
        // console.log(history);
        return (
            <div className="stockHistory">
                {history.map((item, index) => {
                    return (
                        <div className="historyEntry custom-border-color custom-c-dark" key={index}>
                            <div className="inner">
                                <div className="heading custom-border-color">
                                    <div className="date">{this.formatDate(item.date).date}</div>
                                    <div className="hours">{this.formatDate(item.date).time}</div>
                                    <div className="type">{item.type}</div>
                                </div>
                                {item.detail
                                    ?
                                    <div className="detail">
                                        <span>details:</span>
                                        {item.detail}
                                    </div>
                                    :
                                    null
                                }
                                {item.ref
                                    ?
                                    <div className="ref">
                                        <span>ref:</span>
                                        {item.ref}
                                    </div>
                                    :
                                    null
                                }
                                <div className="author">
                                    <span>by:</span>
                                    {item.user}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    displayOnly() {
        return (this.props.displayOnly || (this.props.user.role === 4 && this.props.item))
    }

    

    renderCreator() {
        let displayOnly = this.displayOnly();
        return (
            <div className="creator">
                {this.props.item && this.props.item.id ? <div style={{
                    fontSize: "0.8rem",
                    top: "-5px"
                }}>
                    {this.props.item.id}
                </div> : null}
                {(this.props.item && this.props.itemUnlocked && this.props.item.lockedBy && !displayOnly && this.props.user.role <= 1) && <div style={{ marginBottom: 15 }}>
                    {this.props.item.lockedBy.includes("-WOR-")
                        ?
                        <a style={{ marginBottom: "8px", display: "block" }}href={`/workorders/${this.props.item.lockedBy}`}>{`Item locked by ${this.props.item.lockedBy}`}</a>
                        :
                        <label className="custom-c-dark">{`Item locked by ${this.props.item.lockedBy}`}</label>
                    }
                    <button 
                        className="button custom-bg-feature2" style={{ display: "block", width: "100%" }}
                        onClick={() => {
                            if (window.confirm("Are you sure you want to unlock this item? This can cause unexpected issues if you don't know what you're doing."))
                                this.props.itemUnlocked(this.props.item.id);
                        }}
                    >
                        Unlock item
                    </button>
                </div>}
                <div className="field-combiner">
                    {!displayOnly
                        ?
                        <CodeField
                            varname="code"
                            label="Code"
                            change={this.itemChanged.bind(this)}
                        >
                            {this.state.item.code || ""}
                        </CodeField>
                        :
                        <div className="display-only split first custom-c-dark">
                            <label>Code</label>
                            <p>{this.state.item.code || "none"}</p>
                        </div>
                    }
                    {!displayOnly
                        ?
                        <NumberField
                            varname="quantity"
                            label="Quantity"
                            change={this.itemChanged.bind(this)}
                            min={1}
                        >
                            {this.state.item.quantity || 1}
                        </NumberField>
                        :
                        <div className="display-only split custom-c-dark">
                            <label>Quantity</label>
                            <p>{this.state.item.quantity || 1}</p>
                        </div>
                    }
                </div>
                {this.props.allCreatorFields
                    ?
                    !displayOnly
                        ?
                        <LocationSearchField
                            label="Location"
                            varname="location"
                            change={(vn, location, err) => {
                                this.itemChanged("location", location ? location.locator : null, null);
                            }}
                        >
                            {this.state.item.location || ""}
                        </LocationSearchField>
                        :
                        <div className="display-only custom-c-dark">
                            <label>Location</label>
                            <p>{this.state.item.location || "none"}</p>
                        </div>
                    :
                    null
                }
                {this.props.allCreatorFields
                    ?
                    !displayOnly
                        ?
                        <DataField
                            varname="customer"
                            defaults={[
                                {
                                    name: "internal",
                                    displayName: "Internal User"
                                }
                            ]}
                            optionRenderer={customer => (
                                <div className="option-inner">
                                    <b>{customer.displayName}</b> <br />({customer.name})
                                </div>
                            )}
                            label="Customer"
                            route="customers"
                            dataName="name"
                            searchBy="term"
                            change={(varname, value, error) => {
                                this.itemChanged("customer", value ? value.name : null, null);
                            }}
                        >
                            {this.state.item.customer || ""}
                        </DataField>
                        :
                        <div className="display-only custom-c-dark">
                            <label>Customer</label>
                            <p>{this.state.item.customer || "none"}</p>
                        </div>
                    :
                    null
                }
                {/* Supplier editing */}
                {!displayOnly
                    ?
                    <TextField
                        varname="supplier"
                        label="Supplier"
                        change={(vn, value, err) => {
                            this.itemChanged("supplier", value || null);
                        }}
                    >
                        {this.state.item.supplier || ""}
                    </TextField>
                    :
                    <div className="display-only custom-c-dark">
                        <label>Supplier</label>
                        <p>{this.state.item.supplier || "No supplier"}</p>
                    </div>
                }
                {/* Group editing */}
                {!displayOnly ?
                    <RestrictedTextField
						regex={/^[a-z\d\_\.]*$/}
                        varname="group"
                        label="Group"
                        change={(vn, value, err) => {
                            this.itemChanged("group", value || null);
                        }}
                    >{this.state.item.group || ""}</RestrictedTextField>
                    :
                    <div className="display-only custom-c-dark">
                        <label>Group</label>
                        <p>{this.state.item.group || "No group"}</p>
                    </div>
                }
                {!displayOnly
                    ?
                    <TextField
                        varname="productCode"
                        label="Product code"
                        change={(vn, value, err) => {
                            this.itemChanged("productCode", value || null);
                        }}
                    >
                        {this.state.item.productCode || ""}
                    </TextField>
                    :
                    <div className="display-only custom-c-dark">
                        <label>Product Code</label>
                        <p>{this.state.item.productCode || "none"}</p>
                    </div>
                }
                {!displayOnly
                    ?
                    <TextField
                        varname="productBarcode"
                        label="Product barcode"
                        change={(vn, value, err) => {
                            this.itemChanged("productBarcode", value || null);
                        }}
                    >
                        {this.state.item.productBarcode || ""}
                    </TextField>
                    :
                    <div className="display-only custom-c-dark">
                        <label>Product Barcode</label>
                        <p>{this.state.item.productBarcode || "none"}</p>
                    </div>
                }
                {/* Date in and out */}
                {!this.props.hideInOut
                    ?
                    <React.Fragment>
                        {this.props.user.role < 2
                            ?
                            <DateField
                                varname="in"
                                label="Date in"
                                change={this.itemChanged.bind(this)}
                            >
                                {this.state.item.in}
                            </DateField>
                            :
                            <div className="display-only split first custom-c-dark">
                                <label>Date In</label>
                                <p>{this.state.item.in ? renderDate(this.state.item.in) : "-"}</p>
                            </div>
                        }
                        {this.props.user.role < 2
                            ?
                            <DateField
                                varname="out"
                                label="Date out"
                                change={this.itemChanged.bind(this)}
                            >
                                {this.state.item.out}
                            </DateField>
                            :
                            <div className="display-only split first custom-c-dark">
                                <label>Date Out</label>
                                <p>{this.state.item.in ? renderDate(this.state.item.out) : "-"}</p>
                            </div>
                        }
                    </React.Fragment>
                    :
                    null
                }
                {/* Linking of items */}
                {this.props.allCreatorFields && this.props.item
                    ?
                    !displayOnly
                        ?
                        <div className="linker-wrapper">
                            <label className="custom-c-dark">Link items</label>
                            <ItemLinker
                                apiRoot={this.props.apiRoot}
                                router={this.props.router}
                                itemType="stock"
                                itemLinks={this.props.item.links || []}
                                itemId={this.props.item.id}
                            />
                        </div>
                        :
                        <div className="display-only custom-c-dark">
                            <label>Link items</label>
                            {this.props.item.links && this.props.item.links.length
                                ?
                                this.props.item.links.map(link => {
                                    return (
                                        <div
                                            key={link.id}
                                            className="link"
                                        >
                                            <div
                                                className={link.type === "report" ? "inner custom-bg-light list-hover" : "inner custom-bg-light"}
                                                onClick={() => {
                                                    if (link.type === "report") {
                                                        this.props.router.history.push(`/reports/${link.id}`)
                                                    }
                                                }}
                                            >
                                                <div className="icon-wrap custom-bg-feature1">
                                                    <div className={`icon-white ${link.type}`}></div>
                                                </div>
                                                <div className="text">
                                                    <p>{link.id}</p>
                                                    <p>{link.type}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <p>none</p>
                            }
                        </div>
                    :
                    null
                }
                {!displayOnly
                    ?
                    <TextareaField
                        varname="description"
                        label="Description"
                        change={(vn, value, err) => {
                            this.itemChanged("description", value || null);
                        }}
                    >
                        {this.state.item.description || ""}
                    </TextareaField>
                    :
                    <div className="display-only custom-c-dark">
                        <label>Description</label>
                        <p>{this.state.item.description || "none"}</p>
                    </div>
                }
                {this.props.item && this.props.item.code
                    ?
                    <Barcode
                        moreInfo={<div>
                            <div>
                                CUST: {this.props.item.customer} | PC: {this.props.item.productCode || "NO CODE"}
                            </div>
                            {this.props.item.in ? <div>
                                IN: {new Date(this.props.item.in).toUTCString()}
                            </div> : null}
                            <hr />
                            <div>{this.props.item.description}</div>
                        </div>}
                    >{this.props.item.code}</Barcode>
                    :
                    null
                }
                {this.props.item && this.props.item.history
                    ?
                    <div
                        className="button toggle-stock-history custom-bg-feature2"
                        onClick={() => {
                            let showHistory = !this.state.showHistory;
                            this.setState({ showHistory })
                        }}
                    >
                        {!this.state.showHistory ? "Show History" : "Hide History"}
                    </div>
                    :
                    null
                }
                {this.state.showHistory
                    ?
                    this.renderStockHistory()
                    :
                    null
                }
            </div>
        )
    }

    render() {
        return (
            <div className="item-creator custom-border-color">
                <div
                    className="icon close-icon"
                    onClick={() => {
                        this.props.closeCreator()
                    }}
                >
                </div>
                {this.renderCreator()}
                {!this.displayOnly()
                    ?
                    <hr />
                    :
                    null
                }
                {!this.displayOnly()
                    ?
                    <div
                        className={"button create-item-button custom-bg-feature2" + (this.canSubmit() ? "" : " disabled")}
                        onClick={() => {
                            if (this.canSubmit()) {
                                //Sending the whole item or just the changes(creating/editing)
                                let item = this.props.item ? this.state.changes : this.state.item;
                                this.submit(item)
                            }
                        }}
                    >
                        Save
                    </div>
                    :
                    null
                }
            </div>
        )
    }

}

function renderDate(ms) {
    function twoDigit(n) {
        let nstr = String(n);
        return nstr.length !== 2 ? "0" + nstr : nstr;
    }
    if (!ms) return "";
    let d = new Date(ms);
    if (d.toString() === "Invalid Date") return "";
    // return `${String(d.getFullYear()).substring(2)}/${d.getMonth()+1}/${d.getDate()}`
    return `${twoDigit(d.getDate())}/${twoDigit(d.getMonth() + 1)}/${String(d.getFullYear()).substring(2)}`
}

export default StockCreator;